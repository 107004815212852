import { Box, IconButton, IconButtonProps, Tooltip, TooltipProps } from '@mui/material';
import { MouseEventHandler, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSingleSourceStore } from '../../store/single-source-store/single-source-store';
import { getRoutePathFromParams, RoutePaths } from '../../config/route-paths/route-paths';
import { Icon } from '../../icons/icon';
import { useActiveWorkspace } from '../../hooks/use-active-workspace';
import { useActiveOrganization } from '../../hooks/use-active-organization';

type ShowDetailsButtonProps = Omit<IconButtonProps, 'id' | 'type'> &
  Pick<TooltipProps, 'placement'> & {
    id: string;
    type: string;
  };

export const ShowDetailsButton = (props: ShowDetailsButtonProps) => {
  const { id, type, placement, onClick: onClickProp, ...rest } = props;

  const { formatMessage: f } = useIntl();
  const navigate = useNavigate();
  const organization = useActiveOrganization();
  const workspace = useActiveWorkspace();
  const setConstructToPanTo = useSingleSourceStore.use.setConstructToPanTo();

  const onClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      setConstructToPanTo(id);
      navigate(
        getRoutePathFromParams(RoutePaths.Entity, {
          organizationName: organization?.name,
          workspaceName: workspace?.name,
          entityId: id,
        }),
      );
      onClickProp?.(event);
    },
    [id, navigate, onClickProp, organization?.name, setConstructToPanTo, workspace?.name],
  );

  return (
    <Tooltip title={f({ id: 'show-entity-details' }, { type })} disableInteractive placement={placement ?? 'right'}>
      <Box>
        <IconButton size="small" {...rest} onClick={onClick}>
          <Icon name="show-details" height={20} width={20} />
        </IconButton>
      </Box>
    </Tooltip>
  );
};
