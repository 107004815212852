import { useClickOutside } from '@mantine/hooks';
import { Box, Divider, MenuItem as MuiMenuItem, SxProps, Typography, useTheme } from '@mui/material';
import { Theme } from '@mui/system';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuOptionExpandIcon } from '../menu-option-expand-icon/menu-option-expand-icon';
import { MenuItemSubMenuItem, SubMenu } from './sub-menu/sub-menu';
import { useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { motion } from 'framer-motion';

export type MenuItem = {
  id: string;
  label: string;
  value: string;
  subMenu?: { list: MenuItemSubMenuItem[] };
  Icon?: any;
  to?: string;
  onClick?: () => void;
  hasSeparatorAbove?: boolean;
  isActive?: boolean;
};

export type MenuOptionProps = {
  option: MenuItem;
};

export const MenuOption = (props: MenuOptionProps) => {
  const { option } = props;
  const { Icon } = option;

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const theme = useTheme();
  const navigate = useNavigate();

  const hasSubMenu = Number(option?.subMenu?.list?.length) > 0;

  const onSubMenuToggle = useCallback(() => {
    setIsSubMenuOpen((prev) => !prev);
  }, []);

  const onOptionClick = useCallback(() => {
    if (option.to) {
      navigate(option.to);
      option.onClick?.();
    }
  }, [navigate, option]);

  const subMenuContainerRef = useClickOutside(() => setIsSubMenuOpen(false));

  const menuOptionRootSx = {
    display: { md: 'flex' },
    alignItems: { md: 'center' },
    borderBottom: { md: option.isActive ? ` 2px solid ${theme.palette.primary.main}` : '' },
  };

  const menuOptionItemRootSx: SxProps<Theme> = {
    height: '100%',
  };

  const menuOptionItemSx = {
    display: 'flex',
    px: { xs: 0, md: 2 },
    alignItems: 'center',
    pl: { xs: !hasSubMenu ? 0 : 2, md: 2 },
    height: { xs: hasSubMenu ? '65px' : '48px', md: '100%' },
    pt: hasSubMenu ? { xs: 2.75, md: 0 } : '',
    pb: hasSubMenu ? { xs: 2.25, md: 0 } : '',
    cursor: 'pointer',
    color: {
      xs: theme.palette.text.primary,
      md: option.isActive ? theme.palette.primary.main : theme.palette.text.primary,
    },
    '& span': { mt: { md: option.isActive ? '4px' : '2px' } },
    '.iconPath': { fill: theme.palette.action.active },
  };

  const menuOptionItemIconSx = {
    display: { xs: 'flex', md: 'none' },
    alignItems: 'center',
    justifyContent: 'center',
    mr: 4.375,
    ml: 2,
  };

  const subMenuRootSx: SxProps<Theme> = {
    display: 'flex',
    maxHeight: { md: 'calc(100vh - 64px)' },
    overflow: { md: 'auto' },
    border: { md: '1px solid #ccc' },
    borderRadius: { md: '4px' },
    flexDirection: 'column',
    pb: { xs: 0, md: 1 },
    pl: 0,
    pt: { md: 1 },
    top: { md: '100%' },
    backgroundColor: 'white',
    zIndex: { md: 10000 },
    position: { md: 'absolute' },
    boxShadow: { md: 4 },
    width: { md: 320 },
  };

  const dividerSx: SxProps<Theme> = {
    display: { xs: 'block', md: 'none' },
  };

  const { refs, context, floatingStyles } = useFloating({
    placement: 'bottom-start',
    open: isSubMenuOpen,
    onOpenChange: setIsSubMenuOpen,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  return (
    <Box ref={subMenuContainerRef} sx={menuOptionRootSx}>
      <Box data-testid={`menu-item-${option.id}`} onClick={onOptionClick} sx={menuOptionItemRootSx}>
        <MuiMenuItem sx={menuOptionItemSx} disableRipple ref={refs.setReference} {...getReferenceProps()}>
          {Icon ? (
            <Box sx={menuOptionItemIconSx}>
              <Icon style={{ color: theme.palette.action.active, width: '24px', height: '24px' }} />
            </Box>
          ) : null}
          <Typography
            sx={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
            variant="buttonMedium"
            noWrap
          >
            {option.label}
          </Typography>
          {hasSubMenu ? <MenuOptionExpandIcon isOpen={isSubMenuOpen} /> : null}
        </MuiMenuItem>
        {isSubMenuOpen ? (
          <Box sx={{ ...floatingStyles, zIndex: 10 }} ref={refs.setFloating} {...getFloatingProps()}>
            <motion.div
              style={{ originY: 'top', originX: 'left' }}
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.2, bounce: 0 }}
            >
              <Box sx={subMenuRootSx}>
                <SubMenu list={option?.subMenu?.list || []} onSubMenuToggle={onSubMenuToggle} />
              </Box>
            </motion.div>
          </Box>
        ) : null}
      </Box>
      {hasSubMenu ? <Divider sx={dividerSx} /> : null}
    </Box>
  );
};
