export const findPreviousEnabledItem = <T extends { disabled: boolean }>(items: T[], index: number) => {
  let i = index;
  do {
    i = (i - 1 + items.length) % items.length;
    if (i === index) return index;
  } while (items[i].disabled);
  return i;
};

export const findNextEnabledItem = <T extends { disabled: boolean }>(items: T[], index: number) => {
  let i = index;
  do {
    i = (i + 1) % items.length;
    if (i === index) return index;
  } while (items[i].disabled);
  return i;
};
