import './wdyr';

import { createRoot } from 'react-dom/client';
import { App } from './App';
import './sentry';
import './dayjs';
import { Application, ZustandStore } from '@xspecs/single-source-model';
import { singleSourceStore } from './store/single-source-store/single-source-store';
import { ApplicationProvider } from './wrappers/application-context/application-context';
// import { Action } from '@temp';
//
// console.log('singleSourceStore: ', Action);

const store = new ZustandStore(singleSourceStore);
const application = Application.getInstance(store);

const root = createRoot(document.getElementById('root'));
root.render(
  <ApplicationProvider application={application}>
    <App />
  </ApplicationProvider>,
);
