import { PlateElement, PlateElementProps } from '@udecode/plate-common';
import { forwardRef } from 'react';

export const ParagraphElement = forwardRef<React.ElementRef<typeof PlateElement>, PlateElementProps>(
  ({ className, children, ...props }: PlateElementProps, ref) => {
    return (
      <PlateElement ref={ref} {...props}>
        {children}
      </PlateElement>
    );
  },
);
ParagraphElement.displayName = 'ParagraphElement';
