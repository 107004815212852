import { ItemId, RenderItemParams } from '@atlaskit/tree';
import { Box } from '@mui/material';
import { MouseEvent, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { FileType } from '../../../gql/graphql';
import {
  TreeViewItemPrimitive,
  TreeViewItemPrimitiveProps,
} from '../../tree-view-item-primitive/tree-view-item-primitive';

export type TreeViewItemProps = {
  onClick: (item: RenderItemParams['item'], event?: MouseEvent<HTMLDivElement>) => void;
  onDelete?: (itemId: ItemId) => void | Promise<void>;
  activeItemId?: ItemId;
  readOnly?: boolean;
  hideAddButtonForNonFolders?: boolean;
  expandItem?: (itemId: ItemId) => void;
  shouldDisableItem?: (item: RenderItemParams['item']) => boolean;
  onShare?: (itemId: ItemId) => void;
  disabledFolderClick?: boolean;
  getEndComponent?: (item: RenderItemParams['item']) => ReactNode;
} & RenderItemParams &
  Pick<TreeViewItemPrimitiveProps, 'renderTail'>;

export const TreeViewItem = (props: TreeViewItemProps) => {
  const {
    item,
    onExpand,
    onCollapse,
    provided,
    depth,
    onClick,
    shouldDisableItem,
    disabledFolderClick,
    renderTail,
    activeItemId,
  } = props;

  const ref = useRef<HTMLDivElement>();

  const isActive = activeItemId === item.id || item.data.isSelected;

  const disabled = useMemo(() => shouldDisableItem?.(item), [item, shouldDisableItem]);

  const isFolderClickDisabled = disabledFolderClick && item.data.fileType === FileType.Folder;

  const onItemClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (!isFolderClickDisabled && !disabled) {
        onExpand(item.id);
        onClick(item, event);
      }
    },
    [disabled, isFolderClickDisabled, item, onClick, onExpand],
  );

  const onExpandClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (item.isExpanded) onCollapse(item.id);
      else onExpand(item.id);
    },
    [item.id, item.isExpanded, onCollapse, onExpand],
  );

  const rootSx = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      py: 0.125,
      pl: `0px !important`,
      ml: `${depth * 25}px`,
      backgroundColor: isActive && !disabled ? 'action.selected' : '',
      color: disabled ? 'action.disabled' : '',
      cursor: !disabled && 'pointer !important',
      transition: 'background-color 0.1s ease-in',
      ':hover': {
        backgroundColor: !disabled && 'action.hover',
      },
    }),
    [depth, disabled, isActive],
  );

  useEffect(() => {
    if (isActive) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isActive]);

  return (
    <Box
      data-testid={`tree-view-item-${item.id}`}
      ref={(node: HTMLDivElement) => {
        provided.innerRef(node);
        ref.current = node;
      }}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{ ...provided.draggableProps.style }}
      onClick={onItemClick}
      sx={rootSx}
    >
      <TreeViewItemPrimitive item={item} onToggleExpansionState={onExpandClick} renderTail={renderTail} />
    </Box>
  );
};
