import { isExpanded, isSelectionAtBlockEnd, isSelectionAtBlockStart, TEditor, Value } from '@udecode/plate-common';

export const exitBreakAtEdges = <V extends Value>(
  editor: TEditor<V>,
  {
    start,
    end,
  }: {
    start?: boolean;
    end?: boolean;
  },
) => {
  let queryEdge = false;
  let isEdge = false;
  let isStart = false;
  if (start || end) {
    queryEdge = true;

    if (start && isSelectionAtBlockStart(editor)) {
      isEdge = true;
      isStart = true;
    }

    if (end && isSelectionAtBlockEnd(editor)) {
      isEdge = true;
    }

    if (isEdge && isExpanded(editor.selection)) {
      editor.deleteFragment();
    }
  }

  return {
    queryEdge,
    isEdge,
    isStart,
  };
};
