import { Box, styled } from '@mui/material';
import { Attachment, AttachmentType } from '@xspecs/single-source-model';
import { AttachmentIcon } from '../attachment-icon/attachment-icon';
import { AssetAssigner } from '../asset-assginer/asset-assigner';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSingleSourceModel } from '../../../../../hooks/use-single-source-model';
import { NodeTypeEvent, useTrackEvents } from '../../../../../hooks/use-track-events';
import { AssetUploadField } from './asset-upload-field';
import { Overlay } from '../../constructs/overlay/overlay';
import { LinkAssetToAttachmentCommand } from 'domain/single-source-model/src/commands/attachments/LinkAssetToAttachmentCommand';

type AttachmentsItemProps = {
  attachmentId: string;
  data: any;
  selected: boolean;
  hasOverlay: boolean;
};

export const AttachmentItem = (props: AttachmentsItemProps) => {
  const { attachmentId, selected, data, hasOverlay } = props;

  const model = useSingleSourceModel();

  const [clickCount, setClickCount] = useState(0);

  const { trackNodeOperation } = useTrackEvents();

  const onChange = useCallback(
    (assetId: string) => {
      model.messageBus.send(LinkAssetToAttachmentCommand, { attachmentId, assetId });
      if (assetId.length > 0) {
        trackNodeOperation(NodeTypeEvent.AssetSelected, data.type);
      }
    },
    [model.messageBus, attachmentId, trackNodeOperation, data.type],
  );

  const containerRef = useRef(null);

  const handleMouseDownCapture = useCallback(() => {
    if (clickCount === 0 && selected) {
      setClickCount(2);
    } else {
      setClickCount(clickCount + 1);
    }
  }, [clickCount, selected, setClickCount]);

  useEffect(() => {
    const currentElement = containerRef.current;
    const handleBlur = (event: FocusEvent) => {
      setTimeout(() => {
        if (containerRef.current && containerRef.current.contains(event.relatedTarget)) {
          return;
        }

        const attachment = model.entities.get<Attachment>(attachmentId);
        if (!attachment) return;

        if (attachment.subType === AttachmentType.Upload) {
          return;
        }
        setTimeout(() => {
          if (attachment && attachment.id && clickCount > 0) {
            model.entities.clearNewState(attachment.id);
          }

          if (!attachment.asset) {
            model.entities.delete(attachment.id);
            trackNodeOperation(NodeTypeEvent.Deleted, data.type);
          }

          setClickCount(0);
        }, 0);
      }, 10);
    };

    if (currentElement) {
      currentElement.addEventListener('blur', handleBlur, true);
    }

    return () => {
      if (currentElement) {
        currentElement.removeEventListener('blur', handleBlur, true);
      }
    };
  }, [clickCount, model.entities, trackNodeOperation, data, attachmentId]);

  const boxShadow = data.isFloating ? '5px 5px 10px #c3c3c3' : 'none';

  useEffect(() => {
    if (data.isNew) {
      setClickCount(2);
    }
  }, [data.isNew]);

  useEffect(() => {
    if (data.isDragging) {
      setClickCount(1);
    }
  }, [data.isDragging]);

  const containerSx = useMemo(
    () => ({
      display: clickCount > 0 ? 'none' : 'block',
      position: 'absolute',
      height: '100%',
      width: '100%',
      zIndex: 1,
    }),
    [clickCount],
  );

  return (
    <Root clickCount={clickCount} ref={containerRef} tabIndex={0}>
      {hasOverlay ? <Overlay /> : null}
      <Content boxShadow={boxShadow}>
        <Box sx={containerSx} onMouseDownCapture={handleMouseDownCapture} />
        <div>
          {data.metadata?.favicon ? (
            <div style={favIconContainer}>
              <img alt="SiteLogo" src={data.metadata?.favicon} width={20} height={20} />
            </div>
          ) : (
            <AttachmentIcon type={data.subType} size={20} uploadType={data.uploadType} />
          )}
        </div>
        {data.type === AttachmentType.Upload ? (
          <AssetUploadField data={data} />
        ) : (
          <AssetAssigner attachmentId={attachmentId} onChange={onChange} data={data} />
        )}
      </Content>
    </Root>
  );
};

const Root = styled('div')<{ boxShadow?: string; clickCount: number }>(({ clickCount }) => ({
  position: 'relative',
  cursor: clickCount > 1 ? 'text' : 'grab',
  flex: 'none',
}));

const Content = styled('div')<{ boxShadow: string }>(({ theme, boxShadow }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '1px',
  border: `1px solid ${theme.palette.text.secondary}`,
  borderRadius: 4,
  zIndex: 0,
  backgroundColor: 'white',
  boxShadow: boxShadow,
}));

const favIconContainer = {
  marginLeft: 2,
  marginRight: 2,
  width: '22px',
  height: '22px',
};
