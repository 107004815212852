import { LabelOutlined } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

export const LabelsSelectorHeader = () => {
  const { formatMessage: f } = useIntl();

  return (
    <div>
      <Stack gap={1} direction="row">
        <LabelOutlined fontSize="medium" />
        <Typography variant="subtitle1">{f({ id: 'labels' })}</Typography>
      </Stack>
      <Typography variant="caption">{f({ id: 'choose-or-create-new-label' })}</Typography>
    </div>
  );
};
