import { EventBase } from './commands/framework/EventBase';
import { SingleSourceModel } from './SingleSourceModel';
import { EventHandlerBase } from './commands/framework/EventHandlerBase';
import { getTargetDxDyBetweenEntities } from './test-utilities/positioning-helpers';

// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
let testEnv: {
  model: SingleSourceModel;
  dispatch: (command: any, params: any) => void;
  getTargetDxDyBetweenEntities: (
    entityId1: string,
    entityId2: string,
    frameIndex?: number,
    laneIndex?: number,
  ) => {
    x: number;
    y: number;
  };
};

// TEST CODE EXECUTOR - PRESS CONTROL-OPTION-COMMAND-SHIFT-T TO RUN THE TEST CODE BELOW
// THIS IS USEFUL FOR SEEING WHAT THE TESTS ARE DOING
const testCode = () => {
  // PASTE TEST CODE HERE
};

const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined';
const location = isBrowser ? window.location : { origin: 'http://localhost', hostname: 'localhost' };

export class TestCodeEvent extends EventBase {
  static eventType = 'TestCodeEvent';
}

export class TestCodeHandler extends EventHandlerBase {
  constructor(model: SingleSourceModel) {
    super(model);
    testEnv = {
      model,
      dispatch: model.messageBus.send.bind(model.messageBus),
      getTargetDxDyBetweenEntities: (
        entityId1,
        entityId2,
        frameIndex,
        laneIndex,
      ): {
        x: number;
        y: number;
      } => {
        return getTargetDxDyBetweenEntities(model.entityRepository, entityId1, entityId2, frameIndex, laneIndex);
      },
    };
  }

  handles() {
    return [TestCodeEvent];
  }

  execute() {
    if (location.hostname !== 'localhost') return;
    window['adaptor'].reset();
    testCode();
  }
}
