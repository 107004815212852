import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

interface OpenEntityAnnotatorCommandParams extends IParams {
  entityId: string;
}

interface EntityAnnotatorOpenedEventParams extends IParams {
  entityId: string;
}

export class EntityAnnotatorOpenedEvent extends EventBase {
  static eventType = 'EntityAnnotatorOpenedEvent';

  constructor(
    public readonly params: EntityAnnotatorOpenedEventParams,
    public readonly source = OpenEntityAnnotatorCommand,
  ) {
    super();
  }
}

export class OpenEntityAnnotatorCommand extends CommandBase<OpenEntityAnnotatorCommandParams> {
  execute(params: OpenEntityAnnotatorCommandParams): EntityAnnotatorOpenedEvent | CommandError {
    const entity = this.model.entityRepository.get(params.entityId);
    if (!entity) return CommandError.of(new Error(`Entity with id ${params.entityId} not found`), 'error');
    this.model.annotator.openAnnotator();
    return new EntityAnnotatorOpenedEvent({ entityId: params.entityId });
  }
}
