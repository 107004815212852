import { useQuery, useReactiveVar } from '@apollo/client';
import { SUBSCRIPTION_PLANS_QUERY } from '../graphql/queries';
import { useMemo } from 'react';
import { activeOrganizationVar } from '../state/state';

export const usePlans = () => {
  const organizationId = useReactiveVar(activeOrganizationVar)?.id;

  const { data, loading } = useQuery(SUBSCRIPTION_PLANS_QUERY, { variables: { organizationId } });

  const currentPlan = useMemo(
    () => data?.subscriptionPlans.find((plan) => plan.isCurrentPlan),
    [data?.subscriptionPlans],
  );

  return {
    plans: data?.subscriptionPlans ?? [],
    currentPlan,
    loading,
  } as const;
};
