import { IClipboard } from './IClipboard';

export class TestClipboard implements IClipboard {
  private content: string = '';

  async copy(text: string): Promise<void> {
    this.content = text;
  }

  async read(): Promise<string> {
    return this.content;
  }

  dispose(): void {
    this.content = '';
  }
}
