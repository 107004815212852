import { Box, SxProps, Theme } from '@mui/material';
import { useMemo } from 'react';

type OverlayProps = {
  className?: string;
  sx?: SxProps<Theme>;
};

export const Overlay = (props: OverlayProps) => {
  const { className, sx } = props;

  const overlaySx: SxProps<Theme> = useMemo(
    () => ({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: OVERLAY_COLOR,
      zIndex: 999,
      ...sx,
    }),
    [sx],
  );

  return <Box className={className} sx={overlaySx} />;
};

export const OVERLAY_COLOR = 'rgba(255, 255, 255, .75)';
