import { ExpandLess } from '@mui/icons-material';
import { styled } from '@mui/material';
import { motion } from 'framer-motion';

type MenuOptionExpandIconProps = {
  isOpen: boolean;
};

export const MenuOptionExpandIcon = (props: MenuOptionExpandIconProps) => {
  const { isOpen } = props;

  return (
    <Root animate={{ transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)' }}>
      <ExpandLess data-testid="expand-less-icon" color="action" />
    </Root>
  );
};

const Root = styled(motion.span)(({ theme }) => ({
  marginLeft: 'auto',
  marginRight: theme.spacing(2.75),
  [theme.breakpoints.up('md')]: {
    marginLeft: 0,
    marginRight: 0,
  },
}));
