import { CommandBase, IParams } from './CommandBase';

export type EventConstructor<T extends EventBase> = new (...args: any[]) => T;
export type CommandConstructor<T extends CommandBase<IParams>> = new (...args: any[]) => T;

export abstract class EventBase {
  public readonly params: IParams;
  public readonly source: CommandConstructor<CommandBase<IParams>>;
  protected static readonly eventType: string;

  public get type(): string {
    return (this.constructor as typeof EventBase).type;
  }

  public static get type(): string {
    if (!this.eventType) throw new Error('Event type not defined');
    return this.eventType;
  }
}
