import { useReactFlow, useStore } from '@xyflow/react';
import { useCallback } from 'react';

export const useCanvasCenter = () => {
  const domNode = useStore((state) => state.domNode);
  const { screenToFlowPosition } = useReactFlow();

  return useCallback(() => {
    const boundingClientRect = domNode?.getBoundingClientRect();
    if (!boundingClientRect) return { x: 0, y: 0 };
    return screenToFlowPosition({
      x: boundingClientRect.x + boundingClientRect.width / 2,
      y: boundingClientRect.y + boundingClientRect.height / 2,
    });
  }, [domNode, screenToFlowPosition]);
};
