import { Typography } from '@mui/material';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { useMemo } from 'react';
import dompurify from 'dompurify';

const unescapedHtml = /[&<>"']/g;
const hasUnescapedHtml = RegExp(unescapedHtml.source);
const htmlEscapes = {
  '&': '&amp',
  '<': '&lt',
  '>': '&gt',
  '"': '&quot',
  "'": '&#39',
};

function escapeString(str: string) {
  return str && hasUnescapedHtml.test(str) ? str.replace(unescapedHtml, (character) => htmlEscapes[character]) : str;
}

function highlightMatch(text: string, originalContent: string) {
  let content = escapeString(originalContent);
  const index = content.indexOf(text);
  if (index >= 0) {
    content =
      content.substring(0, index) +
      '<mark>' +
      content.substring(index, index + text.length) +
      '</mark>' +
      content.substring(index + text.length);
    return content;
  }
  return originalContent;
}

export type TreeViewItemNameProps = {
  name: string;
  isHighlighted?: boolean;
};

export const TreeViewItemName = (props: TreeViewItemNameProps) => {
  const { name, isHighlighted } = props;

  const searchQuery = useSingleSourceStore.use.searchQuery();

  const highlightedName = useMemo(() => {
    if (isHighlighted) {
      return highlightMatch(searchQuery, name);
    }
    return name;
  }, [isHighlighted, name, searchQuery]);

  return (
    <Typography
      title={name || 'Untitled'}
      color="text.secondary"
      sx={{
        marginLeft: 0.5,
        padding: '0 12px 0 0',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      variant="body1"
      dangerouslySetInnerHTML={{
        __html: dompurify.sanitize(highlightedName),
      }}
    />
  );
};
