import { useCallback } from 'react';
import { OnMount } from '@monaco-editor/react';
import { MonacoBinding } from 'y-monaco';
import { PlaceholderContentWidget } from './placeholder-content-widget';
import { useApplication } from '../../wrappers/application-context/application-context';
import { singleSourceStore } from '../../store/single-source-store/single-source-store';
import { SpecFile } from '@xspecs/single-source-model';
import { useActiveOrganization } from '../../hooks/use-active-organization';

export const useMonaco = ({ id, placeholder }: { id: string; placeholder: string }) => {
  const organization = useActiveOrganization();
  const { application } = useApplication();

  const onMount = useCallback<OnMount>(
    (editor) => {
      const fileId = `${organization?.id}/${id}`;
      application.loadSpecCommand({ fileId, version: undefined });
      const file = singleSourceStore.getState().filesById[fileId].file as SpecFile;
      new PlaceholderContentWidget(placeholder, editor);
      const ytext = file.sharedType;
      new MonacoBinding(ytext, editor.getModel(), new Set([editor]), file.awareness);
    },
    [application, id, organization?.id, placeholder],
  );

  return {
    onMount,
  } as const;
};
