import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { Comment } from '../../entities/threads/Comment';
import { Thread } from '../../entities/threads/Thread';
import { CommandError } from '../../ErrorStore';

export interface AddCommentToThreadParams extends IParams {
  threadId: string;
  commentId: string;
  value: object;
  createdBy: string;
}

interface CommentAddedToThreadParams extends IParams {
  threadId: string;
  commentId: string;
  value: object;
  createdBy: string;
}

export class CommentAddedToThreadEvent extends EventBase {
  static eventType = 'CommentAddedToThreadEvent';

  constructor(public readonly params: CommentAddedToThreadParams, public readonly source = AddCommentToThreadCommand) {
    super();
  }
}

export class AddCommentToThreadCommand extends CommandBase<AddCommentToThreadParams> {
  execute(params: AddCommentToThreadParams): CommentAddedToThreadEvent | CommandError {
    const thread = this.model.entities.get<Thread>(params.threadId);
    const comment = new Comment(params.commentId, JSON.stringify(params.value), params.createdBy, thread, []);
    this.model.entities.addCommentToThread(params.threadId, comment);
    return new CommentAddedToThreadEvent({
      threadId: params.threadId,
      value: params.value,
      createdBy: params.createdBy,
      commentId: params.commentId,
    });
  }
}
