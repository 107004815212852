import { FieldDefinitionNode, NamedTypeNode, TypeNode } from 'graphql/language';
import { DocumentNode, Kind, parse, visit } from 'graphql';

export class GqlSchemaToFieldsTranslator {
  public static translate(schemaString: string): string[] {
    const fieldPaths: string[] = [];
    let ast: DocumentNode;
    try {
      ast = parse(schemaString);
    } catch (e) {
      return fieldPaths;
    }
    const getNamedType = (typeNode: TypeNode): NamedTypeNode => {
      if (typeNode.kind === Kind.NAMED_TYPE) {
        return typeNode;
      } else if (typeNode.kind === Kind.NON_NULL_TYPE || typeNode.kind === Kind.LIST_TYPE) {
        return getNamedType(typeNode.type);
      }
      throw new Error('Unexpected type node kind');
    };

    // recursively build paths down to leaf nodes and add to fieldPaths only when a leaf is reached.
    const buildPaths = (parentPath: string, fieldNode: FieldDefinitionNode, typeNode: TypeNode) => {
      const fieldName = fieldNode.name.value;
      const fullPath = parentPath ? `${parentPath}.${fieldName}` : fieldName;
      const namedType = getNamedType(typeNode);

      let hasNestedFields = false;
      visit(ast, {
        ObjectTypeDefinition(node) {
          if (node.name.value === namedType.name.value && node.fields && node.fields.length > 0) {
            hasNestedFields = true;
          }
        },
      });

      // if no nested fields, this is a leaf node; add the path
      if (!hasNestedFields) {
        fieldPaths.push(fullPath);
      } else {
        // otherwise, recursively build paths for nested fields
        visit(ast, {
          ObjectTypeDefinition(node) {
            if (node.name.value === namedType.name.value) {
              node.fields?.forEach((nestedField) => {
                buildPaths(fullPath, nestedField, nestedField.type);
              });
            }
          },
        });
      }
    };

    visit(ast, {
      ObjectTypeDefinition(node) {
        if (node.name.value === 'Query') {
          node.fields?.forEach((field) => {
            buildPaths('', field, field.type);
          });
        }
      },
    });

    return fieldPaths.sort();
  }
}
