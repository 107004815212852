import { Box, Typography, useTheme } from '@mui/material';
import { NavBar } from '../../components/nav-bar/nav-bar';
import { useIntl } from 'react-intl';
import { Icon } from '../../icons/icon';

export const NoAccess = () => {
  const { formatMessage: f } = useIntl();

  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'background.paper',
      }}
    >
      <NavBar isLoggedIn isPrimary hideLogo />
      <Box
        sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
      >
        <Icon name="narrative-filled" height={48} width={46} color={theme.palette.primary.main} />
        <Typography sx={{ mt: 4.5 }} variant="h6">
          {f({ id: 'no-access' })}
        </Typography>
        <Typography sx={{ mt: 2 }} variant="body1">
          {f({ id: 'contact-administrator' })}
        </Typography>
        <Typography variant="body1">{f({ id: 'or' }).toUpperCase()}</Typography>
        <Typography variant="body1" color="primary">
          {f({ id: 'contact-support' })}
        </Typography>
      </Box>
    </Box>
  );
};
