import { SxProps, TextField, Theme, Typography } from '@mui/material';
import { ChangeEvent } from 'react';

type LabelsListItemNameProps = {
  name: string;
  isBeingEdited: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const LabelsListItemName = (props: LabelsListItemNameProps) => {
  const { name, isBeingEdited, onChange } = props;

  if (isBeingEdited) {
    return <TextField autoFocus size="small" variant="standard" defaultValue={name} onChange={onChange} />;
  }

  return (
    <Typography title={name} variant="body1" flexGrow={1} sx={overflowSx}>
      {name}
    </Typography>
  );
};

const overflowSx: SxProps<Theme> = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };
