import { ConstructBase } from './ConstructBase';
import { EntityType } from '../EntityType';
import { z } from 'zod';

export class Data extends ConstructBase {
  static schema = ConstructBase.baseSchema.extend({
    width: z.number().default(80),
  });

  static parse(data: unknown): Data {
    return ConstructBase.parseBase.call(Data, data, Data.schema);
  }

  get type(): EntityType {
    return EntityType.Data;
  }
}
