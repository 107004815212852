import { Box, SxProps, Theme } from '@mui/material';
import { useMemo } from 'react';

type LabelColorProps = {
  color: string;
  rootSx?: SxProps<Theme>;
};

export const LabelColor = (props: LabelColorProps) => {
  const { color, rootSx = {} } = props;

  const sx = useMemo(
    () => ({
      width: 8,
      height: 8,
      minWidth: 8,
      minHeight: 8,
      borderRadius: '50%',
      backgroundColor: color,
      ...rootSx,
    }),
    [color, rootSx],
  );

  return <Box sx={sx} />;
};
