import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { Preview } from '../../entities/assets/Preview';

interface RenameEntityCommandParams extends IParams {
  entityId: string;
  newName: string;
}

interface EntityRenamedEventParams extends IParams {
  entityId: string;
  newName: string;
}

export class EntityRenamedEvent extends EventBase {
  static eventType = 'EntityRenamedEvent';

  constructor(public readonly params: EntityRenamedEventParams, public readonly source = RenameEntityCommand) {
    super();
  }
}

export class RenameEntityCommand extends CommandBase<RenameEntityCommandParams> {
  execute(params: RenameEntityCommandParams): EntityRenamedEvent | CommandError {
    let entity = this.model.entities.get(params.entityId);
    if (!entity) return CommandError.of(new Error('Entity not found'), 'error');
    if (entity instanceof Preview) entity = entity.parent;
    const response = this.model.entities.rename(entity.id, params.newName);
    if (response instanceof Error) return CommandError.of(response, 'error');
    return new EntityRenamedEvent({
      entityId: entity.id,
      newName: params.newName,
    });
  }
}
