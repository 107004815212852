import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

interface ResolveThreadCommandParams extends IParams {
  threadId: string;
}

interface ThreadResolvedEventParams extends IParams {
  threadId: string;
}

export class ThreadResolvedEvent extends EventBase {
  static eventType = 'ThreadResolvedEvent';

  constructor(public readonly params: ThreadResolvedEventParams, public readonly source = ResolveThreadCommand) {
    super();
  }
}

export class ResolveThreadCommand extends CommandBase<ResolveThreadCommandParams> {
  execute(params: ResolveThreadCommandParams): ThreadResolvedEvent | CommandError {
    const result = this.model.entities.resolveThread(params.threadId);
    if (result instanceof Error) return CommandError.of(result, 'error');
    return new ThreadResolvedEvent({ threadId: params.threadId });
  }
}
