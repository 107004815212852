import { CommandBase, IParams } from '../framework/CommandBase';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

interface RenameScriptLaneParmas extends IParams {
  scriptId: string;
  laneIndex: number;
  name: string;
}

interface ScriptLaneRenamedParams extends IParams {
  scriptId: string;
  laneIndex: number;
  name: string;
}

export class ScriptLaneRenamedEvent extends EventBase {
  static eventType = 'ScriptLaneRenamedEvent';

  constructor(public readonly params: ScriptLaneRenamedParams, public readonly source = RenameScriptLaneCommand) {
    super();
  }
}

export class RenameScriptLaneCommand extends CommandBase<RenameScriptLaneParmas> {
  execute(params: RenameScriptLaneParmas): ScriptLaneRenamedEvent | CommandError {
    const script = this.model.entityRepository.get<ScriptBase>(params.scriptId);
    const isRenamed = script.renameLane(params.laneIndex, params.name);
    if (!isRenamed) return;
    this.model.entityRepository.update(script);
    return new ScriptLaneRenamedEvent({ scriptId: params.scriptId, laneIndex: params.laneIndex, name: params.name });
  }
}
