import { z } from 'zod';
import { EntityType } from '../EntityType';
import { EntityBase } from '../EntityBase';
import { Thread } from './Thread';

export class Comment extends EntityBase {
  public constructor(
    id: string,
    public value: string,
    public createdBy: string,
    public parent?: Thread,
    public scopes: string[] = [],
    public createdAt: Date = new Date(),
    public updatedAt: Date = new Date(),
  ) {
    super(id, '', parent, { x: 0, y: 0 }, scopes);
  }

  // static version = '1.0.1'; // Rename Tags to Labels;
  static version = '1.0.2'; // Add createdAt updatedAt field to comments

  static schema = z.preprocess(
    (input) => {
      if (typeof input === 'object' && input !== null) {
        const obj = input as any;
        // Correct casing for createdAt and updatedAt
        if ('CreatedAt' in obj) {
          obj.createdAt = obj.CreatedAt;
          delete obj.CreatedAt;
        }
        if ('UpdatedAt' in obj) {
          obj.updatedAt = obj.UpdatedAt;
          delete obj.UpdatedAt;
        }
        return obj;
      }
      return input;
    },
    EntityBase.abstractBaseSchema.extend({
      type: z.nativeEnum(EntityType).describe(`The type of the construct`),
      name: z
        .string()
        .optional()
        .describe(
          `
      * The name of the comment
    `,
        )
        .optional(),
      createdBy: z.string().describe(`The user who created the comment`),
      value: z.string().describe(`The value of the comment`),
      // Now transform string to Date for createdAt
      createdAt: z
        .string()
        .describe(`The date the comment was created`)
        .transform((str) => new Date(str)),
      // Now transform string to Date for updatedAt
      updatedAt: z
        .string()
        .describe(`The date the comment was updated`)
        .transform((str) => new Date(str)),
    }),
  );

  public update(value: string): void {
    this.value = value;
    this.updatedAt = new Date();
  }

  public ingest(entity: EntityBase): void | string | Error {
    return `${entity.id} not ingested. Target entity does not support ingesting ${entity.type}.`;
  }

  get type(): EntityType {
    return EntityType.Comment;
  }

  static parse(data: unknown): Comment {
    const parsedData = Comment.schema.parse(data);
    return new this(
      parsedData.id,
      parsedData.value,
      parsedData.createdBy,
      parsedData.parent,
      parsedData.scopes,
      new Date(parsedData.createdAt),
      new Date(parsedData.updatedAt),
    );
  }

  isValid(): boolean {
    const validationData = {
      ...this,
      type: this.type,
      createdAt: this.createdAt instanceof Date ? this.createdAt.toISOString() : this.createdAt,
      updatedAt: this.updatedAt instanceof Date ? this.updatedAt.toISOString() : this.updatedAt,
    };
    return Comment.schema.safeParse(validationData).success;
  }

  serialize(reference: boolean = false): unknown {
    if (reference) return super.serialize(reference);
    return {
      ...(super.serialize() as any),
      value: this.value,
      createdBy: this.createdBy,
      createdAt: this.createdAt.toISOString(),
      updatedAt: this.updatedAt.toISOString(),
    };
  }
}
