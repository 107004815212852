import { createPluginFactory } from '@udecode/plate-common';
import { onKeyDownExitBreak } from './on-key-down-exit-break';
import { ExitBreakPlugin } from './types';

export const KEY_EXIT_BREAK = 'exitBreak';

export const createExitBreakPlugin = createPluginFactory<ExitBreakPlugin>({
  key: KEY_EXIT_BREAK,
  handlers: {
    onKeyDown: onKeyDownExitBreak,
  },
  options: {
    rules: [{ hotkey: 'mod+enter' }, { hotkey: 'mod+shift+enter', before: true }],
  },
});
