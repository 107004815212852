import { ConstructBase } from './ConstructBase';
import { EntityType } from '../EntityType';
import { Local, LocalStoreProvider, localStoreProvider } from '../../data/LocalStoreProvider';
import { z } from 'zod';
import { ActionScript } from '../scripts/ActionScript';
import { ScriptBase } from '../scripts/ScriptBase';

export class Action extends ConstructBase {
  //static version = '2.0.0'; // renamed Component to Action;
  static version = '2.0.3'; // re-structure of Scripts, cells and frames

  static schema = ConstructBase.baseSchema.extend({
    script: z.lazy(() => ScriptBase.schema),
  });

  public script: ActionScript;

  @Local
  get isExpanded(): boolean {
    return localStoreProvider.get(LocalStoreProvider.getKey(this, 'isExpanded'), true) ?? false;
  }

  set isExpanded(newValue: boolean) {
    const key = LocalStoreProvider.getKey(this, 'isExpanded');
    localStoreProvider.set(key, newValue, true);
    this.notify();
    if (this.script) this.script.notify();
  }

  removeSubscript() {
    this.script = null;
  }

  static parse(data: object, omitReferences: boolean = false): Action {
    if (omitReferences) {
      const validatedData = Action.schema.omit({ script: true }).parse(data);
      return ConstructBase.parseBase.call(Action, validatedData);
    }
    return super.parseBase(data, Action.schema) as Action;
  }

  isValid(): boolean {
    return Action.schema.safeParse(this).success;
  }

  get type(): EntityType {
    return EntityType.Action;
  }

  static references = ['script'];

  serialize(reference: boolean = false): unknown {
    if (reference) return super.serialize(reference);
    return {
      ...(super.serialize() as any),
      script: this?.script?.serialize(true),
    };
  }
}
