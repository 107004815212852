import React, { useState } from 'react';
import { Box } from '@mui/material';
import { offset, safePolygon, shift, useFloating, useHover, useInteractions } from '@floating-ui/react';
import { AddFrameButton } from '../../../../frames/add-frame-button/add-frame-button';

export const AddFrame = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'right-start',
    middleware: [offset({ mainAxis: -8, crossAxis: 0 }), shift({ padding: 8 })],
  });
  const hover = useHover(context, {
    handleClose: safePolygon(),
    delay: { close: 100 },
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  return (
    <>
      <Box
        sx={{ position: 'relative', width: '20px', height: '100%' }}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <Box
          sx={{
            position: 'absolute',
            height: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            borderRight: `3px dashed transparent`,
            transition: 'border-color 300ms ease-in-out',
            ...(isOpen && { borderColor: 'green' }),
          }}
        />
      </Box>
      {isOpen && (
        <Box className="floating" ref={refs.setFloating} sx={{ ...floatingStyles }} {...getFloatingProps()}>
          <AddFrameButton />
        </Box>
      )}
    </>
  );
};
