import { Box } from '@mui/material';
import { SwagShopHeader, SwagShopHeaderProps } from './header/header';
import data from './mock-data.json';
import { CategoryBar, CategoryBarProps } from './category-bar/category-bar';
import { ProductDetails, ProductDetailsProps } from './product-details/product-details';
import { SubscribeBar, SubscribeBarProps } from './subscribe-bar/subscribe-bar';
import { SimilarProducts, SimilarProductsProps } from './similar-products/similar-products';
import { SwagShopFooter, SwagShopFooterProps } from './footer/footer';

export const SwagShopRoute = () => {
  const typedData = data as unknown as {
    header: SwagShopHeaderProps;
    categoryBar: CategoryBarProps;
    productDetails: ProductDetailsProps;
    subscribeBar: SubscribeBarProps;
    similarProducts: SimilarProductsProps;
    footer: SwagShopFooterProps;
  };

  return (
    <Box>
      <SwagShopHeader {...typedData.header} />
      <CategoryBar {...typedData.categoryBar} />
      <ProductDetails {...typedData.productDetails} />
      <SimilarProducts {...typedData.similarProducts} />
      <SubscribeBar {...typedData.subscribeBar} />
      <SwagShopFooter {...typedData.footer} />
    </Box>
  );
};
