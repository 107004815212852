import React, { ReactNode, useRef } from 'react';
import { useRemoteCursorOverlayPositions } from '@slate-yjs/react';
import { CursorData } from '../../types/editor-common.types';
import { RemoteUserSelection } from '../remote-user-selection/remote-user-selection';

export type RemoteCursorOverlayProps = {
  children: ReactNode;
};

export const RemoteCursorOverlay = (props: RemoteCursorOverlayProps) => {
  const { children } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const [cursors] = useRemoteCursorOverlayPositions<CursorData>({
    containerRef,
  });

  return (
    <div ref={containerRef} style={{ position: 'relative' }}>
      {children}
      {cursors.map((cursor) => (
        <RemoteUserSelection key={cursor.clientId + JSON.stringify(cursors[0]?.range)} {...cursor} />
      ))}
    </div>
  );
};
