import { EntityType } from '../EntityType';
import { ScriptBase } from './ScriptBase';
import { LaneConfig } from './LaneConfig';
import { ScriptConfig } from './ScriptConfig';

export class ActionScript extends ScriptBase {
  static version = '1.0.2'; // Renames tags to labels

  get config(): ScriptConfig {
    const lanes = [
      new LaneConfig(
        [
          EntityType.Command,
          EntityType.Data,
          EntityType.Event,
          EntityType.Process,
          EntityType.Constraints,
          EntityType.ExternalSystem,
          EntityType.Resolver,
          EntityType.ReadModel,
          EntityType.Projection,
          EntityType.Gateway,
        ],
        undefined,
        1,
        '#F8F8F8',
      ),
    ];
    return new ScriptConfig(lanes, lanes[0], false, true, [], 200, 200);
  }

  get type(): EntityType {
    return EntityType.ActionScript;
  }
}
