import { LabelsListItem, LabelsListItemProps } from './labels-list-item/labels-list-item';

type LabelsListProps = {
  labels: LabelsListItem[];
  onExitCreateNewLabel: () => void;
  labelsListItemProps: Pick<LabelsListItemProps, 'onSave' | 'onConfirmDelete'>;
};

export const LabelsList = (props: LabelsListProps) => {
  const { labels, onExitCreateNewLabel, labelsListItemProps } = props;

  return (
    <div>
      {labels.map((item) => (
        <LabelsListItem
          key={item.label.id}
          {...item}
          {...labelsListItemProps}
          onExitCreateNewLabel={item.isNew ? onExitCreateNewLabel : undefined}
        />
      ))}
    </div>
  );
};
