import { Stack } from '@mui/material';
import { Allotment } from 'allotment';
import { useOutlet, useParams } from 'react-router-dom';
import { SingleSourceModel } from '../../components/single-source-model';
import { SingleSourceModelWrapper } from '../../wrappers/single-source-model-wrapper/single-source-model-wrapper';
// import { Explorer } from '../../components/explorer/explorer';
import { useCallback, useMemo, useRef, useState } from 'react';
import { SingleSourceFilters } from '../../components/single-source-model/filters/single-source-filters';

export const WorkspaceRoute = () => {
  const { entityId } = useParams();

  const entityBaseId = entityId ? entityId.split('-')[0] : '';

  const outlet = useOutlet({ entityId: entityBaseId });

  const [leftPaneVisible, setLeftPaneVisible] = useState(false);
  const [leftPaneWidth, setLeftPaneWidth] = useState(0);

  const ref = useRef<any>();

  const handleResize = (sizes) => {
    setLeftPaneWidth(sizes[0]);
  };

  // const toggleLeftPane = () => {
  //   setLeftPaneVisible(!leftPaneVisible);
  // };
  //
  // const buttonStyle = useMemo(
  //   () => ({
  //     position: 'absolute',
  //     left: `${leftPaneWidth}px`,
  //     top: '50%',
  //     transform: 'translateY(-50%)',
  //     borderRadius: '0 4px 4px 0',
  //     zIndex: 20,
  //     width: 20,
  //     height: 28,
  //     border: '1px solid',
  //     borderColor: 'divider',
  //     backgroundColor: 'background.paper',
  //   }),
  //   [leftPaneWidth],
  // );

  const onDragEnd = useCallback((e) => {
    localStorage.setItem('allotments', JSON.stringify(e));
  }, []);

  const defaultSizes = useMemo(() => {
    const DEFAULT = [90, 20];
    const x = JSON.parse(localStorage.getItem('allotments'));
    if (!x || x.length !== DEFAULT.length) localStorage.setItem('allotments', JSON.stringify(DEFAULT));
    return x ?? DEFAULT;
  }, []);

  return (
    <Stack height="100%" width="100%">
      <SingleSourceModelWrapper>
        <SingleSourceFilters />
        <Allotment
          separator
          defaultSizes={defaultSizes}
          onChange={handleResize}
          proportionalLayout={true}
          vertical={false}
          ref={ref}
          onDragEnd={onDragEnd}
        >
          {/*<Allotment.Pane visible={leftPaneVisible} minSize={200}>*/}
          {/*<Explorer />*/}
          {/*</Allotment.Pane>*/}
          <Allotment.Pane>
            <SingleSourceModel />
          </Allotment.Pane>
          <Allotment.Pane visible={Boolean(outlet)} minSize={200} className={'!overflow-visible'}>
            {outlet ? outlet : null}
          </Allotment.Pane>
        </Allotment>
        {/*<IconButton onClick={toggleLeftPane} sx={buttonStyle} color="primary">*/}
        {/*  {leftPaneVisible ? <ChevronLeft /> : <ChevronRight />}*/}
        {/*</IconButton>*/}
      </SingleSourceModelWrapper>
    </Stack>
  );
};
