import { IconComponentProps } from '../icon';

export const Comment = ({ color }: IconComponentProps) => {
  return (
    <path
      d="M7 22.8867C6.90781 22.8867 6.81538 22.8666 6.72931 22.8251C6.51263 22.721 6.375 22.5019 6.375 22.2617V18.5117C3.96256 18.5117 2 16.5492 2 14.1367V7.26172C2 4.84928 3.96256 2.88672 6.375 2.88672H17.625C20.0374 2.88672 22 4.84928 22 7.26172V14.1367C22 16.5492 20.0374 18.5117 17.625 18.5117H12.9073C12.766 18.5117 12.6272 18.5602 12.517 18.6487L7.39037 22.7497C7.27744 22.84 7.13912 22.8867 7 22.8867ZM6.375 4.13672C4.652 4.13672 3.25 5.53872 3.25 7.26172V14.1367C3.25 15.8597 4.652 17.2617 6.375 17.2617C7.06437 17.2617 7.625 17.8223 7.625 18.5117V20.9613L11.736 17.6728C12.0671 17.4076 12.4831 17.2617 12.9072 17.2617H17.6249C19.3479 17.2617 20.7499 15.8597 20.7499 14.1367V7.26172C20.7499 5.53872 19.3479 4.13672 17.6249 4.13672H6.375Z"
      fill={color}
      fillOpacity="0.54"
    />
  );
};
