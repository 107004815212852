import { EntityType } from '../EntityType';

export class AssetTypes {
  static items: EntityType[] = [
    EntityType.Actor,
    EntityType.Spec,
    EntityType.Doc,
    EntityType.Schema,
    EntityType.Query,
    EntityType.Upload,
  ];

  static isAsset(constructType: EntityType): boolean {
    return this.items.includes(constructType);
  }
}
