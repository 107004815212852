import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

interface ToggleResolvedThreadsVisibilityParams extends IParams {
  visibility: boolean;
}

interface ResolvedThreadVisibilityToggledEventParams extends IParams {}

export class ResolvedThreadVisibilityToggledEvent extends EventBase {
  static eventType = 'ResolvedThreadVisibilityToggledEvent';

  constructor(
    public readonly params: ResolvedThreadVisibilityToggledEventParams,
    public readonly source = ToggleResolvedThreadsVisibility,
  ) {
    super();
  }
}

export class ToggleResolvedThreadsVisibility extends CommandBase<ToggleResolvedThreadsVisibilityParams> {
  execute(params: ToggleResolvedThreadsVisibilityParams): ResolvedThreadVisibilityToggledEvent | CommandError {
    this.model.graph.updatePreferences({ showResolvedThreads: params.visibility });
    return new ResolvedThreadVisibilityToggledEvent(params);
  }
}
