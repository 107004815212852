import { DeletedEntity, EntityChangeset } from '../../types';
import { EntityRepository } from '../../data/EntityRepository';
import { EntityType } from '../../entities/EntityType';
import { Label } from '../../entities/assets/Label';
import { Filter } from '../../entities/Filter';
import { EntityBase } from '../../entities/EntityBase';
import { IStore } from '../../data/Store';

export class GraphLabelFilters {
  constructor(
    private readonly changeset: EntityChangeset,
    private readonly store: IStore,
    private readonly entityRepository: EntityRepository,
  ) {}

  public update(): void {
    if (this.changeset.deleted.length > 0) {
      const deletedLabels = this.changeset.deleted.filter((e) => e.type === EntityType.Label);
      this.removeDeletedLabelsFromStore(deletedLabels);
      this.removeDeletedFiltersFromStore(deletedLabels);
    }
    const addedLabels = this.changeset.added
      .concat(this.changeset.updated.map((entry) => entry.entity))
      .filter((e) => e instanceof Label);
    this.addLabelsToStore(addedLabels);
    const filters = this.changeset.added
      .concat(this.changeset.updated.map((entry) => entry.entity))
      .filter((e) => e instanceof Filter);
    this.setAddedFilters(filters);
    const currentFilter = this.store.getState().appliedSavedFilter ?? this.store.getState().unsavedFilter;
    if (currentFilter) {
      this.entityRepository.filterEntities(this.entityRepository.list(), currentFilter);
    }
  }

  private setAddedFilters(filters: EntityBase[]) {
    for (const filter of filters) {
      const existingFilters = this.store.getState().savedFilters;
      const index = existingFilters.findIndex((f) => f.id === filter.id);
      existingFilters.splice(index, index === -1 ? 0 : 1, filter);
      this.store.getState().setSavedFilters([...existingFilters]);
    }
  }

  private addLabelsToStore(labels: EntityBase[]) {
    for (const label of labels) {
      const existingLabels = this.store.getState().labels;
      const index = existingLabels.findIndex((t) => t.id === label.id);
      existingLabels.splice(index, index === -1 ? 0 : 1, label);
      this.store.getState().setLabels([...existingLabels]);
    }
  }

  private removeDeletedFiltersFromStore(labelsToDelete: DeletedEntity[]): void {
    const existingFilters = [...this.store.getState().savedFilters];
    const filtersToDelete = this.changeset.deleted.filter((e) => e.type === EntityType.Filter);
    filtersToDelete.forEach((deletedFilter) => {
      const index = existingFilters.findIndex((f) => f.id === deletedFilter.id);
      if (index !== -1) {
        existingFilters.splice(index, 1);
      }
    });
    this.store.getState().setSavedFilters(existingFilters);

    const savedFilters = this.store.getState().savedFilters;
    for (const filter of savedFilters) {
      const updatedCriteria = filter.criteria.filter((c) => !labelsToDelete.some((f) => f.id === c.id));
      const updatedFilter = new Filter(
        filter.id,
        filter.name,
        filter.filterType,
        updatedCriteria,
        filter.scopes,
        filter.createdBy,
      );
      const index = savedFilters.findIndex((f) => f.id === filter.id);
      savedFilters.splice(index, 1, updatedFilter);
    }
  }

  private removeDeletedLabelsFromStore(labelsToDelete: DeletedEntity[]): void {
    const existingStoreLabels: Label[] = [...this.store.getState().labels];
    labelsToDelete.forEach((deletedLabel) => {
      const index = existingStoreLabels.findIndex((t) => t.id === deletedLabel.id);
      if (index !== -1) {
        existingStoreLabels.splice(index, 1);
      }
    });
    this.store.getState().setLabels(existingStoreLabels);
  }
}
