import { Box, Button, Stack, Typography } from '@mui/material';
import { FormattedNumber, useIntl } from 'react-intl';
import { SubscriptionPlansQuery } from '../../../../../gql/graphql';
import { PlansListItemFeatureList } from './feature-list/plans-list-item-feature-list';
import { Fragment, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { SUBSCRIPTION_SESSION_QUERY } from '../../../../../graphql/queries';
import { LoadingButton } from '@mui/lab';
import { useActiveOrganization } from '../../../../../hooks/use-active-organization';

type PlansListItemProps = {
  plan: SubscriptionPlansQuery['subscriptionPlans'][0];
};

export const PlansListItem = (props: PlansListItemProps) => {
  const { plan } = props;

  const { formatMessage: f } = useIntl();

  return (
    <Stack key={plan.id} gap={3} alignItems="flex-start" width="25%">
      <Stack gap={0.5}>
        <Typography variant="h6">{plan.name}</Typography>
        <FormattedNumber value={plan.price} style="currency" currency={plan.currency} maximumFractionDigits={0}>
          {(value) => (
            <Stack direction="row" alignItems="center" justifyContent="center" gap={0.5} height={30}>
              {plan.isCustomPlan && plan.price === 0 ? (
                <Typography variant="caption" color="text.primary">
                  {f({ id: 'contact-sales-for-pricing' })}
                </Typography>
              ) : (
                <Fragment>
                  <Typography variant="h5" color="text.primary">
                    {value}
                  </Typography>
                  <Typography variant="caption">
                    {f({ id: 'per-user-per-period' }, { period: plan.priceInterval })}
                  </Typography>
                </Fragment>
              )}
            </Stack>
          )}
        </FormattedNumber>
        <PlansListItemActionButton plan={plan} />
      </Stack>
      <PlansListItemFeatureList description={plan.description} features={plan.features} />
    </Stack>
  );
};

type PlansListItemActionButtonProps = {
  plan: SubscriptionPlansQuery['subscriptionPlans'][0];
};

const PlansListItemActionButton = (props: PlansListItemActionButtonProps) => {
  const { plan } = props;

  const { formatMessage: f } = useIntl();

  const organization = useActiveOrganization();

  const [startSession, { loading }] = useLazyQuery(SUBSCRIPTION_SESSION_QUERY, {
    variables: {
      planId: plan.id,
      organizationId: organization.id,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const onUpgrade = useCallback(async () => {
    const result = await startSession();
    window.location.replace(result.data.subscriptionSession.url);
  }, [startSession]);

  if (plan.isCurrentPlan) {
    return (
      <Button variant="outlined" disabled>
        {f({ id: 'current-plan' })}
      </Button>
    );
  }

  if (plan.isCustomPlan) {
    return (
      <Button variant="contained">
        <a href="mailto:support@xspecs.ai">{f({ id: 'get-in-touch' })}</a>
      </Button>
    );
  }

  if (plan.canSubscribe) {
    return (
      <LoadingButton variant="contained" onClick={onUpgrade} loading={loading} disabled={!plan.canSubscribe}>
        {f({ id: 'upgrade' })}
      </LoadingButton>
    );
  }

  // Placeholder for upgrade button
  return <Box height={37} />;
};
