import { FC } from 'react';
import { IconComponentProps } from '../icon';

export const ExpandImage: FC<IconComponentProps> = () => {
  return (
    <>
      <g clipPath="url(#clip0_2731_14881)">
        <path
          d="M16.82 5H6.52C5.68 5 5 5.68 5 6.52V16.82C5 17.66 5.68 18.34 6.52 18.34H16.82C17.66 18.34 18.34 17.66 18.34 16.82V6.52C18.34 5.68 17.66 5 16.82 5ZM6.48 12.89L9.07 9.56L10.92 11.79L13.51 8.45L16.84 12.89H6.48Z"
          fill="#EEEEEE"
        />
        <path
          d="M16.38 9.92996V7.44996C16.38 7.44996 16.36 7.38996 16.35 7.35996C16.35 7.32996 16.35 7.28996 16.35 7.25996C16.35 7.23996 16.33 7.23996 16.32 7.21996C16.3 7.17996 16.27 7.14996 16.23 7.10996C16.19 7.06996 16.16 7.03996 16.12 7.01996C16.1 7.01996 16.1 6.98996 16.08 6.98996C16.05 6.97996 16.01 6.98996 15.98 6.98996C15.95 6.98996 15.92 6.95996 15.89 6.95996H13.41C13.13 6.95996 12.91 7.17996 12.91 7.45996C12.91 7.73996 13.13 7.95996 13.41 7.95996H14.69L12.17 10.49C11.97 10.69 11.97 11 12.17 11.2C12.37 11.4 12.68 11.4 12.88 11.2L15.4 8.67996V9.94996C15.4 10.23 15.62 10.45 15.9 10.45C16.18 10.45 16.4 10.23 16.4 9.94996L16.38 9.92996Z"
          fill="#616161"
        />
        <path
          d="M6.94999 13.4V15.88C6.94999 15.88 6.96999 15.94 6.97999 15.97C6.97999 16 6.97999 16.04 6.97999 16.07C6.97999 16.09 6.99999 16.09 7.00999 16.11C7.02999 16.15 7.05999 16.18 7.09999 16.22C7.13999 16.26 7.16999 16.29 7.20999 16.31C7.22999 16.31 7.22999 16.34 7.24999 16.34C7.27999 16.35 7.31999 16.34 7.34999 16.34C7.37999 16.34 7.40999 16.37 7.43999 16.37H9.91999C10.2 16.37 10.42 16.15 10.42 15.87C10.42 15.59 10.2 15.37 9.91999 15.37H8.63999L11.16 12.84C11.36 12.64 11.36 12.33 11.16 12.13C10.96 11.93 10.65 11.93 10.45 12.13L7.92999 14.65V13.38C7.92999 13.1 7.70999 12.88 7.42999 12.88C7.14999 12.88 6.92999 13.1 6.92999 13.38L6.94999 13.4Z"
          fill="#616161"
        />
      </g>
      <defs>
        <clipPath id="clip0_2731_14881">
          <rect width="13.33" height="13.33" fill="white" transform="translate(5 5)" />
        </clipPath>
      </defs>
    </>
  );
};
