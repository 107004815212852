import { IconButton } from '@mui/material';
import { ArrowUpwardRounded } from '@mui/icons-material';

type CommentTextAreaSubmitButtonProps = {
  disabled: boolean;
  onClick: () => void;
};

export const CommentTextAreaSubmitButton = (props: CommentTextAreaSubmitButtonProps) => {
  const { onClick, disabled } = props;

  return (
    <IconButton size="small" disabled={disabled} sx={rootSx} onClick={onClick} disableFocusRipple>
      <ArrowUpwardRounded color="inherit" fontSize="inherit" />
    </IconButton>
  );
};

const rootSx = {
  backgroundColor: 'primary.main',
  color: 'white',
  '&:hover': {
    backgroundColor: 'primary.main',
    color: 'white',
  },
};
