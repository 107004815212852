import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useLazyQuery } from '@apollo/client';
import { CUSTOMER_PORTAL_SESSION } from '../../../../graphql/queries';
import { useCallback, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useSnackStack } from '../../../../wrappers/snack-stack-context';
import { useActiveOrganization } from '../../../../hooks/use-active-organization';

export const CustomerPortalButton = () => {
  const { formatMessage: f } = useIntl();

  const [loading, setLoading] = useState(false);

  const organization = useActiveOrganization();

  const { addToast } = useSnackStack();

  const [fetchCustomerPortalSessionUrl] = useLazyQuery(CUSTOMER_PORTAL_SESSION, {
    variables: { organizationId: organization.id },
  });

  const onGoToBillingHistory = useCallback(async () => {
    try {
      setLoading(true);
      const result = await fetchCustomerPortalSessionUrl();
      const url = result?.data?.customerPortalSession.url;
      if (url) {
        window.location.replace(url);
      } else {
        addToast({ severity: 'warning', message: f({ id: 'unable-to-fetch-billing-history' }) });
      }
    } catch (error) {
      addToast({ severity: 'warning', message: f({ id: 'unable-to-fetch-billing-history' }) });
    } finally {
      setLoading(false);
    }
  }, [addToast, f, fetchCustomerPortalSessionUrl]);

  return organization.hasBilling ? (
    <LoadingButton variant="outlined" size="small" loading={loading} onClick={onGoToBillingHistory}>
      {f({ id: 'go-to-customer-billing-portal' })}
    </LoadingButton>
  ) : (
    <Typography variant="body1">{f({ id: 'no-invoices-to-show' })}</Typography>
  );
};
