import { Box } from '@mui/material';
import { NoAccess } from '../../pages/no-access/no-access';

export const NoAccessRoute = () => {
  return (
    <Box data-testid="NoAccessRoute" sx={{ height: '100%', width: '100%' }}>
      <NoAccess />
    </Box>
  );
};
