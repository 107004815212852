import { EntityType } from '../EntityType';

export class LaneConfig {
  constructor(
    public ingests: EntityType[],
    public displayName?: string,
    public maxEntities?: number,
    public color?: string,
    public conflictingEntityTypeGroups: EntityType[][] = [],
  ) {}
}
