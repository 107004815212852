import { useParams } from 'react-router-dom';
import { ORGANIZATIONS_QUERY } from '../graphql/queries';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

export const useOrganizationAndWorkspace = () => {
  const params = useParams();

  const { data, loading } = useQuery(ORGANIZATIONS_QUERY);

  const organization = useMemo(
    () => data?.organizations.find((org) => org.name === params.organizationName),
    [data, params.organizationName],
  );
  const workspace = useMemo(() => {
    if (!organization) return null;
    return organization.workspaces.find((w) => w.name === params.workspaceName);
  }, [organization, params.workspaceName]);

  return {
    organization,
    workspace,
    loading,
  } as const;
};
