import { createPluginFactory, PlateEditor, TOperation } from '@udecode/plate-common';
import { onInsertNode, onSplitNode } from './utils';
import { QueryNodeOptions } from '@udecode/plate-common';

const KEY_NODE_ID = 'nodeId';

const withNodeIdOverrides = (editor: PlateEditor): PlateEditor => {
  const { apply } = editor;

  editor.apply = (operation: TOperation) => {
    if (operation.type === 'split_node') {
      return apply(onSplitNode(operation));
    }

    if (operation.type === 'insert_node') {
      return apply(onInsertNode(editor, operation));
    }

    return apply(operation);
  };

  return editor;
};

export type NodeIdPlugin = QueryNodeOptions;

export const createNodeIdPlugin = createPluginFactory<NodeIdPlugin>({
  key: KEY_NODE_ID,
  withOverrides: withNodeIdOverrides,
});
