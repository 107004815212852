import { useState, useContext, createContext, ReactElement, FC, PropsWithChildren, useMemo, useCallback } from 'react';
import { sid } from '@xspecs/short-id';

export type ToastProps = {
  /**
   * Key to render multiple toasts.
   * This is being set automatically unless specified manually.
   */
  key?: string;
  /**
   * Alert title
   */
  title?: string;
  /**
   * Alert message
   */
  message?: string;
  /**
   * Custom component or html-layout
   */
  children?: ReactElement;
  /**
   * Indicates when the alert will disappear in ms. Defaults too 5000.
   * Pass 0 for infinite duration.
   */
  duration?: number;
  /**
   * Alert color
   */
  severity?: 'success' | 'info' | 'warning' | 'error';
  /**
   * Alert position on the screen
   */
  position?: {
    vertical?: 'top' | 'bottom';
    horizontal?: 'left' | 'right' | 'center';
  };
  /**
   * On Close callback
   */
  onClose?: (key: string) => void;
};

export type SnackStackContextProps = {
  toastsPack: ToastProps[];
  setToastsPack: (toasts: ToastProps[]) => void;
  addToast: (toast: ToastProps) => string;
  removeToast: (key: ToastProps['key']) => void;
};

const SnackStackContext = createContext<SnackStackContextProps>({
  toastsPack: [],
  setToastsPack: () => {},
  addToast: () => '',
  removeToast: () => {},
});

export const SnackStackProvider: FC<PropsWithChildren> = ({ children }) => {
  const [toastsPack, setToastsPack] = useState<ToastProps[]>([]);

  const addToast = useCallback((toast: ToastProps) => {
    const key = toast.key || sid();
    setToastsPack((prev) => {
      const rest = prev.length < 1 ? prev : prev.slice(0, -1);
      return [{ ...toast, key }, ...rest];
    });
    return key;
  }, []);

  const removeToast = useCallback((key: ToastProps['key']) => {
    setToastsPack((prev) => prev.filter((toast) => toast.key !== key));
  }, []);

  const value = useMemo<SnackStackContextProps>(
    () => ({
      toastsPack,
      setToastsPack,
      addToast,
      removeToast,
    }),
    [toastsPack, setToastsPack, addToast, removeToast],
  );

  return <SnackStackContext.Provider value={value}>{children}</SnackStackContext.Provider>;
};

export const useSnackStack = () => useContext<SnackStackContextProps>(SnackStackContext);
