import { MenuItem, Select } from '@mui/material';
import * as muiColors from '@mui/material/colors';
import { LabelColor } from '../label-color/label-color';
import { useCallback } from 'react';

export type LabelsColorSelectProps = {
  color: string;
  onColorChange: (color: string) => void;
};

export const LabelsColorSelect = (props: LabelsColorSelectProps) => {
  const { color, onColorChange } = props;

  const onChange = useCallback(
    (e) => {
      onColorChange(e.target.value);
    },
    [onColorChange],
  );

  return (
    <Select
      variant="outlined"
      size="small"
      inputProps={{ sx: { alignContent: 'center' } }}
      MenuProps={{ sx: { maxHeight: 194 } }}
      value={color}
      onChange={onChange}
    >
      {LABEL_COLORS.map((color) => (
        <MenuItem key={color} value={color}>
          <LabelColor color={color} />
        </MenuItem>
      ))}
    </Select>
  );
};

export const LABEL_COLORS: string[] = [
  muiColors.deepPurple['500'],
  muiColors.grey['300'],
  muiColors.grey['500'],
  muiColors.grey['900'],
  muiColors.indigo['100'],
  muiColors.amber['600'],
  muiColors.amber['900'],
  muiColors.pink['A700'],
  muiColors.pink['300'],
  muiColors.pink['200'],
  muiColors.purple['A700'],
  muiColors.blue['500'],
  muiColors.blue['50'],
  muiColors.teal['A700'],
  muiColors.teal['700'],
  muiColors.cyan['A700'],
  muiColors.cyan['A200'],
  muiColors.lime['A700'],
  muiColors.lime['900'],
  muiColors.lime['300'],
  muiColors.green['50'],
  muiColors.red['A700'],
  muiColors.amber['50'],
  muiColors.yellow['A200'],
];

export const getRandomLabelColor = () => {
  return LABEL_COLORS[Math.floor(Math.random() * LABEL_COLORS.length)];
};
