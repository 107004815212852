import { findNode, focusEditor, getNodeParent, getPluginType, useEditorState } from '@udecode/plate-common';
import { KEY_ALIGN, setAlign } from '@udecode/plate-alignment';
import { MouseEventHandler, useCallback, useMemo } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FormatAlignCenter, FormatAlignJustify, FormatAlignLeft, FormatAlignRight } from '@mui/icons-material';
import { alignValidTypes } from '../../../editor/doc-editor.plugins';

export enum AlignType {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  JUSTIFY = 'justify',
}

export const BalloonMenuAlignment = () => {
  const editor = useEditorState();

  const buttonSx = useMemo(() => ({ border: 'none', borderRadius: 'unset', color: 'action' }), []);

  const onMouseDown = useCallback<(value: AlignType) => MouseEventHandler<HTMLButtonElement>>(
    (value: AlignType) => (e) => {
      e.preventDefault();
      e.stopPropagation();
      setAlign(editor, { value, key: KEY_ALIGN });
      focusEditor(editor);
    },
    [editor],
  );

  const activeNodeEntry = findNode(editor, { match: { [KEY_ALIGN]: AlignType.CENTER } });

  const disabled = useMemo(() => {
    if (!editor?.selection?.anchor) return false;
    const node = getNodeParent(editor, editor.selection.anchor.path);
    return !alignValidTypes.includes(getPluginType(editor, node.type as string));
  }, [editor]);

  return (
    <ToggleButtonGroup
      data-testid="balloon-menu-alignment"
      exclusive
      value={activeNodeEntry?.[0][KEY_ALIGN]}
      disabled={disabled}
    >
      <ToggleButton
        data-testid="balloon-menu-align-left"
        value={AlignType.LEFT}
        onMouseDown={onMouseDown(AlignType.LEFT)}
        sx={buttonSx}
      >
        <FormatAlignLeft />
      </ToggleButton>
      <ToggleButton
        data-testid="balloon-menu-align-center"
        value={AlignType.CENTER}
        onMouseDown={onMouseDown(AlignType.CENTER)}
        sx={buttonSx}
      >
        <FormatAlignCenter />
      </ToggleButton>
      <ToggleButton
        data-testid="balloon-menu-align-right"
        value={AlignType.RIGHT}
        onMouseDown={onMouseDown(AlignType.RIGHT)}
        sx={buttonSx}
      >
        <FormatAlignRight />
      </ToggleButton>
      <ToggleButton
        data-testid="balloon-menu-align-justify"
        value={AlignType.JUSTIFY}
        onMouseDown={onMouseDown(AlignType.JUSTIFY)}
        sx={buttonSx}
      >
        <FormatAlignJustify />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
