import { SingleSourceModel } from './SingleSourceModel';
import { User } from './types';
import { SingleSourceModelTestFactory } from './test-utilities/SingleSourceModelTestFactory';
import { GraphLabelFilters } from './read-models/graph/GraphLabelFilters';
import { NotificationTypes } from './observable/SingleSourceObserver';
import { FileStoreClient } from './data/FileStoreClient';
import { SingleSourceModelYjsAdaptor } from './data/SingleSourceModelYjsAdaptor';
import { IStore } from './data/Store';
import { FileType } from './data/File.types';
import { VERSION } from '@xspecs/app-version';
import { TestCodeHandler } from './TestCodeHandler';

const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined';
const location = isBrowser ? window.location : { origin: 'http://localhost', hostname: 'localhost' };

export class Application {
  private fileStoreClient: FileStoreClient;
  public model: SingleSourceModel;
  public adaptor: SingleSourceModelYjsAdaptor;
  public readonly store: IStore;

  private static instance: Application;
  private readonly testEnv: boolean;

  public static getInstance(store?: IStore, appVersion = VERSION, testEnv = false) {
    if (testEnv) return new Application(store, appVersion, true);
    if (!Application.instance && !store)
      throw new Error('You must provide a store if no Application instance has been requested yet');
    if (Application.instance && store)
      throw new Error('Application instance already exists, you cannot provide a store');
    if (!Application.instance) Application.instance = new Application(store, appVersion, false);
    return Application.instance;
  }

  private constructor(store: IStore, appVersion: number, testEnv: boolean) {
    this.testEnv = testEnv;
    this.store = store;
    this.fileStoreClient = new FileStoreClient(resolveFileStoreUrl(), this.store, appVersion, testEnv);
  }

  start(params: { token: string; user: User; scopes: string[]; modelId: string; version: string }) {
    const { token, user, scopes, modelId, version } = params;
    this.fileStoreClient.setContext({ token, user, scopes, host: resolveHost() });
    const modelFile = this.fileStoreClient.loadModel({ fileId: modelId, version, type: FileType.Model });

    if (this.testEnv) this.model = SingleSourceModelTestFactory.create(scopes, modelFile, this.store);
    else this.model = SingleSourceModel.getInstance(scopes, modelFile, this.store);

    this.adaptor = SingleSourceModelYjsAdaptor.getInstance(
      this.model,
      modelFile.storageMap,
      modelFile.versionMap,
      () => {
        setTimeout(() => {
          this.store.getState().setIsLoaded(true);
        }, 0);
      },
    );
    // this.model.graphTransformer.changeClient(client);

    const testCodeHandler = new TestCodeHandler(this.model);
    this.model.messageBus.registerEventHandler(testCodeHandler.handles(), testCodeHandler);

    // READ MODEL SUBSCRIPTIONS
    this.model.observer.subscribe(
      [NotificationTypes.OnAfterSave, NotificationTypes.OnTransientChange, NotificationTypes.onScopesChange],
      (changes, notificationType) => {
        // FIXME: This seems like a hack to avoid updating read models when no scope is set
        if (this.model.entityRepository.getScopes().length === 0) return this.clearReadModels();
        // UPDATE BOUNDARIES READ MODEL
        this.model.boundariesIndex.updateIndex(changes);
        // UPDATE GRAPH READ MODEL
        this.model.graph.updateGraph(changes);
        // UPDATE ASSETS READ MODEL
        this.model.assets.update(changes);

        if (notificationType != NotificationTypes.OnTransientChange) {
          // UPDATE FILTERS READ MODEL
          new GraphLabelFilters(changes, this.store, this.model.entityRepository).update();
        }
      },
      true,
    );
    this.model.observer.subscribe(
      [NotificationTypes.OnAfterSave, NotificationTypes.OnTransientChange, NotificationTypes.OnUserStateChange],
      (changes) => {
        // UPDATE ENTITY DETAILS READ MODEL
        this.model.entityDetails.update(changes);
        this.model.annotator.update(changes);
      },
    );
    this.model.observer.subscribe([NotificationTypes.OnError], () => {
      this.store.getState().setErrors([...this.model.errorStore.errors]);
    });

    //
    // this.model.observer.subscribe(NotificationTypes.Explorer, changes => {
    //   this.model.explorer.load(changes);
    // });
  }

  changeContext({ token, user, scopes }: { token: string; user: User; scopes: string[] }) {
    this.fileStoreClient.setContext({ token, user, scopes, host: resolveHost() });
    this.clearReadModels();
    this.model.changeScopes(scopes);
  }

  clearReadModels() {
    this.model.cache.clear();
    this.model.graph.clear();
    this.model.assets.clear();
    this.model.annotator.clear();
    this.model.boundariesIndex.clear();
    this.model.entityDetails.clear();
    this.model.annotator.clear();
  }

  dispose() {
    this.model.dispose();
    this.adaptor.dispose();
  }

  loadDocCommand({ fileId, version }: { fileId: string; version: string }) {
    this.fileStoreClient.loadDoc({ fileId, version });
  }

  loadSpecCommand({ fileId, version }: { fileId: string; version: string }) {
    this.fileStoreClient.loadSpec({ fileId, version });
  }

  loadModelVersion({ fileId, version }: { fileId: string; version: string }) {
    return this.fileStoreClient.loadModelVersion({ fileId, version });
  }
}

// ## -------------
// ## -------------
// ## -------------

// export function getSharedInfrastructureHost() {
//   if (location.hostname === 'app.narrative.tech' || location.hostname === 'app.xspecs.ai') {
//     return 'main.narrative.tech';
//   } else {
//     return 'dev.xspecs.io';
//   }
// }

// export function getBranchName() {
//   const hostname = location.hostname;
//
//   if (hostname === 'localhost') {
//     return 'local';
//   }
//
//   const prodRegex = /^(main|app)\.xspecs\.(io|ai)$/;
//   if (prodRegex.test(hostname)) {
//     return 'main';
//   }

// const subdomainRegex = /^([a-zA-Z0-9_-]+)\.xspecs\.io$/;
// const subdomainMatch = hostname.match(subdomainRegex);
//
// if (subdomainMatch) {
//   return subdomainMatch[1];
// }
//
// return null;
// }

// export const isProd =
//   location.hostname.startsWith('app.xspecs.ai') || location.hostname.startsWith('app.narrative.tech');
//
// export const isDev = location.hostname.startsWith('localhost');

export const resolveHost = () => {
  if (location.origin.includes('localhost')) return undefined;
  if (location.origin.includes('app.xspecs.ai') || location.origin.includes('app.narrative.tech')) return undefined;
  return `${location.origin.split('.')[0].split('//')[1]}`;
};

const resolveFileStoreUrl = () => {
  if (location.origin.includes('localhost')) return 'ws://127.0.0.1:8080';
  if (location.origin.includes('app.xspecs.ai')) return 'wss://fs.narrative.tech';
  if (location.origin.includes('app.narrative.tech')) return 'wss://fs.narrative.tech';
  return 'wss://fsdev.xspecs.io';
};
