import { ConfirmEmail } from '../../pages/confirm-email/confirm-email';
import { Box } from '@mui/material';

export const ConfirmEmailRoute = () => {
  return (
    <Box data-testid="confirm-email-route" height="100%" width="100%">
      <ConfirmEmail />
    </Box>
  );
};
