import { ConstructBase } from './ConstructBase';
import { EntityType } from '../../entities/EntityType';

export class Moment extends ConstructBase {
  get type(): EntityType {
    return EntityType.Moment;
  }

  static parse(data: unknown): Moment {
    return ConstructBase.parseBase.call(Moment, data);
  }
}
