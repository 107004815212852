import { EntityType } from '../EntityType';
import { GqlEntityBase } from './GqlEntityBase';

export class GqlOperation extends GqlEntityBase {
  public type: EntityType = EntityType.GqlOperation;

  isValid(): boolean {
    return GqlEntityBase.baseSchema.safeParse(this).success;
  }

  static parse(data: unknown): GqlOperation {
    return GqlEntityBase.parseBase.call(GqlOperation, data);
  }
}
