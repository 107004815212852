import { FC } from 'react';
import { IconComponentProps } from '../icon';

export const CollapseSubscript: FC<IconComponentProps> = () => {
  return (
    <>
      <rect width="20" height="20" fill="#D8D8D8" />
      <rect x="1" y="1" width="18" height="5" fill="#F8F8F8" />
      <rect x="1" y="7" width="18" height="6" fill="#F8F8F8" />
      <rect x="1" y="14" width="18" height="5" fill="#F8F8F8" />
      <path
        d="M9.41376 11.5258C9.41291 11.5171 9.41129 11.5088 9.41006 11.5003C9.40859 11.4899 9.40738 11.4796 9.40536 11.4694C9.40342 11.4596 9.40074 11.4501 9.39831 11.4405C9.39609 11.4316 9.39415 11.4228 9.3915 11.414C9.38865 11.4046 9.3851 11.3955 9.38179 11.3863C9.37865 11.3775 9.37578 11.3687 9.37222 11.3601C9.36867 11.3515 9.36446 11.3433 9.36052 11.3349C9.35633 11.326 9.3524 11.3171 9.34775 11.3084C9.34343 11.3003 9.33853 11.2927 9.33386 11.2849C9.32877 11.2764 9.32396 11.2677 9.31841 11.2594C9.31264 11.2508 9.30619 11.2427 9.29998 11.2344C9.29478 11.2274 9.28997 11.2203 9.28445 11.2136C9.27233 11.1988 9.25959 11.1847 9.24611 11.1712C9.2326 11.1577 9.21784 11.1443 9.20307 11.1322C9.19632 11.1267 9.18921 11.1218 9.18226 11.1166C9.17398 11.1104 9.16586 11.104 9.15722 11.0982C9.1489 11.0927 9.14029 11.0879 9.13176 11.0828C9.12394 11.0781 9.11631 11.0732 9.10827 11.0689C9.09958 11.0643 9.09066 11.0603 9.08178 11.0561C9.07338 11.0522 9.06512 11.048 9.05654 11.0444C9.04792 11.0409 9.03912 11.038 9.03037 11.0348C9.02115 11.0315 9.01208 11.028 9.00267 11.0252C8.99392 11.0225 8.98501 11.0206 8.97617 11.0183C8.96658 11.0159 8.95709 11.0132 8.94733 11.0113C8.93707 11.0093 8.92674 11.008 8.91643 11.0066C8.90792 11.0054 8.89956 11.0037 8.89092 11.0029C8.87175 11.001 8.85255 11 8.83333 11H4.74998C4.42782 11 4.16666 11.2612 4.16666 11.5833C4.16666 11.9055 4.42782 12.1666 4.74998 12.1666H7.42502L3.17085 16.4208C2.94305 16.6486 2.94305 17.018 3.17085 17.2458C3.39865 17.4736 3.76801 17.4736 3.99581 17.2458L8.24998 12.9916V15.6667C8.24998 15.9888 8.51114 16.25 8.8333 16.25C9.15547 16.25 9.41665 15.9888 9.41665 15.6667V11.5834C9.41665 11.5641 9.41564 11.5449 9.41376 11.5258Z"
        fill="black"
        fillOpacity="0.6"
      />
      <path
        d="M16.4208 3.17088L12.1667 7.42505V4.75001C12.1667 4.42784 11.9055 4.16668 11.5834 4.16668C11.2612 4.16668 11 4.42784 11 4.75001V8.83333C11 8.85255 11.001 8.87175 11.0029 8.89089C11.0037 8.8995 11.0054 8.90787 11.0066 8.9164C11.0081 8.92671 11.0093 8.93707 11.0113 8.9473C11.0133 8.95709 11.0159 8.96655 11.0184 8.97615C11.0206 8.98501 11.0225 8.99389 11.0252 9.00264C11.028 9.01205 11.0316 9.02116 11.0349 9.03034C11.038 9.03909 11.0409 9.0479 11.0444 9.05651C11.048 9.06512 11.0522 9.07335 11.0562 9.08175C11.0603 9.09061 11.0643 9.09955 11.0689 9.10825C11.0733 9.11628 11.0781 9.12391 11.0828 9.13173C11.0879 9.14024 11.0927 9.14888 11.0983 9.15719C11.104 9.16583 11.1105 9.17392 11.1167 9.18224C11.1219 9.18918 11.1267 9.19629 11.1322 9.20305C11.1443 9.21781 11.1571 9.232 11.1706 9.24548C11.1841 9.25894 11.1989 9.27231 11.2136 9.28442C11.2204 9.28997 11.2275 9.29478 11.2344 9.29995C11.2427 9.30616 11.2508 9.31261 11.2595 9.31838C11.2678 9.32393 11.2764 9.32875 11.2849 9.33383C11.2927 9.33851 11.3003 9.3434 11.3084 9.34772C11.3171 9.35237 11.326 9.35631 11.3349 9.36049C11.3433 9.36446 11.3516 9.36864 11.3601 9.37219C11.3688 9.37575 11.3775 9.37862 11.3863 9.38176C11.3955 9.38507 11.4046 9.3886 11.414 9.39147C11.4228 9.39412 11.4317 9.39607 11.4405 9.39828C11.4501 9.40071 11.4596 9.40339 11.4694 9.40533C11.4796 9.40736 11.49 9.40856 11.5003 9.41004C11.5088 9.41124 11.5172 9.41288 11.5258 9.41373C11.5449 9.41562 11.5641 9.41663 11.5833 9.41663H15.6667C15.9888 9.41663 16.25 9.15547 16.25 8.8333C16.25 8.51114 15.9888 8.24998 15.6667 8.24998H12.9916L17.2458 3.99578C17.4736 3.76798 17.4736 3.39862 17.2458 3.17082C17.018 2.94302 16.6487 2.94308 16.4208 3.17088Z"
        fill="black"
        fillOpacity="0.6"
      />
    </>
  );
};
