import { useAuth } from '../../auth';
import { Box, CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';

const invitationId = uuidv4();

export const LoginRoute = () => {
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const organizationId = params.get('organizationId');
  const inviteId = params.get('invitationId');
  const workspaceId = params.get('workspaceId');
  const specId = params.get('specId');
  const docId = params.get('docId');

  const isAcceptInvite = organizationId && inviteId;

  const isShareResource = organizationId && workspaceId && inviteId && (specId || docId);

  const connection = params.get('connection');

  const { loginWithRedirect, isLoading } = useAuth();

  useEffect(() => {
    if (isLoading) return;
    if (isShareResource) {
      localStorage.setItem(
        invitationId,
        JSON.stringify({ organizationId, workspaceId, inviteId, specId, docId, expires: dayjs().add(5, 'minutes') }),
      );
      loginWithRedirect({ appState: { invitationId } });
    } else if (isAcceptInvite) {
      localStorage.setItem(
        invitationId,
        JSON.stringify({ organizationId, inviteId, expires: dayjs().add(5, 'minutes') }),
      );
      loginWithRedirect({ appState: { invitationId } });
    } else if (connection) {
      // todo:  Add appState and validate to protect against CSRF attack
      loginWithRedirect({ authorizationParams: { connection: connection, redirect_uri: window.location.origin } });
    } else {
      // eslint-disable-next-line no-console
      loginWithRedirect();
    }
  }, [
    docId,
    inviteId,
    isAcceptInvite,
    isLoading,
    isShareResource,
    loginWithRedirect,
    navigate,
    workspaceId,
    specId,
    organizationId,
    connection,
  ]);

  return (
    <Box
      data-testid="login-route"
      display="flex"
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  );
};
