import { Stack, styled } from '@mui/material';
import { SingleSourceModelToolbarConstructsButton } from './constructs-button';
import { SingleSourceModelToolbarSelectorToolButton } from './selector-tool-button';
import { SingleSourceModelToolbarAvatars } from './avatars';
import { SingleSourceModelToolbarScriptButton } from './narrative-button';
import { SingleSourceModelToolbarConstructButton } from './construct-button/single-source-model-toolbar-construct-button';
import { CreateUploadCommand, EntityType, UploadType } from '@xspecs/single-source-model';
import { UploadButton } from './upload/upload-button';
import { sid } from '@xspecs/short-id';
import { ChangeEvent, useState } from 'react';
import { FILE_UPLOAD_URLS_QUERY } from '../../../graphql/queries';
import { useLazyQuery } from '@apollo/client';
import { useApplication } from '../../../wrappers/application-context/application-context';
import { useCanvasCenter } from '../../../hooks/use-canvas-center';
import { useSnackStack } from '../../../wrappers/snack-stack-context';
import { useIntl } from 'react-intl';
import { useImageDimensions } from '../canvas/hooks/use-image-dimensions';
import { resizeImage } from '../../../utils/resizeImage';

export const SingleSourceModelToolbar = () => {
  const { formatMessage: f } = useIntl();
  const { application } = useApplication();
  const getCanvasCenter = useCanvasCenter();
  const { addToast } = useSnackStack();
  const [loading, setLoading] = useState(false);
  const [getPreSignedUrl] = useLazyQuery(FILE_UPLOAD_URLS_QUERY, {
    fetchPolicy: 'no-cache',
  });
  const { getImageDimensions } = useImageDimensions();

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const assetId = sid();
    const file = e.target.files[0];
    if (!file) return;
    const { data } = await getPreSignedUrl({ variables: { parentId: assetId } });
    if (!data?.signedMediaUploadUrl) {
      addToast({
        message: f({ id: 'failed-to-get-signed-media-upload-url' }),
        severity: 'error',
      });
      return;
    }
    const uploadFileResult = await fetch(data.signedMediaUploadUrl.putUrl, {
      method: 'PUT',
      headers: { 'Content-Type': file.type || 'application/octet-stream' },
      body: file,
    });
    if (!uploadFileResult.ok) {
      addToast({
        message: f({ id: 'failed-to-upload-file' }),
        severity: 'error',
      });
      return;
    }
    const type = file.type.startsWith('image') ? UploadType.Image : UploadType.File;
    const attachmentId = sid();
    const center = getCanvasCenter();

    let imgDimensions;
    if (type === UploadType.Image) {
      let originalDimensions;

      try {
        originalDimensions = await getImageDimensions(file);
      } catch (error) {
        originalDimensions = {};
      } finally {
        imgDimensions = resizeImage(originalDimensions);
      }
    }

    application.model.messageBus.send(CreateUploadCommand, {
      assetId,
      id: attachmentId,
      name: file.name,
      position: { x: center.x, y: center.y },
      type,
      url: data.signedMediaUploadUrl.getUrl,
      metadata: imgDimensions,
    });
  };

  return (
    <Root>
      <Stack direction="row" alignItems="center" gap={0.5} sx={{ overflow: 'auto' }}>
        <SingleSourceModelToolbarSelectorToolButton />
        <SingleSourceModelToolbarConstructButton type={EntityType.Capability} />
        <SingleSourceModelToolbarScriptButton />
        <SingleSourceModelToolbarConstructButton type={EntityType.Moment} />
        <SingleSourceModelToolbarConstructButton type={EntityType.Interface} />
        <SingleSourceModelToolbarConstructButton type={EntityType.Action} />
        <SingleSourceModelToolbarConstructButton type={EntityType.Actor} />
        <SingleSourceModelToolbarConstructButton type={EntityType.Spec} />
        <SingleSourceModelToolbarConstructButton type={EntityType.Thread} />
        <UploadButton
          loading={loading}
          onChange={async (e) => {
            try {
              setLoading(true);
              await onChange(e);
            } finally {
              setLoading(false);
            }
          }}
        />
        <SingleSourceModelToolbarConstructsButton />
      </Stack>
      <Stack direction="row" alignItems="center" gap={1.5}>
        <SingleSourceModelToolbarAvatars />
      </Stack>
    </Root>
  );
};

const Root = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '2px 8px',
});
