import { ListItemButton, Stack, SxProps, Theme } from '@mui/material';
import { Label } from '@xspecs/single-source-model';
import { LabelsListItemActions } from './labels-list-item-actions';
import { LabelsListItemColor } from './color/labels-list-item-color';
import { LabelsListItemName } from './name/labels-list-item-name';
import clsx from 'clsx';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

export type LabelSaveParams = { id: string; name: string; color: string; isNew: boolean };

export type LabelsListItem = {
  label: Label;
  isNew: boolean;
};

export type LabelsListItemProps = LabelsListItem & {
  onExitCreateNewLabel?: () => void;
  onSave: (params: LabelSaveParams) => void;
  onConfirmDelete: (id: string) => void;
};

export const LabelsListItem = (props: LabelsListItemProps) => {
  const { label, isNew, onExitCreateNewLabel, onSave: onSaveProp, onConfirmDelete: onConfirmDeleteProp } = props;

  const { id, color: colorProp, name: nameProp } = label;

  const [name, setName] = useState(nameProp);
  const [color, setColor] = useState(colorProp);
  const [isBeingEdited, setIsBeingEdited] = useState(isNew ?? false);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  const rootSx: SxProps<Theme> = {
    width: '100%',
    height: 48,
    px: 2,
    py: 1.5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 1,
    backgroundColor: isBeingEdited || isConfirmingDelete ? 'rgba(0, 0, 0, 0.12)' : 'background.paper',
    '.labelsListItemActions': {
      visibility: 'hidden',
    },
    '&:hover': {
      '.labelsListItemActions': {
        visibility: 'visible',
      },
    },
  };

  const reset = useCallback(() => {
    setName(nameProp);
    setColor(colorProp);
    setIsBeingEdited(false);
    setIsConfirmingDelete(false);
  }, [colorProp, nameProp]);

  const onEdit = useCallback(() => {
    setIsBeingEdited(true);
  }, []);

  const onClose = useCallback(() => {
    setIsBeingEdited(false);
    reset();
    if (isNew) onExitCreateNewLabel?.();
  }, [isNew, onExitCreateNewLabel, reset]);

  const onDelete = useCallback(() => {
    setIsConfirmingDelete(true);
  }, []);

  const onCancelDelete = useCallback(() => {
    setIsConfirmingDelete(false);
  }, []);

  const onConfirmDelete = useCallback(() => {
    onConfirmDeleteProp(id);
  }, [id, onConfirmDeleteProp]);

  const onSave = useCallback(() => {
    onSaveProp({ id, name, color, isNew });
    onClose();
  }, [color, id, isNew, name, onClose, onSaveProp]);

  const onNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, []);

  useEffect(() => {
    setName(nameProp);
  }, [nameProp]);

  return (
    <ListItemButton data-testid={`LabelsListItem-${id}`} sx={rootSx} disableRipple>
      <Stack
        direction="row"
        alignItems="center"
        gap={1.5}
        sx={{ width: `calc(100% - ${isConfirmingDelete ? 140 : 56}px)` }}
      >
        <LabelsListItemColor color={color} isBeingEdited={isBeingEdited} onColorChange={setColor} />
        <LabelsListItemName name={name} isBeingEdited={isBeingEdited} onChange={onNameChange} />
      </Stack>
      <LabelsListItemActions
        className={clsx(!isBeingEdited && !isConfirmingDelete && 'labelsListItemActions')}
        isBeingEdited={isBeingEdited}
        isConfirmingDelete={isConfirmingDelete}
        onEdit={onEdit}
        onClose={onClose}
        onDelete={onDelete}
        onCancelDelete={onCancelDelete}
        onConfirmDelete={onConfirmDelete}
        onSave={onSave}
        isSaveDisabled={!name || !color || (name === nameProp && color === colorProp)}
      />
    </ListItemButton>
  );
};
