import {
  getBlockAbove,
  KeyboardHandlerReturnType,
  PlateEditor,
  queryNode,
  Value,
  WithPlatePlugin,
} from '@udecode/plate-common';
import isHotkey from 'is-hotkey';
import { exitBreak } from './exit-break';
import { ExitBreakPlugin } from './types';

export const onKeyDownExitBreak =
  (
    editor: PlateEditor,
    { options: { rules = [] } }: WithPlatePlugin<ExitBreakPlugin, Value, PlateEditor>,
  ): KeyboardHandlerReturnType =>
  (event) => {
    if (event.defaultPrevented) return;

    const entry = getBlockAbove(editor);
    if (!entry) return;

    rules.forEach(({ hotkey, ...rule }) => {
      if (isHotkey(hotkey, event as any) && queryNode(entry, rule.query)) {
        if (exitBreak(editor as any, rule)) {
          event.preventDefault();
          event.stopPropagation();
        }
      }
    });
  };
