import { ListItemButton, styled, Typography } from '@mui/material';
import { EntityType } from '@xspecs/single-source-model';
import { DragEventHandler, FC, useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSingleSourceStore } from '../../../../store/single-source-store/single-source-store';
import { Icon } from '../../../../icons/icon';

type PaletteItemProps = {
  type: EntityType;
};

export const PaletteItem: FC<PaletteItemProps> = ({ type }) => {
  const previewRef = useRef(null);

  const { formatMessage: f } = useIntl();

  const setConstructToInsert = useSingleSourceStore.use.setConstructToInsert();
  const setConstructToInsertUsingDrag = useSingleSourceStore.use.setConstructToInsertUsingDrag();

  const onDragStart = useCallback<DragEventHandler>(
    (event) => {
      event.dataTransfer.setData('application/reactflow', type);
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setDragImage(previewRef.current, 0, 0);
      setConstructToInsertUsingDrag(type);
    },
    [setConstructToInsertUsingDrag, type],
  );

  const onDragEnd = useCallback(() => {
    setConstructToInsertUsingDrag(null);
  }, [setConstructToInsertUsingDrag]);

  const onClick = useCallback(() => {
    setConstructToInsert(type);
  }, [setConstructToInsert, type]);

  return (
    <ListItemButton
      disableRipple
      data-testid={`${type}-palette-item`}
      disableTouchRipple
      sx={{ gap: 1 }}
      draggable
      onDragStart={onDragStart}
      onClick={onClick}
      onDragEnd={onDragEnd}
    >
      {type === EntityType.ActionScript ? (
        <Icon ref={previewRef} name="subscript" height={20} width={20} />
      ) : (
        <Preview ref={previewRef} type={type} />
      )}
      <Typography>{f({ id: type })}</Typography>
    </ListItemButton>
  );
};

const Preview = styled('div', {
  shouldForwardProp: (prop) => prop !== 'type',
})<{ type: EntityType }>(({ theme, type }) => ({
  backgroundColor: theme.palette.constructs[type].color,
  aspectRatio: theme.palette.constructs[type].aspectRatio,
  height: 20,
  boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.25)',
}));
