import { Explorer } from './read-models/explorer/Explorer';
import { EntityRepository } from './data/EntityRepository';
import { NotificationTypes, SingleSourceObserver } from './observable/SingleSourceObserver';
import { Graph } from './read-models/graph/Graph';
import { EntityManager } from './entities/EntityManager';
import { Clipboard } from './clipboard/Clipboard';
import { NavigatorClipboard } from './clipboard/NavigatorClipboard';
import { Cache } from './Cache';
import { MessageBus } from './commands/framework/MessageBus';
import { register } from './commands/Register';
import { LabelsInteractor } from './interactors/LabelsInteractor';
import { FiltersInteractor } from './interactors/FiltersInteractor';
import { CanvasInteractor } from './interactors/CanvasInteractor';
import { TransientStore } from './data/Transient';
import { EntitySelectionTracker } from './data/EntitySelectionTracker';
import { localStoreProvider } from './data/LocalStoreProvider';
import { ModelFile } from './data/ModelFile';
import { IStore } from './data/Store';
import { AwarenessTransientStoreProvider } from './data/AwarenessTransientStoreProvider';
import { BoundariesIndex } from './read-models/boundary/BoundariesIndex';
import { EntityDetails } from './read-models/entity-details/EntityDetails';
import { EntityDetailsInteractor } from './interactors/EntityDetailsInteractor';
import { ThreadInteractor } from './interactors/ThreadInteractor';
import { ScriptInteractor } from './interactors/ScriptInteractor';
import { ErrorStore } from './ErrorStore';
import { Annotator } from './read-models/annotator/Annotator';
import { Assets } from './read-models/assets/Assets';

export class SingleSourceModel {
  private static instance: SingleSourceModel;
  public readonly entityRepository: EntityRepository;
  public readonly observer: SingleSourceObserver;
  public readonly graph: Graph;
  public readonly explorer: Explorer;
  public readonly entityDetails: EntityDetails;
  public readonly annotator: Annotator;
  public readonly assets: Assets;
  public readonly entities: EntityManager;
  public readonly clipboard: Clipboard;
  public readonly cache: Cache;
  public readonly boundariesIndex: BoundariesIndex;
  public readonly interactor: CanvasInteractor;
  public readonly labelsInteractor: LabelsInteractor;
  public readonly filtersInteractor: FiltersInteractor;
  public readonly threadInteractor: ThreadInteractor;
  public readonly scriptInteractor: ScriptInteractor;
  public readonly entityDetailsInteractor: EntityDetailsInteractor;
  public isLoaded = false;
  public readonly entitySelectionTracker: EntitySelectionTracker;
  public readonly messageBus: MessageBus;
  public readonly errorStore: ErrorStore;

  private constructor(scopes: string[], public readonly modelFile: ModelFile, public readonly store: IStore) {
    this.cache = new Cache();
    this.errorStore = new ErrorStore(this);
    this.observer = new SingleSourceObserver(scopes);
    this.entityRepository = new EntityRepository(scopes, this.observer, modelFile.activeUser);
    this.explorer = new Explorer(this.entityRepository, modelFile.activeUser.id, store);
    this.boundariesIndex = new BoundariesIndex(this.entityRepository);
    this.graph = new Graph(this.entityRepository, store, this.boundariesIndex);
    this.entityDetails = new EntityDetails(this.entityRepository, store);
    this.annotator = new Annotator(this.entityRepository, store);
    this.assets = new Assets(this.entityRepository, store);
    this.entities = new EntityManager(this, this.entityRepository, this.observer, this.cache, modelFile.activeUser);
    this.messageBus = new MessageBus(this);
    register(this.messageBus, this);
    this.interactor = new CanvasInteractor(this, store, modelFile.awareness);
    this.labelsInteractor = new LabelsInteractor(this);
    this.filtersInteractor = new FiltersInteractor(this);
    this.threadInteractor = new ThreadInteractor(this);
    this.scriptInteractor = new ScriptInteractor(this);
    this.entityDetailsInteractor = new EntityDetailsInteractor(this);
    this.entitySelectionTracker = new EntitySelectionTracker(
      this.entityRepository,
      modelFile.awareness,
      this.observer,
      this,
    );
    this.clipboard = new Clipboard(new NavigatorClipboard(), this.entityRepository, this.entitySelectionTracker);
    TransientStore.provider = new AwarenessTransientStoreProvider(modelFile.awareness, this.entitySelectionTracker);
    modelFile.onAwarenessUpdate((states) => this.entitySelectionTracker.onAwarenessUpdate(states));
    localStoreProvider.setPersistedStore(store);
  }

  static getInstance(scopes: string[], modelFile: ModelFile, store: IStore) {
    if (!this.instance) this.instance = new SingleSourceModel(scopes, modelFile, store);
    this.instance.changeScopes(scopes);
    return this.instance;
  }

  public undo() {
    this.observer.notify(NotificationTypes.OnUndo);
  }

  public redo() {
    this.observer.notify(NotificationTypes.OnRedo);
  }

  public validateChanges(changes: { added: any[]; updated: any[]; deleted: any[] }): boolean {
    return this.entityRepository.validateChanges(changes);
  }

  public dispose() {
    SingleSourceModel.instance = null;
    this.entities.dispose();
    this.entityRepository.dispose();
    this.observer.dispose();
    this.graph.dispose();
    this.clipboard.dispose();
  }

  public changeScopes(scopes: string[]) {
    this.entityRepository.changeScopes(scopes);
    this.observer.changeScopes(scopes);
    this.observer.notify(NotificationTypes.onScopesChange, { added: this.entities.list(), updated: [], deleted: [] });
  }
}
