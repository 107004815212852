import { CursorIcon } from '@xspecs/ui-assets';
import { SingleSourceModelToolbarButton } from '../button';
import { useSingleSourceStore } from '../../../../store/single-source-store/single-source-store';
import { useIntl } from 'react-intl';
import { Mode } from '@xspecs/single-source-model';

export const SingleSourceModelToolbarSelectorToolButton = () => {
  const { formatMessage: f } = useIntl();

  const isActive = useSingleSourceStore.use.mode() === Mode.SELECTION;
  const setSelectionMode = useSingleSourceStore.use.setSelectionMode();

  return (
    <SingleSourceModelToolbarButton
      Icon={<CursorIcon />}
      isActive={isActive}
      onClick={setSelectionMode}
      tooltip={f({ id: 'select' })}
    />
  );
};
