import React, { useMemo } from 'react';
import { CursorOverlayData } from '@slate-yjs/react';
import { CursorData } from '../../types/editor-common.types';
import { Box, SxProps, Theme } from '@mui/material';
import { autoPlacement, useFloating } from '@floating-ui/react';

type CaretProps = Pick<CursorOverlayData<CursorData>, 'caretPosition' | 'data'>;

export const RemoteUserCaret = (props: CaretProps) => {
  const { caretPosition, data } = props;

  const { refs, floatingStyles, placement } = useFloating({
    middleware: [autoPlacement({ allowedPlacements: ['top-start', 'top-end'] })],
  });

  const rootSx: SxProps<Theme> = useMemo(
    () => ({
      ...caretPosition,
      background: data?.color,
      position: 'absolute',
      width: '0.125rem',
      zIndex: 100000,
    }),
    [caretPosition, data?.color],
  );

  const contentSx: SxProps<Theme> = useMemo(
    () => ({
      background: data?.color,
      paddingTop: '0.125rem',
      paddingBottom: '0.125rem',
      paddingLeft: '0.375rem',
      paddingRight: '0.375rem',
      color: '#ffffff',
      fontSize: '0.75rem',
      lineHeight: '1rem',
      whiteSpace: 'nowrap',
      borderRadius: '0.25rem',
      borderBottomLeftRadius: placement === 'top-start' ? '0' : undefined,
      borderBottomRightRadius: placement === 'top-end' ? '0' : undefined,
    }),
    [data?.color, placement],
  );

  return (
    <Box>
      <Box ref={refs.setReference} sx={rootSx}></Box>
      <Box ref={refs.setFloating} sx={{ ...floatingStyles, ...contentSx }}>
        {data?.name}
      </Box>
    </Box>
  );
};
