import { SingleSourceModel } from '../SingleSourceModel';
import { ApplySavedFilterCommand } from '../commands/filters/ApplySavedFilterCommand';
import { DeleteFilterCommand, DeleteFilterParams } from '../commands/filters/DeleteFilterCommand';
import { RemoveFilterCriteriaCommand } from '../commands/filters/RemoveFilterCriteriaCommand';
import { SaveFiltersCommand } from '../commands/filters/SaveFiltersCommand';
import { SetUnsavedFilterCommand } from '../commands/filters/SetUnsavedFilterCommand';
import { UnapplySavedFilterCommand } from '../commands/filters/UnapplySavedFilterCommand';
import { UpdateSavedFilterParams, UpdateSavedFiltersCommand } from '../commands/filters/UpdateSavedFiltersCommand';
import { sid } from '@xspecs/short-id';
import { FilterType } from '../entities/Filter';
import { EntityBase } from '../entities/EntityBase';

export class FiltersInteractor {
  constructor(private readonly model: SingleSourceModel) {}

  applyFilter(criteria: EntityBase[]) {
    this.model.messageBus.send(SetUnsavedFilterCommand, {
      id: sid(),
      criteria,
    });
  }

  removeFilter(params: { id: string; type: FilterType }) {
    this.model.messageBus.send(DeleteFilterCommand, params);
  }

  removeFilterCriteria(params: { id: string; type: FilterType; criteriaIds: string[] }) {
    this.model.messageBus.send(RemoveFilterCriteriaCommand, params);
  }

  saveFilter({ id, name }: { id: string; name: string }) {
    this.model.messageBus.send(SaveFiltersCommand, {
      unsavedFilterId: id,
      name,
      filterType: FilterType.publicSaved,
    });
  }

  applySavedFilter(id: string) {
    this.model.messageBus.send(ApplySavedFilterCommand, { id });
  }

  removeSavedFilter(id: string) {
    this.model.messageBus.send(UnapplySavedFilterCommand, { id });
  }

  updateSavedFilters(params: UpdateSavedFilterParams) {
    this.model.messageBus.send(UpdateSavedFiltersCommand, params);
  }

  deleteSavedFilter(params: DeleteFilterParams) {
    this.model.messageBus.send(DeleteFilterCommand, params);
  }
}
