import { useIntl } from 'react-intl';
import { DocEditor } from '../../../doc/editor/doc-editor';
import { Box, Stack } from '@mui/material';

type EntityDocProps = {
  entityId: string;
};

export const EntityDescription = (props: EntityDocProps) => {
  const { entityId } = props;

  const { formatMessage: f } = useIntl();

  return (
    <Stack gap={1} height="100%">
      <Box
        sx={{
          width: '100%',
          borderRadius: '8px',
          border: '1px solid #E0E0E0',
          p: 1,
          minHeight: '100%',
          overflow: 'visible',
        }}
      >
        <DocEditor documentId={`${entityId}-doc`} placeholder={f({ id: 'description' })} />
      </Box>
    </Stack>
  );
};
