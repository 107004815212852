import { Box } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { UserRolesListItem } from './user-roles-list-item';
import { REMOVE_USER_FROM_ORGANIZATION_MUTATION, UPDATE_USER_ROLE_MUTATION } from '../../../graphql/mutations';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { USERS_QUERY } from '../../../graphql/queries';
import { useIntl } from 'react-intl';
import { useSnackStack } from '../../../wrappers/snack-stack-context';
import { useAuth } from '../../../auth';
import { useActiveOrganization } from '../../../hooks/use-active-organization';

type OrganizationSettingsModalUsersProps = {
  onClose: () => void;
};

export const OrganizationSettingsModalUsers = ({ onClose }: OrganizationSettingsModalUsersProps) => {
  const { formatMessage: f } = useIntl();
  const activeOrganization = useActiveOrganization();

  const [updateUserRole] = useMutation(UPDATE_USER_ROLE_MUTATION);
  const [removeUserFromOrganization] = useMutation(REMOVE_USER_FROM_ORGANIZATION_MUTATION);

  const { data: userData } = useQuery(USERS_QUERY, { variables: { organizationId: activeOrganization.id } });

  const [users, setUsers] = useState([]);

  const { addToast } = useSnackStack();

  const { user } = useAuth();

  const handleRoleChange = useCallback(
    async (index: number, { newRole, userToUpdateId }) => {
      const name = userData.users.find((user) => user.id === userToUpdateId)?.name;
      const response = await updateUserRole({
        variables: {
          args: {
            organizationId: activeOrganization.id,
            userToUpdate: userToUpdateId,
            role: newRole,
          },
        },
      });
      if (response.data.updateUserRole.error) {
        addToast({ message: f({ id: 'organization-user-role-update-error' }, { name }), severity: 'error' });
      } else {
        addToast({
          message: f({ id: 'organization-user-role-update-success' }, { name, role: newRole }),
          severity: 'success',
        });
      }
    },
    [activeOrganization?.id, addToast, f, updateUserRole, userData],
  );

  const handleRemove = useCallback(
    async (index: number) => {
      const removedUser = [...users];
      const userToRemove = removedUser.splice(index, 1)[0];
      setUsers(removedUser);

      const name = userToRemove.name;
      const response = await removeUserFromOrganization({
        variables: {
          args: {
            organizationId: activeOrganization.id,
            userToRemove: userToRemove.id,
          },
        },
      });

      if (response.data.removeUserFromOrganization.error) {
        addToast({ message: f({ id: 'organization-user-remove-error' }, { name }), severity: 'error' });
        setUsers(userData.users);
      } else {
        addToast({ message: f({ id: 'organization-user-remove-success' }, { name }), severity: 'success' });
      }
    },
    [activeOrganization.id, addToast, f, removeUserFromOrganization, userData, users],
  );

  const myUser = useMemo(() => {
    return users.find((u) => u.id === user?.sub);
  }, [user?.sub, users]);

  useEffect(() => {
    if (userData && userData.users) {
      setUsers(userData.users);
    }
  }, [userData]);

  if (!userData) return null;

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        maxHeight: 300,
        overflowY: 'scroll',
      }}
    >
      {users.map(({ role, name, picture, id }, index) => (
        <UserRolesListItem
          key={id}
          role={role}
          name={name}
          picture={picture}
          userId={id}
          onRoleChange={(newRole) => handleRoleChange(index, newRole)}
          onRemove={() => handleRemove(index)}
          myRole={myUser?.role}
        />
      ))}
    </Box>
  );
};
