import { EntityType } from '../EntityType';
import { z } from 'zod';
import { AssetBase } from './AssetBase';
import { Position } from '../../types';

export enum UploadType {
  Image = 'image',
  File = 'file',
}

export type UploadMetadata = {
  title?: string;
  imageUrl?: string | null;
  favicon?: string | null;
  width?: number;
  height?: number;
};

export const defaultMetadata = {
  title: '',
  imageUrl: null,
  favicon: null,
};

export class Upload extends AssetBase {
  public metadata: UploadMetadata = defaultMetadata;

  constructor(
    id: string,
    name: string,
    subType?: UploadType,
    url?: string,
    metadata: UploadMetadata = { title: '', imageUrl: null, favicon: null },
    position: Position = { x: 0, y: 0 },
    scopes: string[] = [],
  ) {
    super(id, name, undefined, position, scopes);
    this.url = url;
    this.subType = subType;
    this.metadata = metadata;
  }

  static baseSchema = AssetBase.baseSchema.extend({
    url: z
      .string()
      .optional()
      .describe(
        `
      * the url of the upload
    `,
      ),
    subType: z.string().optional().describe(`
      * the type of the upload
    `),
    metadata: z
      .object({
        title: z.string().optional().default(''),
        imageUrl: z.string().nullable().optional().default(null),
        favicon: z.string().nullable().optional().default(null),
        width: z.number().optional().default(0),
        height: z.number().optional().default(0),
      })
      .optional()
      .default({}),
  });
  static references = [];

  public url?: string;

  public subType?: UploadType;

  get type(): EntityType {
    return EntityType.Upload;
  }

  static parse(data: unknown): Upload {
    const validatedData = Upload.baseSchema.parse(data, Upload.baseSchema);
    const upload = new Upload(
      validatedData.id,
      validatedData.name,
      validatedData.subType as UploadType,
      validatedData.url,
      validatedData.metadata,
      validatedData.position,
      validatedData.scopes,
    );

    return upload;
  }

  public serialize(reference: boolean = false): unknown {
    if (reference) return super.serialize(reference);
    return {
      ...(super.serialize() as any),
      id: this.id,
      url: this.url,
      subType: this.subType,
      metadata: this.metadata,
    };
  }
}
