import { ToggleButton, ToggleButtonProps } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';

type BalloonMenuBoldProps = { Icon: SvgIconComponent } & ToggleButtonProps;

export const BalloonMenuMarkButton = (props: BalloonMenuBoldProps) => {
  const { Icon, ...buttonProps } = props;

  return (
    <ToggleButton sx={{ minWidth: '40px', border: 'none', borderRadius: 'unset' }} {...buttonProps}>
      <Icon color="action" />
    </ToggleButton>
  );
};
