import {
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useFetch } from '../../../hooks/use-fetch';
import { Loading } from '../../loading/loading';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { GqlGraph } from '@xspecs/single-source-model';
import { logger } from '@xspecs/logger';
import { fetchGraphDetails } from './instant-mock-api';
import { useSnackStack } from '../../../wrappers/snack-stack-context';
import { Icon } from '../../../icons/icon';
import { OpenInNew } from '@mui/icons-material';
import { getProposalLink } from './query-view';

type InstantMockConfigProps = {
  queryId: string;
};

export const InstantMockConfig = (props: InstantMockConfigProps) => {
  const { queryId } = props;

  const { formatMessage: f } = useIntl();

  const { addToast } = useSnackStack();

  const setInstantMockUrl = useSingleSourceStore.use.setInstantMockUrl();
  const instantMockUrl = useSingleSourceStore.use.instantMockUrl();
  const selectedGraphs = useSingleSourceStore.use.selectedGraph();
  const selectedGraph = selectedGraphs[queryId] ?? null;
  const setSelectedGraph = useSingleSourceStore.use.setSelectedGraph();
  const variantProposal = useSingleSourceStore.use.variantProposal();
  const setVariantProposal = useSingleSourceStore.use.setVariantProposal();
  const graphDetails = useSingleSourceStore.use.graphDetails();
  const setGraphDetails = useSingleSourceStore.use.setGraphDetails();
  const loadingVariantsProposal = useSingleSourceStore.use.loadingVariantsProposal();
  const setLoadingVariantsProposal = useSingleSourceStore.use.setLoadingVariantsProposal();
  const isCopyGqlDisabled = !selectedGraph || !variantProposal;

  const proposal = useMemo(() => {
    return graphDetails?.proposals.find((proposal) => proposal.key === variantProposal);
  }, [graphDetails?.proposals, variantProposal]);

  const isInstantMockUrlValid = useMemo(() => {
    try {
      URL.canParse(instantMockUrl);
      return true;
    } catch {
      return false;
    }
  }, [instantMockUrl]);

  const {
    data,
    loading: loadingGraph,
    error: graphFetchError,
  } = useFetch<GqlGraph[]>(instantMockUrl + '/graphs', undefined, isInstantMockUrlValid, []);

  const graphs = Array.isArray(data) ? data : [];

  const reset = useCallback(() => {
    setGraphDetails(null);
    setVariantProposal('');
  }, [setGraphDetails, setVariantProposal]);

  const onInstantMockUrlChange = useCallback(
    (event) => {
      const potentialUrl = event.target.value.trim();
      setInstantMockUrl(potentialUrl);
    },
    [setInstantMockUrl],
  );

  const onGraphChange = useCallback(
    async (event) => {
      reset();
      const newGraph = event.target.value as string;
      setSelectedGraph({ selectedGraph: newGraph, queryId });
      setLoadingVariantsProposal(true);

      try {
        const data = await fetchGraphDetails(newGraph);
        setGraphDetails(data);
        const current = data.variants.find((variant) => variant.key?.endsWith('current'));
        setVariantProposal(current?.key ?? null);
      } catch (error) {
        logger.error('Error fetching graph details:', error);
        addToast({ severity: 'error', message: f({ id: 'something-went-wrong' }) });
      } finally {
        setLoadingVariantsProposal(false);
      }
    },
    [reset, setSelectedGraph, queryId, setLoadingVariantsProposal, setGraphDetails, setVariantProposal, addToast, f],
  );

  const onVariantProposalChange = useCallback(
    (event) => {
      setVariantProposal(event.target.value as string);
    },
    [setVariantProposal],
  );

  const onCopyGqlLink = useCallback(async () => {
    if (isCopyGqlDisabled) return;
    const [graphId, variantOrProposal] = variantProposal.split('@');
    await navigator.clipboard.writeText(
      `${instantMockUrl.replace('/api', '')}/${graphId}/${variantOrProposal}/graphql`,
    );
    addToast({ severity: 'success', message: f({ id: 'link-copied-to-clipboard' }) });
  }, [addToast, f, instantMockUrl, isCopyGqlDisabled, variantProposal]);

  useEffect(() => {
    if (selectedGraph && variantProposal && !graphDetails) {
      onGraphChange({ target: { value: selectedGraph } });
    }
  }, [graphDetails, onGraphChange, selectedGraph, variantProposal]);

  return (
    <Stack gap={2}>
      <TextField
        size="small"
        label={f({ id: 'instant-mock-server-url' })}
        onChange={onInstantMockUrlChange}
        value={instantMockUrl}
        helperText={
          isInstantMockUrlValid
            ? graphFetchError
              ? f({ id: 'error-occurred-while-fetching' })
              : undefined
            : f({ id: 'invalid-url' })
        }
        error={!!graphFetchError}
        InputProps={{
          endAdornment: (
            <Tooltip
              title={isCopyGqlDisabled ? f({ id: 'select-variant-graph-to-copy' }) : f({ id: 'copy-gql-endpoint' })}
              placement="top"
            >
              <span>
                <IconButton size="small" onClick={onCopyGqlLink} disabled={isCopyGqlDisabled} sx={{ color: 'action' }}>
                  <Icon name="copy-gql-link" width={28} height={28} />
                </IconButton>
              </span>
            </Tooltip>
          ),
          sx: {
            pr: 1,
          },
        }}
      />
      <Stack direction="row" gap={1.5}>
        {loadingGraph ? (
          <Loading rootProps={{ width: '50%' }} circularProgressProps={{ size: 16 }} />
        ) : (
          <FormControl sx={{ width: '50%' }}>
            <InputLabel id="GraphSelectLabel" sx={{ mt: '-7px' }}>
              {f({ id: graphs.length === 0 ? 'no-graphs-available' : 'graph' })}
            </InputLabel>
            <Select
              size="small"
              labelId="GraphSelectLabel"
              id="GraphSelect"
              value={selectedGraph}
              label={f({ id: 'graph' })}
              onChange={onGraphChange}
              disabled={Boolean(!graphs || loadingGraph || !isInstantMockUrlValid || graphFetchError || !graphs.length)}
            >
              {graphs.map((graph) => (
                <MenuItem key={graph.id} value={graph.id}>
                  {graph.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {loadingVariantsProposal ? (
          <Loading rootProps={{ width: '50%' }} circularProgressProps={{ size: 16 }} />
        ) : (
          <FormControl sx={{ width: '50%' }}>
            <InputLabel id="VariantProposalSelectLabel" sx={{ mt: '-7px' }}>
              {f({ id: 'variant-proposal' })}
            </InputLabel>
            <Select
              size="small"
              labelId="VariantProposalSelectLabel"
              id="VariantProposalSelect"
              value={variantProposal}
              label={f({ id: 'variant-proposal' })}
              onChange={onVariantProposalChange}
              disabled={
                !selectedGraph ||
                !graphDetails ||
                !graphDetails.variants ||
                !graphDetails.variants.length ||
                loadingGraph
              }
            >
              <ListSubheader>{f({ id: 'variants' })}</ListSubheader>
              <Divider />
              {graphDetails?.variants.map((variant) => (
                <MenuItem key={variant.key} value={variant.key}>
                  {variant.name}
                </MenuItem>
              ))}
              <ListSubheader>{f({ id: 'proposals' })}</ListSubheader>
              <Divider />
              {graphDetails?.proposals.map((proposal) => (
                <MenuItem key={proposal.key} value={proposal.key}>
                  {proposal.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Tooltip title={f({ id: proposal ? 'open-proposal' : 'select-a-proposal' })} placement="top">
          <span>
            <IconButton
              disabled={!proposal}
              onClick={() => {
                window.open(getProposalLink(proposal.key), '_blank');
              }}
            >
              <OpenInNew />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
    </Stack>
  );
};
