import { Chip, useTheme, styled } from '@mui/material';
import { LabelTemp } from '@xspecs/single-source-model';
import { useMemo } from 'react';
import { Overlay } from '../../../single-source-model/canvas/constructs/overlay/overlay';

type LabelsStackItemProps = LabelTemp & { hasOverlay: boolean };

export const LabelsStackItem = (props: LabelsStackItemProps) => {
  const { id, name, color, hasOverlay } = props;

  const theme = useTheme();

  const sx = useMemo(() => {
    return {
      backgroundColor: color,
      color: theme.palette.getContrastText(color),
      maxWidth: 140,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      pointerEvents: hasOverlay ? 'none' : 'auto',
    };
  }, [color, hasOverlay, theme.palette]);

  return (
    <Root>
      {hasOverlay ? <LabelOverlay /> : null}
      <Chip key={id} title={name} label={name} sx={sx} />
    </Root>
  );
};

const Root = styled('div')({
  height: '100%',
  width: 'min-content',
  position: 'relative',
});

const LabelOverlay = styled(Overlay)(({ theme }) => ({
  borderRadius: theme.spacing(3),
}));
