import { Stack } from '@mui/material';
import { PlansListItem } from './item/plans-list-item';
import { SubscriptionPlansQuery } from '../../../../gql/graphql';

type PlansListProps = {
  plans: SubscriptionPlansQuery['subscriptionPlans'];
};

export const PlansList = (props: PlansListProps) => {
  const { plans } = props;

  return (
    <Stack direction="row" gap={15} flexWrap="wrap" alignItems="flex-start" justifyContent="center">
      {plans.map((plan) => (
        <PlansListItem key={plan.id} plan={plan} />
      ))}
    </Stack>
  );
};
