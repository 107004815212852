import { FC } from 'react';
import { IconComponentProps } from '../icon';

export const Expand: FC<IconComponentProps> = () => {
  return (
    <>
      <path
        d="M30.6195 5.36081C30.618 5.34461 30.6149 5.32892 30.6126 5.31298C30.6099 5.29365 30.6076 5.27427 30.6038 5.25504C30.6002 5.23669 30.5951 5.2189 30.5906 5.2009C30.5864 5.18434 30.5828 5.16768 30.5778 5.15127C30.5725 5.13358 30.5658 5.11651 30.5596 5.09923C30.5537 5.08288 30.5483 5.06637 30.5417 5.05022C30.535 5.03407 30.5271 5.01864 30.5197 5.0029C30.5119 4.98629 30.5045 4.96952 30.4958 4.95322C30.4877 4.93814 30.4785 4.92379 30.4697 4.90913C30.4602 4.89318 30.4512 4.87703 30.4408 4.86145C30.4299 4.84524 30.4178 4.83002 30.4062 4.81443C30.3965 4.80146 30.3874 4.78813 30.3771 4.77547C30.3544 4.74783 30.3305 4.72122 30.3052 4.69595C30.305 4.69574 30.3049 4.69554 30.3046 4.69533C30.3044 4.69513 30.3042 4.69502 30.3041 4.69482C30.2788 4.66954 30.2522 4.6456 30.2245 4.62289C30.2118 4.61248 30.1985 4.60346 30.1855 4.59372C30.17 4.58208 30.1547 4.57003 30.1385 4.55921C30.1229 4.5488 30.1068 4.53978 30.0908 4.53024C30.0761 4.52148 30.0618 4.5123 30.0468 4.5042C30.0305 4.49548 30.0137 4.4881 29.9971 4.48026C29.9813 4.47282 29.9659 4.46498 29.9498 4.45826C29.9336 4.4516 29.9171 4.44616 29.9007 4.44032C29.8834 4.43411 29.8664 4.4275 29.8488 4.42217C29.8323 4.41719 29.8156 4.41355 29.7991 4.4094C29.7811 4.40484 29.7633 4.39981 29.745 4.39617C29.7258 4.39238 29.7064 4.39007 29.6871 4.3873C29.6711 4.38505 29.6554 4.38197 29.6392 4.38038C29.6033 4.3769 29.5673 4.375 29.5312 4.375H21.875C21.2709 4.375 20.7812 4.86468 20.7812 5.46873C20.7812 6.07279 21.2709 6.56247 21.875 6.56247H26.8907L18.9141 14.5391C18.487 14.9662 18.487 15.6588 18.9141 16.0859C19.3412 16.513 20.0338 16.513 20.4609 16.0859L28.4375 8.10932V13.125C28.4375 13.7291 28.9271 14.2187 29.5312 14.2187C30.1353 14.2187 30.625 13.7291 30.625 13.125V5.46884C30.625 5.43274 30.6231 5.3967 30.6195 5.36081Z"
        fill="white"
      />
      <path
        d="M14.5391 18.9141L6.56252 26.8907V21.875C6.56252 21.271 6.07284 20.7813 5.46878 20.7813C4.86468 20.7813 4.375 21.271 4.375 21.875V29.5312C4.375 29.5673 4.3769 29.6033 4.38043 29.6392C4.38202 29.6553 4.38505 29.671 4.38736 29.687C4.39012 29.7063 4.39238 29.7258 4.39623 29.7449C4.39987 29.7633 4.40489 29.781 4.40945 29.799C4.41361 29.8156 4.41725 29.8323 4.42222 29.8487C4.42755 29.8663 4.43417 29.8834 4.44037 29.9006C4.44626 29.9171 4.45165 29.9336 4.45831 29.9497C4.46498 29.9659 4.47287 29.9813 4.48031 29.997C4.48815 30.0136 4.49553 30.0304 4.50425 30.0467C4.51235 30.0618 4.52153 30.0761 4.5303 30.0908C4.53983 30.1067 4.54885 30.1229 4.55926 30.1385C4.57008 30.1547 4.58213 30.1699 4.59377 30.1854C4.60351 30.1985 4.61253 30.2118 4.62294 30.2245C4.64565 30.2521 4.66959 30.2788 4.69487 30.304C4.69508 30.3042 4.69518 30.3044 4.69538 30.3046C4.69559 30.3048 4.69579 30.305 4.696 30.3052C4.72127 30.3304 4.74788 30.3543 4.77552 30.377C4.78818 30.3874 4.80151 30.3965 4.81448 30.4062C4.83007 30.4178 4.8453 30.4299 4.8615 30.4407C4.87703 30.4511 4.89318 30.4601 4.90918 30.4697C4.92384 30.4785 4.93814 30.4876 4.95327 30.4957C4.96957 30.5044 4.98634 30.5118 5.00295 30.5197C5.01869 30.5271 5.03417 30.5349 5.05027 30.5416C5.06642 30.5483 5.08288 30.5537 5.09928 30.5596C5.11656 30.5658 5.13364 30.5724 5.15132 30.5778C5.16773 30.5827 5.18439 30.5864 5.20095 30.5905C5.21895 30.5951 5.23674 30.6001 5.25509 30.6038C5.27432 30.6075 5.2937 30.6098 5.31303 30.6126C5.32897 30.6148 5.34471 30.6179 5.36086 30.6195C5.39675 30.623 5.43274 30.6249 5.46873 30.6249H13.125C13.7291 30.6249 14.2188 30.1353 14.2188 29.5312C14.2188 28.9271 13.7291 28.4375 13.125 28.4375H8.10932L16.0859 20.4608C16.5131 20.0337 16.5131 19.3412 16.0859 18.914C15.6588 18.4869 14.9663 18.487 14.5391 18.9141Z"
        fill="white"
      />
    </>
  );
};
