import { AssetBase } from './AssetBase';
import { EntityType } from '../EntityType';

export class Doc extends AssetBase {
  get type(): EntityType {
    return EntityType.Doc;
  }

  static parse(data: unknown): Doc {
    return AssetBase.parseBase.call(Doc, data);
  }
}
