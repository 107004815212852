export interface TransientStoreProvider {
  get(key: string, parentKey?: string): any;

  getGlobalSelection(key: string): any;

  getLocalSelection(key: string): any;

  set(key: string, value: any, parentKey?: string, sync?: boolean): void;

  setBoolean?(key: string, value: boolean, parentKey?: string, sync?: boolean): void;

  observe(callback: (...any) => void): void;
}

export class DefaultTransientStoreProvider implements TransientStoreProvider {
  private store = new Map<string, any>();

  observe(callback: (...any: any[]) => any): void {
    throw new Error('Method not implemented.', callback());
  }

  get(key: string): any {
    return this.store.get(key);
  }

  getGlobalSelection(key: string): any {
    return this.get(key);
  }

  getLocalSelection(key: string): any {
    return this.get(key);
  }

  set(key: string, value: any): void {
    this.store.set(key, value);
  }

  setBoolean(key: string, value: any): void {
    this.store.set(key, value);
  }
}

export function getKey(that: any, propertyKey: string): string {
  if (that.id) return `${that.id}_${propertyKey}`;
  else return propertyKey;
}

export class TransientStore {
  static provider: TransientStoreProvider;
  static getKey = getKey;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function Transient(target: any, propertyKey: string): void {
  function getter() {
    return TransientStore.provider.get(getKey(this, propertyKey));
  }

  function setter(newValue: any) {
    TransientStore.provider.set(getKey(this, propertyKey), newValue);
  }

  Object.defineProperty(target, propertyKey, {
    get: getter,
    set: setter,
    enumerable: true,
    configurable: true,
  });

  Object.defineProperty(target, `__isTransient_${propertyKey}`, {
    value: true,
    writable: false,
    enumerable: false,
    configurable: true,
  });
}

export function isTransient(instance: any, propertyKey: string): boolean {
  return !!instance[`__isTransient_${propertyKey}`];
}
