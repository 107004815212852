import { FC } from 'react';
import { IconComponentProps } from '../icon';

export const Doc: FC<IconComponentProps> = ({ color }) => {
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V2H4ZM20 4H4V20H20V4Z"
        fill={color}
      />
      <path
        d="M15.3333 12.5H8.66667C8.48986 12.5 8.32029 12.421 8.19526 12.2803C8.07024 12.1397 8 11.9489 8 11.75C8 11.5511 8.07024 11.3603 8.19526 11.2197C8.32029 11.079 8.48986 11 8.66667 11H15.3333C15.5101 11 15.6797 11.079 15.8047 11.2197C15.9298 11.3603 16 11.5511 16 11.75C16 11.9489 15.9298 12.1397 15.8047 12.2803C15.6797 12.421 15.5101 12.5 15.3333 12.5Z"
        fill={color}
      />
      <path
        d="M15.3333 9.5H8.66667C8.48986 9.5 8.32029 9.42098 8.19526 9.28033C8.07024 9.13968 8 8.94891 8 8.75C8 8.55109 8.07024 8.36032 8.19526 8.21967C8.32029 8.07902 8.48986 8 8.66667 8H15.3333C15.5101 8 15.6797 8.07902 15.8047 8.21967C15.9298 8.36032 16 8.55109 16 8.75C16 8.94891 15.9298 9.13968 15.8047 9.28033C15.6797 9.42098 15.5101 9.5 15.3333 9.5Z"
        fill={color}
      />
      <path
        d="M12.6667 15.5H8.66667C8.48986 15.5 8.32029 15.421 8.19526 15.2803C8.07024 15.1397 8 14.9489 8 14.75C8 14.5511 8.07024 14.3603 8.19526 14.2197C8.32029 14.079 8.48986 14 8.66667 14H12.6667C12.8435 14 13.013 14.079 13.1381 14.2197C13.2631 14.3603 13.3333 14.5511 13.3333 14.75C13.3333 14.9489 13.2631 15.1397 13.1381 15.2803C13.013 15.421 12.8435 15.5 12.6667 15.5Z"
        fill={color}
      />
    </>
  );
};
