import { CommandBase, IParams } from '../framework/CommandBase';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

interface AddScriptFrameParams extends IParams {
  scriptId: string;
  frameIndex: number;
}

interface ScriptFrameAddedParams extends IParams {
  scriptId: string;
  laneIndex: number;
}

export class ScriptFrameAddedEvent extends EventBase {
  static eventType = 'ScriptFrameAddedEvent';

  constructor(public readonly params: ScriptFrameAddedParams, public readonly source = AddScriptFrameCommand) {
    super();
  }
}

export class AddScriptFrameCommand extends CommandBase<AddScriptFrameParams> {
  execute(params: AddScriptFrameParams): ScriptFrameAddedEvent | CommandError {
    const script = this.model.entityRepository.get<ScriptBase>(params.scriptId);
    if (!script.addFrame(params.frameIndex)) return CommandError.of(script.getError(), 'error');
    this.model.entityRepository.update(script);
    return new ScriptFrameAddedEvent({ scriptId: params.scriptId, laneIndex: params.frameIndex });
  }
}
