import { PlateElement, PlateLeaf, PlatePluginComponent } from '@udecode/plate-common';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import { ELEMENT_H1, ELEMENT_H2, ELEMENT_H3, ELEMENT_H4, ELEMENT_H5, ELEMENT_H6 } from '@udecode/plate-heading';
import { MARK_BOLD, MARK_ITALIC, MARK_STRIKETHROUGH, MARK_UNDERLINE } from '@udecode/plate-basic-marks';
import { ELEMENT_LI, ELEMENT_OL, ELEMENT_UL } from '@udecode/plate-list';
import { withProps } from '@udecode/cn';
import { HeadingElement } from '../../../plate-components/heading-element';
import { ParagraphElement } from '../../../plate-components/paragraph-element';
import { LinkElement } from '../../../plate-components/link-element';
import { ImageElement } from '../../../plate-ui/image-element';
import { ListElement } from '../../../plate-components/list-element';
import { ELEMENT_CLOUD_ATTACHMENT, ELEMENT_CLOUD_IMAGE } from '@udecode/plate-cloud';
import { CloudAttachmentElement } from '../../../plate-ui/cloud-attachment-element';
import { CloudImageElement } from '../../../plate-ui/cloud-image-element';
import { LinkDocElement } from '../../../plate-components/link-doc-element/link-doc-element';
import { MentionElement } from '../../../plate-ui/mention-element';
import { MentionInputElement } from '../../../plate-ui/mention-input-element';
import { ELEMENT_IMAGE } from '@udecode/plate-media';
import { ELEMENT_LINK } from '@udecode/plate-link';
import { ELEMENT_MENTION, ELEMENT_MENTION_INPUT } from '@udecode/plate-mention';
import { ELEMENT_LINKED_DOCUMENT } from './linked-document-plugin/linked-document-plugin';

export const components: Record<string, PlatePluginComponent> = {
  [ELEMENT_PARAGRAPH]: ParagraphElement,
  [ELEMENT_H1]: withProps(HeadingElement, { variant: 'h1' }),
  [ELEMENT_H2]: withProps(HeadingElement, { variant: 'h2' }),
  [ELEMENT_H3]: withProps(HeadingElement, { variant: 'h3' }),
  [ELEMENT_H4]: withProps(HeadingElement, { variant: 'h4' }),
  [ELEMENT_H5]: withProps(HeadingElement, { variant: 'h5' }),
  [ELEMENT_H6]: withProps(HeadingElement, { variant: 'h6' }),

  [ELEMENT_LINK]: LinkElement,
  [ELEMENT_IMAGE]: ImageElement,
  [ELEMENT_UL]: withProps(ListElement, { variant: 'ul' }),
  [ELEMENT_OL]: withProps(ListElement, { variant: 'ol' }),
  [ELEMENT_LI]: withProps(PlateElement, { as: 'li' }),
  [ELEMENT_CLOUD_ATTACHMENT]: CloudAttachmentElement,
  [ELEMENT_CLOUD_IMAGE]: CloudImageElement,

  [ELEMENT_LINKED_DOCUMENT]: LinkDocElement,

  [MARK_BOLD]: withProps(PlateLeaf, { as: 'strong' }),
  [MARK_ITALIC]: withProps(PlateLeaf, { as: 'em' }),
  [MARK_STRIKETHROUGH]: withProps(PlateLeaf, { as: 's' }),
  [MARK_UNDERLINE]: withProps(PlateLeaf, { as: 'u' }),

  // [MARK_COMMENT]: CommentLeaf,

  [ELEMENT_MENTION]: MentionElement,
  [ELEMENT_MENTION_INPUT]: MentionInputElement,
};
