import { IconButton as MuiIconButton, styled } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { MouseEventHandler } from 'react';

type ExpandIconProps = {
  isExpanded: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export const ExpandIcon = (props: ExpandIconProps) => {
  const { isExpanded, onClick } = props;

  return (
    <IconButton data-testid="ExpandIcon" onClick={onClick} size="small">
      <ExpandMoreIconContainer isExpanded={isExpanded}>
        <ExpandMore color="action" />
      </ExpandMoreIconContainer>
    </IconButton>
  );
};

const IconButton = styled(MuiIconButton)({
  padding: 0,
});

const ExpandMoreIconContainer = styled(ExpandMore, {
  shouldForwardProp: (prop) => prop !== 'isExpanded',
})<{ isExpanded: boolean }>(({ isExpanded }) => ({
  transition: 'transform 0.1s',
  transform: !isExpanded && 'rotate(-90deg)',
  width: '100%',
}));
