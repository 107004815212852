import { Button, Stack } from '@mui/material';
import { ArrowBackOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { getRoutePathFromParams, RoutePaths } from '../../../config/route-paths/route-paths';
import { useIntl } from 'react-intl';
import { useActiveWorkspace } from '../../../hooks/use-active-workspace';
import { useActiveOrganization } from '../../../hooks/use-active-organization';

export const SettingsNav = () => {
  const { formatMessage: f } = useIntl();

  const organization = useActiveOrganization();
  const workspace = useActiveWorkspace();

  const navigate = useNavigate();
  const location = useLocation();

  const sections = useMemo(
    () => [
      { label: f({ id: 'plans' }), id: '#plans' },
      { label: f({ id: 'billing' }), id: '#billing' },
    ],

    [f],
  );

  const goBack = useCallback(() => {
    if (location.key === 'default') {
      navigate(
        getRoutePathFromParams(RoutePaths.Workspace, {
          organizationName: organization?.name,
          workspaceName: workspace?.name,
        }),
      );
    } else {
      navigate(-1);
    }
  }, [location.key, navigate, organization?.name, workspace?.name]);

  return (
    <Stack py={1} width={300} alignItems="flex-start">
      <Button startIcon={<ArrowBackOutlined />} onClick={goBack}>
        {f({ id: 'plans-and-billing' })}
      </Button>
      <Stack sx={{ pl: 4, pr: 6 }} gap={1.5}>
        {sections.map(({ label, id }) => (
          <a key={id} href={id}>
            {label}
          </a>
        ))}
      </Stack>
    </Stack>
  );
};
