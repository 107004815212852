import { SingleSourceModelToolbarButton } from '../button';
import { Icon } from '../../../../icons/icon';
import { EntityType, Mode } from '@xspecs/single-source-model';
import { DragEventHandler, useCallback, useRef } from 'react';
import { useSingleSourceStore } from '../../../../store/single-source-store/single-source-store';
import { useIntl } from 'react-intl';

export const SingleSourceModelToolbarScriptButton = () => {
  const { formatMessage: f } = useIntl();

  const previewRef = useRef();

  const isActive = useSingleSourceStore.use.mode() === Mode.INSERT_SCRIPT;
  const setInsertNarrativeMode = useSingleSourceStore.use.setInsertNarrativeMode();
  const setConstructToInsertUsingDrag = useSingleSourceStore.use.setConstructToInsertUsingDrag();

  const onDragStart = useCallback<DragEventHandler>(
    (event) => {
      event.dataTransfer.setData('application/reactflow', EntityType.Narrative);
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setDragImage(previewRef.current, 0, 0);
      setConstructToInsertUsingDrag(EntityType.Narrative);
    },
    [setConstructToInsertUsingDrag],
  );

  return (
    <SingleSourceModelToolbarButton
      draggable
      Icon={<Icon ref={previewRef} name="script" height={24} width={24} />}
      isActive={isActive}
      onClick={setInsertNarrativeMode}
      onDragStart={onDragStart}
      label={f({ id: 'script' })}
      tooltip={f({ id: 'add-script' })}
    />
  );
};
