import {
  autoUpdate,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react';
import { useMergedRef } from '@mantine/hooks';
import { forwardRef, useCallback, useEffect, useId, useMemo, useRef, useState } from 'react';

type AutoCompleteProps = {
  data: string[];
  value: string;
  placeholder?: string;
  onSubmit: (value: string) => void;
  inputStyle?: React.CSSProperties;
  inputClassName?: string;
};

export function AutoComplete(props: AutoCompleteProps) {
  const { data, value, placeholder, onSubmit: onSubmitProp, inputStyle = {}, inputClassName } = props;

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const listRef = useRef<Array<HTMLElement | null>>([]);

  const noResultsFound = useCallback(
    (inputValue: string) => (value ? `Rename "${inputValue}"` : `Add "${inputValue}"`),
    [value],
  );
  const reverseNoResultsFound = useCallback(
    (inputValue: string) => (value ? /Rename "(.+)"/ : /Add "(.+)"/).exec(inputValue)?.[1] ?? inputValue,
    [value],
  );

  const { refs, floatingStyles, context } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    open,
    onOpenChange: setOpen,
    placement: 'bottom-start',
    middleware: [
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            minWidth: `${rects.reference.width}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
        padding: 10,
      }),
    ],
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const mergedInputRef = useMergedRef(refs.setReference, inputRef);
  const role = useRole(context, { role: 'listbox' });
  const dismiss = useDismiss(context);
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
  });
  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([role, dismiss, listNav]);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setInputValue(value);
    if (value) {
      setOpen(true);
      setActiveIndex(0);
    } else {
      setOpen(false);
    }
  }

  const items = useMemo(() => {
    const _items = data.filter((item) => item.toLowerCase().includes(inputValue.toLowerCase()));
    if (_items.length === 0) {
      _items.push(noResultsFound(inputValue));
    }
    return _items;
  }, [data, inputValue, noResultsFound]);

  const onSubmit = useCallback(
    (value: string) => {
      let _value = value;
      if (value === noResultsFound(value)) {
        _value = inputValue;
      }
      _value = reverseNoResultsFound(_value);
      setInputValue(_value);
      setOpen(false);
      onSubmitProp(_value);
    },
    [inputValue, noResultsFound, onSubmitProp, reverseNoResultsFound],
  );

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <>
      <input
        {...getReferenceProps({
          autoFocus: true,
          className: inputClassName,
          ref: mergedInputRef,
          onChange,
          value: inputValue,
          placeholder,
          'aria-autocomplete': 'list',
          style: {
            height: '100%',
            width: '100%',
            paddingLeft: 4,
            paddingRight: 4,
            ...inputStyle,
          },
          onKeyDown(event) {
            if (
              event.key === 'Enter'
              // && activeIndex != null && items[activeIndex]
            ) {
              inputRef.current?.blur();
            }
          },
          onBlur() {
            onSubmit(items[activeIndex] ?? value);
            setActiveIndex(null);
          },
        })}
        className={inputClassName}
      />
      {open && (
        <div
          {...getFloatingProps({
            ref: refs.setFloating,
            style: {
              ...floatingStyles,
              background: '#eee',
              color: 'black',
              overflowY: 'auto',
            },
          })}
        >
          {items.map((item, index) => (
            <Item
              key={item}
              {...getItemProps({
                ref(node) {
                  listRef.current[index] = node;
                },
                onClick() {
                  onSubmit(item);
                  refs.domReference.current?.focus();
                },
              })}
              active={activeIndex === index}
            >
              {item}
            </Item>
          ))}
        </div>
      )}
    </>
  );
}

interface ItemProps {
  children: React.ReactNode;
  active: boolean;
}

const Item = forwardRef<HTMLDivElement, ItemProps & React.HTMLProps<HTMLDivElement>>(
  ({ children, active, ...rest }, ref) => {
    const id = useId();

    return (
      <div
        ref={ref}
        role="option"
        id={id}
        aria-selected={active}
        {...rest}
        style={{
          background: active ? 'lightblue' : 'none',
          padding: 4,
          cursor: 'default',
          ...rest.style,
        }}
      >
        {children}
      </div>
    );
  },
);
Item.displayName = 'Item';
