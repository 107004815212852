import { CommandBase, IParams } from '../framework/CommandBase';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

interface DeleteScriptFrameParams extends IParams {
  scriptId: string;
  frameIndex: number;
}

interface ScriptFrameDeletedParams extends IParams {
  scriptId: string;
  frameIndex: number;
}

export class ScriptFrameDeletedEvent extends EventBase {
  static eventType = 'ScriptFrameDeletedEvent';

  constructor(public readonly params: ScriptFrameDeletedParams, public readonly source = DeleteScriptFrameCommand) {
    super();
  }
}

export class DeleteScriptFrameCommand extends CommandBase<DeleteScriptFrameParams> {
  execute(params: DeleteScriptFrameParams): ScriptFrameDeletedEvent | CommandError {
    const script = this.model.entityRepository.get<ScriptBase>(params.scriptId);
    const entities = script.getEntitiesInFrame(params.frameIndex);
    const entityIdsToDelete = entities.map((entity) => entity.id);
    if (!script.removeFrame(params.frameIndex)) return CommandError.of(script.getError(), 'error');
    entityIdsToDelete.forEach((entityId) => this.model.entityRepository.delete(entityId));
    this.model.entityRepository.update(script);
    return new ScriptFrameDeletedEvent({ scriptId: params.scriptId, frameIndex: params.frameIndex });
  }
}
