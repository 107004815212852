import { Box, BoxProps, CircularProgress } from '@mui/material';
import { CircularProgressProps } from '@mui/material/CircularProgress/CircularProgress';

type LoadingProps = {
  rootProps?: BoxProps;
  circularProgressProps?: CircularProgressProps;
};

export const Loading = (props: LoadingProps) => {
  const { rootProps = {}, circularProgressProps = {} } = props;

  return (
    <Box data-testid="loading" display="flex" alignItems="center" justifyContent="center" {...rootProps}>
      <CircularProgress {...circularProgressProps} />
    </Box>
  );
};
