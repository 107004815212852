import DocumentsIcon from '@mui/icons-material/StickyNote2';
import { Box, useTheme } from '@mui/material';
import { ChangesetsIcon, SpecIcon, TestIcon } from '@xspecs/ui-assets';

export enum ItemType {
  Documents = 'Document',
  Changesets = 'Changeset',
  Test = 'Test',
  Spec = 'Spec',
}

type NavBarItemTypeIconProps = {
  itemType: ItemType;
  color?: string;
};

export const NavBarItemTypeIcon = (props: NavBarItemTypeIconProps) => {
  const { itemType, color } = props;

  const theme = useTheme();

  return (
    <Box
      data-testid="nav-bar-item-type-icon"
      sx={{
        display: 'flex',
        color: color ?? theme.palette.action.active,
        '.iconPath': { fill: color ?? theme.palette.action.active, fillOpacity: 1 },
      }}
    >
      {itemType === ItemType.Documents ? <DocumentsIcon data-testid="nav-documents-icon" /> : null}
      {itemType === ItemType.Changesets ? <ChangesetsIcon data-testid="nav-changesets-icon" /> : null}
      {itemType === ItemType.Test ? <TestIcon data-testid="nav-test-icon" /> : null}
      {itemType === ItemType.Spec ? <SpecIcon /> : null}
    </Box>
  );
};
