import { useState, useEffect, useCallback, SyntheticEvent, FC, useMemo } from 'react';
import { Alert, AlertTitle, Grow } from '@mui/material';
import { ToastProps, useSnackStack } from '../../wrappers/snack-stack-context';

type SnackbarToastProps = {
  toast: ToastProps;
};

const TIMEOUT = 300;

export const SnackbarToast: FC<SnackbarToastProps> = ({ toast }) => {
  const [open, setOpen] = useState(true);
  const { removeToast } = useSnackStack();

  const close = useCallback(() => {
    setOpen(false);
    setTimeout(() => {
      if (toast?.onClose) {
        toast.onClose(toast.key);
      }
      removeToast(toast.key);
    }, TIMEOUT);
  }, [toast, removeToast]);

  const handleClose = useCallback(
    (event: SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      if (toast?.onClose) {
        toast.onClose(toast.key);
      }
      close();
    },
    [close, toast],
  );

  const sx = useMemo(
    () => ({
      minWidth: 280,
      width: { xs: 1, md: 'auto' },
      mb: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      whiteSpace: 'pre-line',
      '& .MuiAlert-action': { paddingTop: '0' },
    }),
    [],
  );

  useEffect(() => {
    if (toast.duration !== 0) {
      setTimeout(() => {
        close();
      }, toast.duration || 6000);
    }
  }, [close, toast, toast.duration]);

  return (
    <Grow in={open} timeout={TIMEOUT}>
      <Alert key={toast.key} severity={toast?.severity || 'info'} onClose={handleClose} variant="standard" sx={sx}>
        {toast?.title && <AlertTitle sx={{ mb: 0 }}>{toast.title}</AlertTitle>}
        {toast?.message}
        {toast?.children}
      </Alert>
    </Grow>
  );
};
