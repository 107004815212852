import { ThreadHeader } from '../header/thread-header';
import { CommentTextArea } from '../comment-text-area/comment-text-area';
import { Box, styled } from '@mui/material';
import { CommentsList, CommentsListHandle } from './comments/list/comments-list';
import { Comment } from '@xspecs/single-source-model';
import { useCallback, useEffect, useRef } from 'react';
import { Value } from '@udecode/plate-common';
import { useSingleSourceModel } from '../../../../../../hooks/use-single-source-model';
import { sid } from '@xspecs/short-id';
import { useAuth } from '../../../../../../auth';
import { useMutation } from '@apollo/client';
import { MENTION_USERS_MUTATION } from '../../../../../../graphql/mutations';
import { useIntl } from 'react-intl';
import { AppTypeEvent, useTrackEvents } from '../../../../../../hooks/use-track-events';
import { useActiveWorkspace } from '../../../../../../hooks/use-active-workspace';

type ThreadProps = {
  threadId: string;
  comments: Comment[];
  isResolved: boolean;
  onClose: () => void;
};

export const Thread = (props: ThreadProps) => {
  const { onClose, threadId, comments, isResolved } = props;

  const { formatMessage: f } = useIntl();

  const commentsListHandleRef = useRef<CommentsListHandle>();

  const workspace = useActiveWorkspace();

  const { user } = useAuth();

  const { trackEvent } = useTrackEvents();

  const model = useSingleSourceModel();

  const [mentionUsers] = useMutation(MENTION_USERS_MUTATION);

  const hasComments = comments.length !== 0;

  useEffect(() => {
    trackEvent(AppTypeEvent.CommentThreadViewed, { threadId });
  }, [trackEvent, threadId]);

  const onSubmitComment = useCallback(
    async (value: Value, id: string, mentionedUserIds: string[]) => {
      const commentId = sid();
      model.threadInteractor.addCommentToThread({ threadId: id, value: value, createdBy: user.sub, commentId });
      trackEvent(AppTypeEvent.CommentAdded, { threadId: id, commentId });
      commentsListHandleRef.current?.scrollToBottom();
      await mentionUsers({
        variables: {
          args: {
            threadId: id,
            commentId,
            mentionedUserIds: mentionedUserIds,
            workspaceId: workspace.id,
          },
        },
      });

      mentionedUserIds.forEach((userId) => {
        trackEvent(AppTypeEvent.CommentUserMentioned, { threadId: id, commentId, userId: userId });
      });
    },
    [mentionUsers, model.threadInteractor, trackEvent, user.sub, workspace.id],
  );

  return (
    <Box>
      <ThreadHeader threadId={threadId} onClose={onClose} disableResolve={!hasComments} isResolved={isResolved} />
      {hasComments ? <CommentsList ref={commentsListHandleRef} comments={comments} /> : null}
      <TextAreaContainer>
        <CommentTextArea
          id={threadId}
          autoFocus
          onSubmitComment={onSubmitComment}
          placeholder={isResolved ? f({ id: 'reply-to-re-open-discussion' }) : undefined}
        />
      </TextAreaContainer>
    </Box>
  );
};

const TextAreaContainer = styled(Box)(() => ({
  border: '1px solid #C0C0C0',
  borderRadius: '0px 8px 8px 8px',
  padding: 8,
}));
