import { IconButton, IconButtonProps, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';

type NavBarIconButtonProps = {
  id: string;
  icon: ReactNode;
  sx?: SxProps<Theme>;
} & IconButtonProps;

export const NavBarIconButton = (props: NavBarIconButtonProps) => {
  const { id, sx = {}, icon, ...rest } = props;

  return (
    <IconButton
      {...rest}
      data-testid={id}
      sx={{ color: { xs: 'primary.contrastText', sm: 'primary.contrastText', md: 'action.active' }, ...sx }}
    >
      {icon}
    </IconButton>
  );
};
