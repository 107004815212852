import { Box, InputBase, Stack, styled, Typography } from '@mui/material';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import LinkIcon from '@mui/icons-material/Link';
import { UploadLinkOverlay } from './upload-link-overlay';

export type UploadLinkProps = {
  image: string;
  title: string;
  url: string;
  favicon: string;
  onNameChange: (name: string) => void;
};

export const UploadLink = (props: UploadLinkProps) => {
  const { image, title, url, favicon, onNameChange } = props;

  return (
    <Container>
      <ImageWrapper>
        {image ? <Image src={image} /> : <PlaceholderImageIcon />}
        <UploadLinkOverlay url={url} title={title} favicon={favicon} noExpand />
      </ImageWrapper>
      <Content>
        {favicon ? <Favicon src={favicon} /> : <LinkIcon fontSize="medium" sx={{ color: 'action.active' }} />}
        <Box sx={{ width: 'calc(100% - 30px)', display: 'flex', flexDirection: 'column' }}>
          <TitleInput title={title} className="nodrag" value={title} onChange={(e) => onNameChange(e.target.value)} />
          <Typography
            title={url}
            variant="tooltip"
            component="p"
            color="text.secondary"
            sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {url}
          </Typography>
        </Box>
      </Content>
    </Container>
  );
};

const Container = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  padding: theme.spacing(1),
  backgroundColor: 'white',
  borderRadius: 4,
  boxShadow:
    '0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.2)',
  '&:hover .overlay': {
    opacity: 1,
  },
  justifyContent: 'space-between',
  gap: 8,
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  minHeight: 100,
  flexGrow: 1,
  borderRadius: 4,
  backgroundColor: theme.palette.other.imagePlaceholderBg,
  border: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
}));

const Image = styled('img')(() => ({
  width: 'auto',
  height: 'auto',
  borderRadius: 4,
}));

const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  width: '100%',
}));

const Favicon = styled('img')(() => ({
  width: 24,
}));

const TitleInput = styled(InputBase)(({ theme }) => ({
  ...theme.typography.subtitle2,
  input: {
    padding: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const PlaceholderImageIcon = styled(InsertPhotoOutlinedIcon)(({ theme }) => ({
  margin: 'auto auto',
  color: theme.palette.action.disabled,
  width: 64,
  height: 64,
}));
