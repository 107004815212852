import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { ReactNode, useCallback } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

type ErrorBoundaryProps = {
  children: ReactNode;
};

const ErrorBoundaryFallback = () => {
  const { formatMessage: f } = useIntl();

  const onRetry = useCallback(() => {
    window['undo']();
    window['undo']();
    window.location.reload();
  }, []);

  return (
    <Stack width="100%" height="100%" alignItems="center" justifyContent="center" gap={1.5}>
      <Typography variant="h6" maxWidth={300} align="center">
        {f({ id: 'something-went-wrong' })}
      </Typography>
      <Typography variant="h6" maxWidth={300} align="center">
        {f({ id: 'lets-try-again' })}
      </Typography>
      <Button variant="outlined" size="small" onClick={onRetry}>
        {f({ id: 'retry' })}
      </Button>
      {/*<Box>*/}
      {/*  <Box sx={{ my: 5, position: 'relative', width: '100%' }}>*/}
      {/*    <Typography*/}
      {/*      sx={{*/}
      {/*        position: 'absolute',*/}
      {/*        left: '50%',*/}
      {/*        transform: 'translate(-50%, -47%)',*/}
      {/*        px: 0.75,*/}
      {/*        backgroundColor: 'white',*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      {f({ id: 'OR' })}*/}
      {/*    </Typography>*/}
      {/*    <Divider sx={{ width: '100%' }} />*/}
      {/*  </Box>*/}
      {/*  <RestoreVersionDropList />*/}
      {/*</Box>*/}
    </Stack>
  );
};

export const ErrorBoundary = (props: ErrorBoundaryProps) => {
  const { children } = props;

  return <ReactErrorBoundary fallback={<ErrorBoundaryFallback />}>{children}</ReactErrorBoundary>;
};
