import { Button, Stack, TextField, Typography } from '@mui/material';
import { Modal } from '../../modal/modal';
import { InsertLink } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { useCallback, useState } from 'react';

export type UploadLinkModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onUpload: (url: string) => void;
};

const INITIAL_URL = '';

export const UploadLinkModal = (props: UploadLinkModalProps) => {
  const { isOpen, onClose: onCloseProp, onUpload: onUploadProp } = props;

  const { formatMessage: f } = useIntl();

  const [url, setUrl] = useState(INITIAL_URL);
  const disabled = !isValidUrl(url);

  const onUpload = useCallback(
    (e) => {
      e.preventDefault();
      onUploadProp(url);
      onCloseProp();
      setUrl(INITIAL_URL);
    },
    [onCloseProp, onUploadProp, url],
  );

  const onClose = useCallback(() => {
    onCloseProp();
    setUrl(INITIAL_URL);
  }, [onCloseProp]);

  return (
    <Modal
      id="UploadLinkModal"
      isOpen={isOpen}
      onClose={onClose}
      headerStartContent={
        <Stack alignItems="flex-start">
          <Stack direction="row" gap={1} justifyContent="center" alignItems="center">
            <InsertLink color="action" />
            <Typography variant="h6">{f({ id: 'upload-link' })}</Typography>
          </Stack>
          <Typography variant="caption">{f({ id: 'upload-link-desc' })}</Typography>
        </Stack>
      }
      headerSxOverrides={{ justifyContent: 'space-between' }}
    >
      <form onSubmit={onUpload}>
        <Stack gap={3} sx={{ pt: 3 }}>
          <TextField
            placeholder="https://"
            autoFocus
            size="small"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
          <Stack direction="row" gap={1} alignSelf="flex-end">
            <Button onClick={onClose}>{f({ id: 'cancel' })}</Button>
            <Button
              type="submit"
              variant="contained"
              // onClick={onUpload}
              disabled={disabled}
            >
              {f({ id: 'upload' })}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Modal>
  );
};

function isValidUrl(urlString: string): boolean {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-zA-Z\\d_]*)?$',
    'i', // fragment locator
  );

  return !!pattern.test(urlString);
}
