import { Box, Button, SxProps, Theme } from '@mui/material';
import { useIntl } from 'react-intl';
import { TuneRounded } from '@mui/icons-material';
import { ReactNode, useState } from 'react';
import { FiltersButtonMenu, FiltersButtonMenuProps } from './menu/filters-button-menu';
import { TransitionProps } from '@mui/material/transitions';
import { useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { motion } from 'framer-motion';

export type FiltersButtonProps = {
  filtersButtonMenuProps: FiltersButtonMenuProps;
  ButtonComponent?: ReactNode;
  buttonSx?: SxProps<Theme>;
};

export const FiltersButton = (props: FiltersButtonProps) => {
  const { filtersButtonMenuProps, ButtonComponent, buttonSx } = props;

  const { formatMessage: f } = useIntl();

  const [isOpen, setIsOpen] = useState(false);

  const { refs, context, floatingStyles } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: setIsOpen,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  return (
    <Box height="100%">
      <Box ref={refs.setReference} height="100%" {...getReferenceProps()}>
        {ButtonComponent || (
          <Button
            startIcon={<TuneRounded />}
            sx={{
              height: '100%',
              ...buttonSx,
            }}
            disableRipple
          >
            {f({ id: 'filters' })}
          </Button>
        )}
      </Box>
      {isOpen ? (
        <Box ref={refs.setFloating} sx={{ ...floatingStyles, zIndex: isOpen ? 1000 : -1 }} {...getFloatingProps()}>
          <motion.div
            style={{ originY: 'top', originX: 'left' }}
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.2, bounce: 0 }}
          >
            <Box
              sx={{
                width: 320,
                backgroundColor: 'background.paper',
                borderRadius: 1,
                boxShadow: 4,
                zIndex: 1000,
                py: 1,
              }}
            >
              <FiltersButtonMenu {...filtersButtonMenuProps} />
            </Box>
          </motion.div>
        </Box>
      ) : null}
    </Box>
  );
};

const transitionProps: TransitionProps = {
  timeout: { enter: 200, exit: 200 },
  easing: { enter: 'ease-out', exit: 'ease-out' },
};
