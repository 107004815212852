import { Button, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { Filter } from '@xspecs/single-source-model';
import { SaveFilterButton, SaveFilterButtonProps } from '../save-filter-button/save-filter-button';
import { SelectedCriteria } from '../selected-criteria/selected-criteria';

type SelectedFiltersProps = {
  unsavedFilter: Filter;
  onDeleteCriteria: (filterId: string) => void;
  onClearAll: () => void;
} & Pick<SaveFilterButtonProps, 'onSave'>;

export const UnsavedSelectedFilters = (props: SelectedFiltersProps) => {
  const { unsavedFilter, onDeleteCriteria, onClearAll, onSave } = props;

  const { formatMessage: f } = useIntl();

  const onDelete = useCallback(
    (criteriaId: string) => () => {
      onDeleteCriteria(criteriaId);
    },
    [onDeleteCriteria],
  );

  if (!unsavedFilter || unsavedFilter.criteria.length === 0) {
    return null;
  }

  return (
    <Stack direction="row" gap={1.5} alignItems="center" p={1} height={58}>
      <Typography>{f({ id: 'selected-filters' })}</Typography>
      <Stack direction="row" gap={1.5} sx={{ maxWidth: 800, overflowY: 'scroll' }}>
        {unsavedFilter.criteria.map((entity) => (
          <SelectedCriteria key={entity.id} entity={entity} onDelete={onDelete(entity.id)} />
        ))}
      </Stack>
      <Button onClick={onClearAll}>{f({ id: 'clear-all' })}</Button>
      <SaveFilterButton onSave={onSave} />
    </Stack>
  );
};
