import {
  createFilterOptions,
  InputBase,
  List,
  MenuItem,
  styled,
  Typography,
  useAutocomplete,
  UseAutocompleteProps,
} from '@mui/material';
import { sid } from '@xspecs/short-id';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSingleSourceModel } from '../../../../../hooks/use-single-source-model';
import { NodeTypeEvent, useTrackEvents } from '../../../../../hooks/use-track-events';
import { AddEntityCommand, AssetBase, AttachmentType } from '@xspecs/single-source-model';
import { LinkAssetToAttachmentCommand } from 'domain/single-source-model/src/commands/attachments/LinkAssetToAttachmentCommand';
import { FloatingPortal, useFloating } from '@floating-ui/react';

type AssetInternal = {
  inputValue?: string;
  name: string;
  id?: string;
};

const filter = createFilterOptions<AssetInternal>();

export type Asset = {
  id: string;
  name: string;
};

type AssetAssignerProps = {
  attachmentId: string;
  onChange: (value: string | null) => void;
  data: {
    subType: AttachmentType;
    asset?: AssetBase;
    isNew: boolean;
  };
  mouseOver?: string;
};

export const AssetAssigner = (props: AssetAssignerProps) => {
  const { attachmentId, onChange: onChangeProp, data } = props;

  const { formatMessage: f } = useIntl();
  const { trackNodeOperation } = useTrackEvents();
  const model = useSingleSourceModel();

  const [open, setOpen] = useState(false);
  const [list, setList] = useState(model.entities.listAssetsByType(data.subType));
  const [value, setValue] = useState(data.asset);

  const containerRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>();

  const addNew = useCallback(
    (asset: Asset) => {
      if (!asset.name) return;
      if (data.subType === AttachmentType.Upload) {
        setValue(data.asset);
        inputRef.current.blur();
      } else {
        model.messageBus.send(AddEntityCommand, {
          id: asset.id,
          name: asset.name,
          type: data.subType as any,
          position: { x: 0, y: 0 },
        });
        model.messageBus.send(LinkAssetToAttachmentCommand, { attachmentId, assetId: asset.id });
      }
      setList(model.entities.listAssetsByType(data.subType));
      setOpen(false);
      model.entities.clearNewState(attachmentId);
    },
    [data, model.messageBus, model.entities, attachmentId],
  );

  const onClick = useCallback(() => {
    setOpen(true);
  }, []);

  const assets = useMemo<AssetInternal[]>(() => list.map((asset) => ({ name: asset.name, id: asset.id })), [list]);

  const option = useMemo(() => assets.find((asset) => asset.id === value?.id) ?? null, [assets, value]);

  const onOpen = useCallback(() => {
    setList(model.entities.listAssetsByType(data.subType));
    setOpen(true);
  }, [data.subType, model.entities]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const isOptionEqualToValue = useCallback((option, value) => option.id === value.id, []);

  const getOptionLabel = useCallback((option) => (typeof option === 'string' ? option : option.name), []);

  const filterOptions = useCallback(
    (options, params) => {
      const filtered = filter(options, params);
      const { inputValue } = params;
      const isExisting = options.some((option) => inputValue === option.name);
      if (data.subType !== AttachmentType.Upload && inputValue !== '' && !isExisting) {
        filtered.unshift({ inputValue, name: f({ id: 'create-option' }, { name: inputValue }) });
      }
      return filtered;
    },
    [data.subType, f],
  );

  const onChange = useCallback(
    (_event, newValue) => {
      setOpen(false);
      model.entities.clearNewState(attachmentId);
      const existingAsset = list.find((asset) => asset?.name === newValue);
      if (data.subType === AttachmentType.Upload) {
        addNew(data.asset);
        return;
      }
      if (existingAsset) {
        inputRef.current.blur();
        onChangeProp(existingAsset.id);
      } else if (typeof newValue === 'string') {
        const newAsset: Asset = { name: newValue, id: sid() };
        addNew(newAsset);
        trackNodeOperation(NodeTypeEvent.AssetAdded, data.subType.toString());
        model.entities.clearNewState(attachmentId);
        inputRef.current.blur();
        onChangeProp(newAsset.id);
      } else if (newValue && newValue.inputValue) {
        const newAsset: Asset = { name: newValue.inputValue, id: sid() };
        addNew(newAsset);
        trackNodeOperation(NodeTypeEvent.AssetAdded, data.subType.toString());
        onChangeProp(newAsset.id);
      } else {
        onChangeProp(newValue?.id ?? null);
      }
    },
    [model.entities, attachmentId, list, data.subType, data.asset, addNew, onChangeProp, trackNodeOperation],
  );

  const autoCompleteProps = useMemo<UseAutocompleteProps<AssetInternal, false, true, true>>(
    () => ({
      id: 'AssetAssignerAutocomplete',
      value: option,
      options: assets,
      autoComplete: true,
      open,
      onOpen,
      onClose,
      isOptionEqualToValue,
      getOptionLabel,
      filterOptions,
      onChange,
      disableClearable: true,
      freeSolo: true,
    }),
    [assets, filterOptions, getOptionLabel, isOptionEqualToValue, onChange, onClose, onOpen, open, option],
  );

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } =
    useAutocomplete(autoCompleteProps);

  useEffect(() => {
    if (data.isNew && !open) {
      if (data.subType !== AttachmentType.Upload) {
        setOpen(Boolean(data.asset));
      }
      inputRef.current.focus();
    }
  }, [data, open]);

  useEffect(() => {
    setValue(data.asset);
    setList(model.entities.listAssetsByType(data.subType));
  }, [data.asset, data.asset?.id, data.asset?.name, data.subType, model.entities]);

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
  });

  return (
    <div title={option?.name} ref={containerRef} tabIndex={1}>
      <div ref={refs.setReference}>
        <div {...getRootProps()}>
          <InputBase
            inputProps={{
              ...getInputProps(),
              sx: {
                padding: 0,
                userSelect: 'none',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
            onClick={onClick}
            inputRef={inputRef}
            placeholder={f({ id: 'asset-assigner-placeholder' })}
            color="primary"
            size="small"
            sx={{ width: '100%', padding: 0, useSelect: 'none' }}
            onBlur={() => {
              setOpen(false);
              // const asset = { name: inputRef.current.value, id: sid() };
              // addNew(asset);
              // onChangeProp(asset.id);
              model.entities.clearNewState(attachmentId);
            }}
          />
        </div>
      </div>
      {groupedOptions.length > 0 ? (
        <FloatingPortal>
          <div ref={refs.setFloating} style={floatingStyles}>
            <ListBox
              className="nodrag nowheel"
              {...getListboxProps()}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {(groupedOptions as typeof assets).map((option, index) => (
                <ListBoxItem {...getOptionProps({ option, index })} key={`AssetAssignerAutocomplete-option-${index}`}>
                  <Typography variant="caption" sx={{ padding: '6px 16px' }}>
                    {option.name ?? 'Untitled'}
                  </Typography>
                </ListBoxItem>
              ))}
            </ListBox>
          </div>
        </FloatingPortal>
      ) : null}
    </div>
  );
};

const ListBox = styled(List)(({ theme }) => ({
  width: 189,
  padding: 0,
  margin: 0,
  zIndex: 1,
  position: 'absolute',
  listStyle: 'none',
  backgroundColor: theme.palette.background.paper,
  overflow: 'auto',
  maxHeight: 200,
  borderRadius: '4px',
  border: '1px solid rgba(0,0,0,.25)',
  '& li.Mui-focused': {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.text.primary,
    cursor: 'pointer',
  },
  '& li:active': {
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.text.primary,
  },
}));

const ListBoxItem = styled(MenuItem)({
  padding: 0,
});
