import { Box, Typography } from '@mui/material';

export interface ShortcutItemProps {
  command: string;
  name: string;
}

export const ShortcutItem = (props: ShortcutItemProps) => {
  const { command, name } = props;

  return (
    <Box sx={{ display: 'flex', py: 1, borderBottom: '1px solid', borderColor: 'text.disabled' }}>
      <Typography width="50%" sx={{ color: 'text.primary' }}>
        {command}
      </Typography>
      <Typography width="50%" sx={{ color: 'text.secondary' }}>
        {name}
      </Typography>
    </Box>
  );
};
