import { Avatar, AvatarGroup, Box, Tooltip, Typography } from '@mui/material';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useOnClickOutside } from '@udecode/plate-common';

export type Avatar = {
  id: string;
  sub: string;
  name: string;
  picture: string;
  color: string;
};

export type AvatarsProps = {
  users: Avatar[];
  onAvatarClick?: (id: string) => void;
  isFollowed?: (id: string) => boolean;
};

const initialsRegex = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
const getInitials = (name: string) => {
  const initials = [...name.matchAll(initialsRegex)] || [];

  return ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();
};

const MAX_AVATARS_TO_DISPLAY = 5;

export const Avatars = (props: AvatarsProps) => {
  const { users, onAvatarClick, isFollowed } = props;

  const [openId, setOpenId] = useState('');

  const avatars = useMemo(
    () => users.slice(0, MAX_AVATARS_TO_DISPLAY).map((user) => ({ ...user, initials: getInitials(user.name) })),
    [users],
  );

  const additionalUsers = useMemo(
    () => (users.length > MAX_AVATARS_TO_DISPLAY ? users.slice(MAX_AVATARS_TO_DISPLAY) : []),
    [users],
  );

  const setOpenTooltip = useCallback(
    (id: string) => () => {
      setOpenId(id);
    },
    [],
  );

  const onClick = useCallback(
    (id: string) => () => {
      setOpenTooltip(id)();
      onAvatarClick?.(id);
    },
    [onAvatarClick, setOpenTooltip],
  );

  const closeTooltip = useCallback(() => {
    setOpenId('');
  }, []);

  const resolveFontSizeForExtraUsersCount = useCallback(() => {
    if (additionalUsers.length < 10) return 20;
    if (additionalUsers.length < 100) return 16;
    return 12;
  }, [additionalUsers.length]);

  const avatarRef = useRef();

  useOnClickOutside(closeTooltip, { refs: [avatarRef] });

  return (
    <AvatarGroup data-testid="avatars" max={1000} sx={{ minWidth: 50 }}>
      {avatars.map(({ id, name, picture, initials, color }) => (
        <Tooltip
          data-testid="avatar-name"
          key={id}
          title={name}
          onClose={closeTooltip}
          open={openId === id}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          onMouseOver={setOpenTooltip(id)}
          onMouseLeave={closeTooltip}
          PopperProps={{
            disablePortal: true,
            sx: { '& .MuiTooltip-tooltip': { border: `1px solid ${color}`, color, backgroundColor: 'white' } },
          }}
        >
          <Avatar
            data-testid="avatar"
            alt={name}
            src={picture}
            sx={{
              backgroundColor: color,
              cursor: 'pointer',
              border: `${isFollowed?.(id) ? 4 : 2}px solid ${color} !important`,
            }}
            onClick={onClick(id)}
            ref={avatarRef}
          >
            <Typography data-testid="avatar-initials">{initials}</Typography>
          </Avatar>
        </Tooltip>
      ))}
      {additionalUsers.length > 0 ? (
        <Tooltip
          PopperProps={{ sx: { '& .MuiTooltip-tooltip': { backgroundColor: 'white' } } }}
          title={
            <Box
              sx={{
                backgroundColor: 'background.paper',
                color: 'primary.main',
                border: '1px solid #ccc',
                px: 1,
                borderRadius: 1,
                mt: -0.5,
                maxHeight: '300px',
                overflowY: 'scroll',
              }}
            >
              {additionalUsers.map(({ id, name, color }) => (
                <Typography fontSize={12} key={id} color={color}>
                  {name}
                </Typography>
              ))}
            </Box>
          }
        >
          <Avatar sx={{ border: '2px solid' }}>
            <Typography sx={{ fontSize: resolveFontSizeForExtraUsersCount(), fontWeight: '500' }}>
              +{additionalUsers.length}
            </Typography>
          </Avatar>
        </Tooltip>
      ) : null}
    </AvatarGroup>
  );
};
