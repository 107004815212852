import { CommandBase, IParams } from '../framework/CommandBase';
import { Position } from '../../types';
import { EventBase } from '../framework/EventBase';
import { EntityBase } from '../../entities/EntityBase';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { DEBUG_CONFIG } from '../../debug-config';
import { CommandError } from '../../ErrorStore';

interface DragParams extends IParams {
  entityIds: string[];
  dxDy: Position;
  isModPressed?: boolean;
}

export class DragEntitiesCommand extends CommandBase<DragParams> {
  shouldSave() {
    return false;
  }

  execute(params: DragParams): EventBase | CommandError {
    params.entityIds.forEach((entityId) => {
      const firstEntity = this.model.entityRepository.get(params.entityIds[0]);
      let entities: Set<EntityBase>;
      if (
        params.isModPressed &&
        params.entityIds.length === 1 &&
        firstEntity &&
        ['Narrative', 'Action'].includes(firstEntity.type)
      ) {
        entities = new Set([firstEntity]);
      } else {
        entities = this.model.entityRepository.getAllUniqueChildren(params.entityIds);
      }
      const ids = Array.from(entities).map((item) => item.id);
      this.model.graph.drag(ids, params.dxDy);
      this.deleteBoundaryCacheEntriesOnDragStart(entityId, entities);
    });
    return undefined;
  }

  private deleteBoundaryCacheEntriesOnDragStart(entityId: string, entities: Set<EntityBase>) {
    // just do this for the first drag
    if (
      this.model.boundariesIndex.getEntry(entityId) ||
      this.model.boundariesIndex.getEntry(entities[0]) ||
      this.model.boundariesIndex.getEntry(`${entityId}_0_0`) ||
      this.model.boundariesIndex.getEntry(`${entities[0]}_0_0`)
    ) {
      entities.forEach((entity) => this.deleteIndexEntry(entity.id));
      this.deleteIndexEntry(entityId);
    }
  }

  private deleteIndexEntry(id: string) {
    this.model.boundariesIndex.removeEntry(id);
    const entity = this.model.entityRepository.get(id);
    if (entity instanceof ScriptBase) {
      for (const frame of entity.getFrames()) {
        for (const lane of frame.lanes) {
          this.deleteIndexEntry(`${entity.id}_${frame.frameIndex}_${lane.laneIndex}`);
        }
      }
    }
    if (DEBUG_CONFIG.boundaries) this.model.graph['showBoundaries']();
  }
}
