import { KeyboardHandlerReturnType } from '@udecode/plate-common';
import { showInsertMenuVar } from '../../../../../state/state';

export const onInsertMenuKeyDownHandler = (/* editor */): KeyboardHandlerReturnType => (e) => {
  if (e.key === '/') {
    showInsertMenuVar(true);
    return;
  }
  if (!showInsertMenuVar()) return;
  if (['ArrowDown', 'ArrowUp', 'Enter', 'Tab'].includes(e.key)) {
    e.preventDefault();
    return;
  }

  showInsertMenuVar(false);
};
