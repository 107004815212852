import { FC } from 'react';
import { IconComponentProps } from '../icon';

export const ExpandScript: FC<IconComponentProps> = () => {
  return (
    <>
      <rect width="20" height="20" fill="#D8D8D8" />
      <rect x="1" y="1" width="18" height="5" fill="#F8F8F8" />
      <rect x="1" y="7" width="18" height="6" fill="#F5F0F8" />
      <rect x="1" y="14" width="18" height="5" fill="#F0E1F9" />
      <path
        d="M16.9971 3.52577C16.9963 3.51713 16.9947 3.50876 16.9934 3.50025C16.9919 3.48995 16.9907 3.47961 16.9887 3.46936C16.9868 3.45957 16.9841 3.45008 16.9817 3.44048C16.9795 3.43165 16.9775 3.42276 16.9749 3.41401C16.972 3.40458 16.9685 3.39547 16.9652 3.38626C16.962 3.37754 16.9591 3.36873 16.9556 3.36012C16.952 3.3515 16.9478 3.34327 16.9439 3.33488C16.9397 3.32602 16.9358 3.31708 16.9311 3.30838C16.9268 3.30034 16.9219 3.29269 16.9172 3.28487C16.9121 3.27636 16.9073 3.26775 16.9018 3.25944C16.896 3.2508 16.8895 3.24268 16.8833 3.23436C16.8781 3.22745 16.8733 3.22034 16.8678 3.21358C16.8557 3.19884 16.843 3.18465 16.8295 3.17117C16.8294 3.17106 16.8293 3.17095 16.8292 3.17084C16.8291 3.17073 16.829 3.17068 16.8289 3.17057C16.8154 3.15709 16.8012 3.14432 16.7864 3.13221C16.7797 3.12666 16.7726 3.12184 16.7656 3.11665C16.7573 3.11044 16.7492 3.10402 16.7406 3.09825C16.7323 3.0927 16.7236 3.08788 16.7151 3.0828C16.7073 3.07812 16.6997 3.07323 16.6916 3.06891C16.6829 3.06426 16.674 3.06032 16.6651 3.05614C16.6567 3.05217 16.6485 3.04799 16.6399 3.04441C16.6313 3.04085 16.6225 3.03795 16.6137 3.03484C16.6045 3.03153 16.5954 3.028 16.586 3.02516C16.5773 3.0225 16.5684 3.02056 16.5595 3.01835C16.5499 3.01591 16.5404 3.01323 16.5307 3.01129C16.5204 3.00927 16.5101 3.00804 16.4998 3.00656C16.4913 3.00536 16.4829 3.00372 16.4743 3.00287C16.4551 3.00101 16.4359 3 16.4167 3H12.3333C12.0112 3 11.75 3.26116 11.75 3.58332C11.75 3.90549 12.0112 4.16665 12.3333 4.16665H15.0084L10.7542 8.42084C10.5264 8.64864 10.5264 9.018 10.7542 9.2458C10.982 9.47361 11.3514 9.47361 11.5792 9.2458L15.8333 4.99164V7.66668C15.8333 7.98884 16.0945 8.25 16.4167 8.25C16.7388 8.25 17 7.98884 17 7.66668V3.58338C17 3.56413 16.999 3.54491 16.9971 3.52577Z"
        fill="black"
        fillOpacity="0.6"
      />
      <path
        d="M8.42084 10.7541L4.16668 15.0083V12.3333C4.16668 12.0111 3.90552 11.7499 3.58335 11.7499C3.26116 11.7499 3 12.0111 3 12.3333V16.4166C3 16.4358 3.00101 16.455 3.0029 16.4741C3.00375 16.4828 3.00536 16.4911 3.00659 16.4997C3.00807 16.51 3.00927 16.5203 3.01132 16.5306C3.01326 16.5403 3.01594 16.5498 3.01838 16.5594C3.02059 16.5683 3.02253 16.5771 3.02518 16.5859C3.02803 16.5953 3.03155 16.6044 3.03486 16.6136C3.03801 16.6223 3.04088 16.6311 3.04443 16.6398C3.04799 16.6484 3.0522 16.6566 3.05616 16.665C3.06035 16.6739 3.06429 16.6828 3.06893 16.6915C3.07325 16.6995 3.07815 16.7072 3.08282 16.715C3.08791 16.7235 3.09272 16.7321 3.09827 16.7404C3.10404 16.7491 3.11047 16.7572 3.11668 16.7655C3.12187 16.7724 3.12668 16.7795 3.13223 16.7863C3.14435 16.8011 3.15712 16.8153 3.1706 16.8287C3.17071 16.8288 3.17076 16.829 3.17087 16.829C3.17098 16.8291 3.17109 16.8292 3.1712 16.8293C3.18468 16.8428 3.19887 16.8556 3.21361 16.8677C3.22036 16.8732 3.22747 16.878 3.23439 16.8832C3.2427 16.8894 3.25082 16.8959 3.25946 16.9016C3.26775 16.9072 3.27636 16.912 3.28489 16.9171C3.29271 16.9218 3.30034 16.9267 3.30841 16.931C3.31711 16.9356 3.32605 16.9396 3.33491 16.9437C3.3433 16.9477 3.35156 16.9519 3.36014 16.9554C3.36876 16.959 3.37754 16.9619 3.38629 16.965C3.3955 16.9683 3.40461 16.9719 3.41404 16.9747C3.42279 16.9774 3.43168 16.9793 3.44051 16.9815C3.45011 16.984 3.45959 16.9866 3.46938 16.9886C3.47964 16.9906 3.48997 16.9918 3.50028 16.9933C3.50879 16.9945 3.51718 16.9961 3.52579 16.997C3.54493 16.9989 3.56413 16.9999 3.58332 16.9999H7.66668C7.98884 16.9999 8.25 16.7387 8.25 16.4166C8.25 16.0944 7.98884 15.8332 7.66668 15.8332H4.99164L9.24583 11.579C9.47363 11.3512 9.47363 10.9819 9.24583 10.7541C9.01803 10.5263 8.64867 10.5263 8.42084 10.7541Z"
        fill="black"
        fillOpacity="0.6"
      />
    </>
  );
};
