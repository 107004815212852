import { CreateWorkspace } from '../../pages/create-workspace/create-workspace';
import { Box } from '@mui/material';

export const CreateWorkspaceRoute = () => {
  return (
    <Box data-testid="create-workspace-route" height="100%" width="100%">
      <CreateWorkspace />
    </Box>
  );
};
