import { Stack, Typography } from '@mui/material';
import { CheckRounded } from '@mui/icons-material';
import { SubscriptionPlansQuery } from '../../../../../../gql/graphql';

type PlansListItemFeatureListProps = {
  description: SubscriptionPlansQuery['subscriptionPlans'][0]['description'];
  features: SubscriptionPlansQuery['subscriptionPlans'][0]['features'];
};

export const PlansListItemFeatureList = (props: PlansListItemFeatureListProps) => {
  const { description, features } = props;

  return (
    <Stack>
      <Typography height={[100, 100, 80, 80, 60]}>{description}</Typography>
      <Stack gap={1}>
        {features.map((feature) => {
          return (
            <Stack key={feature} direction="row" gap={0.5}>
              <CheckRounded color="primary" />
              <Typography variant="body1">{feature}</Typography>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
