import { getKey } from './Transient';
import { IStore } from './Store';

export class LocalStoreProvider {
  private store = new Map<string, any>();
  private persistedStore: IStore;

  private getPersistedField(key: string) {
    return this.persistedStore.getState().states[key];
  }

  private setPersistedField(key: string, value: any) {
    this.persistedStore.getState().setStateField(key, value);
  }

  get(key: string, persisted = false): any {
    if (persisted) {
      return this.getPersistedField(key);
    }
    return this.store.get(key);
  }

  set(key: string, value: any, persisted = false): void {
    if (persisted) {
      this.setPersistedField(key, value);
      return;
    }
    this.store.set(key, value);
  }

  setPersistedStore(store: IStore): void {
    this.persistedStore = store;
  }

  static getKey = getKey;
}

export const localStoreProvider = new LocalStoreProvider();

// eslint-disable-next-line @typescript-eslint/naming-convention
export function Local(target: any, propertyKey: string): void {
  function getter() {
    return localStoreProvider.get(getKey(this, propertyKey));
  }

  function setter(newValue: any) {
    localStoreProvider.set(getKey(this, propertyKey), newValue);
  }

  Object.defineProperty(target, propertyKey, {
    get: getter,
    set: setter,
    enumerable: true,
    configurable: true,
  });

  Object.defineProperty(target, `__isLocal_${propertyKey}`, {
    value: true,
    writable: false,
    enumerable: false,
    configurable: true,
  });
}

export function isLocal(instance: any, propertyKey: string): boolean {
  return !!instance[`__isLocal_${propertyKey}`];
}
