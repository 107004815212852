import { Box, Divider } from '@mui/material';
import { BalloonMenuLink } from './balloon-menu-link/balloon-menu-link';
import { BalloonMenuTextTypeSelection } from './balloon-menu-text-type-selection';
import { BalloonMenuMarks } from './balloon-menu-marks/balloon-menu-marks';
import { BalloonMenuLists } from './balloon-menu-lists';
import { BalloonMenuAlignment } from './balloon-menu-alignment/balloon-menu-alignment';

type BalloonMenuProps = {
  closeMenu: () => void;
};

export const BalloonMenu = (props: BalloonMenuProps) => {
  return (
    <Box height="100%" display="flex" alignItems="center">
      <BalloonMenuTextTypeSelection {...props} />
      {/*<Divider data-testid="text-comment-separator" orientation="vertical" />*/}
      {/*<BalloonMenuComment {...props} />*/}
      <Divider data-testid="text-link-separator" orientation="vertical" />
      <BalloonMenuLink {...props} />
      <Divider data-testid="link-marks-separator" orientation="vertical" />
      <BalloonMenuMarks />
      <Divider data-testid="marks-lists-separator" orientation="vertical" />
      <BalloonMenuLists />
      <Divider data-testid="alignment-separator" orientation="vertical" />
      <BalloonMenuAlignment />
    </Box>
  );
};
