import {
  AnyObject,
  createPlateEditor,
  createPlugins,
  focusEditor,
  getEditorString,
  getEndPoint,
  getNodeEntries,
  Plate,
  PlateContent,
  PlateLeaf,
  resetEditor,
  Value,
} from '@udecode/plate-common';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import css from './comment-text-area.module.css';
import React, { KeyboardEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { Stack, styled } from '@mui/material';
import { CommentTextAreaSubmitButton } from './submit-button/comment-text-area-submit-button';
import { isEqual } from 'lodash';
import { ELEMENT_MENTION, ELEMENT_MENTION_INPUT, TMentionElement } from '@udecode/plate-mention';
import { MentionUserCombobox } from '../../../../../mention-user-combobox/mention-user-combobox';
import {
  createBasicMarksPlugin,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
} from '@udecode/plate-basic-marks';
import { createComboboxPlugin } from '@udecode/plate-combobox';
import { mentionPlugin } from '../../../../../plate-plugins/mention-plugin/mention-plugin';
import { withProps } from '@udecode/cn';
import { MentionElement } from '../../../../../plate-ui/mention-element';
import { MentionInputElement } from '../../../../../plate-ui/mention-input-element';

type CommentTextAreaProps = {
  id: string;
  disablePlaceholder?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  initialValue?: Value;
  autoFocus?: boolean;
  onSubmitComment?: (value: Value, id: string, mentionedUserIds: string[]) => void;
};

export const CommentTextArea = (props: CommentTextAreaProps) => {
  const { disablePlaceholder, readOnly, initialValue, id, autoFocus, onSubmitComment, placeholder } = props;

  const [shouldAutoFocus, setShouldAutoFocus] = useState(autoFocus);
  const [disabled, setDisabled] = useState(false);

  const { formatMessage: f } = useIntl();

  const editor = useMemo(() => {
    return createPlateEditor({
      id,
      plugins,
      normalizeInitialValue: true,
    });
  }, [id]);

  const submitComment = useCallback(() => {
    const mentionedUserId = (findElementMentions(editor.children) as TMentionElement[]).map((m) => m.userId as string);
    onSubmitComment?.(editor.children, id, mentionedUserId);
    resetEditor(editor);
    setShouldAutoFocus(true);
  }, [editor, id, onSubmitComment]);

  const focus = useCallback(() => {
    focusEditor(editor, getEndPoint(editor, [0]));
  }, [editor]);

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLDivElement>>(
    (event) => {
      const isModKey = event.ctrlKey || event.metaKey || event.shiftKey;

      if (!isModKey && event.key === 'Enter' && !disabled) {
        event.preventDefault();
        submitComment();
        return;
      }

      if (event.key === 'Escape' && !disabled) {
        event.stopPropagation(); // stop the event from bubbling up because we don't want to close the thread
        resetEditor(editor);
        focus();
        return;
      }
    },
    [disabled, submitComment, editor, focus],
  );

  const onChange = useCallback((value: Value) => setDisabled(isDisabled(value, initialValue)), [initialValue]);

  useEffect(() => {
    if (shouldAutoFocus || !readOnly) {
      focus();
      setShouldAutoFocus(false);
    }
  }, [focus, readOnly, shouldAutoFocus]);

  return (
    <Root>
      <Plate id={id} editor={editor} initialValue={initialValue} onChange={onChange}>
        <PlateContent
          id={id}
          className={clsx('nodrag', css.editor)}
          placeholder={!disablePlaceholder && (placeholder ?? f({ id: 'comment-reply-placeholder' }))}
          readOnly={readOnly}
          onKeyDown={onKeyDown}
        />
        <MentionUserCombobox />
      </Plate>
      {!readOnly ? <CommentTextAreaSubmitButton disabled={disabled} onClick={submitComment} /> : null}
    </Root>
  );
};

const Root = styled(Stack)(({ theme }) => ({
  alignItems: 'flex-end',
  gap: theme.spacing(0.5),
  width: '100%',
}));

const isDisabled = (value: Value, defaultValue?: Value) => {
  if (defaultValue && isEqual(value, defaultValue)) return true;
  const editor = createPlateEditor();
  editor.children = value;
  const content = getEditorString(editor, []);
  const mentions = Array.from(getNodeEntries(editor, { at: [], match: { type: ELEMENT_MENTION } }));
  return !(mentions.length > 0 || content);
};

const plugins = createPlugins([createBasicMarksPlugin(), createComboboxPlugin(), mentionPlugin], {
  components: {
    [MARK_BOLD]: withProps(PlateLeaf, { as: 'strong' }),
    [MARK_ITALIC]: withProps(PlateLeaf, { as: 'em' }),
    [MARK_STRIKETHROUGH]: withProps(PlateLeaf, { as: 's' }),
    [MARK_UNDERLINE]: withProps(PlateLeaf, { as: 'u' }),

    [ELEMENT_MENTION]: MentionElement,
    [ELEMENT_MENTION_INPUT]: MentionInputElement,
  },
});

function findElementMentions(obj: AnyObject): AnyObject[] {
  const mentions: AnyObject[] = [];

  function traverse(currentObj: AnyObject) {
    if (typeof currentObj !== 'object' || currentObj === null) {
      return;
    }

    if (currentObj.type === ELEMENT_MENTION) {
      mentions.push(currentObj);
    }

    Object.values(currentObj).forEach((value) => {
      if (typeof value === 'object') {
        traverse(value);
      }
    });
  }

  traverse(obj);
  return mentions;
}
