export enum EntityType {
  Action = 'Action',
  Actor = 'Actor',
  Attachment = 'Attachment',
  Capability = 'Capability',
  Command = 'Command',
  Comment = 'Comment',
  Constraints = 'Constraints',
  Data = 'Data',
  Doc = 'Doc',
  Edge = 'Edge',
  Event = 'Event',
  ExternalSystem = 'ExternalSystem',
  Filter = 'Filter',
  Gateway = 'Gateway',
  Interface = 'Interface',
  Label = 'Label',
  Moment = 'Moment',
  Narrative = 'Narrative',
  Process = 'Process',
  Projection = 'Projection',
  Query = 'Query',
  ReadModel = 'ReadModel',
  Resolver = 'Resolver',
  Schema = 'Schema',
  NarrativeScript = 'NarrativeScript',
  Spec = 'Spec',
  ActionScript = 'ActionScript',
  Thread = 'Thread',
  Upload = 'Upload',
  Preview = 'Preview',

  GqlField = 'GqlField',
  GqlOperation = 'GqlOperation',
}

export enum AnnotationType {
  Operation = EntityType.GqlOperation,
  Field = EntityType.GqlField,
}

export enum AttachmentType {
  Actor = EntityType.Actor,
  Doc = EntityType.Doc,
  Query = EntityType.Query,
  Schema = EntityType.Schema,
  Spec = EntityType.Spec,
  Upload = EntityType.Upload,
}
export const isValidAttachmentType = (type: any): type is AttachmentType => {
  return Object.values(AttachmentType).includes(type);
};
export const isValidEntityType = (type: any): type is EntityType => {
  return Object.values(EntityType).includes(type);
};

export type LabelTemp = {
  id: string;
  name: string;
  color: string;
};
