import { CommandBase, IParams } from '../framework/CommandBase';
import { ApplyFiltersCommand } from './ApplyFiltersCommand';
import { EventBase } from '../framework/EventBase';
import { EntityBase } from '../../entities/EntityBase';
import { Filter, FilterType } from '../../entities/Filter';
import { CommandError } from '../../ErrorStore';

export interface UpdateSavedFilterParams extends IParams {
  id: string;
  name: string;
  filterType: FilterType.publicSaved;
  criteria: EntityBase[];
}

export class SavedFilterUpdatedEvent extends EventBase {
  static eventType = 'SavedFilterUpdatedEvent';

  constructor(public readonly params: UpdateSavedFilterParams, public readonly source = UpdateSavedFiltersCommand) {
    super();
  }
}

export class UpdateSavedFiltersCommand extends CommandBase<UpdateSavedFilterParams> {
  execute(params: UpdateSavedFilterParams): SavedFilterUpdatedEvent | CommandError {
    const filter = new Filter(
      params.id,
      params.name,
      params.filterType,
      params.criteria,
      undefined,
      this.model.modelFile.activeUser.id,
    );
    this.model.entityRepository.add(filter);
    this.model.messageBus.send(ApplyFiltersCommand, {
      filter,
    });
    return new SavedFilterUpdatedEvent(params);
  }
}
