import RBush from 'rbush';
import { DeletedEntity, EntityChangeset, Position, UpdatedEntity } from '../../types';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { EntityBase } from '../../entities/EntityBase';
import { EntityRepository } from '../../data/EntityRepository';
import { logger } from '@xspecs/logger';
import { AssetBase } from '../../entities/assets/AssetBase';
import { Label } from '../../entities/assets/Label';
import { EntityType } from '../../entities/EntityType';
import { Narrative } from '../../entities/constructs/Narrative';
import { Action } from '../../entities/constructs/Action';

export interface BoundingBox {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
}

export interface EntryData {
  entityId: string;
  entity: EntityBase;
  scriptId?: string;
  frameId?: number;
  laneId?: number;
}

export interface CacheEntry extends BoundingBox, EntryData {}

export class BoundariesIndex {
  private quadTree: RBush<CacheEntry> = new RBush();
  private quadTreeItems: { [key: string]: CacheEntry } = {};

  constructor(private readonly entityRepository: EntityRepository) {}

  getIntersectingEntitiesData(entity: EntityBase): EntryData[];
  getIntersectingEntitiesData(position: Position): EntryData[];
  getIntersectingEntitiesData(boundary: BoundingBox): EntryData[];
  getIntersectingEntitiesData(arg: string | EntityBase | Position | BoundingBox): EntryData[] {
    switch (true) {
      case typeof arg === 'string':
        const entity = this.entityRepository.get(arg);
        if (entity) return this.#getIntersectingEntityIds(this.entityToCacheEntry(entity));
        return [];

      case arg instanceof EntityBase:
        return this.#getIntersectingEntityIds(this.entityToCacheEntry(arg as EntityBase)).filter(
          (e) => e.entity?.id !== arg.id,
        );

      case isPosition(arg):
        const pos = arg as unknown as Position;
        return this.#getIntersectingEntityIds({
          minX: pos.x,
          minY: pos.y,
          maxX: pos.x + 1,
          maxY: pos.y + 1,
        });

      case isBoundingBox(arg):
        return this.#getIntersectingEntityIds(arg);

      default:
        logger.error('Invalid argument');
        return [];
    }
  }

  #getIntersectingEntityIds(boundary: BoundingBox): EntryData[] {
    const items = this.quadTree.search(boundary);
    return items.map((item) => ({
      entityId: item.entityId,
      entity: this.entityRepository.get(item.entityId),
      scriptId: item.scriptId,
      frameId: item.frameId,
      laneId: item.laneId,
    }));
  }

  upsertEntry(cacheEntry: CacheEntry) {
    this.removeExistingEntry(cacheEntry);
    this.insertEntry(cacheEntry);
  }

  private insertEntry(cacheEntry: CacheEntry) {
    this.quadTreeItems[cacheEntry.entityId] = cacheEntry;
    this.quadTree.insert(cacheEntry);
  }

  private removeExistingEntry(cacheEntry: CacheEntry) {
    const quadTreeItem = this.quadTreeItems[cacheEntry.entityId];
    if (quadTreeItem) this.quadTree.remove(quadTreeItem);
  }

  public removeEntry(entityId: string) {
    const quadTreeItem = this.quadTreeItems[entityId];
    if (quadTreeItem) {
      this.quadTree.remove(quadTreeItem);
      delete this.quadTreeItems[entityId];
    }
  }

  public getEntry(entityId: string) {
    return this.quadTreeItems[entityId];
  }

  public clear() {
    this.quadTree.clear();
    this.quadTreeItems = {};
  }

  // ############################################################################################################
  // UPDATE ENTRIES
  // ############################################################################################################

  public updateIndex(changes: EntityChangeset = { added: [], updated: [], deleted: [] }) {
    this.handleDeletions(changes.deleted);
    this.handleAdditions(changes.added);
    this.handleUpdates(changes.updated);
  }

  private handleDeletions(deleted: DeletedEntity[]) {
    deleted.forEach((entity) => {
      if ([EntityType.NarrativeScript, EntityType.ActionScript].includes(entity.type))
        this.handleScriptDelete(entity.id);
      else this.removeEntry(entity.id);
    });
  }

  private handleUpdates(updated: UpdatedEntity[]) {
    updated.forEach((update) => {
      if (this.entityIsIgnored(update.entity)) return;
      if (
        update.modifiedProperties.length === 1 &&
        ['isSelected', 'selectedBy', 'children'].includes(update.modifiedProperties[0])
      )
        return;
      const entity = update.entity;
      this.handleScriptDelete(entity);
      if (!entity.isVisible) {
        this.removeEntry(entity.id);
        return;
      }
      if (entity instanceof ScriptBase) this.handleScriptUpdate(entity);
      else if (entity instanceof EntityBase) this.upsertCacheEntry(entity);
    });
  }

  private handleAdditions(added: EntityBase[]) {
    added.forEach((entity) => {
      if (this.entityIsIgnored(entity)) return;
      this.handleScriptDelete(entity);
      if (!entity.isVisible) return;
      if (entity instanceof ScriptBase) this.handleScriptUpdate(entity);
      else if (entity instanceof EntityBase) this.upsertCacheEntry(entity);
    });
  }

  private upsertCacheEntry(entity: EntityBase) {
    this.upsertEntry(this.entityToCacheEntry(entity));
  }

  private entityToCacheEntry(entity: EntityBase) {
    return {
      entityId: entity.id,
      entity: entity,
      ...this.entityToBoundingBox(entity),
    };
  }

  private entityToBoundingBox(entity: EntityBase): BoundingBox {
    return {
      minX: entity.position.x,
      minY: entity.position.y,
      maxX: entity.position.x + entity.width,
      maxY: entity.position.y + entity.height,
    };
  }

  public getDebugNodes() {
    return this.quadTree.all();
  }

  public toJSON() {
    return this.quadTree.toJSON();
  }

  private handleScriptUpdate(script: ScriptBase) {
    if (!script.isOpen) return;
    script.getCells().forEach((cellFrame) => {
      cellFrame.forEach((cell) => {
        const frameIndex = parseInt(cell.id.substring(0, cell.id.lastIndexOf('_')));
        const laneIndex = parseInt(cell.id.substring(cell.id.lastIndexOf('_') + 1));
        const entry = {
          entityId: `${script.id}_${cell.id}`,
          entity: script,
          scriptId: script.id,
          frameId: frameIndex,
          laneId: laneIndex,
          minX: cell.x,
          minY: cell.y,
          maxX: cell.x + cell.width,
          maxY: cell.y + cell.height,
        };
        this.upsertEntry(entry);
      });
    });
  }

  private handleScriptDelete(entity: EntityBase): void;
  private handleScriptDelete(entityId: string): void;
  private handleScriptDelete(e: string | EntityBase): void {
    const entityId = e instanceof EntityBase ? e.id : e;
    if ((e instanceof Narrative || e instanceof Action) && !e.script.isOpen) {
      this.deleteScriptEntries(e.id);
      return;
    }
    this.deleteScriptEntries(entityId);
  }

  private deleteScriptEntries(entityId: string) {
    Object.entries(this.quadTreeItems)
      .filter(([key]) => key.startsWith(entityId))
      .forEach(([key, value]) => {
        this.quadTree.remove(value);
        delete this.quadTreeItems[key];
      });
  }

  private entityIsIgnored(entity: EntityBase) {
    return entity instanceof AssetBase || entity instanceof Label;
  }
}

function isBoundingBox(arg: any): arg is BoundingBox {
  return (
    typeof arg.minX === 'number' &&
    typeof arg.minY === 'number' &&
    typeof arg.maxX === 'number' &&
    typeof arg.maxY === 'number'
  );
}

function isPosition(arg: any): arg is BoundingBox {
  return typeof arg.x === 'number' && typeof arg.y === 'number';
}
