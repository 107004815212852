import { Fragment, useRef, useState } from 'react';
import { IconButton } from '@mui/material';
import { LabelOutlined, MoreHoriz } from '@mui/icons-material';
import {
  arrow,
  autoPlacement,
  autoUpdate,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { createPortal } from 'react-dom';
import { LabelsSelectorProps } from '../labels-selector/labels-selector';
import { FloatingLabelsSelector } from '../floating-labels-selector/floating-labels-selector';

export type LabelsDetailsButtonProps = {
  type: 'more' | 'details';
  selectorProps: LabelsSelectorProps;
};

export const LabelsDetailsButton = (props: LabelsDetailsButtonProps) => {
  const { selectorProps, type } = props;

  const [isOpen, setIsOpen] = useState(false);

  const arrowRef = useRef(null);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'right',
    middleware: [
      offset(15),
      autoPlacement({
        allowedPlacements: ['right', 'right-start', 'right-end'],
      }),
      arrow({ element: arrowRef.current }),
    ],
    whileElementsMounted: autoUpdate,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context, {
    referencePress: false,
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, click]);

  const Icon = type === 'more' ? MoreHoriz : LabelOutlined;

  return (
    <Fragment>
      <IconButton ref={refs.setReference} {...getReferenceProps()} size="small">
        <Icon fontSize="small" />
      </IconButton>
      {isOpen
        ? createPortal(
            <FloatingLabelsSelector
              RootProps={{
                className: 'nowheel',
                ref: refs.setFloating,
                sx: floatingStyles,
                ...getFloatingProps(),
              }}
              context={context}
              arrowRef={arrowRef}
              selectorProps={selectorProps}
            />,
            document.body,
          )
        : null}
    </Fragment>
  );
};
