import { EntityBase } from '../EntityBase';
import { z } from 'zod';
import { NarrativeScript } from './NarrativeScript';
import { EntityType } from '../EntityType';
import { ScriptBase } from './ScriptBase';

export class Capability extends EntityBase {
  static schema = EntityBase.abstractBaseSchema.extend({
    scripts: z.array(ScriptBase.schema).optional().default([]),
    zIndex: z.number().default(5000),
  });

  get type(): EntityType {
    return EntityType.Capability;
  }

  static version = '1.0.1'; // Renames Tags to Labels;

  public readonly scripts: NarrativeScript[] = [];

  static parse(data: unknown): Capability {
    const capability = super.parseBase(data, Capability.schema) as Capability;
    if (capability.scripts.length > 0) {
      capability.scripts.forEach((script) => {
        script.parent = capability;
      });
    }
    return capability;

    // const parsedData = Capability.schema.parse(data);
    // const capability = new Capability(
    //   parsedData.id,
    //   parsedData.name,
    //   parsedData.parent,
    //   parsedData.position,
    //   parsedData.scopes,
    //   parsedData.attributes,
    //   parsedData.width,
    //   parsedData.height,
    //   parsedData.isVisible,
    //   parsedData.zIndex,
    // );
    // if (parsedData.scripts?.length > 0) {
    //   parsedData.scripts.forEach((script: any) => {
    //     capability.linkScript(NarrativeScript.parse(script));
    //   });
    // }
    // return capability;
  }

  isValid(): boolean {
    return Capability.schema.safeParse(this).success;
  }

  linkScript(script: NarrativeScript): void {
    script.hide();
    script.parent = this;
    this.scripts.push(script);
  }

  unLinkScript(script: NarrativeScript): void {
    script.parent = undefined;
    this.scripts.splice(this.scripts.indexOf(script), 1);
  }

  static references = ['scripts'];

  serialize(reference: boolean = false): unknown {
    if (reference) return super.serialize(reference);
    return {
      ...(super.serialize() as any),
      id: this.id,
      scripts: this.scripts.map((entity) => entity.serialize(true)),
    };
  }
}
