import { FC } from 'react';
import { IconComponentProps } from '../icon';

export const Script: FC<IconComponentProps> = () => (
  <>
    <rect width="22" height="21" fill="#D8D8D8" />
    <rect x="1" y="1" width="20" height="6" fill="#F8F8F8" />
    <rect x="1" y="8" width="20" height="6" fill="#F5F0F8" />
    <rect x="1" y="14" width="20" height="6" fill="#F0E1F9" />
  </>
);
