import { Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { usePlans } from '../../../../hooks/use-plans';

export const BillingCurrentPlan = () => {
  const { formatMessage: f } = useIntl();

  const { currentPlan } = usePlans();

  return (
    <Stack gap={1.5}>
      <Typography variant="h6">{f({ id: 'current-plan' })}</Typography>
      <Typography>{f({ id: 'current-plan-info' }, { plan: currentPlan?.name })}</Typography>
      {/*<Button variant="outlined">*/}
      {/*  <a href="#plans">{f({ id: 'view-plans-and-pricing' })}</a>*/}
      {/*</Button>*/}
    </Stack>
  );
};
