import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

interface UnresolveThreadCommandParams extends IParams {
  threadId: string;
}

interface ThreadUnresolvedEventParams extends IParams {
  threadId: string;
}

export class ThreadUnresolvedEvent extends EventBase {
  static eventType = 'ThreadUnresolvedEvent';

  constructor(public readonly params: ThreadUnresolvedEventParams, public readonly source = UnresolveThreadCommand) {
    super();
  }
}

export class UnresolveThreadCommand extends CommandBase<UnresolveThreadCommandParams> {
  execute(params: UnresolveThreadCommandParams): ThreadUnresolvedEvent | CommandError {
    this.model.entities.unresolveThread(params.threadId);
    return new ThreadUnresolvedEvent({ threadId: params.threadId });
  }
}
