import { FC } from 'react';

type FileStatusProps = {
  fileId: string;
};

export const FileStatus: FC<FileStatusProps> = () => {
  return null;
  // const { fileId } = props;
  //
  // const { formatMessage: f } = useIntl();
  //
  // const file = useSingleSourceStore.use.filesById()[fileId];
  //
  // return (
  //   <Snackbar
  //     open={Boolean(file) && file?.status !== Status.Synced}
  //     message={f({ id: `connection-status-${file?.status ?? Status.Connecting}` })}
  //     anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
  //   />
  // );
};
