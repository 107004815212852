import { Box, Typography } from '@mui/material';
import { ShortcutItem, ShortcutItemProps } from '../shortcut-item/shortcut-item';

export interface ShortcutSectionProps {
  name: string;
  shortcuts: ShortcutItemProps[];
}

export const ShortcutSection = (props: ShortcutSectionProps) => {
  const { name, shortcuts } = props;

  return (
    <Box my={2}>
      <Typography variant="h6" mb={1}>
        {name}
      </Typography>
      {shortcuts.map((shortcut) => (
        <ShortcutItem key={shortcut.name} {...shortcut} />
      ))}
    </Box>
  );
};
