import { Plans } from '../../components/settings/plans/plans';
import { Box, Divider, Stack } from '@mui/material';
import { Billing } from '../../components/settings/billing/billing';
import { SettingsNav } from '../../components/settings/nav/settings-nav';
import { navBarOverridePropsVar } from '../../state/state';
import { useEffect } from 'react';

export const SettingsRoute = () => {
  useEffect(() => {
    navBarOverridePropsVar({ hideWorkspaces: true });
    return () => {
      navBarOverridePropsVar({});
    };
  }, []);

  return (
    <Stack direction="row" alignItems="flex-start">
      <SettingsNav />
      <Divider orientation="vertical" flexItem />
      <Box sx={{ height: 'calc(100vh - 64px)', overflowY: 'scroll' }} flexGrow={1}>
        <Stack sx={{ gap: 8, py: 8, px: 12.5, width: '100%', height: '100%', mb: 15 }}>
          <Plans />
          <Billing />
        </Stack>
      </Box>
    </Stack>
  );
};
