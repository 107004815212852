import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { ConstructBase } from '../../entities/constructs/ConstructBase';
import { NarrativeScript } from '../../entities/scripts/NarrativeScript';
import { Capability } from '../../entities/scripts/Capability';
import { Edge } from '../../entities/transitions/Edge';
import { sid } from '@xspecs/short-id';
import { CommandError } from '../../ErrorStore';
import { Preview } from '../../entities/assets/Preview';

interface AddRelationshipParams extends IParams {
  sourceId: string;
  targetId: string;
}

interface RelationshipAddedParams extends IParams {
  sourceId: string;
  targetId: string;
}

export class RelationshipAddedEvent extends EventBase {
  static eventType = 'RelationshipAddedEvent';

  constructor(public readonly params: RelationshipAddedParams, public readonly source = AddRelationshipCommand) {
    super();
  }
}

export class AddRelationshipCommand extends CommandBase<AddRelationshipParams> {
  execute(params: AddRelationshipParams): RelationshipAddedEvent | CommandError {
    const { sourceId, targetId } = params;
    const result = this.addRelationship(sourceId, targetId);
    if (result instanceof Error) return CommandError.of(result, 'error');
    return new RelationshipAddedEvent({ sourceId, targetId });
  }

  private addRelationship(sourceId: string, targetId: string): void | Error {
    const source = this.model.entityRepository.get(sourceId) as ConstructBase;
    if (!source) return new Error('Source entity not found');
    const target = this.model.entityRepository.get(targetId) as ConstructBase;
    if (!target) return new Error('Target entity not found');
    if (source === target) return new Error('Cannot create a relationship with the same entity');
    if (source instanceof Preview || target instanceof Preview)
      return new Error('Cannot create a relationship with an upload preview');
    if (source instanceof NarrativeScript && target instanceof Capability) {
      return this.addRelationship(targetId, sourceId);
    }
    if (source instanceof Capability && target instanceof NarrativeScript) {
      const existingCapabilityToScriptEdges = this.model.entityRepository
        .list()
        .find((e) => e instanceof Edge && e.source instanceof Capability && e.target === target);
      if (existingCapabilityToScriptEdges) {
        return new Error('A script can only have one parent capability. TIP: Remove the existing relationship first.');
      }
    }
    const edge = new Edge({ id: sid(), source: source, target: target });
    this.model.entityRepository.add(edge);
    //source.edges.push(edge);
    //target.edges.push(edge);
    this.model.entityRepository.update(source);
    this.model.entityRepository.update(target);
  }
}
