import { CommandBase, IParams } from '../framework/CommandBase';
import { Position } from '../../types';
import { EventBase } from '../framework/EventBase';
import { EntityBase } from '../../entities/EntityBase';
import { Thread } from '../../entities/threads/Thread';
import { ActiveUser } from '../../data/File.types';
import { CommandError } from '../../ErrorStore';

interface CreateThreadParams extends IParams {
  id: string;
  userId: string;
  parent: EntityBase;
  position: Position;
  scopes: [];
  activeUser: ActiveUser;
}

export class ThreadCreatedEvent extends EventBase {
  static eventType = 'ThreadCreatedEvent';

  constructor(public readonly params: CreateThreadParams, public readonly source = CreateThreadCommand) {
    super();
  }
}

export class CreateThreadCommand extends CommandBase<CreateThreadParams> {
  execute(params: CreateThreadParams): ThreadCreatedEvent | CommandError {
    const thread = new Thread(params.id, params.userId, params.parent, params.position, params.scopes);
    thread.isSelected = true;
    this.model.entities.add(thread);
    // trackEvent(AppTypeEvent.CommentThreadAdded, thread.id); // TODO automate the tracking of events using the dispatcher
    return new ThreadCreatedEvent(params);
  }
}
