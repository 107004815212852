import { ChangeTracker } from './ChangeTracker';
import { logger } from '@xspecs/logger';
import { NotificationTypes, SingleSourceObserver } from '../observable/SingleSourceObserver';
import { EntityChangeset, SerializedEntityChangeset, UpdatedEntity } from '../types';
import { Edge } from '../entities/transitions/Edge';
import { ChangeDetector } from './ChangeDetector';
import { ActiveUser } from './File.types';
import { DEBUG_CONFIG } from '../debug-config';
import { EntityParserFactory } from '../entities/constructs/EntityParserFactory';
import { EntityBase } from '../entities/EntityBase';
import { ScriptBase } from '../entities/scripts/ScriptBase';
import { Thread } from '../entities/threads/Thread';
import { Filter } from '../entities/Filter';
import { Action } from '../entities/constructs/Action';
import { Comment } from '../entities/threads/Comment';
import { AssetBase } from '../entities/assets/AssetBase';
import { Narrative } from '../entities/constructs/Narrative';
import { Label } from '../entities/assets/Label';
import { ConstructBase } from '../entities/constructs/ConstructBase';
import { GqlEntityBase } from '../entities/gql-entities/GqlEntityBase';

export class EntityRepository {
  private entities: { [key: string]: EntityBase } = {};
  private readonly changeDetector = new ChangeDetector();
  public readonly localChangeTracker = new ChangeTracker(this.changeDetector);
  private remoteChangeTracker = new ChangeTracker(this.changeDetector);
  private scopes: string[] = [];

  constructor(
    scopes: string[],
    private readonly observer: SingleSourceObserver,
    private readonly activeUser: ActiveUser,
  ) {
    this.scopes.push(...scopes);
  }

  changeScopes(scopes: string[]) {
    this.scopes = scopes;
    this.observer.notify(NotificationTypes.onScopesChange, {
      added: this.list(),
      updated: [],
      deleted: [],
    });
  }

  getScopes() {
    return this.scopes;
  }

  public add(entity: EntityBase): void {
    if (!entity.scopes.includes('*')) {
      this.scopes.forEach((scope) => {
        if (!entity.scopes.includes(scope)) entity.scopes.push(scope);
      });
    }
    entity.isNew = true;
    // entity.isSelected = true;
    this.entities[entity.id] = entity;
    this.updateParentChildren(entity);
    this.localChangeTracker.trackAdded(entity);
  }

  public update(entity: EntityBase): void {
    this.entities[entity.id] = entity;
    this.localChangeTracker.trackUpdated(entity);
    this.updateParentChildren(entity);
  }

  public delete(id: string): void {
    const entity = this.entities[id];
    if (!entity) {
      logger.warn(`EntityRepository: Attempted to delete entity ${id} but it does not exist.`);
      return;
    }
    entity.children.forEach((child) => {
      if (child) {
        if (child instanceof AssetBase) {
          child.parent = undefined;
          this.update(child);
        } else {
          this.delete(child.id);
        }
      }
    });
    if (entity.labels && entity.labels.length > 0) {
      for (const label of entity.labels) {
        label.entities = label.entities.filter((e) => e.id !== id);
        this.update(label);
      }
    }
    this.removeFromChildren(entity);
    const type = entity.type;
    entity.deselect();
    this.deleteEdges(entity);
    if (entity instanceof ScriptBase) this.deleteScript(entity);
    else if (entity instanceof Thread) this.deleteCommentsFromThread(entity);
    else if (entity instanceof Comment) this.deleteCommentFromThread(entity);
    else if (entity instanceof GqlEntityBase) this.deleteGqlEntityFromParent(entity);
    if (entity.parent instanceof ScriptBase || entity.parent instanceof ConstructBase) {
      entity.parent.eject(entity, false);
      this.localChangeTracker.trackUpdated(entity.parent);
    }
    this.updateAndTrackParents(entity);
    delete this.entities[id];
    this.localChangeTracker.trackDeleted(id, type);
  }

  public exists<T extends EntityBase = EntityBase>(entityOrId: T | string, scoped = true): boolean {
    return !!this.get(entityOrId, scoped);
  }

  public get<T extends EntityBase = EntityBase>(entityOrId: T | string, scoped = true): T | undefined {
    const entityId = typeof entityOrId === 'string' ? entityOrId : entityOrId.id;
    const entity = this.entities[entityId];
    if (!entity) return;
    if (!scoped) return entity as T;
    if (scoped && this.inScope(entity)) return entity as T;
  }

  public list<T extends EntityBase>(type?: new (...args: any[]) => T): T[] {
    if (this.scopes.length === 0) return [];
    return Object.values(this.entities).filter((entity) => {
      const typeMatch = type ? entity instanceof type : true;
      const scopeMatch = entity.scopes.includes('*') || entity.scopes.some((scope) => this.scopes.includes(scope));
      return typeMatch && scopeMatch;
    }) as T[];
  }

  public filterEntities(entities: EntityBase[], filter?: Filter) {
    const updatedEntities: EntityBase[] = [];
    entities.forEach((entity) => {
      if (entity instanceof Label) return;
      entity.isFiltered = this.isEntityFiltered(entity, filter);
      updatedEntities.push(entity);
      this.observer.notify(NotificationTypes.OnTransientChange, {
        added: [],
        updated: [{ entity, modifiedProperties: ['isFiltered'] }],
        deleted: [],
      });
      if (entity instanceof ScriptBase) {
        entity.isFiltered =
          this.isEntityFiltered(entity, filter) ||
          this.isEntityFiltered(entity.parent, filter) ||
          entity.children.some((entity) => this.isEntityFiltered(entity, filter));
        updatedEntities.push(entity);
        this.observer.notify(NotificationTypes.OnTransientChange, {
          added: [],
          updated: [{ entity, modifiedProperties: ['isFiltered'] }],
          deleted: [],
        });
      }
    });
    const updatedEntitiesWithMods: UpdatedEntity[] = updatedEntities.map((entity: EntityBase) => {
      return { entity, modifiedProperties: ['isFiltered'] };
    });
    const changeset: EntityChangeset = { added: [], updated: updatedEntitiesWithMods, deleted: [] };
    this.observer.notify(NotificationTypes.OnTransientChange, changeset);
  }

  private isEntityFiltered(entity: EntityBase, filter?: Filter): boolean {
    const criteria = new Set(filter?.criteria ?? []);
    return criteria.size === 0 ? true : entity.labels.some((label) => criteria.has(label));
  }

  public getMap(): { [key: string]: EntityBase } {
    // filter based on scope
    return this.entities as { [key: string]: EntityBase };
  }

  public applyRemoteChanges(changes: SerializedEntityChangeset): boolean {
    return this.processChanges(changes, true, false);
  }

  public applyLocalChanges(changes: SerializedEntityChangeset): boolean {
    return this.processChanges(changes, true, true);
  }

  public validateChanges(changes: SerializedEntityChangeset): boolean {
    return this.processChanges(changes, false);
  }

  public save(): void {
    if (DEBUG_CONFIG.observer) logger.log('EntityRepository: save');
    const { added, updated, deleted } = this.localChangeTracker.getSerializedChanges();
    if (added.length === 0 && updated.length === 0 && deleted.length === 0) return;
    this.observer.notify(NotificationTypes.OnBeforeSave, this.localChangeTracker.getChanges());
    this.observer.notify(NotificationTypes.OnSave, this.localChangeTracker.getSerializedChanges());
    this.broadCastChanges();
  }

  public broadCastChanges(): void {
    //this.observer.notifyAll(this.combineRemoteChangesWithLocalChanges());
    if (this.localChangeTracker.hasChanges()) {
      if (DEBUG_CONFIG.observer) logger.log('EntityRepository: local changes', this.localChangeTracker.getChanges());
      const localChanges = this.localChangeTracker.getChanges();
      this.observer.notify(NotificationTypes.OnAfterSave, localChanges);
      this.localChangeTracker.clearChanges();
    }
    if (this.remoteChangeTracker.hasChanges()) {
      if (DEBUG_CONFIG.observer) logger.log('EntityRepository: remote changes', this.remoteChangeTracker.getChanges());
      const remoteChanges = this.remoteChangeTracker.getChanges();
      this.observer.notify(NotificationTypes.OnAfterSave, remoteChanges);
      this.remoteChangeTracker.clearChanges();
    }
  }

  public dispose() {
    this.entities = {};
    this.localChangeTracker.clearChanges();
    this.scopes = [];
  }

  private deleteEdges(entity: EntityBase) {
    if (entity instanceof Edge) return;
    const edges = this.list().filter(
      (edge) => edge instanceof Edge && (edge.source === entity || edge.target === entity),
    );
    edges.forEach((edge: Edge) => {
      this.delete(edge.id);
    });
  }

  private updateAndTrackParents(entity: EntityBase) {
    if (entity.parent) {
      this.localChangeTracker.trackUpdated(entity.parent);
      this.updateAndTrackParents(entity.parent);
    }
  }

  private inScope(entity: EntityBase) {
    if (entity.scopes.includes('*')) return true;
    return entity.scopes.some((scope) => this.scopes.includes(scope));
  }

  private deleteScript(script: ScriptBase) {
    script.children.forEach((e) => {
      e.parent = null;
      this.update(e);
    });
    if ((script.parent && script.parent instanceof Action) || script.parent instanceof Narrative) {
      script.parent.removeSubscript();
      this.update(script.parent);
    }
  }

  private deleteCommentsFromThread(thread: Thread) {
    thread.comments.forEach((comment) => this.delete(comment.id));
  }

  private deleteCommentFromThread(comment: Comment) {
    const thread = comment.parent;
    if (thread) {
      thread.removeComment(comment);
      this.update(thread);
    }
  }

  private deleteGqlEntityFromParent(entity: GqlEntityBase) {
    if (entity.query) {
      entity.query.gqlEntities = entity.query.gqlEntities.filter((e) => e.id !== entity.id);
    }
  }

  private filterByScope(entities: EntityBase[]): EntityBase[] {
    return entities.filter((entity) =>
      entity.scopes.some((scope) => this.scopes.includes('*') || this.scopes.includes(scope)),
    );
  }

  private processChanges(
    changes: SerializedEntityChangeset = { added: [], updated: [], deleted: [] },
    applyChanges: boolean,
    isLocalChange = false,
  ): boolean {
    let potentiallyOffendingEntity: any;
    try {
      if (applyChanges) {
        changes.deleted.forEach((deletedItem) => this.deleteEntityWithoutTracking(deletedItem.id));
      }
      // get a clean copy of the entities
      const processedEntities = [...changes.added, ...changes.updated]
        .map((rawEntity) => {
          if (!rawEntity || !rawEntity.type) {
            logger.error('EntityRepository: offending entity', rawEntity);
            return;
          }
          const clonedEntity = JSON.parse(JSON.stringify(rawEntity));
          potentiallyOffendingEntity = clonedEntity;
          const references = EntityParserFactory.getReferences(clonedEntity.type);
          references.forEach((ref) => {
            clonedEntity[`__applyChangesTemp__${ref}`] = clonedEntity[ref];
            delete clonedEntity[ref];
          });
          return clonedEntity;
        })
        .filter(Boolean);

      // parse entities without any refs
      processedEntities.forEach((processedEntity) => {
        potentiallyOffendingEntity = processedEntity;
        let existing: EntityBase;
        try {
          existing = this.get(processedEntity.id, false);
        } catch (e) {
          logger.warn(
            'EntityRepository:processChanges - Allowing potentially offending entity',
            potentiallyOffendingEntity,
          );
          logger.error(e);
        }
        const parsedEntity = EntityParserFactory.parse(
          processedEntity,
          null,
          () => {
            throw new Error(
              `EntityRepository:processChanges - This should never be called on the first parse as we haven't done our second pass yet`,
            );
          },
          true,
        );
        if (applyChanges) {
          if (existing) this.updateEntityWithoutTracking(processedEntity.id, parsedEntity);
          else this.addEntityWithoutTracking(parsedEntity);
        }
      });

      if (!applyChanges) return true;

      // linking removed refs
      processedEntities.forEach((processedEntity) => {
        const references = EntityParserFactory.getReferences(processedEntity.type);
        // Recursive function to handle nested hydration
        const hydrateReferences = (tempReferenceField, referenceFieldKey) => {
          if (Array.isArray(tempReferenceField)) {
            return tempReferenceField
              .map((item) => hydrateReferences(item, referenceFieldKey))
              .filter((item) => item !== undefined);
          } else if (typeof tempReferenceField === 'object' && tempReferenceField !== null) {
            if (tempReferenceField.$ref !== undefined) {
              const hydratedEntity = this.get(tempReferenceField.$ref, false);
              if (!hydratedEntity) {
                logger.warn(
                  `EntityRepository:processChanges - Entity reference '${tempReferenceField.$ref}' not found in field '${referenceFieldKey}' and ignored while hydrating entity`,
                  processedEntity,
                );
              }
              return hydratedEntity;
            } else {
              const hydratedObject = {};
              for (const key in tempReferenceField) {
                hydratedObject[key] = hydrateReferences(tempReferenceField[key], referenceFieldKey);
              }
              return hydratedObject;
            }
          }
          return tempReferenceField;
        };
        references.forEach((referenceFieldKey) => {
          const tempReferenceField = processedEntity[`__applyChangesTemp__${referenceFieldKey}`];
          if (tempReferenceField) {
            const entity = this.get(processedEntity.id, false);
            entity[referenceFieldKey] = hydrateReferences(tempReferenceField, referenceFieldKey);
          }
        });
      });
      changes.added.forEach((addedItem) => {
        const entity = this.get(addedItem.id, false);
        if (!entity.isValid()) {
          logger.warn(
            `EntityRepository:processChanges Entity of type ${addedItem?.type} is not valid and will be ignored.`,
            entity,
          );
          delete this.entities[addedItem.id];
        } else if (isLocalChange) {
          this.localChangeTracker.trackAdded(entity);
        } else {
          this.remoteChangeTracker.trackAdded(entity);
        }
        this.changeDetector.setInitialState(entity);
        this.updateParentChildren(entity);
      });
      changes.updated.forEach((updatedItem) => {
        const entity = this.get(updatedItem.id, false);
        if (isLocalChange) {
          this.localChangeTracker.trackUpdated(entity);
        } else {
          this.remoteChangeTracker.trackUpdated(entity);
          this.changeDetector.setInitialState(entity);
        }
        this.updateParentChildren(entity);
      });
      changes.deleted.forEach((deletedItem) => {
        if (isLocalChange) {
          this.localChangeTracker.trackDeleted(deletedItem.id, deletedItem.type);
        } else {
          this.remoteChangeTracker.trackDeleted(deletedItem.id, deletedItem.type);
          this.changeDetector.removeFromInitialState(deletedItem.id);
        }
        this.removeFromChildren(this.get(deletedItem.id));
      });
      return true;
    } catch (e) {
      logger.error('EntityRepository: Failed to process changes with error', e);
      logger.error('EntityRepository: potentially offending entity', potentiallyOffendingEntity);
      return false;
    }
  }

  private updateParentChildren(entity: EntityBase) {
    const parent = entity.parent;
    if (parent) {
      if (!parent.children) parent.children = [];
      if (!parent.children.includes(entity)) {
        this.removeFromChildren(entity);
        parent.children.push(entity);
      }
    } else {
      this.removeFromChildren(entity);
    }
  }

  private removeFromChildren(entity: EntityBase): void {
    this.list().forEach((e) => {
      if (e.children.includes(entity)) {
        e.children = e.children.filter((child) => child.id !== entity.id);
      }
    });
  }

  private updateEntityWithoutTracking(id: string, updatedEntity: Partial<EntityBase>) {
    const entity = this.get(id, false);
    if (!entity) throw new Error(`A remote update of a local entity failed. Entity ${id} not found.`);
    this.deepMerge(updatedEntity, entity);
  }

  private addEntityWithoutTracking(entity: EntityBase): void {
    this.entities[entity.id] = entity;
  }

  private deleteEntityWithoutTracking(id: string): void {
    delete this.entities[id];
  }

  private deepMerge(source: any, target: any) {
    Object.keys(source).forEach((key) => {
      if (Array.isArray(source[key])) {
        target[key] = source[key];
      } else if (source[key] instanceof Object && key in target)
        Object.assign(source[key], this.deepMerge(source[key], target[key]));
    });
    Object.assign(target, source);
    return target;
  }

  public getAllUniqueChildren(entityIds: string[]): Set<EntityBase> {
    const entities = new Set<EntityBase>();
    entityIds.forEach((entityId) => this.getUniqueChildren(this.get(entityId), entities));
    return entities;
  }

  private getUniqueChildren(entity: EntityBase, entities: Set<EntityBase>) {
    entities.add(entity);
    entity.children.forEach((child) => this.getUniqueChildren(child, entities));
  }

  //   get anscestor  => top parent => getAllUniqueChildren
}
