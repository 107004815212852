import { FC } from 'react';
import { IconComponentProps } from '../icon';

export const Spec: FC<IconComponentProps> = ({ color }) => {
  return (
    <>
      <path
        d="M8.83541 16.2925C9.61166 17.2631 10.9054 17.7399 12.1391 17.7399C14.3292 17.7399 16 16.0735 16 13.8931C16 11.8505 14.6268 11.1352 12.9153 10.601L12.9126 10.6001C12.5938 10.5006 12.2777 10.4019 11.9589 10.283C11.9436 10.2771 11.9283 10.2711 11.913 10.2652C11.4729 10.0944 11.044 9.92803 11.044 9.36991C11.044 8.81621 11.5033 8.41881 12.0596 8.41881C12.6557 8.41881 12.9957 8.79589 13.1149 9.36991H15.7006C15.6212 7.44652 14.0298 6 12.0976 6C10.168 6 8.39739 7.40854 8.39739 9.41053C8.39739 11.0363 9.39177 12.0475 10.8648 12.5641C10.9609 12.598 11.0824 12.6374 11.2196 12.6818C11.8779 12.8951 12.8968 13.2251 13.1935 13.6344C13.3118 13.8128 13.3525 14.0115 13.3525 14.2102C13.3525 14.8849 12.7943 15.3211 12.1576 15.3211C11.2427 15.3211 10.8842 14.7056 10.7058 13.9135H8C8.07948 14.8257 8.25875 15.5578 8.83541 16.2925Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4C2 2.89543 2.89543 2 4 2H22V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V4ZM4 4H20V20H4V4Z"
        fill={color}
      />
    </>
  );
};
