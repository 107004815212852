import { CommandBase, IParams } from '../framework/CommandBase';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

interface AddScriptLaneParams extends IParams {
  scriptId: string;
  laneIndex: number;
}

interface ScriptLaneAddedParams extends IParams {
  scriptId: string;
  laneIndex: number;
}

export class ScriptLaneAddedEvent extends EventBase {
  static eventType = 'ScriptLaneAddedEvent';

  constructor(public readonly params: ScriptLaneAddedParams, public readonly source = AddScriptLaneCommand) {
    super();
  }
}

export class AddScriptLaneCommand extends CommandBase<AddScriptLaneParams> {
  execute(params: AddScriptLaneParams): ScriptLaneAddedEvent | CommandError {
    const script = this.model.entityRepository.get<ScriptBase>(params.scriptId);
    if (!script.addLane(params.laneIndex)) return CommandError.of(script.getError(), 'error');
    this.model.entityRepository.update(script);
    return new ScriptLaneAddedEvent({ scriptId: params.scriptId, laneIndex: params.laneIndex });
  }
}
