import { LogoutOutlined, MenuBookRounded, PersonAddAltRounded } from '@mui/icons-material';
import { Box, Button, IconButton } from '@mui/material';
import { NavBarProps } from './nav-bar';
import { ProfileMenu } from './profile-menu/profile-menu';
import { useAuth } from '../../auth';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { showInviteUsersVar } from '../../state/state';
import { RoutePaths } from '../../config/route-paths/route-paths';
import { AppTypeEvent, useTrackEvents } from '../../hooks/use-track-events';
import { UpgradeButton } from './upgrade-button/upgrade-button';
import { HelpMenu } from './help-menu/help-menu';

type NavRightItemsProps = { isPrimary?: boolean } & Pick<NavBarProps, 'hasOrganizations' | 'isLoggedIn'>;

const CustomSlackIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    preserveAspectRatio="xMidYMid meet"
    width="20" // Adjust the width as needed
    height="20" // Adjust the height as needed
  >
    <title>Join our Slack community</title>
    <path d="M22,12 a6,6 0 1 1 6,-6 v6z M22,16 a6,6 0 0 1 0,12 h-16 a6,6 0 1 1 0,-12" fill="#36C5F0" />
    <path d="M48,22 a6,6 0 1 1 6,6 h-6z M32,6 a6,6 0 1 1 12,0v16a6,6 0 0 1 -12,0z" fill="#2EB67D" />
    <path d="M38,48 a6,6 0 1 1 -6,6 v-6z M54,32 a6,6 0 0 1 0,12 h-16 a6,6 0 1 1 0,-12" fill="#ECB22E" />
    <path d="M12,38 a6,6 0 1 1 -6,-6 h6z M16,38 a6,6 0 1 1 12,0v16a6,6 0 0 1 -12,0z" fill="#E01E5A" />
  </svg>
);

export const NavRightItems = (props: NavRightItemsProps) => {
  const { isLoggedIn, hasOrganizations, isPrimary } = props;

  const { logout } = useAuth();

  const { trackEvent } = useTrackEvents();

  const { formatMessage: f } = useIntl();

  const onLogout = useCallback(() => {
    trackEvent(AppTypeEvent.LoggedOut, null);
    logout({ logoutParams: { returnTo: window.location.origin + RoutePaths.Login } });
  }, [logout, trackEvent]);

  const onInvite = useCallback(() => showInviteUsersVar(true), []);

  const handleHelpClick = () => {
    // const width = window.innerWidth * 0.8;
    // const height = window.innerHeight * 0.65;
    // const left = window.innerWidth * 0.1;
    // const top = window.innerHeight * 0.18;
    // Opens a new window centered and sized at half of the user's screen dimensions
    // window.open(
    // 'https://www.narrative.tech/docs/user-guide/introduction',
    // 'newwindow',
    // `width=${width},height=${height},left=${left},top=${top}`,
    // );
    window.open('https://www.narrativedriven.org', '_blank').focus();
  };

  const handleSlackLink = () => {
    window
      .open('https://join.slack.com/t/narrative-driven/shared_invite/zt-2gtzb38vr-irHjbx3iBXrXNFPI~RpiZg', '_blank')
      .focus();
  };

  return (
    <Box
      sx={{
        color: { xs: 'primary.contrastText', md: isPrimary ? 'primary.contrastText' : 'action.active' },
        display: 'flex',
        alignItems: 'center',
        height: '100%',
      }}
    >
      {isLoggedIn && !hasOrganizations ? (
        <IconButton data-testid="logout-button" sx={{ color: 'inherit', ml: 'auto', mr: 1 }} onClick={onLogout}>
          <LogoutOutlined color="inherit" />
        </IconButton>
      ) : null}
      {isLoggedIn && hasOrganizations ? (
        <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', gap: 1 }}>
          <Button
            data-testid="nav-right-items-invite-button"
            sx={{ mr: 1, display: { xs: 'none', md: 'flex' } }}
            startIcon={<PersonAddAltRounded />}
            onClick={onInvite}
            size="small"
            variant="outlined"
          >
            {f({ id: 'invite-users' })}
          </Button>
          <UpgradeButton />

          {/*<NavBarIconButton id="nav-alerts-button" icon={<NotificationsIcon color="inherit" />} />*/}
          {/*<NavBarIconButton id="nav-search-button" icon={<SearchIcon color="inherit" />} onClick={onSearch} />*/}
          <IconButton color="inherit" onClick={handleHelpClick} title={'Read the NDD Guide'}>
            <MenuBookRounded />
          </IconButton>
          <IconButton color="inherit" onClick={handleSlackLink}>
            <CustomSlackIcon />
          </IconButton>
          <HelpMenu />
          <ProfileMenu />
        </Box>
      ) : null}
    </Box>
  );
};
