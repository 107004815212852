import { IconComponentProps } from '../icon';

export const ConstructPreview = ({ color }: IconComponentProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 2H22V22H2V2Z" fill="#D8D8D8" />
      <path d="M2.5 2.5H21.5V21.5H2.5V2.5Z" fill={color} />
    </svg>
  );
};
