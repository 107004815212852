import { FC } from 'react';
import { IconComponentProps } from '../icon';

export const AddFrame: FC<IconComponentProps> = ({ color }) => {
  return (
    <>
      <path
        d="M14 18H10C9.5 18 9 17.8 8.6 17.4C8.2 17 8 16.5 8 16V2C8 1.5 8.2 1 8.6 0.6C9 0.2 9.5 0 10 0H14C14.5 0 15 0.2 15.4 0.6C15.8 1 16 1.5 16 2V16C16 16.5 15.8 17 15.4 17.4C15 17.8 14.5 18 14 18ZM10 2V16H14V2H10Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 11C2 11.6 2.4 12 3 12C3.6 12 4 11.6 4 11V10H5C5.6 10 6 9.6 6 9C6 8.4 5.6 8 5 8H4V7C4 6.4 3.6 6 3 6C2.4 6 2 6.4 2 7V8H1C0.4 8 0 8.4 0 9C0 9.6 0.4 10 1 10H2V11Z"
        fill={color}
      />
    </>
  );
};
