import { DEBUG_KEYS } from './debug-keys';

const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined';

const DEBUG_PREFIX = 'DEBUG_';

const getConfigValue = (key: string, defaultValue: number) => {
  if (!localStorage.getItem(key)) {
    localStorage.setItem(key, JSON.stringify(defaultValue));
    return defaultValue;
  }
  return JSON.parse(localStorage.getItem(key));
};

const setConfigValue = (key: string, value: number) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const cleanLocalStorage = (defaults: { [key: string]: number }) => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && key.startsWith(DEBUG_PREFIX) && !defaults.hasOwnProperty(key.replace(DEBUG_PREFIX, ''))) {
      localStorage.removeItem(key);
    }
  }
};

const createDebugConfig = (defaults: { [key: string]: number }) => {
  cleanLocalStorage(defaults);
  return new Proxy(defaults, {
    get(target, prop: string) {
      const key = DEBUG_PREFIX + prop;
      return getConfigValue(key, target[prop]);
    },
    set(target, prop: string, value: number) {
      const key = DEBUG_PREFIX + prop;
      setConfigValue(key, value);
      target[prop] = value;
      return true;
    },
  });
};

export const DEBUG_CONFIG = isBrowser ? createDebugConfig(DEBUG_KEYS) : DEBUG_KEYS;
