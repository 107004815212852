import { AssetBase } from './AssetBase';
import { EntityType } from '../EntityType';

export class Schema extends AssetBase {
  get type(): EntityType {
    return EntityType.Schema;
  }

  static parse(data: unknown): Schema {
    return AssetBase.parseBase.call(Schema, data);
  }
}
