import { CreateOrganization } from '../../pages/create-organization/create-organization';
import { Box } from '@mui/material';

export const CreateOrganizationRoute = () => {
  return (
    <Box data-testid="create-organization-route" height="100%" width="100%">
      <CreateOrganization />
    </Box>
  );
};
