import { LabelColor } from '../../../label-color/label-color';
import { LabelsColorSelect, LabelsColorSelectProps } from '../../../labels-color-select/labels-color-select';

type LabelsListItemColorDefaultProps = {
  color: string;
};

type LabelsListItemColorProps = LabelsListItemColorDefaultProps & {
  isBeingEdited?: false;
};

type LabelsListItemNameIsBeingEditedProps = LabelsListItemColorDefaultProps & {
  isBeingEdited: true;
} & Pick<LabelsColorSelectProps, 'onColorChange'>;

export const LabelsListItemColor = (props: LabelsListItemColorProps | LabelsListItemNameIsBeingEditedProps) => {
  const { color, isBeingEdited } = props;

  if (isBeingEdited) {
    const { onColorChange } = props;
    return <LabelsColorSelect color={color} onColorChange={onColorChange} />;
  }

  return <LabelColor color={color} />;
};
