import { SingleSourceModel } from '../SingleSourceModel';
import { DEBUG_CONFIG } from '../debug-config';
import { logger } from '@xspecs/logger';

export type Organization = {
  id: string;
  name: string;
};

export type Workspace = {
  id: string;
  name: string;
};

//Prefix with node_ to send data to GTM
export enum NodeTypeEvent {
  Added = 'node_added',
  Deleted = 'node_deleted',
  Selected = 'node_selected',
  Updated = 'node_updated',
  Moved = 'node_moved',
  AttachedToScript = 'node_attached_to_narrative',
  AttachedToConstruct = 'node_attached_to_construct',
  DetailViewed = 'node_detail_viewed',
  AssetAdded = 'node_asset_added',
  AssetSelected = 'node_asset_selected',
}

//prefix with nar_ to send data to GTM
export enum AppTypeEvent {
  CommentThreadAdded = 'nar_comment_thread_added',
  CommentThreadDeleted = 'nar_comment_thread_deleted',
  CommentThreadViewed = 'nar_comment_thread_viewed',
  CommentThreadResolved = 'nar_comment_thread_resolved',
  CommentThreadUnResolved = 'nar_comment_thread_unresolved',
  CommentAdded = 'nar_comment_added',
  CommentDeleted = 'nar_comment_deleted',
  CommentUpdated = 'nar_comment_updated',
  CommentUserMentioned = 'nar_comment_user_mentioned',
  OrganizationCreated = 'nar_organization_created',
  OrganizationSelected = 'nar_organization_selected',
  OrganizationDeleted = 'nar_organization_deleted',
  WorkspaceCreated = 'nar_workspace_created',
  WorkspaceNameChanged = 'nar_workspace_name_changed',
  WorkspaceSelected = 'nar_workspace_selected',
  WorkspaceDeleted = 'nar_workspace_deleted',
  SearchPerformed = 'nar_search_performed',
  UserInvited = 'nar_user_invited',
  LoggedIn = 'nar_logged_in',
  LoggedOut = 'nar_logged_out',
  TokenExpired = 'nar_token_expired',
}

export class Analytics {
  private static instance: Analytics;

  private constructor(
    private readonly model: SingleSourceModel,
    private readonly container: any,
    private readonly organization: Organization,
    private readonly workspace: Workspace,
  ) {}

  static setInstance(model: SingleSourceModel, container: any, organization: Organization, workspace: Workspace) {
    this.instance = new Analytics(model, container, organization, workspace);
    return this.instance;
  }

  static getInstance() {
    return this.instance;
  }

  trackPageView(path: string, title: string, pageType: string) {
    this.container.dataLayer.push({
      event: 'page_view',
      page_path: path,
      page_title: title,
      page_type: pageType,
      organization: this.organization ? this.organization.name : null,
      workspace: this.workspace ? this.workspace.name : null,
    });
    this.debug(`page_view: ${pageType} - ${path} - ${title}`);
  }

  trackNodeOperation(eventType: NodeTypeEvent, constructType: string) {
    this.container.dataLayer.push({ event: eventType, construct_type: constructType });
    this.debug(eventType, constructType);
  }

  trackNodeOperationByNodeId(eventType: NodeTypeEvent, entityId: string) {
    const entity = this.model.entities.get(entityId);
    const constructType = entity ? entity.type : 'NA';
    this.container.dataLayer.push({ event: eventType, ConstructType: constructType });
    this.debug(eventType, constructType);
  }

  trackEvent(event: AppTypeEvent, data: any) {
    this.container.dataLayer.push({ event: event, ...data });
    this.debug(`analytics: ${event}: ${JSON.stringify(data)}`);
  }

  private debug(...args: any) {
    if (DEBUG_CONFIG.analytics) logger.log('Analytics:', ...args);
  }
}
