import { Box, Typography } from '@mui/material';
import { Menu, MenuItem } from '@szhsin/react-menu';
import { useIntl } from 'react-intl';
import { EntityType } from '@xspecs/single-source-model';
import { SingleSourceModelToolbarConstructButton } from '../construct-button/single-source-model-toolbar-construct-button';
import { ChangeEvent, CSSProperties, useRef } from 'react';
import { FolderOutlined, InsertLink } from '@mui/icons-material';
import { useSingleSourceStore } from '../../../../store/single-source-store/single-source-store';
import { Loading } from '../../../loading/loading';

type UploadButtonProps = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
};

export const UploadButton = (props: UploadButtonProps) => {
  const { onChange, loading } = props;

  const { formatMessage: f } = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);
  const setShowUploadLinkModal = useSingleSourceStore.use.setShowUploadLinkModal();

  const onFromUrlClick = () => {
    setShowUploadLinkModal(true);
  };

  return (
    <Menu
      menuButton={
        <Box width="fit-content">
          {loading ? (
            <Loading circularProgressProps={{ size: 16 }} rootProps={{ width: 101 }} />
          ) : (
            <SingleSourceModelToolbarConstructButton type={EntityType.Upload} />
          )}
        </Box>
      }
      align="start"
      position="anchor"
      portal
      transition
    >
      <MenuItem style={menuItemStyle} onClick={() => inputRef.current.click()}>
        <input
          type="file"
          style={{ display: 'none' }}
          ref={inputRef}
          onChange={(e) => {
            onChange(e);
            inputRef.current.value = '';
          }}
        />
        <FolderOutlined color="inherit" />
        <Typography variant="caption" fontSize="16px">
          {f({ id: 'my-device' })}
        </Typography>
      </MenuItem>
      <MenuItem style={menuItemStyle} onClick={onFromUrlClick}>
        <InsertLink color="inherit" />
        <Typography variant="caption" fontSize="16px">
          {f({ id: 'from-url' })}
        </Typography>
      </MenuItem>
    </Menu>
  );
};

const menuItemStyle: CSSProperties = {
  padding: '4px 16px',
  display: 'flex',
  flexDirection: 'row',
  gap: 16,
  width: 188,
};
