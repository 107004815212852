import { z } from 'zod';
import { EntityBase } from './EntityBase';
import { EntityType } from './EntityType';

export enum FilterType {
  unsaved = 'unsaved',
  userSaved = 'user-saved',
  publicSaved = 'public-saved',
}

export class Filter extends EntityBase {
  constructor(
    public readonly id: string,
    public name: string,
    public filterType: FilterType,
    public criteria: EntityBase[],
    public readonly scopes: string[] = [],
    public createdBy?: string,
  ) {
    super(id, '', undefined, { x: 0, y: 0 }, scopes);
  }

  static schema = EntityBase.abstractBaseSchema.extend({
    type: z.nativeEnum(EntityType).describe(`The type of the entity`),
    parent: z.lazy(() => EntityBase.abstractBaseSchema).optional(),
    filterType: z.string(),
    createdBy: z.string().optional(),
    criteria: z.array(z.lazy(() => EntityBase.abstractBaseSchema)).optional(),
  });

  get type(): EntityType {
    return EntityType.Filter;
  }

  static references = ['criteria'];

  addCriteria(entities: EntityBase[]): void {
    for (const entity of entities) {
      this.criteria.push(entity);
    }
  }

  removeCriteria(entityIds: string[]): EntityBase[] {
    for (const id of entityIds) {
      this.criteria = this.criteria.filter((criteriaEntity) => criteriaEntity.id !== id) ?? [];
    }
    return this.criteria;
  }

  static parse(data: unknown): Filter {
    const validatedData = Filter.schema.parse(data);
    const filter = new Filter(
      validatedData.id,
      validatedData.name,
      validatedData.filterType,
      validatedData.criteria,
      validatedData.scopes,
      validatedData.createdBy,
    );
    return filter;
  }

  isValid(): boolean {
    return Filter.schema.safeParse(this).success;
  }

  serialize(reference: boolean = false): unknown {
    if (reference) return super.serialize(reference);
    return {
      ...(super.serialize() as any),
      id: this.id,
      filterType: this.filterType,
      createdBy: this.createdBy,
      criteria: this.criteria.map((entity) => entity.serialize(true)),
    };
  }
}
