import { useAuth, withAuthenticationRequired } from '../../auth';
import { Navigate, Outlet } from 'react-router-dom';
import { RoutePaths } from '../../config/route-paths/route-paths';
import { useReactiveVar } from '@apollo/client';
import { authStatusVar } from '../../state/state';
import { useEffect } from 'react';
import { AppTypeEvent, useTrackEvents } from '../../hooks/use-track-events';

export const PrivateRoute = withAuthenticationRequired(() => {
  const { user, logout } = useAuth();

  const authStatus = useReactiveVar(authStatusVar);

  const { trackEvent } = useTrackEvents();

  useEffect(() => {
    if (authStatus.status === 'expired') {
      trackEvent(AppTypeEvent.TokenExpired, null);
      logout({ logoutParams: { returnTo: window.location.origin + RoutePaths.Login } }).catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
    }
  }, [authStatus, authStatus.status, logout, trackEvent]);

  if (authStatus.status === 'unauthorized') {
    return <Navigate data-testid="no-access" to={RoutePaths.NoAccess} />;
  }

  if (!user?.email_verified) {
    return <Navigate data-testid="confirm-email" to={RoutePaths.ConfirmEmail} />;
  }

  return <Outlet />;
});
