import { IconComponentProps } from '../icon';
import { FC } from 'react';

export const Query: FC<IconComponentProps> = ({ color }) => {
  return (
    <>
      <path
        d="M5 4.5H20.6959V7H17.8864C18.1802 7.30617 18.4443 7.64104 18.6741 8H20.6959C21.2482 8 21.6959 7.55228 21.6959 7V4C21.6959 3.44772 21.2482 3 20.6959 3H4.69592C4.14364 3 3.69592 3.44772 3.69592 4V7C3.69592 7.55228 4.14364 8 4.69592 8H7.71774C7.94755 7.64104 8.21168 7.30617 8.5055 7H4.69592L5 4.5Z"
        fill={color}
      />
      <path
        d="M7.19409 9H4.69592C4.14364 9 3.69592 9.44772 3.69592 10V13C3.69592 13.5523 4.14364 14 4.69592 14H7.19409C7.06018 13.6789 6.95129 13.3448 6.86985 13H4.69592V10H6.86985C6.95129 9.65525 7.06018 9.3211 7.19409 9Z"
        fill={color}
      />
      <path
        d="M19.522 13H20.6959V10H19.522C19.4406 9.65525 19.3317 9.3211 19.1978 9H20.6959C21.2482 9 21.6959 9.44772 21.6959 10V13C21.6959 13.5523 21.2482 14 20.6959 14H19.1978C19.3317 13.6789 19.4406 13.3448 19.522 13Z"
        fill={color}
      />
      <path
        d="M7.71774 15H4.69592C4.14364 15 3.69592 15.4477 3.69592 16V19C3.69592 19.0758 3.70435 19.1495 3.72031 19.2205L4.69592 18.2449V16H6.94079L7.80624 15.1345C7.7762 15.0901 7.7467 15.0452 7.71774 15Z"
        fill={color}
      />
      <path
        d="M20.6959 20H7.25083L8.25083 19H20.6959V16H17.8864C18.1802 15.6938 18.4443 15.359 18.6741 15H20.6959C21.2482 15 21.6959 15.4477 21.6959 16V19C21.6959 19.5523 21.2482 20 20.6959 20Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1959 17C16.2335 17 18.6959 14.5376 18.6959 11.5C18.6959 8.46243 16.2335 6 13.1959 6C10.1584 6 7.69592 8.46243 7.69592 11.5C7.69592 12.8569 8.18728 14.099 9.00176 15.0581L3.21501 20.8449C2.92833 21.1316 2.92833 21.5964 3.21501 21.8831C3.5017 22.1697 3.96651 22.1697 4.25319 21.8831L10.0939 16.0424C10.9769 16.6466 12.0452 17 13.1959 17ZM13.1959 15.5C15.4051 15.5 17.1959 13.7091 17.1959 11.5C17.1959 9.29086 15.4051 7.5 13.1959 7.5C10.9868 7.5 9.19592 9.29086 9.19592 11.5C9.19592 13.7091 10.9868 15.5 13.1959 15.5Z"
        fill={color}
      />
    </>
  );
};
