import { IconComponentProps } from '../icon';

export const NarrativeText = ({ color }: IconComponentProps) => {
  return (
    <>
      <path
        d="M82.0903 22.0309C82.0903 21.7717 82.1406 21.5224 82.2362 21.2931C82.3318 21.0638 82.4727 20.8495 82.6538 20.67C82.835 20.4905 83.0513 20.341 83.2878 20.2413C83.5243 20.1416 83.7659 20.0917 84.0174 20.0917C84.2841 20.0917 84.5357 20.1366 84.7622 20.2263C84.9886 20.3161 85.1999 20.4457 85.3861 20.6251C85.5823 20.8096 85.7283 21.0239 85.839 21.2682C85.9497 21.5125 86 21.7617 86 22.016C86 22.3001 85.9547 22.5543 85.8591 22.7886C85.7635 23.0229 85.6276 23.2323 85.4414 23.4217C85.2553 23.6112 85.0439 23.7557 84.8074 23.8554C84.5709 23.9551 84.3143 24 84.0325 24C83.7508 24 83.4992 23.9502 83.2677 23.8505C83.0362 23.7508 82.8249 23.6062 82.6387 23.4068C82.4576 23.2124 82.3217 23.003 82.2312 22.7687C82.1406 22.5344 82.0903 22.2901 82.0903 22.0359V22.0309ZM82.9859 22.0209C82.9859 22.3649 83.0815 22.6341 83.2727 22.8335C83.4639 23.0329 83.7206 23.1326 84.0476 23.1326C84.3747 23.1326 84.6263 23.0279 84.8175 22.8235C85.0087 22.6192 85.1043 22.345 85.1043 21.996C85.1043 21.6869 85.0037 21.4277 84.8024 21.2283C84.6011 21.0289 84.3495 20.9292 84.0476 20.9292C83.7457 20.9292 83.4841 21.0289 83.2828 21.2333C83.0815 21.4377 82.9859 21.6969 82.9859 22.016V22.0209Z"
        fill={color}
      />
      <path
        d="M81.5468 19.8225H80.7015V19.0049H81.5468V19.8225ZM80.6965 23.8903V20.1964H81.5418V23.8903H80.6965Z"
        fill={color}
      />
      <path
        d="M79.1216 20.4955V19.0049H79.977V23.8903H79.1216V23.5862C78.9505 23.7208 78.7694 23.8205 78.5782 23.8853C78.387 23.9501 78.1807 23.985 77.9593 23.985C77.7228 23.985 77.5014 23.9501 77.2951 23.8753C77.0888 23.8006 76.9026 23.6959 76.7365 23.5513C76.5101 23.3569 76.339 23.1326 76.2183 22.8684C76.0975 22.6041 76.0371 22.325 76.0371 22.0309C76.0371 21.7567 76.0925 21.4975 76.2032 21.2432C76.3139 20.989 76.4749 20.7746 76.6761 20.5902C76.8472 20.4307 77.0384 20.311 77.2598 20.2263C77.4812 20.1415 77.7127 20.1017 77.9593 20.1017C78.2058 20.1017 78.4121 20.1316 78.5983 20.1964C78.7845 20.2612 78.9555 20.3559 79.1216 20.4855V20.4955ZM79.0864 22.0608C79.0864 21.7467 78.9857 21.4825 78.7845 21.2731C78.5832 21.0638 78.3316 20.9591 78.0247 20.9591C77.7177 20.9591 77.4712 21.0688 77.2598 21.2831C77.0485 21.4975 76.9428 21.7567 76.9428 22.0508C76.9428 22.3449 77.0485 22.6191 77.2598 22.8285C77.4712 23.0378 77.7379 23.1375 78.0649 23.1375C78.3517 23.1375 78.5882 23.0329 78.7895 22.8235C78.9908 22.6141 79.0864 22.3599 79.0864 22.0608Z"
        fill={color}
      />
      <path
        d="M74.603 23.8903V23.4915C74.4772 23.661 74.3313 23.7856 74.1652 23.8654C73.9992 23.9452 73.813 23.99 73.6067 23.99C73.1388 23.99 72.7815 23.8455 72.5299 23.5613C72.2783 23.2772 72.1525 22.8684 72.1525 22.335V20.1964H73.0281V21.8564C73.0281 22.3549 73.0834 22.6989 73.1991 22.8784C73.3149 23.0578 73.5111 23.1525 73.7979 23.1525C74.0847 23.1525 74.2961 23.0479 74.4219 22.8435C74.5477 22.6391 74.608 22.2901 74.608 21.7966V20.1964H75.4634V23.8903H74.603Z"
        fill={color}
      />
      <path
        d="M70.321 23.8903V20.9641H69.7524V20.1964H70.321V19H71.1864V20.1964H71.7601V20.9641H71.1864V23.8903H70.321Z"
        fill={color}
      />
      <path
        d="M67.795 22.669V22.7188C67.795 22.8833 67.8353 23.0129 67.9208 23.1176C68.0064 23.2223 68.112 23.2722 68.2479 23.2722C68.3837 23.2722 68.4794 23.2323 68.5599 23.1575C68.6404 23.0827 68.6806 22.983 68.6806 22.8634C68.6806 22.7537 68.6504 22.664 68.59 22.5942C68.5297 22.5244 68.424 22.4546 68.273 22.3798C68.1875 22.34 68.0617 22.2901 67.8957 22.2203C67.7296 22.1505 67.6189 22.1007 67.5736 22.0608C67.4378 21.9511 67.3321 21.8265 67.2667 21.6869C67.2013 21.5473 67.1661 21.3928 67.1661 21.2133C67.1661 20.9043 67.2768 20.6401 67.4982 20.4257C67.7195 20.2114 67.9862 20.1017 68.2982 20.1017C68.6303 20.1017 68.892 20.1964 69.0882 20.3858C69.2844 20.5753 69.3851 20.8445 69.4052 21.1834H68.6253V21.1635C68.6253 21.0439 68.5951 20.9541 68.5297 20.8843C68.4642 20.8145 68.3787 20.7846 68.268 20.7846C68.1724 20.7846 68.0919 20.8195 68.0265 20.8843C67.9611 20.9491 67.9309 21.0339 67.9309 21.1286C67.9309 21.2881 68.0969 21.4377 68.4341 21.5773C68.5246 21.6122 68.59 21.6421 68.6353 21.662C68.9574 21.8066 69.1737 21.9611 69.2945 22.1256C69.4153 22.2901 69.4706 22.5144 69.4706 22.7936C69.4706 23.1376 69.3549 23.4217 69.1184 23.6461C68.8819 23.8704 68.59 23.985 68.2328 23.985C67.8755 23.985 67.5837 23.8654 67.3623 23.6311C67.1409 23.3968 67.0201 23.0728 67 22.669H67.795Z"
        fill={color}
      />
      <path
        d="M85.8248 11.0638H76.2898C76.3775 11.5645 76.6918 12.0551 77.2382 12.5257C77.934 13.1414 78.7431 13.4468 79.6709 13.4468C80.9851 13.4468 81.9283 12.9512 82.5056 11.9599H85.6031C85.2372 13.0613 84.5208 13.9975 83.4591 14.7635C82.3252 15.5845 81.0625 15.995 79.676 15.995C77.8618 15.995 76.3311 15.3942 75.0735 14.1977C73.8211 13.0012 73.1923 11.5494 73.1923 9.84731C73.1923 8.14518 73.7953 6.70338 75.0065 5.47685C76.2589 4.19524 77.8103 3.55444 79.6503 3.55444C81.4902 3.55444 83.0571 4.20025 84.294 5.49687C85.4279 6.70839 86 8.09512 86 9.65707C86 10.0876 85.9433 10.5582 85.8248 11.0588V11.0638ZM76.2022 8.82603H82.9952C82.8303 7.94994 82.418 7.26408 81.7583 6.77847C81.1655 6.33292 80.4749 6.10763 79.676 6.10763C78.9854 6.10763 78.3411 6.28786 77.7536 6.65332C76.9702 7.13892 76.4548 7.86483 76.2022 8.82603Z"
        fill={color}
      />
      <path
        d="M66.6621 16L62.2142 3.55945H65.0747L67.7342 11.8098H67.796L70.2957 3.55945H73.1923L68.7238 16H66.657H66.6621Z"
        fill={color}
      />
      <path d="M58.3126 16V3.55945H61.2194V16H58.3126ZM58.3126 2.59324V0H61.2194V2.59324H58.3126Z" fill={color} />
      <path
        d="M52.8235 16V6.22278H51.4062V3.55444H52.8235V0H55.7304V3.55444H57.4776V6.22278H55.7304V16H52.8235Z"
        fill={color}
      />
      <path
        d="M50.3238 3.55945V16H47.6592V14.1727C47.1901 14.7034 46.5562 15.1439 45.7573 15.4944C44.9585 15.8298 44.175 16 43.4174 16C41.7836 16 40.4075 15.4393 39.289 14.3179C38.1088 13.1264 37.5161 11.5945 37.5161 9.71715C37.5161 7.94994 38.0985 6.48811 39.2633 5.32666C40.4435 4.15019 41.8867 3.55945 43.5926 3.55945C44.6389 3.55945 45.613 3.8448 46.4995 4.42052C47.0974 4.82603 47.4685 5.16145 47.6128 5.43179H47.6592V3.55945H50.3238ZM47.5664 9.75719C47.5664 8.62077 47.1953 7.70964 46.4531 7.02378C45.7831 6.42303 44.9739 6.12265 44.0256 6.12265C42.902 6.12265 42.0052 6.52315 41.3352 7.31915C40.7218 8.03504 40.4178 8.87609 40.4178 9.8423C40.4178 10.8085 40.7579 11.6846 41.4434 12.3855C42.1289 13.0864 42.9896 13.4368 44.0256 13.4368C45.0615 13.4368 45.8913 13.0914 46.5614 12.4055C47.2314 11.7196 47.5664 10.8385 47.5664 9.75719Z"
        fill={color}
      />
      <path
        d="M32.022 16V3.55945H34.6608V5.03129H34.7072C35.0525 4.54068 35.3618 4.19524 35.6349 3.98999C36.0679 3.69962 36.6142 3.55444 37.2791 3.55444H37.5162V6.35294C35.7741 6.42303 34.9031 7.38423 34.9031 9.23654V16H32.0271H32.022Z"
        fill={color}
      />
      <path
        d="M25.6207 16V3.55945H28.2595V5.03129H28.3059C28.6512 4.54068 28.9605 4.19524 29.2336 3.98999C29.6666 3.69962 30.2129 3.55444 30.8778 3.55444H31.1149V6.35294C29.3728 6.42303 28.5018 7.38423 28.5018 9.23654V16H25.6258H25.6207Z"
        fill={color}
      />
      <path
        d="M24.7033 3.55945V16H22.0386V14.1727C21.5696 14.7034 20.9357 15.1439 20.1368 15.4944C19.3379 15.8298 18.5545 16 17.7969 16C16.163 16 14.7869 15.4393 13.6685 14.3179C12.4882 13.1264 11.8955 11.5945 11.8955 9.71715C11.8955 7.94994 12.4779 6.48811 13.6427 5.32666C14.823 4.15019 16.2661 3.55945 17.9721 3.55945C19.0184 3.55945 19.9925 3.8448 20.879 4.42052C21.4768 4.82603 21.8479 5.16145 21.9922 5.43179H22.0386V3.55945H24.7033ZM21.951 9.75719C21.951 8.62077 21.5799 7.70964 20.8377 7.02378C20.1677 6.42303 19.3585 6.12265 18.4102 6.12265C17.2866 6.12265 16.3898 6.52315 15.7198 7.31915C15.1065 8.03504 14.8024 8.87609 14.8024 9.8423C14.8024 10.8085 15.1425 11.6846 15.828 12.3855C16.5135 13.0864 17.3742 13.4368 18.4102 13.4368C19.4462 13.4368 20.2759 13.0914 20.946 12.4055C21.616 11.7196 21.951 10.8385 21.951 9.75719Z"
        fill={color}
      />
      <path
        d="M0.00515512 16V3.55945H2.74194V5.01126C3.78305 4.04506 4.91178 3.55945 6.12813 3.55945C6.73631 3.55945 7.35995 3.68961 7.99904 3.94994C8.75669 4.2403 9.37517 4.66583 9.84934 5.22653C10.607 6.12265 10.9832 7.54443 10.9832 9.49687V16H8.01966V10.0626C8.01966 7.48936 7.18986 6.20275 5.52511 6.20275C4.66439 6.20275 3.98921 6.5582 3.49958 7.26408C3.14395 7.76971 2.96356 8.77597 2.96356 10.2778V16H0H0.00515512Z"
        fill={color}
      />
    </>
  );
};
