import { createPluginFactory } from '@udecode/plate-common';
import { onInsertMenuKeyDownHandler } from './on-insert-menu-key-down-handler';

const KEY_INSERT_MENU = 'insert-menu';
export const createInsertMenuPlugin = createPluginFactory({
  key: KEY_INSERT_MENU,
  handlers: {
    onKeyDown: onInsertMenuKeyDownHandler,
  },
});
