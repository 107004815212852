import { Position } from './types';
import { EntityBase } from './entities/EntityBase';
import { CellularCacheItem, nodeToRect } from './utils';
import { default as RBush } from 'rbush';
import { DEBUG_CONFIG } from './debug-config';
import { logger } from '@xspecs/logger';

export class Cache {
  private quadTree: RBush<any>;
  private quadTreeItems: any = new Map();

  constructor() {
    this.quadTree = new RBush<any>();
  }

  clear() {
    this.quadTreeItems.clear();
    this.quadTree.clear();
  }

  updateEntity(entity: CellularCacheItem) {
    const quadTreeItem = this.quadTreeItems.get(entity.id);
    if (quadTreeItem) this.quadTree.remove(quadTreeItem);

    const entityRect = nodeToRect(entity);
    const item = {
      minX: entityRect.x,
      minY: entityRect.y,
      maxX: entityRect.x + entityRect.width,
      maxY: entityRect.y + entityRect.height,
      id: entity.id,
      type: entity.type,
    };
    this.quadTreeItems.set(entity.id, item);
    this.quadTree.insert(item);
  }

  public getIntersections(entity: EntityBase): Set<string> {
    const entityDimensions = {
      position: entity.position,
      width: entity.width,
      height: entity.height,
    };
    const criteria = this.toBBox({
      x: entityDimensions.position.x,
      y: entityDimensions.position.y,
      width: entityDimensions.width,
      height: entityDimensions.height,
    });
    const potentialIntersections: Set<string> = new Set(this.quadTree.search(criteria).map((v) => v.id));
    potentialIntersections.delete(entity.id);
    if (DEBUG_CONFIG.intersections) logger.log('GeoCache', potentialIntersections);
    return potentialIntersections;
  }

  public getIntersectionsByPosition(position: Position): Set<string> {
    return new Set(this.quadTree.search(this.toBBox(position)).map((v) => v.id));
  }

  getQuadTreeItems() {
    return this.quadTree.all();
  }

  private toBBox(item: Position & Partial<{ width: number; height: number }>) {
    const offsetX = item.width ? item.width / 2 : 0;
    const offsetY = item.height ? item.height / 2 : 0;
    return {
      minX: item.x + offsetX,
      minY: item.y + offsetY,
      maxX: item.x + offsetX,
      maxY: item.y + offsetY,
    };
  }

  remove(entityId: string) {
    const entry = this.quadTreeItems.get(entityId);
    this.quadTree.remove(entry);
    this.quadTreeItems.delete(entry);
  }
}
