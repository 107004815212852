import { CommandBase } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { GqlEntityBase } from '../../entities/gql-entities/GqlEntityBase';
import { Query } from '../../entities/assets/Query';
import { GqlField } from '../../entities/gql-entities/GqlField';
import { EntityParserFactory } from '../../entities/constructs/EntityParserFactory';
import { sid } from '@xspecs/short-id';
import { EntityType } from '../../entities/EntityType';
import { GqlOperation } from '../../entities/gql-entities/GqlOperation';

type UpdateGqlEntityParams = {
  entityId: string;
  width?: number;
  height?: number;
  y?: number;
  x?: number;
  selected?: boolean;
  name?: string;
};

type EntityResizedParams = {
  entityId: string;
} & Partial<UpdateGqlEntityParams>;

export class GqlEntityUpdatedCommand extends EventBase {
  static eventType = 'GqlEntityUpdatedCommand';

  constructor(public readonly params: EntityResizedParams, public readonly source = UpdateGqlEntityCommand) {
    super();
  }
}

export class UpdateGqlEntityCommand extends CommandBase<UpdateGqlEntityParams> {
  execute(params: UpdateGqlEntityParams): GqlEntityUpdatedCommand | CommandError {
    const entity = this.model.entityRepository.get(params.entityId);

    if (!(entity instanceof GqlEntityBase) && !(entity instanceof Query))
      return CommandError.of(new Error('Entity is not a GqlEntity'), 'error');

    if (entity instanceof Query) {
      this.handleQueryAnnotation(entity, params);
    }

    if (entity instanceof GqlEntityBase) {
      this.handleGqlEntity(entity, params);
    }

    return new GqlEntityUpdatedCommand({ entityId: entity.id, ...params });
  }

  private handleQueryAnnotation(entity: Query, params: UpdateGqlEntityParams) {
    entity.annotation = {
      ...entity.annotation,
      width: params.width ?? entity.annotation.width,
      height: params.height ?? entity.annotation.height,
      position: {
        x: params.x ?? entity.annotation.position.x,
        y: params.y ?? entity.annotation.position.y,
      },
    };

    this.model.entityRepository.update(entity);
  }

  private handleGqlEntity(entity: GqlEntityBase, params: UpdateGqlEntityParams) {
    entity.width = params.width ?? entity.width;
    entity.height = params.height ?? entity.height;
    entity.position.x = params.x ?? entity.position.x;
    entity.position.y = params.y ?? entity.position.y;
    entity.isSelected = params.selected ?? entity.isSelected;

    if (params.name && entity.name !== params.name) {
      if (params.name.includes('.')) {
        this.addOrUpdateGqlEntitiesBasedOnPath(params, entity);
      } else {
        entity.name = params.name ?? '';
      }
      entity.query.syncGqlEntitiesToQuery();
      //entity.query.syncQueryToGqlEntities(entity.query.queryText);
      this.model.entityRepository.update(entity.query);
    }

    this.model.entityRepository.update(entity);
  }

  private addOrUpdateGqlEntitiesBasedOnPath(params: UpdateGqlEntityParams, entity: GqlEntityBase) {
    const pathSegments = params.name.split('.');
    entity.name = pathSegments[pathSegments.length - 1];

    let parent: GqlEntityBase | Query = entity.query.gqlEntities.find(
      (e) => e instanceof GqlOperation,
    ) as GqlEntityBase;

    const updatedEntities: GqlEntityBase[] = [];
    let currentPath = '';

    for (let i = 0; i < pathSegments.length - 1; i++) {
      const segment = pathSegments[i];
      currentPath = currentPath ? `${currentPath}.${segment}` : segment;
      let gqlField = entity.query.gqlEntities.find((e) => e.name === segment && e.parent === parent) as GqlEntityBase;
      if (!gqlField) {
        gqlField = EntityParserFactory.parse<GqlField>({
          id: sid(),
          name: segment,
          type: EntityType.GqlField,
          position: parent.position,
          scopes: this.model.entityRepository.getScopes(),
        });
        gqlField.parent = parent;
        this.addGqlEntityAtCorrectIndex(entity.query, gqlField, updatedEntities);
        this.model.entityRepository.add(gqlField);
      }

      updatedEntities.push(gqlField);
      parent = gqlField;
    }

    entity.parent = parent;
    updatedEntities.push(entity);

    this.reorderGqlEntities(entity.query, updatedEntities);
  }

  private addGqlEntityAtCorrectIndex(query: Query, gqlEntity: GqlEntityBase, orderedEntities: GqlEntityBase[]) {
    const index = orderedEntities.length;
    query.gqlEntities.splice(index, 0, gqlEntity);
  }

  private reorderGqlEntities(query: Query, updatedEntities: GqlEntityBase[]) {
    const originalEntities = [...query.gqlEntities];

    const operations = originalEntities.filter((e) => e instanceof GqlOperation);

    // Iterate through updated entities and move them in the original entity list based on parent-child relationships
    updatedEntities.forEach((updatedEntity) => {
      const parentIndex = originalEntities.findIndex((e) => e === updatedEntity.parent);
      if (parentIndex !== -1) {
        let insertIndex = parentIndex + 1;
        // go through siblings to find the correct position for insertion
        while (insertIndex < originalEntities.length && originalEntities[insertIndex].parent === updatedEntity.parent) {
          insertIndex++;
        }
        // remove the entity if it already exists in the list to reinsert it at the correct position
        const existingIndex = originalEntities.indexOf(updatedEntity);
        if (existingIndex !== -1) {
          originalEntities.splice(existingIndex, 1);
        }

        // add the updated entity straight right after the siblings of its parent
        originalEntities.splice(insertIndex, 0, updatedEntity);
      }
    });

    const nonOperations = originalEntities.filter((e) => !(e instanceof GqlOperation));

    // operation always comes first followed by the rest of the entities
    query.gqlEntities = operations.concat(nonOperations);
  }
}
