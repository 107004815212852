import { EntityChangeset, SerializedEntityChangeset } from '../types';
import { ChangeDetector } from './ChangeDetector';
import { EntityBase } from '../entities/EntityBase';
import { EntityType } from '../entities/EntityType';

export class ChangeTracker {
  private changes: EntityChangeset = {
    added: [],
    updated: [],
    deleted: [],
  };

  constructor(private readonly changeDetector: ChangeDetector) {}

  /**
   * Tracks an added entity.
   * @param entity The entity that was added.
   */
  trackAdded(entity: EntityBase) {
    // Ensure the entity isn't already tracked as added
    if (!this.changes.added.some((addedEntity) => addedEntity.id === entity.id)) {
      this.changes.added.push(entity);
      this.changeDetector.setInitialState(entity);
    }
  }

  /**
   * Tracks an updated entity.
   * If the entity is already tracked as added, it will not be tracked as updated.
   * Also, ensures it's not marked as deleted before adding to updated.
   * @param entity The entity that was updated.
   */
  trackUpdated(entity: EntityBase) {
    if (!this.changeDetector.updateState(entity)) {
      return;
    }
    const isDeleted = this.changes.deleted.some((deletedEntity) => deletedEntity.id === entity.id);

    // Ensure the entity is not already in the added list
    const isAdded = this.changes.added.some((addedEntity) => addedEntity.id === entity.id);

    // Check if the entity is already marked as updated
    const updateIndex = this.changes.updated.findIndex((updatedEntity) => updatedEntity.entity.id === entity.id);

    if (!isDeleted && !isAdded) {
      const updatedProps = this.changeDetector.getModifiedProperties(entity.id);
      if (updateIndex !== -1) {
        // Update the existing record in the updated list
        this.changes.updated[updateIndex] = { entity, modifiedProperties: updatedProps };
      } else {
        // Add a new record to the updated list
        this.changes.updated.push({ entity, modifiedProperties: updatedProps });
      }
    }
  }

  /**
   * Tracks a deleted entity.
   * @param entityId The ID of the entity that was deleted.
   * @param type The type of the entity that was deleted.
   */
  trackDeleted(entityId: string, type: EntityType) {
    // Ensure the entity isn't already tracked as deleted
    if (this.changes.deleted.some((deletedEntity) => deletedEntity.id === entityId)) {
      return;
    }
    this.changes.deleted.push({ id: entityId, type: type });
    this.changeDetector.removeFromInitialState(entityId);

    // Remove the entity from updated if it exists there
    this.changes.updated = this.changes.updated.filter((updated) => updated.entity.id !== entityId);

    // Remove the entity from added if it exists there, since deleting it supersedes the add
    this.changes.added = this.changes.added.filter((entity) => entity.id !== entityId);
  }

  /**
   * Clears all tracked changes.
   */
  clearChanges() {
    this.changes = {
      added: [],
      updated: [],
      deleted: [],
    };
    //this.changeDetector.clear();
  }

  /**
   * Gets all tracked changes.
   * @returns An object containing arrays of added, updated, and deleted entities.
   */
  getChanges(): EntityChangeset {
    return {
      added: this.changes.added,
      updated: this.changes.updated.map((entry) => ({
        entity: entry.entity,
        modifiedProperties: entry.modifiedProperties,
      })),
      deleted: this.changes.deleted,
    };
  }

  /**
   * Gets all tracked changes as a flat list of serialized entities.
   * @returns An array containing the ids of added, updated, and deleted entities.
   */
  getSerializedChanges(): SerializedEntityChangeset {
    const changes = {
      added: this.changes.added.map((entity) => entity.serialize()),
      updated: this.changes.updated.map((entry) => entry.entity.serialize()),
      deleted: this.changes.deleted,
    };
    return JSON.parse(JSON.stringify(changes));
  }

  hasChanges() {
    return this.changes.added.length > 0 || this.changes.updated.length > 0 || this.changes.deleted.length > 0;
  }
}
