import { Chip, Stack, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { EntityBase } from '@xspecs/single-source-model';
import { Icon } from '../../../icons/icon';

type SelectedFilterByPropertyProps = {
  entity: EntityBase;
  onDelete?: () => void;
};

export const SelectedCriteria = (props: SelectedFilterByPropertyProps) => {
  const { entity, onDelete } = props;

  const { formatMessage: f } = useIntl();

  return (
    <Tooltip title={entity.name}>
      <Chip
        sx={{ borderRadius: '2px', px: 1, '.MuiChip-label': { p: 0 } }}
        label={
          <Stack direction="row" gap={1}>
            <Typography variant="subtitle2" color="text.primary">
              {f({ id: entity.type }) + ':'}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary" sx={{ maxWidth: 126, ...overflowSx }}>
              {entity.name}
            </Typography>
          </Stack>
        }
        onClick={onDelete}
        onDelete={onDelete}
        deleteIcon={onDelete ? <Icon name="close" color="action" /> : null}
      />
    </Tooltip>
  );
};

const overflowSx: SxProps<Theme> = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};
