import { EntityType } from '@xspecs/single-source-model';
import { NodeTypes } from '@xyflow/react';
import { ConstructNode } from './construct-node/construct-node';
import { ScriptNode } from '../nodes/script-node/script-node';

const nodes: NodeTypes = {
  [EntityType.Capability]: ConstructNode,
  [EntityType.Moment]: ConstructNode,
  [EntityType.Narrative]: ConstructNode,
  [EntityType.Event]: ConstructNode,
  [EntityType.Command]: ConstructNode,
  [EntityType.Process]: ConstructNode,
  [EntityType.Data]: ConstructNode,
  [EntityType.Projection]: ConstructNode,
  [EntityType.ReadModel]: ConstructNode,
  [EntityType.Constraints]: ConstructNode,
  [EntityType.Interface]: ConstructNode,
  [EntityType.Action]: ConstructNode,
  [EntityType.Gateway]: ConstructNode,
  [EntityType.ExternalSystem]: ConstructNode,
  [EntityType.Resolver]: ConstructNode,
  [EntityType.NarrativeScript]: ScriptNode,
  [EntityType.ActionScript]: ScriptNode,
};

export const constructs = nodes;
