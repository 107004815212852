import React from 'react';
import { PlateElement, PlateElementProps } from '@udecode/plate-common';
import { cva } from 'class-variance-authority';

const listVariants = cva('m-0 ps-6', {
  variants: {
    variant: {
      ul: 'list-disc [&_ul]:list-[circle] [&_ul_ul]:list-[square]',
      ol: 'list-decimal',
    },
  },
});

export function ListElement({
  className,
  children,
  variant = 'ul',
  ...props
}: PlateElementProps & { variant?: 'ul' | 'ol' }) {
  const Element = variant!;

  return (
    <PlateElement asChild {...props}>
      <Element
        className={
          variant === 'ul'
            ? 'list-disc ml-[20px] [&_ul]:list-[circle] [&_ul_ul]:list-[square]'
            : 'ml-[20px] list-decimal'
        }
      >
        {children}
      </Element>
    </PlateElement>
  );
}
