import { CommandBase, IParams } from '../framework/CommandBase';
import { ApplyFiltersCommand } from './ApplyFiltersCommand';
import { EventBase } from '../framework/EventBase';
import { Filter, FilterType } from '../../entities/Filter';
import { CommandError } from '../../ErrorStore';

interface RemoveFilterCriteriaParams extends IParams {
  id: string;
  type: FilterType;
  criteriaIds: string[];
}

export class FilterCriteriaRemovedEvent extends EventBase {
  static eventType = 'FilterCriteriaRemovedEvent';
  constructor(public params: RemoveFilterCriteriaParams, public source = RemoveFilterCriteriaCommand) {
    super();
  }
}

export class RemoveFilterCriteriaCommand extends CommandBase<RemoveFilterCriteriaParams> {
  execute(params: RemoveFilterCriteriaParams): FilterCriteriaRemovedEvent | CommandError {
    const filter =
      params.type === FilterType.unsaved
        ? (this.model.store.getState().unsavedFilter as Filter)
        : this.model.entityRepository.get<Filter>(params.id);
    if (!filter) return;
    if (!filter.criteria) return;
    const criteriaEntities = params.criteriaIds.map((id) => this.model.entities.get(id));
    const updatedCriteria = filter.removeCriteria(criteriaEntities.map((criteriaEntity) => criteriaEntity.id));
    if (params.type != FilterType.unsaved) {
      this.model.entityRepository.update(filter);
    }
    const updatedFilter = new Filter(
      filter.id,
      filter.name,
      filter.filterType,
      updatedCriteria,
      filter.scopes,
      filter.createdBy,
    );
    this.model.messageBus.send(ApplyFiltersCommand, {
      filter: updatedFilter,
    });
    return new FilterCriteriaRemovedEvent(params);
  }
}
