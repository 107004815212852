import { EntityBase } from '../EntityBase';
import { Z_INDEXES } from '../../ZIndexes';
import { z } from 'zod';
import { Query } from '../assets/Query';
import { EntityType } from '../EntityType';

export abstract class GqlEntityBase extends EntityBase {
  static baseSchema = EntityBase.abstractBaseSchema.extend({
    zIndex: z.number().default(Z_INDEXES.Annotation),
    width: z.number().default(200),
    height: z.number().default(46),
    position: z
      .object({
        x: z.number().default(0),
        y: z.number().default(0),
      })
      .default({ x: 0, y: 0 }),
  });

  public get path(): string {
    if (this.type === EntityType.GqlOperation) {
      return this.name;
    }

    const buildPath = (entity: GqlEntityBase | undefined, segments: string[] = []): string[] => {
      if (!entity || !(entity.type === EntityType.GqlField)) return segments;
      if (entity.name) segments.unshift(entity.name);
      return buildPath(entity.parent as GqlEntityBase, segments);
    };
    const segments = buildPath(this);
    return segments.join('.');
  }

  protected static parseBase<T extends EntityBase>(
    this: new (...args: any[]) => T,
    data: object,
    schema: z.ZodSchema<any> = GqlEntityBase.baseSchema,
  ): T {
    return super.parseBase(data, schema) as T;
  }

  get query(): Query {
    if (this.parent instanceof Query) return this.parent;
    return (this.parent as GqlEntityBase | null)?.query ?? null;
  }
}
