import { Button, Stack, TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import { ChangeEvent, useCallback, useState } from 'react';

export type SaveFilterButtonProps = {
  onSave: (params: { name: string }) => void;
};

export const SaveFilterButton = (props: SaveFilterButtonProps) => {
  const { onSave: onSaveProp } = props;

  const { formatMessage: f } = useIntl();

  const [isSaveMode, setIsSaveMode] = useState(false);
  const [name, setName] = useState('');

  const onSaveFilter = useCallback(() => {
    setIsSaveMode(true);
  }, []);

  const onCancel = useCallback(() => {
    setIsSaveMode(false);
  }, []);

  const onSave = useCallback(() => {
    onSaveProp({ name });
    setIsSaveMode(false);
    setName('');
  }, [name, onSaveProp]);

  const onNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, []);

  if (!isSaveMode) {
    return (
      <Button data-testid="SaveFilterButton" onClick={onSaveFilter}>
        {f({ id: 'save-filter' })}
      </Button>
    );
  }

  return (
    <Stack direction="row" alignItems="center">
      <TextField
        autoFocus
        size="small"
        placeholder={f({ id: 'name-your-filter' })}
        value={name}
        onChange={onNameChange}
      />
      <Stack direction="row" gap={1.5}>
        <Button onClick={onSave} disabled={!name}>
          {f({ id: 'save' })}
        </Button>
        <Button onClick={onCancel}>{f({ id: 'cancel' })}</Button>
      </Stack>
    </Stack>
  );
};
