import { Link, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { PlansList } from './list/plans-list';
import { Fragment, useMemo } from 'react';
import { Loading } from '../../loading/loading';
import { usePlans } from '../../../hooks/use-plans';
import { useActiveOrganization } from '../../../hooks/use-active-organization';

export const Plans = () => {
  const { formatMessage: f } = useIntl();

  const organization = useActiveOrganization();

  const { plans, currentPlan, loading } = usePlans();

  const description = useMemo(() => {
    return f(
      { id: 'organization-plan-description' },
      {
        plan: currentPlan?.name,
        name: organization?.name,
        link: (
          <Link sx={{ cursor: 'pointer' }} href={'mailto:support@xspecs.ai'}>
            {f({ id: 'here' })}
          </Link>
        ),
      },
    );
  }, [currentPlan?.name, f, organization?.name]);

  return (
    <Stack id="plans" gap={3} width="100%">
      <Typography variant="h4" sx={{ mb: 1.5 }}>
        {f({ id: 'plan' })}
      </Typography>
      {loading ? (
        <Loading rootProps={{ width: '100%', height: '100%' }} circularProgressProps={{ size: 22 }} />
      ) : (
        <Fragment>
          <Typography variant="body1" color="text.secondary">
            {description}
          </Typography>
          <PlansList plans={plans} />
        </Fragment>
      )}
    </Stack>
  );
};
