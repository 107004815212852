import { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';
import { Application } from '@xspecs/single-source-model';

export type ApplicationContextProps = {
  application: Application | null;
};

const ApplicationContext = createContext<ApplicationContextProps>({
  application: null,
});

export const ApplicationProvider: FC<PropsWithChildren<ApplicationContextProps>> = ({ application, children }) => {
  const value = useMemo<ApplicationContextProps>(
    () => ({
      application,
    }),
    [application],
  );

  return <ApplicationContext.Provider value={value}>{children}</ApplicationContext.Provider>;
};

export const useApplication = () => useContext<ApplicationContextProps>(ApplicationContext);
