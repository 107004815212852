import { useFocused, useSelected } from 'slate-react';
import { LinkDoc } from './link-doc/link-doc';

export const LinkDocElement = (props: any) => {
  const { attributes, children, nodeProps, element } = props;

  const isFocused = useFocused();
  const isSelected = useSelected();

  return (
    <span
      {...attributes}
      data-slate-value={element.value}
      contentEditable={false}
      {...props}
      {...nodeProps}
      style={{ display: 'inline-flex', alignItems: 'center' }}
    >
      <LinkDoc
        documentId={element.documentId}
        name={element.name}
        url={element.url}
        isFocused={isFocused}
        isSelected={isSelected}
      />
      {children}
    </span>
  );
};
