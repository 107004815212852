import { Box, SxProps, Tab, Tabs, Theme, Typography } from '@mui/material';
import { SyntheticEvent, useCallback, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { showOrganizationSettingsVar } from '../../state/state';
import { useIntl } from 'react-intl';
import { OrganizationSettingsModalGeneral } from './general/organization-settings-modal-general';
import { OrganizationSettingsModalAdministration } from './administration/organization-settings-modal-administration';
import { OrganizationSettingsModalUsers } from './users/organization-settings-modal-users';
import { Modal } from '../modal/modal';
import { useActiveOrganization } from '../../hooks/use-active-organization';

enum NavItem {
  General = 'general',
  Users = 'users',
  Administration = 'administration',
}

const overflowSx: SxProps<Theme> = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };

export const OrganizationSettingsModal = () => {
  const [activeNavItem, setActiveNavItem] = useState<NavItem>(NavItem.General);

  const open = useReactiveVar(showOrganizationSettingsVar);
  const organization = useActiveOrganization();

  const { formatMessage: f } = useIntl();

  const onClose = useCallback(() => {
    showOrganizationSettingsVar(false);
    setActiveNavItem(NavItem.General);
  }, []);

  const onTabChange = useCallback((event: SyntheticEvent, newValue: NavItem) => {
    setActiveNavItem(newValue);
  }, []);

  return (
    <Modal
      id="OrganizationSettingsModal"
      isOpen={open}
      onClose={onClose}
      rootSxOverrides={{ width: { xs: '100%', md: 415 }, height: { xs: '100%', md: 'auto' }, minHeight: { md: 500 } }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
        <Box>
          <Typography sx={overflowSx} variant="h5" textAlign="center" width={390}>
            {f({ id: 'entity-settings' }, { name: organization?.name })}
          </Typography>
          <Box my={1}>
            <Tabs value={activeNavItem} onChange={onTabChange} textColor="primary" indicatorColor="primary" centered>
              <Tab value={NavItem.General} label={f({ id: 'general' })} />
              <Tab value={NavItem.Users} label={f({ id: 'users' })} />
              <Tab value={NavItem.Administration} label={f({ id: 'administration' })} />
            </Tabs>
          </Box>
          {activeNavItem === NavItem.General ? <OrganizationSettingsModalGeneral /> : null}
          {activeNavItem === NavItem.Administration ? (
            <OrganizationSettingsModalAdministration onClose={onClose} />
          ) : null}
          {activeNavItem === NavItem.Users ? <OrganizationSettingsModalUsers onClose={onClose} /> : null}
        </Box>
      </Box>
    </Modal>
  );
};
