import { Attributes, EntityBase } from '../EntityBase';
import { AttachmentType, EntityType } from '../EntityType';
import { AssetBase } from './AssetBase';
import { EntityParserFactory } from '../constructs/EntityParserFactory';
import { attachmentBaseSchema } from './AttachmentBaseSchema';
import { Z_INDEXES } from '../../ZIndexes';
import { Preview } from './Preview';
import { Upload } from './Upload';

export class Attachment extends EntityBase {
  constructor(
    public id: string,
    public name: string,
    public subType: AttachmentType,
    public parent?: EntityBase,
    public position: { x: number; y: number } = { x: 0, y: 0 },
    public scopes: string[] = [],
    public attributes: Attributes = { fontSize: -1 },
    public width = 140,
    public height = 36,
    public isVisible = true,
    public zIndex = Z_INDEXES.Attachment,
  ) {
    super(id, name, parent, position, scopes, attributes, width, height, isVisible, zIndex);
    this.subType = subType;
  }

  // static version = '1.0.1'; // Rename Tags to Labels;
  static version = '1.0.2'; // Added upload preview to upload attachment subtypes;

  public asset: AssetBase;

  public preview: Preview;

  //public gqlEntities: GqlEntityBase[] = [];

  static references = ['asset', 'preview'];

  get type(): EntityType {
    return EntityType.Attachment;
  }

  static parse(data: unknown): Attachment {
    const validatedData = attachmentBaseSchema.parse(data);
    const attachment = new Attachment(
      validatedData.id,
      validatedData.name,
      validatedData.subType,
      validatedData.parent,
      {
        x: validatedData.position?.x ?? 0,
        y: validatedData.position?.y ?? 0,
      },
      validatedData.scopes,
      validatedData.attributes,
      validatedData.width,
      validatedData.height,
      validatedData.isVisible,
      validatedData.zIndex,
    );
    if (validatedData.asset) {
      attachment.linkAsset(EntityParserFactory.parse(validatedData.asset, validatedData.subType) as AssetBase);
    }
    if (validatedData.preview) {
      const parsedPreviewData = EntityParserFactory.parse<Preview>(validatedData.preview, EntityType.Preview);
      attachment.linkPreview(parsedPreviewData);
    }
    return attachment;
  }

  isValid(): boolean {
    return attachmentBaseSchema.safeParse(this).success;
  }

  public linkAsset(asset: AssetBase): void | Error {
    // if (!(asset instanceof AssetBase)) return new Error('Attachment can only ingest assets');
    this.asset = asset;
    asset.parent = this;
  }

  public linkPreview(preview: Preview): Error | void {
    if (!(this.asset instanceof Upload)) return new Error('Only Upload assets can have previews');
    this.preview = preview;
    preview.parent = this;
  }

  serialize(reference: boolean = false): unknown {
    if (reference) return super.serialize(reference);
    return {
      ...(super.serialize() as any),
      asset: this?.asset?.serialize(true),
      subType: this.subType,
      preview: this.preview?.serialize(true),
    };
  }

  public recalculateDimensions() {
    this.width = this.parent ? this.parent.width : attachmentBaseSchema.shape.width._def.defaultValue();
  }
}
