import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useKeySymbols } from '../../hooks/useKeySymbols';
import { ShortcutSectionProps } from './shortcut-section/shortcut-section';

export const useKeyboardShortcuts = () => {
  const { modKey } = useKeySymbols();
  const { formatMessage: f } = useIntl();

  const shortcuts: ShortcutSectionProps[] = useMemo(
    () => [
      {
        name: f({ id: 'shortcut-section-general' }),
        shortcuts: [
          {
            command: `${modKey} + C`,
            name: f({ id: 'shortcut-copy' }),
          },
          {
            command: `${modKey} + V`,
            name: f({ id: 'shortcut-paste' }),
          },
          {
            command: `${modKey} + X`,
            name: f({ id: 'shortcut-cut' }),
          },
          {
            command: `${modKey} + D`,
            name: f({ id: 'shortcut-duplicate' }),
          },
          {
            command: `${modKey} + click`,
            name: f({ id: 'shortcut-manual-select' }),
          },
          {
            command: `${modKey} + A`,
            name: f({ id: 'shortcut-select-all' }),
          },
          {
            command: `${modKey} + Shift + A`,
            name: f({ id: 'shortcut-deselect-all' }),
          },
          {
            command: `esc`,
            name: f({ id: 'shortcut-esc' }),
          },
          {
            command: `backspace`,
            name: f({ id: 'shortcut-backspace' }),
          },
          {
            command: `${modKey} + Z`,
            name: f({ id: 'shortcut-undo' }),
          },
          {
            command: `${modKey} + Shift + Z`,
            name: f({ id: 'shortcut-redo' }),
          },
        ],
      },
      {
        name: 'Navigation',
        shortcuts: [
          {
            command: `${modKey} +`,
            name: f({ id: 'shortcut-zoom-in' }),
          },
          {
            command: `${modKey} -`,
            name: f({ id: 'shortcut-zoom-out' }),
          },
          {
            command: `${modKey} + 0`,
            name: f({ id: 'shortcut-zoom-reset' }),
          },
        ],
      },
    ],
    [f, modKey],
  );

  return {
    shortcuts,
  } as const;
};
