import { List, ListSubheader, Typography } from '@mui/material';
import { EntityType } from '@xspecs/single-source-model';
import { FC } from 'react';
import { PaletteItem } from '../item/palette-item';

type PaletteConstructListProps = {
  items: EntityType[];
  title: string;
};

export const PaletteConstructList: FC<PaletteConstructListProps> = ({ items, title }) => {
  return (
    <List
      sx={{ p: 0 }}
      subheader={
        <ListSubheader>
          <Typography variant="overline">{title} </Typography>
        </ListSubheader>
      }
    >
      {items.map((item) => (
        <PaletteItem key={item} type={item} />
      ))}
    </List>
  );
};
