import { Avatars } from '../../../avatars/avatars';
import { useSingleSourceStore } from '../../../../store/single-source-store/single-source-store';
import { useActiveOrganization } from '../../../../hooks/use-active-organization';

export const SingleSourceModelToolbarAvatars = () => {
  const organization = useActiveOrganization();
  const activeUsers = useSingleSourceStore.use.activeUsersByFile()[organization.singleSourceModel.id] ?? [];

  return <Avatars users={activeUsers} />;
};
