import { InvitePeople } from '../../pages/invite-people/invite-people';
import { Box } from '@mui/material';

export const InvitePeopleRoute = () => {
  return (
    <Box data-testid="invite-people-route" height="100%" width="100%">
      <InvitePeople />
    </Box>
  );
};
