import { AssetBase } from './AssetBase';
import { EntityType } from '../EntityType';

export class Spec extends AssetBase {
  static parse(data: unknown): Spec {
    return AssetBase.parseBase.call(Spec, data);
  }

  get type(): EntityType {
    return EntityType.Spec;
  }
}
