import { ChooseOrganization } from '../../pages/choose-organization/choose-organization';
import { useQuery } from '@apollo/client';
import { ORGANIZATIONS_QUERY } from '../../graphql/queries';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from '../../config/route-paths/route-paths';

export const ChooseOrganizationRoute = () => {
  const { data, loading } = useQuery(ORGANIZATIONS_QUERY, { fetchPolicy: 'network-only' });

  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (data?.organizations.length === 0) navigate(RoutePaths.CreateOrganization);
  }, [data?.organizations.length, loading, navigate]);

  return (
    <Box data-testid="choose-organization-route">
      <ChooseOrganization items={data?.organizations || []} loading={loading} />
    </Box>
  );
};
