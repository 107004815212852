import { PlateElement, PlateElementProps } from '@udecode/plate-common';
import { Typography } from '@mui/material';
import { useMemo } from 'react';

type HeadingVariants = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export const HeadingElement = ({
  className,
  variant = 'h1',
  isFirstBlock,
  children,
  ...props
}: PlateElementProps & { variant: HeadingVariants; isFirstBlock: boolean }) => {
  const { element, editor } = props;

  const typographyVariant = useMemo(() => {
    switch (variant) {
      case 'h1':
      case 'h4':
        return 'h4';
      case 'h2':
      case 'h5':
        return 'h5';
      case 'h3':
      case 'h6':
        return 'h6';
    }
  }, [variant]);

  return (
    <PlateElement asChild {...props}>
      <Typography variant={typographyVariant}>{children}</Typography>
    </PlateElement>
  );
};
