import { BalloonMenuMarkButton } from '../balloon-menu-mark-button';
import { getPluginType, isMarkActive, toggleMark, useEditorState } from '@udecode/plate-common';
import { ToggleButtonGroup } from '@mui/material';
import { useCallback, useState } from 'react';
import { FormatBold, FormatItalic, FormatUnderlined, StrikethroughS } from '@mui/icons-material';
import { MARK_BOLD, MARK_ITALIC, MARK_STRIKETHROUGH, MARK_UNDERLINE } from '@udecode/plate-basic-marks';

export const BalloonMenuMarks = () => {
  const [activeMarks, setActiveMarks] = useState<string[]>([]);

  const editor = useEditorState();

  const updateActiveMarks = useCallback(
    (key: string) => {
      if (isMarkActive(editor, key)) {
        setActiveMarks((prev) => [...prev, key]);
      } else {
        setActiveMarks((prev) => prev.filter((mark) => mark !== key));
      }
    },
    [editor],
  );

  const handleToggleMark = useCallback(
    (key: string) => (event) => {
      event.preventDefault();
      toggleMark(editor, { key });
      updateActiveMarks(key);
    },
    [editor, updateActiveMarks],
  );

  const onToggleMark = useCallback(
    (key: string) => {
      if (editor) {
        return handleToggleMark(key);
      }
    },
    [editor, handleToggleMark],
  );

  return (
    <ToggleButtonGroup data-testid="balloon-menu-marks" value={activeMarks}>
      <BalloonMenuMarkButton
        data-testid="balloon-menu-bold"
        onMouseDown={onToggleMark(getPluginType(editor, MARK_BOLD))}
        Icon={FormatBold}
        value={MARK_BOLD}
      />
      <BalloonMenuMarkButton
        data-testid="balloon-menu-italic"
        onMouseDown={onToggleMark(getPluginType(editor, MARK_ITALIC))}
        Icon={FormatItalic}
        value={MARK_ITALIC}
      />
      <BalloonMenuMarkButton
        data-testid="balloon-menu-underline"
        onMouseDown={onToggleMark(getPluginType(editor, MARK_UNDERLINE))}
        Icon={FormatUnderlined}
        value={MARK_UNDERLINE}
      />
      <BalloonMenuMarkButton
        data-testid="balloon-menu-strikethrough"
        onMouseDown={onToggleMark(getPluginType(editor, MARK_STRIKETHROUGH))}
        Icon={StrikethroughS}
        value={MARK_STRIKETHROUGH}
      />
    </ToggleButtonGroup>
  );
};
