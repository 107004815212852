import { EntityIcon } from '../../../entity-icon/entity-icon';
import { EntityType, RenameEntityCommand } from '@xspecs/single-source-model';
import { Box, InputBase, Stack, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { ChangeEvent, useCallback } from 'react';
import { useSingleSourceModel } from '../../../../hooks/use-single-source-model';

type EntityHeaderProps = {
  id: string;
  name: string;
  type: EntityType;
};

export const EntityHeader = (props: EntityHeaderProps) => {
  const { name, type, id } = props;

  const theme = useTheme();

  const { formatMessage: f } = useIntl();

  const model = useSingleSourceModel();

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      model.messageBus.send(RenameEntityCommand, { entityId: id, newName: event.target.value });
    },
    [id, model.messageBus],
  );

  return (
    <Stack direction="row" gap={1} alignItems="center" sx={{ width: '100%' }}>
      <Box minWidth={36} width={36}>
        <EntityIcon type={type} width={36} height={36} />
      </Box>
      <InputBase
        placeholder={f({ id: 'untitled' })}
        title={name}
        value={name}
        onChange={onChange}
        sx={{ ...theme.typography.h4, width: '100%' }}
        inputProps={{
          sx: {
            width: '100%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        }}
      />
    </Stack>
  );
};
