import { Box, List, ListItem, ListItemButton, ListItemText, ListSubheader, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { Filter, FilterType } from '@xspecs/single-source-model';
import { ReactNode, useCallback } from 'react';
import { Public, Lock, DesignServices } from '@mui/icons-material';

export type FiltersListProps = {
  savedFilters: Filter[];
  onSavedFilterSelect: (filter: Filter) => void;
};

export const FiltersList = (props: FiltersListProps) => {
  const { savedFilters, onSavedFilterSelect } = props;

  const { formatMessage: f } = useIntl();

  const iconProps = { fontSize: 'small', color: 'action' } as const;

  const map: Record<FilterType, { icon: ReactNode; label: string }> = {
    [FilterType.unsaved]: { icon: <DesignServices {...iconProps} />, label: f({ id: 'unsaved' }) },
    [FilterType.userSaved]: { icon: <Lock {...iconProps} />, label: f({ id: 'user-saved' }) },
    [FilterType.publicSaved]: { icon: <Public {...iconProps} />, label: f({ id: 'public-saved' }) },
  };

  const onItemClick = useCallback(
    (filter: Filter) => () => {
      onSavedFilterSelect(filter);
    },
    [onSavedFilterSelect],
  );

  return (
    <Box sx={{ maxHeight: 300, overflowY: 'scroll' }}>
      <Typography variant="overline" sx={{ px: 2 }}>
        {f({ id: 'saved-filters' })}
      </Typography>
      <List>
        {savedFilters.map((filter) => (
          <ListItem key={filter.id} sx={{ py: 0 }}>
            <ListItemButton
              sx={{ py: 0, px: 1, display: 'flex', justifyContent: 'space-between', gap: 1 }}
              onClick={onItemClick(filter)}
            >
              <ListItemText
                title={filter.name}
                primary={filter.name}
                primaryTypographyProps={{ sx: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' } }}
              />
              <Stack direction="row" alignItems="center" gap={0.5}>
                {map[filter.filterType].icon}
                <Typography
                  title={map[filter.filterType].label}
                  variant="tooltip"
                  width="fit-content"
                  sx={{ textWrap: 'nowrap' }}
                >
                  {map[filter.filterType].label}
                </Typography>
              </Stack>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

type _FiltersListProps = {
  type: 'global' | 'local';
  filters: Filter[];
};

const _FiltersList = (props: _FiltersListProps) => {
  const { type, filters } = props;

  const { formatMessage: f } = useIntl();

  return (
    <List subheader={<ListSubheader sx={{ lineHeight: '24px' }}>{f({ id: `${type}-filters` })}</ListSubheader>}>
      {filters.map((filter) => (
        <ListItem key={filter.id} sx={{ py: 0 }}>
          <ListItemButton sx={{ p: 0 }}>
            <ListItemText primary={filter.name} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};
