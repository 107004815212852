import { CommandBase } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

type ResizeEntityParams = {
  entityId: string;
  width: number;
  height: number;
  y: number;
  x: number;
};

type EntityResizedParams = {
  entityId: string;
  width: number;
  height: number;
};

export class EntityResizedEvent extends EventBase {
  static eventType = 'EntityAddedEvent';

  constructor(public readonly params: EntityResizedParams, public readonly source = ResizeEntityCommand) {
    super();
  }
}

export class ResizeEntityCommand extends CommandBase<ResizeEntityParams> {
  execute(params: ResizeEntityParams): EntityResizedEvent | CommandError {
    const entity = this.model.entityRepository.get(params.entityId);
    entity.width = params.width;
    entity.height = params.height;
    entity.position.x = params.x;
    entity.position.y = params.y;
    this.model.entityRepository.update(entity);
    return new EntityResizedEvent({
      entityId: entity.id,
      width: entity.width,
      height: entity.height,
    });
  }
}
