import { IStore } from '../../data/Store';
import { EntityRepository } from '../../data/EntityRepository';
import { EntityChangeset } from '../../types';
import { AssetBase } from '../../entities/assets/AssetBase';
import { AttachmentType, isValidAttachmentType } from '../../entities/EntityType';

export type AssetsState = Record<AttachmentType, AssetBase[]>;

export class Assets {
  constructor(public readonly entityRepository: EntityRepository, public readonly store: IStore) {}

  assetsCache: Record<AttachmentType, Record<string, AssetBase>> = {
    [AttachmentType.Upload]: {},
    [AttachmentType.Actor]: {},
    [AttachmentType.Doc]: {},
    [AttachmentType.Query]: {},
    [AttachmentType.Schema]: {},
    [AttachmentType.Spec]: {},
  };

  update(changes: EntityChangeset = { added: [], updated: [], deleted: [] }) {
    this.handleDeletions(changes);
    this.handleAdditions(changes);
    this.handleUpdates(changes);
  }

  private handleUpdates(changes: EntityChangeset) {
    changes.updated.forEach((update) => {
      this.updateStore(update.entity.id);
    });
  }

  private handleAdditions(changes: EntityChangeset) {
    changes.added.forEach((entity) => {
      this.updateStore(entity.id);
    });
  }

  private handleDeletions(changes: EntityChangeset) {
    changes.deleted.forEach((entity) => {
      Object.entries(this.assetsCache).forEach(([, value]) => {
        if (value[entity.id]) {
          delete value[entity.id];
        }
      });
      this._updateStore();
    });
  }

  private updateStore(entityId: string) {
    const entity = this.entityRepository.get(entityId);
    if (!isValidAttachmentType(entity.type)) return;
    const type = entity.type as AttachmentType;
    this.assetsCache[type][entityId] = entity as AssetBase;
    this._updateStore();
  }

  private _updateStore() {
    const state = Object.entries(this.assetsCache).reduce((acc, [key, value]) => {
      acc[key] = Object.values(value);
      return acc;
    }, {} as AssetsState);
    this.store.getState().setAssets(state satisfies AssetsState);
  }

  public clear() {
    this.store.getState().setAssets({
      [AttachmentType.Upload]: [],
      [AttachmentType.Actor]: [],
      [AttachmentType.Doc]: [],
      [AttachmentType.Query]: [],
      [AttachmentType.Schema]: [],
      [AttachmentType.Spec]: [],
    } satisfies AssetsState);
  }
}
