import { Box, Button, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useCallback, useState } from 'react';
import { OrganizationSettingsModalGeneralJoiningOrganization } from './organization-settings-modal-general-joining-organization/organization-settings-modal-general-joining-organization';
import { OrganizationSettingsModalGeneralName } from './organization-settings-modal-general-name/organization-settings-modal-general-name';
import { OrganizationSettingsModalContentSharing } from './organization-settings-modal-general-content-sharing/organization-settings-modal-general-content-sharing';

enum View {
  Name = 'Name',
  JoiningOrganization = 'JoiningOrganization',
}

export const OrganizationSettingsModalGeneral = () => {
  const [view, setView] = useState<View | null>(null);

  const { formatMessage: f } = useIntl();

  const onExpandName = useCallback(() => setView(View.Name), []);

  const onExpandJoiningOrganization = useCallback(() => setView(View.JoiningOrganization), []);

  const onCloseSubMenu = useCallback(() => setView(null), []);

  if (view === View.Name) {
    return <OrganizationSettingsModalGeneralName onClose={onCloseSubMenu} />;
  }

  if (view === View.JoiningOrganization) {
    return <OrganizationSettingsModalGeneralJoiningOrganization onClose={onCloseSubMenu} />;
  }

  return (
    <Stack data-testid="OrganizationSettingsModalGeneralRoot" px={1} width="100%" spacing={2}>
      <Box>
        <Typography variant="h6">{f({ id: 'organization-name' })}</Typography>
        <Typography variant="body1">{f({ id: 'change-name-of-this-organization' })}</Typography>
        <Button
          data-testid="OrganizationSettingsModalGeneralNameExpandButton"
          size="small"
          variant="outlined"
          sx={{ mt: 1 }}
          onClick={onExpandName}
        >
          {f({ id: 'expand' })}
        </Button>
      </Box>
      <Box>
        <Typography variant="h6">{f({ id: 'joining-this-organization' })}</Typography>
        <Typography variant="body1">{f({ id: 'choose-how-people-join-organization' })}</Typography>
        <Button size="small" variant="outlined" sx={{ mt: 1 }} onClick={onExpandJoiningOrganization}>
          {f({ id: 'expand' })}
        </Button>
      </Box>
      <Box>
        <Typography variant="h6">{f({ id: 'sharing-content' })}</Typography>
        <Typography variant="body1">{f({ id: 'who-can-see-details-of-links' })}</Typography>
        <OrganizationSettingsModalContentSharing />
      </Box>
    </Stack>
  );
};
