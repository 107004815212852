import {
  LabelsDetailsButton,
  LabelsDetailsButtonProps,
} from '../../../../../labels/more-labels-button/labels-details-button';
import { useLabels } from './use-labels';
import { Label } from '@xspecs/single-source-model';

type FloatingMenuLabelsDetailsButtonProps = {
  entityId: string;
  selectedLabels: Label[];
  onManageLabels?: () => void;
} & Partial<Pick<LabelsDetailsButtonProps, 'type'>>;

export const FloatingMenuLabelsDetailsButton = (props: FloatingMenuLabelsDetailsButtonProps) => {
  const { entityId, selectedLabels, onManageLabels: onManageLabelsProp, type = 'details' } = props;

  const {
    labels,
    createAndSelectLabel,
    onSelectedLabelsChange,
    onManageLabels: onManageLabelsHook,
  } = useLabels(entityId);

  const onManageLabels = () => {
    onManageLabelsHook();
    onManageLabelsProp?.();
  };

  return (
    <LabelsDetailsButton
      type={type}
      selectorProps={{ labels, selectedLabels, onSelectedLabelsChange, createAndSelectLabel, onManageLabels }}
    />
  );
};
