import { createPlugins, PlatePlugin, RenderAfterEditable } from '@udecode/plate-common';
import { FileType, getCreateYjsPlugin } from '../../../plate-yjs/create-yjs-plugin';
import { components } from './plugins/components';
import { LinkFloatingToolbar } from '../../../plate-components/link-floating-toolbar';
import { overrides } from './plugins/auto-format-plugin-overrides/autoFormatPluginOverrides';
import { linkedDocumentPlugin } from './plugins/linked-document-plugin/linked-document-plugin';
import { createInsertMenuPlugin } from './plugins/insert-menu-plugin/create-insert-menu-plugin';
import { createNodeIdPlugin } from './plugins/node-id-plugin/create-node-id-plugin';
import { createExitBreakPlugin } from './plugins/exit-break-plugin/create-exit-break-plugin';
import { createCloudAttachmentPlugin, createCloudImagePlugin, createCloudPlugin } from '@udecode/plate-cloud';
import { mentionPlugin } from '../../plate-plugins/mention-plugin/mention-plugin';
import { createTriggerEventOnMentionPlugin } from '../../plate-plugins/mention-plugin/create-trigger-event-on-mention-plugin/create-trigger-event-on-mention-plugin';
import { createParagraphPlugin, ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import { createHeadingPlugin, ELEMENT_H1, ELEMENT_H2, ELEMENT_H3, KEYS_HEADING } from '@udecode/plate-heading';
import {
  createBoldPlugin,
  createItalicPlugin,
  createStrikethroughPlugin,
  createUnderlinePlugin,
} from '@udecode/plate-basic-marks';
import { createLinkPlugin } from '@udecode/plate-link';
import { createImagePlugin, createMediaEmbedPlugin } from '@udecode/plate-media';
import { createListPlugin } from '@udecode/plate-list';
import { createComboboxPlugin } from '@udecode/plate-combobox';
import { createAlignPlugin } from '@udecode/plate-alignment';
import { createAutoformatPlugin } from '@udecode/plate-autoformat';

export const alignValidTypes = [ELEMENT_PARAGRAPH, ELEMENT_H1, ELEMENT_H2, ELEMENT_H3];

export const cloudImagePluginOptions = {
  maxInitialWidth: 320,
  maxInitialHeight: 320,
  minResizeWidth: 100,
  maxResizeWidth: 720,
};

export const staticPlugins: PlatePlugin[] = [
  createBoldPlugin(),
  createItalicPlugin(),
  createStrikethroughPlugin(),
  createUnderlinePlugin(),

  createParagraphPlugin(),
  createHeadingPlugin(),

  createLinkPlugin({ renderAfterEditable: LinkFloatingToolbar as RenderAfterEditable }),

  createImagePlugin(),
  createListPlugin(),

  createMediaEmbedPlugin(),
  createComboboxPlugin(),
  linkedDocumentPlugin,
  createAlignPlugin({ inject: { props: { validTypes: alignValidTypes } } }),
  mentionPlugin,
];

export const getDocEditorPlugins = (mediaAuthToken: string, userToken: string): PlatePlugin[] =>
  createPlugins(
    [
      ...staticPlugins,
      createTriggerEventOnMentionPlugin(),
      createAutoformatPlugin(overrides),
      createNodeIdPlugin(),
      // createCommentsPlugin(),
      createInsertMenuPlugin(),
      createExitBreakPlugin({
        options: {
          rules: [
            { hotkey: 'enter', query: { start: true, end: true, allow: KEYS_HEADING }, relative: true, level: 1 },
          ],
        },
      }),
      createCloudPlugin({ options: { authToken: mediaAuthToken } }) as any,
      createCloudAttachmentPlugin(),
      createCloudImagePlugin({ options: cloudImagePluginOptions }),
      getCreateYjsPlugin(FileType.DOC, userToken)(),
    ],
    {
      components,
    },
  );
