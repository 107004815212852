import { IStore } from '../../data/Store';
import { EntityRepository } from '../../data/EntityRepository';
import { DeletedEntity, EntityChangeset } from '../../types';
import { TransientStore } from '../../data/Transient';
import { Edge as ReactFlowEdge, Node as ReactFlowNode } from '@xyflow/react';
import { Upload } from '../../entities/assets/Upload';
import { Query } from '../../entities/assets/Query';
import { EntityType } from '../../entities/EntityType';
import { GqlField } from '../../entities/gql-entities/GqlField';

export type AnnotatorState = {
  entityId: string;
  nodes: ReactFlowNode[];
  edges: ReactFlowEdge[];
  hasUpload: boolean;
  queryText: string;
  showAnnotatorView: boolean;
};

export class Annotator {
  constructor(public readonly entityRepository: EntityRepository, public readonly store: IStore) {}

  nodesCache: Record<string, ReactFlowNode> = {};
  edgesCache: Record<string, ReactFlowEdge> = {};

  private get entityId() {
    return TransientStore.provider.get('entityDetailView') ?? null;
  }

  private set entityId(value: string | null) {
    TransientStore.provider.set('entityDetailView', value);
  }

  update(changes: EntityChangeset = { added: [], updated: [], deleted: [] }) {
    this.handleAdditions(changes);
    this.handleUpdates(changes);
    this.handleDeletions(changes);
  }

  openAnnotator() {
    this.store.getState().setAnnotator({
      ...this.store.getState().annotator,
      showAnnotatorView: true,
    } satisfies AnnotatorState);
  }

  closeAnnotator() {
    this.store.getState().setAnnotator({
      ...this.store.getState().annotator,
      showAnnotatorView: false,
    } satisfies AnnotatorState);
  }

  private handleUpdates(changes: EntityChangeset) {
    changes.updated.forEach((update) => {
      this.updateStore(update.entity.id);
    });
  }

  private handleAdditions(changes: EntityChangeset) {
    changes.added.forEach((entity) => {
      this.updateStore(entity.id);
    });
  }

  private handleDeletions(changes: EntityChangeset) {
    changes.deleted.forEach((entity) => {
      this.deleteFromStore(entity);
      if (entity.id === this.entityId) {
        this.entityId = null;
        this.clear();
      }
    });
  }

  private updateStore(entityId: string) {
    const entity = this.entityRepository.get(entityId);
    if (!(entity instanceof Query) && !(entity instanceof GqlField)) {
      return;
    }

    if (entity instanceof GqlField && !entity.isLeaf) {
      return;
    }

    const query = (entity instanceof Query ? entity : entity.query) as Query;
    const nodes = query ? this.getNodes(query) : [];

    this.store.getState().setAnnotator({
      ...this.store.getState().annotator,
      entityId: query?.id,
      nodes,
      queryText: query?.queryText,
      hasUpload: nodes.length > 0,
      edges: Object.values(this.edgesCache),
    } satisfies AnnotatorState);
  }

  private deleteFromStore(deletedEntity: DeletedEntity) {
    if (!(deletedEntity.type === EntityType.Query || deletedEntity.type === EntityType.GqlField)) {
      return;
    }
    const currentState = this.store.getState();
    if (!currentState.annotator) return;
    const queryId = currentState.annotator.entityId;
    currentState.setAnnotator({
      ...currentState.annotator,
      entityId: queryId,
      nodes: [...currentState.annotator.nodes.filter((node) => node.id !== deletedEntity.id)],
      queryText: currentState.annotator.queryText,
      hasUpload: currentState.annotator.hasUpload,
      edges: currentState.annotator.edges,
    } satisfies AnnotatorState);
  }

  private getNodes(query: Query): ReactFlowNode[] {
    const upload = query.upload as Upload;

    const nodes: ReactFlowNode[] = [];

    if (query.annotation && query.upload) {
      nodes.push({
        id: query.id,
        type: 'image',
        data: { src: upload.url, alt: upload.name },
        measured: {
          width: query.annotation.width,
          height: query.annotation.height,
        },
        width: query.annotation.width,
        height: query.annotation.height,
        position: {
          x: query.annotation.position.x,
          y: query.annotation.position.y,
        },
      });
    }

    query.gqlEntities
      .filter((e) => e instanceof GqlField && e.isLeaf)
      .forEach((gqlEntity) => {
        nodes.push({
          id: gqlEntity.id,
          type: gqlEntity.type,
          data: {
            name: gqlEntity.name,
            path: gqlEntity.path,
          },
          width: gqlEntity.width,
          height: gqlEntity.height,
          initialHeight: gqlEntity.height,
          initialWidth: gqlEntity.width,
          selected: gqlEntity.isSelected,
          measured: {
            width: gqlEntity.width,
            height: gqlEntity.height,
          },
          style: {
            width: gqlEntity.width,
            height: gqlEntity.height,
          },
          position: {
            x: gqlEntity.position.x,
            y: gqlEntity.position.y,
          },
        });
      });
    return nodes;
  }

  public clear() {
    this.entityId = null;
    this.store.getState().setAnnotator(null);
  }
}
