import { MouseEventHandler, ReactNode, useMemo } from 'react';
import { Box, MenuItem, Typography } from '@mui/material';

type InsertMenuItemProps = {
  Icon: ReactNode;
  title: string;
  description: string;
  focused: boolean;
  disabled?: boolean;
  onItemClick?: MouseEventHandler;
};

export const InsertMenuItem = (props: InsertMenuItemProps) => {
  const { Icon, title, description, focused, disabled, onItemClick } = props;

  const rootSx = useMemo(
    () => ({
      display: 'flex',
      gap: '9px',
      px: 1.25,
      py: 0.5,
      borderRadius: 0.5,
      backgroundColor: disabled ? '' : focused ? 'action.selected' : 'transparent',
    }),
    [disabled, focused],
  );

  return (
    <MenuItem
      data-testid={title}
      disableTouchRipple
      disableRipple
      sx={rootSx}
      disabled={disabled}
      onMouseDown={onItemClick}
    >
      {Icon}
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Typography variant="body1" color="text.primary">
          {title}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {description}
        </Typography>
      </Box>
    </MenuItem>
  );
};
