import { Button, IconButton, IconButtonProps, Stack } from '@mui/material';
import { CloseRounded, DeleteRounded, EditRounded } from '@mui/icons-material';
import { Fragment, useCallback } from 'react';
import { useIntl } from 'react-intl';

type LabelsListItemActionsProps = {
  className?: string;
  isBeingEdited: boolean;
  isConfirmingDelete: boolean;
  onEdit: () => void;
  onClose: () => void;
  onDelete: () => void;
  onCancelDelete: () => void;
  onConfirmDelete: () => void;
  onSave: () => void;
  isSaveDisabled?: boolean;
};

export const LabelsListItemActions = (props: LabelsListItemActionsProps) => {
  const {
    className,
    isBeingEdited,
    isConfirmingDelete,
    onEdit: onEditProp,
    onClose: onCloseProp,
    onDelete: onDeleteProp,
    onSave: onSaveProp,
    onCancelDelete,
    onConfirmDelete,
    isSaveDisabled,
  } = props;

  const { formatMessage: f } = useIntl();

  const onEdit = useCallback(
    (e) => {
      e.stopPropagation();
      onEditProp();
    },
    [onEditProp],
  );

  const onDelete = useCallback(
    (e) => {
      e.stopPropagation();
      onDeleteProp();
    },
    [onDeleteProp],
  );

  const onClose = useCallback(
    (e) => {
      e.stopPropagation();
      onCloseProp();
    },
    [onCloseProp],
  );

  const onSave = useCallback(
    (e) => {
      e.stopPropagation();
      onSaveProp();
    },
    [onSaveProp],
  );

  return (
    <Stack data-testid="LabelsListItemActions" className={className} direction="row" gap={1} alignItems="center">
      {isBeingEdited ? (
        <Fragment>
          <Button size="small" onClick={onSave} disabled={isSaveDisabled}>
            {f({ id: 'save' })}
          </Button>
          <IconButton onClick={onClose} {...iconButtonProps}>
            <CloseRounded {...iconProps} />
          </IconButton>
        </Fragment>
      ) : isConfirmingDelete ? (
        <Fragment>
          <Button size="small" color="error" onClick={onConfirmDelete}>
            {f({ id: 'yes-delete' })}
          </Button>
          <IconButton onClick={onCancelDelete} {...iconButtonProps}>
            <CloseRounded {...iconProps} />
          </IconButton>
        </Fragment>
      ) : (
        <Fragment>
          <IconButton data-testid="LabelsListItemActionsEditButton" onClick={onEdit} {...iconButtonProps}>
            <EditRounded {...iconProps} />
          </IconButton>
          <IconButton data-testid="LabelsListItemActionsDeleteButton" onClick={onDelete} {...iconButtonProps}>
            <DeleteRounded {...iconProps} />
          </IconButton>
        </Fragment>
      )}
    </Stack>
  );
};

const iconButtonProps: IconButtonProps = {
  size: 'small',
  sx: {
    p: 0.25,
  },
} as const;

const iconProps = {
  color: 'action',
  fontSize: 'small',
} as const;
