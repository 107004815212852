import { IconButton, Stack, Typography } from '@mui/material';
import { CloseRounded, LabelOutlined } from '@mui/icons-material';
import { useIntl } from 'react-intl';

type ManageLabelsModalHeaderProps = {
  onClose: () => void;
};

export const ManageLabelsModalHeader = (props: ManageLabelsModalHeaderProps) => {
  const { onClose } = props;

  const { formatMessage: f } = useIntl();

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
        <Stack direction="row" gap={1} alignItems="center">
          <LabelOutlined fontSize="medium" />
          <Typography variant="h6">{f({ id: 'manage-labels' })}</Typography>
        </Stack>
        <IconButton size="small" sx={{ p: 0 }} onClick={onClose}>
          <CloseRounded fontSize="small" />
        </IconButton>
      </Stack>
      <Typography variant="caption">{f({ id: 'manage-labels-description' })}</Typography>
    </Stack>
  );
};
