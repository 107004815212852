import { FC } from 'react';
import { IconComponentProps } from '../icon';

export const Subscript: FC<IconComponentProps> = () => (
  <>
    <rect width="20" height="20" fill="#D8D8D8" />
    <rect x="1" y="1" width="18" height="5" fill="#F8F8F8" />
    <rect x="1" y="7" width="18" height="6" fill="#F8F8F8" />
    <rect x="1" y="14" width="18" height="5" fill="#F8F8F8" />
    <path
      d="M3.1948 10.9937L5.85611 13.7868C5.91748 13.8535 5.9909 13.9067 6.07207 13.9433C6.15325 13.9799 6.24055 13.9992 6.32889 14C6.41723 14.0008 6.50484 13.9831 6.58661 13.948C6.66838 13.9129 6.74266 13.861 6.80513 13.7955C6.8676 13.7299 6.917 13.6519 6.95046 13.5661C6.98391 13.4803 7.00074 13.3884 6.99998 13.2956C6.99921 13.2029 6.98085 13.1113 6.94598 13.0261C6.91112 12.9409 6.86043 12.8639 6.79688 12.7994L4.60596 10.5L6.79688 8.20055C6.86043 8.13614 6.91112 8.05908 6.94598 7.97389C6.98085 7.8887 6.99921 7.79707 6.99998 7.70435C7.00074 7.61163 6.98391 7.51968 6.95046 7.43387C6.917 7.34805 6.8676 7.27009 6.80513 7.20452C6.74266 7.13896 6.66838 7.08711 6.58661 7.052C6.50484 7.01689 6.41723 6.99922 6.32889 7.00003C6.24055 7.00083 6.15325 7.0201 6.07207 7.05669C5.9909 7.09329 5.91748 7.14648 5.85611 7.21318L3.1948 10.0063C3.07007 10.1373 3 10.3148 3 10.5C3 10.6852 3.07007 10.8627 3.1948 10.9937Z"
      fill="black"
      fillOpacity="0.54"
    />
    <path
      d="M14.1439 7.21318C14.0825 7.14648 14.0091 7.09329 13.9279 7.05669C13.8468 7.0201 13.7595 7.00083 13.6711 7.00003C13.5828 6.99922 13.4952 7.01689 13.4134 7.052C13.3316 7.08711 13.2573 7.13896 13.1949 7.20452C13.1324 7.27009 13.083 7.34805 13.0495 7.43387C13.0161 7.51968 12.9993 7.61163 13 7.70435C13.0008 7.79707 13.0191 7.8887 13.054 7.97389C13.0889 8.05908 13.1396 8.13614 13.2031 8.20055L15.394 10.5L13.2031 12.7994C13.1396 12.8639 13.0889 12.9409 13.054 13.0261C13.0191 13.1113 13.0008 13.2029 13 13.2956C12.9993 13.3884 13.0161 13.4803 13.0495 13.5661C13.083 13.6519 13.1324 13.7299 13.1949 13.7955C13.2573 13.861 13.3316 13.9129 13.4134 13.948C13.4952 13.9831 13.5828 14.0008 13.6711 14C13.7595 13.9992 13.8468 13.9799 13.9279 13.9433C14.0091 13.9067 14.0825 13.8535 14.1439 13.7868L16.8052 10.9937C16.9299 10.8627 17 10.6852 17 10.5C17 10.3148 16.9299 10.1373 16.8052 10.0063L14.1439 7.21318Z"
      fill="black"
      fillOpacity="0.54"
    />
    <path
      d="M10.688 5.52271L8.02561 15.1261C7.97714 15.3009 7.99801 15.4884 8.08363 15.6474C8.16924 15.8064 8.3126 15.9238 8.4822 15.9739C8.54183 15.9909 8.60338 15.9997 8.66524 16C8.80988 15.9999 8.95055 15.9512 9.06596 15.8614C9.18137 15.7715 9.26523 15.6454 9.30486 15.502L11.9672 5.89862C11.995 5.81092 12.0053 5.71835 11.9975 5.62646C11.9897 5.53456 11.9641 5.44522 11.922 5.36378C11.88 5.28235 11.8224 5.21048 11.7528 5.1525C11.6832 5.09451 11.603 5.05158 11.5169 5.02629C11.4308 5.001 11.3407 4.99386 11.2519 5.00529C11.1631 5.01673 11.0774 5.04651 11 5.09286C10.9226 5.1392 10.8551 5.20116 10.8015 5.27501C10.7479 5.34887 10.7093 5.43312 10.688 5.52271Z"
      fill="black"
      fillOpacity="0.54"
    />
  </>
);
