import {
  autoPlacement,
  autoUpdate,
  FloatingPortal,
  offset,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { Box } from '@mui/material';
import { Mode } from '@xspecs/single-source-model';
import { useCallback } from 'react';
import { useSingleSourceStore } from '../../../../store/single-source-store/single-source-store';
import { Palette } from '../../palette/palette';
import { SingleSourceModelToolbarButton } from '../button';
import { MoreVert } from '@mui/icons-material';
import css from './single-source-model-toolbar-constructs-button.module.css';
import { useIntl } from 'react-intl';

export const SingleSourceModelToolbarConstructsButton = () => {
  const { formatMessage: f } = useIntl();

  const setDefaultMode = useSingleSourceStore.use.setSelectionMode();
  const setInsertConstructMode = useSingleSourceStore.use.setInsertConstructMode();
  const setConstructToInsert = useSingleSourceStore.use.setConstructToInsert();
  const mode = useSingleSourceStore.use.mode();
  const constructToInsert = useSingleSourceStore.use.constructToInsert();

  const isActive = mode === Mode.INSERT_CONSTRUCT;
  const showFloating = isActive && !constructToInsert;

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
    open: showFloating,
    onOpenChange: () => setDefaultMode(),
    middleware: [
      offset(10),
      autoPlacement({
        allowedPlacements: ['bottom-start', 'bottom-end', 'bottom'],
      }),
    ],
  });

  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  const onClick = useCallback(() => {
    if (constructToInsert) {
      setConstructToInsert(null);
    } else {
      if (mode === Mode.INSERT_CONSTRUCT) {
        setDefaultMode();
      } else {
        setInsertConstructMode();
      }
    }
  }, [constructToInsert, setConstructToInsert, setInsertConstructMode, mode, setDefaultMode]);

  return (
    <>
      <SingleSourceModelToolbarButton
        data-testid="single-source-model-toolbar-button"
        Icon={<MoreVert />}
        isActive={isActive}
        onClick={onClick}
        ref={refs.setReference}
        {...getReferenceProps()}
        tooltip={f({ id: 'more' })}
      />
      {showFloating ? (
        <FloatingPortal>
          <Box
            ref={refs.setFloating}
            sx={{ ...floatingStyles, zIndex: 'calc(infinity)' }}
            className={css.floating}
            {...getFloatingProps()}
          >
            <Palette />
          </Box>
        </FloatingPortal>
      ) : null}
    </>
  );
};
