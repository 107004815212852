import { useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { Modal } from '../modal/modal';
import { useKeyboardShortcuts } from './shortcuts';
import { useIntl } from 'react-intl';
import { ShortcutSection } from './shortcut-section/shortcut-section';
import { useSingleSourceStore } from '../../store/single-source-store/single-source-store';

export const KeyboardShortcutsModal = () => {
  const open = useSingleSourceStore.use.showKeyboardShortcutsModal();
  const closeModal = useSingleSourceStore.use.setShowKeyboardShortcutModal();
  const { shortcuts } = useKeyboardShortcuts();
  const { formatMessage: f } = useIntl();

  const onClose = useCallback(() => {
    closeModal(false);
  }, [closeModal]);

  return (
    <Modal id="KeyboardShortcutsModal" isOpen={open} onClose={onClose} rootSxOverrides={{ width: '500px' }}>
      <Box px={5}>
        <Typography variant="h5" textAlign="center">
          {f({ id: 'shortcuts' })}
        </Typography>
        {shortcuts.map((section) => (
          <ShortcutSection key={section.name} {...section} />
        ))}
      </Box>
    </Modal>
  );
};
