import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { Label } from '../../entities/assets/Label';
import { CommandError } from '../../ErrorStore';

interface RemoveEntitiesFromLabelCommandParams extends IParams {
  labelId: string;
  entityIds: string[];
}

export class EntitiesRemovedFromLabelEvent extends EventBase {
  static eventType = 'EntitiesRemovedFromLabelEvent';

  constructor(
    public readonly params: RemoveEntitiesFromLabelCommandParams,
    public readonly source = RemoveEntitiesFromLabelCommand,
  ) {
    super();
  }
}

export class RemoveEntitiesFromLabelCommand extends CommandBase<RemoveEntitiesFromLabelCommandParams> {
  execute(params: RemoveEntitiesFromLabelCommandParams): EventBase | CommandError {
    const label = this.model.entityRepository.get<Label>(params.labelId);
    const entities = params.entityIds.map((id) => this.model.entityRepository.get<Label>(id));
    label.removeEntities(entities);
    for (const entity of entities) {
      this.model.entityRepository.update(entity);
    }
    this.model.entityRepository.update(label);
    return new EntitiesRemovedFromLabelEvent(params);
  }
}
