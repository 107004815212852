import { IconButton, Typography } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { Menu, MenuItem } from '@szhsin/react-menu';
import { useIntl } from 'react-intl';

const iconSx = { fontSize: 18 };

const menuItemStyle = {
  padding: '4px 8px',
};

export type CommentItemMoreMenuProps = {
  onEdit?: () => void;
  onDelete?: () => void;
};

export const CommentItemMoreMenu = (props: CommentItemMoreMenuProps) => {
  const { onEdit, onDelete } = props;

  const { formatMessage: f } = useIntl();

  return (
    <Menu
      menuButton={
        <IconButton size="small">
          <MoreVert sx={iconSx} />
        </IconButton>
      }
      align="end"
      position="anchor"
      portal
      transition
    >
      <MenuItem style={menuItemStyle} onClick={onEdit} disabled={!onEdit}>
        <Typography variant="caption" fontSize="16px">
          {f({ id: 'edit' })}
        </Typography>
      </MenuItem>
      <MenuItem style={menuItemStyle} onClick={onDelete} disabled={!onDelete}>
        <Typography variant="caption" fontSize="16px">
          {f({ id: 'delete' })}
        </Typography>
      </MenuItem>
    </Menu>
  );
};
