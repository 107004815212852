import { ReactNode, useCallback, useEffect } from 'react';
import { basicLogger, LDProvider, useLDClient } from 'launchdarkly-react-client-sdk';
import { useAuth } from '../../auth';
import { isDev } from '../../utils';

type FeatureFlagProviderProps = {
  children: ReactNode;
};

// @ts-ignore
const clientSideID = import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID;

const FeatureFlagProviderInner = (props: FeatureFlagProviderProps) => {
  const { children } = props;

  const { user } = useAuth();

  const client = useLDClient();

  const identifyUser = useCallback(async () => {
    void (await client.identify({
      kind: 'user',
      key: user.email,
      firstName: user.given_name,
      lastName: user.family_name,
      name: user.name,
      email: user.email,
      country: user.zoneinfo,
      avatar: user.picture,
    }));
    client.on('error', () => {
      /*do nothing*/
    });
    // eslint-disable-next-line no-console
    // console.log('LDProvider initialized with: ', result);
  }, [client, user]);

  useEffect(() => {
    if (!client || !user) return;
    void identifyUser();
  }, [client, identifyUser, user]);

  return <>{children}</>;
};

export const FeatureFlagProvider = (props: FeatureFlagProviderProps) => {
  const { children } = props;

  return isDev ? (
    children
  ) : (
    <LDProvider
      clientSideID={clientSideID}
      options={{
        streaming: false,
        logger: basicLogger({ level: 'error' }),
      }}
    >
      <FeatureFlagProviderInner>{children}</FeatureFlagProviderInner>
    </LDProvider>
  );
};
