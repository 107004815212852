import { Button, MenuItem, Stack, SxProps, Theme, Typography } from '@mui/material';
import { VersionsQuery } from '../../gql/graphql';
import { formatDistance } from 'date-fns';
import { useIntl } from 'react-intl';
import { MouseEventHandler, useCallback } from 'react';

type OrganizationVersionsListProps = {
  onVersionSelect: (versionId: string) => void;
  onVersionPreview: (versionId: string) => void;
  versions: VersionsQuery['versions'];
};

export const OrganizationVersionsList = (props: OrganizationVersionsListProps) => {
  const { onVersionSelect: onVersionSelectProp, onVersionPreview: onVersionPreviewProp, versions } = props;

  const { formatMessage: f, formatDate } = useIntl();

  const onVersionSelect = useCallback(
    (versionId: string): MouseEventHandler<HTMLButtonElement> =>
      (e) => {
        e.stopPropagation();
        onVersionSelectProp(versionId);
      },
    [onVersionSelectProp],
  );

  const onVersionPreview = useCallback(
    (versionId: string): MouseEventHandler<HTMLButtonElement> =>
      (e) => {
        e.stopPropagation();
        onVersionPreviewProp(versionId);
      },
    [onVersionPreviewProp],
  );

  return (
    <Stack sx={rootSx}>
      {versions.map((version) => (
        <MenuItem key={version.versionId} disableRipple>
          <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between" sx={itemSx}>
            <Stack>
              <Typography variant="caption" color="text.primary">
                {f({ id: 'time-ago' }, { time: formatDistance(version.createdAt, Date.now()) })}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {formatDate(new Date(version.createdAt), {
                  dateStyle: 'full',
                  timeStyle: 'short',
                })}
              </Typography>
            </Stack>
            <Stack direction="row" className="OrganizationVersionsListActions" gap={1}>
              <Button size="small" variant="outlined" onClick={onVersionPreview(version.versionId)}>
                {f({ id: 'preview' })}
              </Button>
              <Button size="small" variant="outlined" onClick={onVersionSelect(version.versionId)}>
                {f({ id: 'restore' })}
              </Button>
            </Stack>
          </Stack>
        </MenuItem>
      ))}
    </Stack>
  );
};

const rootSx: SxProps<Theme> = {
  width: '100%',
  height: '100%',
  overflowY: 'scroll',
};

const itemSx: SxProps<Theme> = {
  '.OrganizationVersionsListActions': {
    opacity: 0,
    pointerEvents: 'none',
  },
  ':hover': {
    '.OrganizationVersionsListActions': {
      opacity: 1,
      pointerEvents: 'all',
    },
  },
};
