import { EntityType } from '../EntityType';
import { EntityBase } from '../EntityBase';
import { z } from 'zod';

export class Label extends EntityBase {
  constructor(
    public readonly id: string,
    public name: string,
    public color: string,
    public readonly createdBy: string,
    public readonly scopes: string[] = [],
  ) {
    super(id, '', undefined, { x: 0, y: 0 }, scopes);
    this.createdAt = new Date();
  }

  static schema = EntityBase.abstractBaseSchema.extend({
    type: z.nativeEnum(EntityType).describe(`The type of the entity`),
    parent: z.lazy(() => EntityBase.abstractBaseSchema).optional(),
    color: z.string(),
    createdBy: z.string(),
    entities: z.array(z.lazy(() => EntityBase.abstractBaseSchema)).optional(),
  });

  readonly createdAt: Date;

  entities: EntityBase[] = [];

  get type(): EntityType {
    return EntityType.Label;
  }

  static references = ['entities'];
  //static version = '1.0.1'; // Renames Tag to Label
  // static version = '1.0.2'; // Removes not found references
  static version = '1.0.3'; // Removes not found references

  addEntities(entities: EntityBase[]): void {
    for (const entity of entities) {
      entity.labels.push(this);
      this.entities.push(entity);
    }
  }

  removeEntities(entities: EntityBase[]): void {
    for (const entity of entities) {
      entity.labels = entity.labels.filter((label) => label.id !== this.id) ?? [];
      this.entities = this.entities.filter((label) => label.id !== entity.id) ?? [];
    }
  }

  static parse(data: unknown): Label {
    const validatedData = Label.schema.parse(data);
    const label = new Label(
      validatedData.id,
      validatedData.name,
      validatedData.color,
      validatedData.createdBy,
      validatedData.scopes,
    );
    label.entities = validatedData.entities as EntityBase[];
    return label;
  }

  isValid(): boolean {
    return Label.schema.safeParse(this).success;
  }

  serialize(reference: boolean = false): unknown {
    if (reference) return super.serialize(reference);
    return {
      ...(super.serialize() as any),
      id: this.id,
      color: this.color,
      createdBy: this.createdBy,
      createdAt: this.createdAt,
      entities: this.entities.map((entity) => entity.serialize(true)),
    };
  }
}
