import React, { useState } from 'react';
import { Box } from '@mui/material';
import { safePolygon, useFloating, useHover, useInteractions } from '@floating-ui/react';
import { AddFrameButton } from '../../../../frames/add-frame-button/add-frame-button';

export const AddLane = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom',
  });
  const hover = useHover(context, {
    handleClose: safePolygon(),
    delay: { close: 100 },
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  return (
    <>
      <Box
        {...getReferenceProps()}
        ref={refs.setReference}
        sx={{
          position: 'relative',
          height: '20px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: '100%',
            height: 'fit-content',
            borderTop: '3px dashed transparent',
            transition: 'border-color 300ms ease-in-out',
            ...(isOpen && { borderColor: 'green' }),
          }}
        />
      </Box>
      {isOpen && (
        <Box
          ref={refs.setFloating}
          sx={{
            ...floatingStyles,
            marginTop: '-10px',
            zIndex: 99999,
          }}
          {...getFloatingProps()}
        >
          <AddFrameButton />
        </Box>
      )}
    </>
  );
};
