import { IconComponentProps } from '../icon';
import { FC } from 'react';

export const RemoveFrame: FC<IconComponentProps> = ({ color }) => {
  return (
    <>
      <path
        d="M14 18H10C9.5 18 9 17.8 8.6 17.4C8.2 17 8 16.5 8 16V2C8 1.5 8.2 1 8.6 0.6C9 0.2 9.5 0 10 0H14C14.5 0 15 0.2 15.4 0.6C15.8 1 16 1.5 16 2V16C16 16.5 15.8 17 15.4 17.4C15 17.8 14.5 18 14 18ZM10 2V16H14V2H10Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.70713 11.1213C4.1314 11.5456 4.69708 11.5456 5.12135 11.1213C5.54561 10.6971 5.54561 10.1314 5.12135 9.70711L4.41424 9L5.12135 8.29289C5.54561 7.86863 5.54561 7.30294 5.12135 6.87868C4.69708 6.45442 4.1314 6.45442 3.70713 6.87868L3.00003 7.58579L2.29292 6.87868C1.86866 6.45442 1.30297 6.45442 0.878706 6.87868C0.454442 7.30294 0.454441 7.86863 0.878706 8.29289L1.58581 9L0.878706 9.70711C0.454441 10.1314 0.454442 10.6971 0.878706 11.1213C1.30297 11.5456 1.86866 11.5456 2.29292 11.1213L3.00003 10.4142L3.70713 11.1213Z"
        fill={color}
      />
    </>
  );
};
