import { useCallback, useMemo, useState } from 'react';
import { Box, Button, Modal, SxProps, Theme } from '@mui/material';
import { useIntl } from 'react-intl';
import { AddRounded } from '@mui/icons-material';
import { ManageLabelsModalHeader } from './header/manage-labels-modal-header';
import { LabelsList } from '../labels-list/labels-list';
import { Label } from '@xspecs/single-source-model';
import { sid } from '@xspecs/short-id';
import { LabelsListItem, LabelsListItemProps } from '../labels-list/labels-list-item/labels-list-item';
import { getRandomLabelColor } from '../labels-color-select/labels-color-select';

export type ManageLabelsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  labels: Label[];
  labelsListItemProps: Pick<LabelsListItemProps, 'onSave' | 'onConfirmDelete'>;
};

export const ManageLabelsModal = (props: ManageLabelsModalProps) => {
  const { isOpen, onClose: onCloseProp, labels: labelsProp, labelsListItemProps } = props;

  const { formatMessage: f } = useIntl();

  const [shouldAddNewLabel, setShouldAddNewLabel] = useState(false);

  const labels = useMemo<LabelsListItem[]>(() => labelsProp.map((label) => ({ label, isNew: false })), [labelsProp]);

  const labelsWithNew = useMemo<LabelsListItem[]>(() => {
    if (shouldAddNewLabel) {
      const newLabel = new Label(sid(), '', getRandomLabelColor(), ''); // is a dummy id as we only need the structure
      return [...labels, { label: newLabel, isNew: true }];
    }
    return labels;
  }, [shouldAddNewLabel, labels]);

  const rootSx = useMemo<SxProps<Theme>>(
    () => ({
      position: 'absolute' as const,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 376,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 2,
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'column',
      gap: 1.5,
      outline: 0,
      overflowY: 'scroll',
    }),
    [],
  );

  const onCreateNewLabel = useCallback(() => {
    setShouldAddNewLabel(true);
  }, []);

  const onExitCreateNewLabel = useCallback(() => {
    setShouldAddNewLabel(false);
  }, []);

  const onClose = useCallback(() => {
    onExitCreateNewLabel();
    onCloseProp();
  }, [onCloseProp, onExitCreateNewLabel]);

  const id = 'ManageLabelsModal';
  return (
    <Modal data-testid={id} id={id} open={isOpen} onClose={onClose} keepMounted={false}>
      <Box sx={rootSx}>
        <ManageLabelsModalHeader onClose={onClose} />
        <Box sx={{ height: 460, overflowY: 'scroll' }}>
          <LabelsList
            labels={labelsWithNew}
            onExitCreateNewLabel={onExitCreateNewLabel}
            labelsListItemProps={labelsListItemProps}
          />
        </Box>
        <Button data-testid="CreateNewLabelButton" startIcon={<AddRounded />} onClick={onCreateNewLabel}>
          {f({ id: 'create-new-label' })}
        </Button>
      </Box>
    </Modal>
  );
};
