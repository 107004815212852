import { Box } from '@mui/material';
import { Modal } from '../modal/modal';
import { useReactiveVar } from '@apollo/client';
import { showInviteUsersVar } from '../../state/state';
import { InvitePeopleContent } from '../../pages/invite-people/invite-people';
import { useCallback } from 'react';

export const InviteUsersModal = () => {
  const open = useReactiveVar(showInviteUsersVar);

  const onClose = useCallback(() => showInviteUsersVar(false), []);

  return (
    <Modal id="InviteUsersModal" isOpen={open} onClose={onClose}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <InvitePeopleContent onSendInvitationSuccess={onClose} onInviteLater={onClose} />
      </Box>
    </Modal>
  );
};
