import { Box, ListItemButton, Typography } from '@mui/material';
import { EntityType } from '@xspecs/single-source-model';
import { Icon } from '../../../../icons/icon';
import { DragEventHandler, useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { lightTheme } from '../../../../themes/light';
import { useSingleSourceStore } from '../../../../store/single-source-store/single-source-store';

type PaletteIconItemProps = {
  type: EntityType;
};

export const PaletteIconItem = ({ type }: PaletteIconItemProps) => {
  const previewRef = useRef(null);

  const { formatMessage: f } = useIntl();

  const setConstructToInsert = useSingleSourceStore.use.setConstructToInsert();

  const onDragStart = useCallback<DragEventHandler>(
    (event) => {
      event.dataTransfer.setData('application/reactflow', type);
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setDragImage(previewRef.current, 0, 0);
    },
    [type],
  );

  const onClick = useCallback(() => {
    setConstructToInsert(type);
  }, [setConstructToInsert, type]);

  const preview = useMemo(() => {
    const commonProps = { ref: previewRef, width: 20, height: 20 };
    switch (type) {
      case EntityType.Actor:
        return <Icon name="actor" {...commonProps} />;
      case EntityType.Spec:
        return <Icon name="spec" {...commonProps} color={lightTheme.palette.primary.main} />;
      case EntityType.Schema:
        return <Icon name="key-value-pairs" {...commonProps} />;
      case EntityType.Query:
        return <Icon name="query" {...commonProps} color="#D346A7" />;
      case EntityType.Doc:
        return <Icon name="doc" {...commonProps} color="#4274A2" />;
      case EntityType.Upload:
        return <Icon name="upload" {...commonProps} color="#4274A2" />;
      default:
        return null;
    }
  }, [type]);

  return (
    <ListItemButton
      disableRipple
      disableTouchRipple
      sx={{
        height: 25,
        gap: 1,
        maxWidth: 'fit-content',
        p: 1,
      }}
      draggable
      onDragStart={onDragStart}
      onClick={onClick}
    >
      <Box>{preview}</Box>
      <Typography>{f({ id: type })}</Typography>
    </ListItemButton>
  );
};
