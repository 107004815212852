import { SearchQuery } from '../../../gql/graphql';
import { List, SxProps, Theme } from '@mui/material';
import { FileListItem, FileListItemProps } from './file-list-item/file-list-item';

export type FileListProps = {
  files: SearchQuery['search'];
  listSxOverrides?: SxProps<Theme>;
} & Pick<FileListItemProps, 'isSelectable' | 'onSelectedChange' | 'selectedFiles'>;

export const FileList = (props: FileListProps) => {
  const { files, listSxOverrides = {} } = props;

  return (
    <List data-testid="file-list" sx={{ overflowY: 'scroll', ...listSxOverrides }}>
      {files.map((file) => (
        <FileListItem key={file.id} {...file} {...props} />
      ))}
    </List>
  );
};
