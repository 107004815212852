import { ConstructBase } from './ConstructBase';
import { EntityType } from '../EntityType';

export class Resolver extends ConstructBase {
  get type(): EntityType {
    return EntityType.Resolver;
  }

  static parse(data: unknown): Resolver {
    return ConstructBase.parseBase.call(Resolver, data);
  }
}
