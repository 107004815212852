import { CommandBase, IParams } from '../framework/CommandBase';
import { ApplyFiltersCommand } from './ApplyFiltersCommand';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

interface UnapplySavedFilterParams extends IParams {
  id: string;
}

export class SavedFilterUnappliedEvent extends EventBase {
  static eventType = 'SavedFilterUnappliedEvent';

  constructor(public readonly params: UnapplySavedFilterParams, public readonly source = UnapplySavedFilterCommand) {
    super();
  }
}

export class UnapplySavedFilterCommand extends CommandBase<UnapplySavedFilterParams> {
  execute(params: UnapplySavedFilterParams): SavedFilterUnappliedEvent | CommandError {
    this.model.messageBus.send(ApplyFiltersCommand, {});
    return new SavedFilterUnappliedEvent(params);
  }
}
