import { ChangeEventHandler, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { focusEditor, useEditorState } from '@udecode/plate-common';
import { PlateCloudEditor, uploadFiles } from '@udecode/plate-cloud';
import { Box, Button, Tab, Tabs, TextField, Typography } from '@mui/material';
import { isValidUrl } from '../../utils';
import { Modal } from '../modal/modal';
import { useReactiveVar } from '@apollo/client';
import { insertMediaModalToShow } from '../../state/state';
import { getListItemEntry, unwrapList } from '@udecode/plate-list';
import { insertImage, insertMediaEmbed } from '@udecode/plate-media';

enum MediaUploadTab {
  UPLOAD,
  EMBED_LINK,
}

export type MediaType = 'image' | 'video';

export const InsertMediaUpload = () => {
  const [activeTab, setActiveTab] = useState<MediaUploadTab>(MediaUploadTab.UPLOAD);
  const [url, setUrl] = useState('');

  const mediaToShow = useReactiveVar(insertMediaModalToShow);

  const { formatMessage: f } = useIntl();

  const editor = useEditorState();

  const isSelectionOnList = useMemo(() => Boolean(getListItemEntry(editor)), [editor]);

  const onTabChange = useCallback((event: SyntheticEvent, newValue: MediaUploadTab) => {
    setActiveTab(newValue);
  }, []);

  const onClose = useCallback(() => {
    setActiveTab(MediaUploadTab.UPLOAD);
    setUrl('');
    insertMediaModalToShow(null);
  }, []);

  const onUploadFile = useCallback(
    (event) => {
      if (isSelectionOnList) unwrapList(editor);
      uploadFiles(editor as PlateCloudEditor, event.target.files);
      onClose();
      focusEditor(editor);
    },
    [editor, isSelectionOnList, onClose],
  );

  const onUploadThroughLink = useCallback(() => {
    if (isSelectionOnList) unwrapList(editor);
    if (mediaToShow === 'image') insertImage(editor, url);
    else insertMediaEmbed(editor, { url });
    onClose();
    focusEditor(editor);
  }, [editor, isSelectionOnList, mediaToShow, onClose, url]);

  const onUrlChange = useCallback<ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>>((event) => {
    setUrl(event.target.value);
  }, []);

  return (
    <Modal
      id="InsertMediaUpload"
      isOpen={Boolean(mediaToShow)}
      onClose={onClose}
      rootSxOverrides={{ height: { xs: '100%', md: 280 } }}
      headerSxOverrides={{ justifyContent: 'space-between' }}
      headerStartContent={
        <Typography variant="h6">{f({ id: mediaToShow === 'image' ? 'insert-image' : 'insert-video' })}</Typography>
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, px: 2 }}>
        <Tabs value={activeTab} onChange={onTabChange} textColor="primary" indicatorColor="primary">
          <Tab data-testid="InsertMediaUploadUploadTab" value={MediaUploadTab.UPLOAD} label={f({ id: 'upload' })} />
          <Tab
            data-testid="InsertMediaUploadEmbedLinkTab"
            value={MediaUploadTab.EMBED_LINK}
            label={f({ id: 'embed-link' })}
          />
        </Tabs>
        {activeTab === MediaUploadTab.UPLOAD ? (
          <Box>
            <Button fullWidth variant="outlined" component="label">
              {f({ id: 'upload-file' })}
              <input data-testid="InsertMediaUploadFileInput" type="file" onChange={onUploadFile} hidden />
            </Button>
          </Box>
        ) : null}
        {activeTab === MediaUploadTab.EMBED_LINK ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              autoFocus
              fullWidth
              inputProps={{
                'data-testid': 'InsertMediaUploadEmbedLinkInput',
              }}
              label={f({ id: 'embed-link' })}
              onChange={onUrlChange}
            />
            <Button
              data-testid="InsertMediaUploadEmbedLinkButton"
              disabled={!url || !isValidUrl(url)}
              fullWidth
              variant="outlined"
              onClick={onUploadThroughLink}
            >
              {f({ id: 'embed-file' })}
            </Button>
          </Box>
        ) : null}
      </Box>
    </Modal>
  );
};
