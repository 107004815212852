import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useActiveOrganization } from '../../../hooks/use-active-organization';

type RemoveUserFromOrganizationConfirmationDialogProps = {
  userId?: string;
  userName?: string;
  onCancel: () => void;
  onConfirm: () => void;
};

export const RemoveUserFromOrganizationConfirmationDialog = (
  props: RemoveUserFromOrganizationConfirmationDialogProps,
) => {
  const { userId, userName, onCancel, onConfirm } = props;

  const { name: organizationName } = useActiveOrganization();

  const [isOpen, setIsOpen] = useState(Boolean(userId));

  const { formatMessage: f } = useIntl();

  useEffect(() => {
    setIsOpen(Boolean(userId));
  }, [userId]);

  return (
    <Dialog open={isOpen} keepMounted={false}>
      <DialogTitle>{f({ id: 'organization-user-remove-confirm-title' }, { userName })}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {f({ id: 'organization-user-remove-confirm' }, { userName, organizationName })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{f({ id: 'cancel' })}</Button>
        <Button
          data-testid="RemoveUserFromOrganizationConfirmationDialogConfirmDeleteButton"
          onClick={onConfirm}
          color="error"
          autoFocus
        >
          {f({ id: 'organization-user-remove-confirm-button' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
