import { Box, SxProps, Theme } from '@mui/material';
import { default as Editor } from '@monaco-editor/react';
import { useElementSize } from '@mantine/hooks';
import { useMonaco } from './use-monaco';
import { useMemo } from 'react';
import { editor } from 'monaco-editor';
import { FileStatus } from '../file-status/file-status';
import IStandaloneEditorConstructionOptions = editor.IStandaloneEditorConstructionOptions;

type SpecProps = {
  id: string;
};

export const Spec = (props: SpecProps) => {
  const { id } = props;

  const { ref, height, width } = useElementSize<HTMLDivElement>();
  const { onMount } = useMonaco({
    id,
    placeholder: `Write your specs as bullet points, "it should", or "Given, When, Then".`,
  });

  const options = useMemo<IStandaloneEditorConstructionOptions>(
    () => ({
      value: '',
      language: 'gherkin',
      theme: 'default',
      'semanticHighlighting.enabled': true,
      lineNumbers: 'off',
      minimap: { enabled: false },
      scrollBeyondLastLine: false,
      scrollBeyondLastColumn: 0,
      fontSize: 13,
      fontFamily: "'JetBrains Mono'",
      scrollbar: {
        horizontal: 'hidden',
        vertical: 'hidden',
      },
      renderLineHighlight: 'none',
      overviewRulerBorder: false,
      glyphMargin: false,
      defaultColorDecorators: false,
      overviewRulerLanes: 0,
      folding: false,
      wrappingIndent: 'none',
      wordWrap: 'on',
      quickSuggestions: false,
      automaticLayout: true,
      guides: {
        indentation: false,
      },
      renderWhitespace: 'none',
    }),
    [],
  );

  return (
    <Box sx={rootSx} ref={ref}>
      <Editor width={width} height={height} language="gherkin" options={options} onMount={onMount} />
      <FileStatus fileId={id} />
    </Box>
  );
};

const rootSx: SxProps<Theme> = {
  backgroundColor: 'background.paper',
  minHeight: 184,
  flexGrow: 1,
  height: '100%',
  border: '1px solid #E0E0E0',
  padding: 1,
  borderRadius: 1,
};
