import { SingleSourceModel } from '../SingleSourceModel';
import { ResolveThreadCommand } from '../commands/comments/ResolveThreadCommand';
import { UnresolveThreadCommand } from '../commands/comments/UnresolveThreadCommand';
import { AddCommentToThreadCommand, AddCommentToThreadParams } from '../commands/comments/AddCommentToThreadCommand';
import { ToggleResolvedThreadsVisibility } from '../commands/comments/ToggleResolvedThreadsVisibility';

export class ThreadInteractor {
  constructor(private readonly model: SingleSourceModel) {}

  addCommentToThread(params: AddCommentToThreadParams) {
    this.model.messageBus.send(AddCommentToThreadCommand, params);
  }

  resolveThread(threadId: string) {
    this.model.messageBus.send(ResolveThreadCommand, { threadId });
  }

  unresolvedThread(threadId: string) {
    this.model.messageBus.send(UnresolveThreadCommand, { threadId });
  }

  toggleResolvedThreadsVisibility(showResolvedThreads: boolean) {
    this.model.messageBus.send(ToggleResolvedThreadsVisibility, { visibility: showResolvedThreads });
  }
}
