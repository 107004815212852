import { InputBase, Typography } from '@mui/material';
import { useSingleSourceModel } from '../../../../../hooks/use-single-source-model';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { useSingleSourceStore } from '../../../../../store/single-source-store/single-source-store';
import { RenameEntityCommand } from '@xspecs/single-source-model';

type AssetUploadFieldProps = {
  data: any;
};

export const AssetUploadField = (props: AssetUploadFieldProps) => {
  const { data } = props;

  const setShowUploadFileModal = useSingleSourceStore.use.setShowUploadFileModal();

  const { formatMessage: f } = useIntl();

  const model = useSingleSourceModel();

  const onUploadClick = useCallback(() => {
    setShowUploadFileModal(data.attachmentId);
  }, [setShowUploadFileModal, data.attachmentId]);

  if (!data.asset) {
    return (
      <Typography
        onClick={onUploadClick}
        sx={{
          cursor: 'pointer !important',
          '&:hover': {
            color: 'primary.main',
          },
        }}
      >
        {f({ id: 'upload' })}
      </Typography>
    );
  }

  return (
    <InputBase
      inputProps={{ sx: { p: 0 } }}
      placeholder="Untitled"
      value={data.asset?.name ?? ''}
      onChange={(event) => {
        model.messageBus.send(RenameEntityCommand, { entityId: data.id, newName: event.target.value });
      }}
    />
  );
};
