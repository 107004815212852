import { createPluginFactory, PlateEditor } from '@udecode/plate-common';
import { ELEMENT_MENTION } from '@udecode/plate-mention';

const withTriggerEventOnMention = (editor: PlateEditor) => {
  const { apply } = editor;

  editor.apply = (operation) => {
    if (operation.type === 'insert_node' && operation.node.type === ELEMENT_MENTION) {
      //   apolloClient.mutate({
      //     mutation: MENTION_USERS_MUTATION,
      //     variables: {
      //       args: {
      //         id: operation.node.id as string,
      //         workspaceId: activeWorkspaceVar().id,
      //         mentionedUserIds: [operation.node.userId] as string[],
      //         fileId: editor.id,
      //       },
      //     },
      //   });
    }
    apply(operation);
  };

  return editor;
};

const key = 'trigger-event-on-mention';

export const createTriggerEventOnMentionPlugin = createPluginFactory({
  key,
  withOverrides: withTriggerEventOnMention,
}) as any;
