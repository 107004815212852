import { TreeViewItemName, TreeViewItemNameProps } from './name/tree-view-item-name';
import { Box, Stack } from '@mui/material';
import { ExpandIcon } from '../expand-icon/expand-icon';
import { MouseEventHandler, useMemo } from 'react';
import { RenderItemParams } from '@atlaskit/tree';
import { EntityIcon } from '../entity-icon/entity-icon';
import { Attachment, EntityType } from '@xspecs/single-source-model';
import { ShowDetailsButton } from '../show-details-button/show-details-button';

export type TreeViewItemPrimitiveProps = {
  item: RenderItemParams['item'];
  onToggleExpansionState: MouseEventHandler<HTMLButtonElement>;
  renderTail?: (item: RenderItemParams['item']) => JSX.Element;
} & Omit<TreeViewItemNameProps, 'name' | 'id'>;

export const TreeViewItemPrimitive = (props: TreeViewItemPrimitiveProps) => {
  const { item, onToggleExpansionState, renderTail } = props;

  const { data, hasChildren, isExpanded } = item;

  const type = useMemo(() => {
    if (item.data.entity instanceof Attachment) {
      return item.data.entity.subType;
    }
    return item.data.entityType;
  }, [item.data.entity, item.data.entityType]);

  const name: string =
    data.entityType === EntityType.Attachment && data.entity._asset ? data.entity._asset.name : data.name;
  const icon: EntityType = data.entityType === EntityType.Attachment ? data.entity.subType : data.entityType;

  const entityId =
    item.data.entity instanceof Attachment && item.data.entity.asset ? item.data.entity.asset.id : item.data.id;

  const panToId =
    item.data.entity instanceof Attachment && item.data.entity.parent ? item.data.entity.parent.id : item.data.id;

  return (
    <Stack direction="row" sx={rootSx}>
      <Stack direction="row" flexGrow={1} alignItems="center">
        <Box minWidth={24} width={24}>
          {hasChildren ? <ExpandIcon isExpanded={isExpanded} onClick={onToggleExpansionState} /> : null}
        </Box>
        <Box minWidth={24} width={24}>
          <EntityIcon type={icon} />
        </Box>
        <Stack direction="row" alignItems="center" gap={0.5} flexGrow={1}>
          <Box flexGrow={1}>
            <TreeViewItemName name={name} isHighlighted={item.data.isHighlighted} />
          </Box>
          {renderTail?.(item)}
        </Stack>
      </Stack>
      <Box className="TreeViewItemOptions">{entityId ? <ShowDetailsButton id={entityId} type={type} /> : null}</Box>
    </Stack>
  );
};

const rootSx = {
  width: '100%',
  alignItems: 'center',
  '.TreeViewItemOptions': {
    opacity: '0',
    pointerEvents: 'none',
    transition: 'opacity 0.1s ease-in',
  },
  ':hover': {
    '.TreeViewItemOptions': {
      opacity: '1',
      pointerEvents: 'auto',
    },
  },
};
