import { Box, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useReactiveVar } from '@apollo/client';
import { StickyNote2Outlined as DocumentsIcon } from '@mui/icons-material';
import { RenderItemParams } from '@atlaskit/tree';
import { insertNodes, useEditorState } from '@udecode/plate-common';
import { showDocLinkTreeViewVar } from '../../../state/state';
import { getRoutePathFromParams, RoutePaths } from '../../../config/route-paths/route-paths';
import { ELEMENT_LINKED_DOCUMENT } from '../editor/plugins/linked-document-plugin/linked-document-plugin';
import { Modal } from '../../modal/modal';
import { TreeView } from '../../tree-view/tree-view';
import { Attachment, EntityType } from '@xspecs/single-source-model';
import { toTree } from '../../explorer/explorer';
import { useSingleSourceExplorer } from '../../../hooks/use-single-source-explorer';
import { useActiveWorkspace } from '../../../hooks/use-active-workspace';
import { useActiveOrganization } from '../../../hooks/use-active-organization';

type DocLinkTreeViewProps = {
  documentId: string;
};

export const DocLinkTreeView = (props: DocLinkTreeViewProps) => {
  const { documentId } = props;

  const { explorerItems } = useSingleSourceExplorer();

  const open = useReactiveVar(showDocLinkTreeViewVar);
  const activeOrganization = useActiveOrganization();
  const activeWorkspace = useActiveWorkspace();

  const editor = useEditorState(documentId);

  const { formatMessage: f } = useIntl();

  const handleClose = useCallback(() => showDocLinkTreeViewVar(false), []);

  const onClick = useCallback(
    (item: RenderItemParams['item']) => {
      const documentId = item.id.toString();
      const url = getRoutePathFromParams(RoutePaths.Entity, {
        organizationName: activeOrganization?.name,
        workspaceName: activeWorkspace?.name,
        fileId: documentId,
      });
      const props = { url, documentId, name: item.data.name };
      insertNodes(editor, { type: ELEMENT_LINKED_DOCUMENT, children: [{ text: '' }], ...props });
      showDocLinkTreeViewVar(false);
    },
    [activeOrganization?.name, activeWorkspace?.name, editor],
  );

  // const contentSx = useMemo<SxProps<Theme>>(
  //   () => ({
  //     position: 'absolute' as const,
  //     top: '50%',
  //     left: '50%',
  //     transform: 'translate(-50%, -50%)',
  //     bgcolor: 'background.paper',
  //     boxShadow: 24,
  //     p: 3,
  //     pb: { xs: 0, md: 3 },
  //     borderRadius: '4px',
  //     display: 'flex',
  //     flexDirection: 'column',
  //     gap: 1.5,
  //     outline: 0,
  //     width: 415,
  //     height: { xs: '100%', md: 'auto' },
  //   }),
  //   [],
  // );

  const remainingDocs = useMemo(
    () =>
      explorerItems.filter((item) => {
        return (
          item.entityType === EntityType.Attachment &&
          (item.entity as Attachment).asset?.type === EntityType.Doc &&
          (item.entity as Attachment).asset?.id !== documentId
        );
      }),
    [explorerItems, documentId],
  );

  const tree = useMemo(() => toTree(remainingDocs, 'root'), [remainingDocs]);

  return (
    <Modal
      id="DocLinkTreeViewModal"
      isOpen={open}
      onClose={handleClose}
      rootSxOverrides={{ width: 415, height: { xs: '100%', md: 'auto' } }}
      headerStartContent={<Typography variant="h6">{f({ id: 'select-doc-to-link' })}</Typography>}
      headerSxOverrides={{ justifyContent: 'space-between' }}
    >
      <Box data-testid="doc-link-tree-view" maxHeight={{ md: '349px' }} overflow="scroll">
        <TreeView
          treeData={tree}
          onClick={onClick}
          RootIcon={<DocumentsIcon color="action" sx={{ mr: 0.8125 }} />}
          readOnly
        />
      </Box>
    </Modal>
  );
};
