import { Box, Divider, MenuItem as MuiMenuItem, styled, Typography } from '@mui/material';
import { Fragment, MouseEventHandler, useCallback } from 'react';

export type MenuItemSubMenuItem = {
  label: string;
  value: string;
  hasSeparatorAbove?: boolean;
  Icon?: any;
  onItemClick?: () => void;
  active?: boolean;
};

export type SubMenuProps = {
  list: MenuItemSubMenuItem[];
  onSubMenuToggle: () => void;
};

export const SubMenu = (props: SubMenuProps) => {
  const { list, onSubMenuToggle } = props;

  const onSubMenuItemClick = useCallback(
    (onItemClick?: () => void): MouseEventHandler =>
      (event) => {
        event.stopPropagation();
        onItemClick?.();
        onSubMenuToggle();
      },
    [onSubMenuToggle],
  );

  return (
    <Fragment>
      {list.map(({ hasSeparatorAbove, Icon, label, value, onItemClick, active }) => (
        <Fragment key={`SubMenuOption${value}`}>
          {hasSeparatorAbove ? <SubMenuDivider data-testid="sub-menu-separator-above" /> : null}
          <MuiMenuItem
            key={`MenuOptionSubMenu${value}`}
            data-testid="sub-menu-item"
            onClick={onSubMenuItemClick(onItemClick)}
            sx={{ backgroundColor: active ? 'action.selected' : 'background.paper' }}
          >
            <SubMenuItemContainer>
              {Icon ? (
                <IconContainer>
                  <Icon data-testid="sub-menu-icon" />
                </IconContainer>
              ) : null}
              <Typography
                variant="body1"
                sx={{
                  fontWeight: '400',
                  fontSize: '16px',
                  lineHeight: '150%',
                  letterSpacing: '0.15px',
                  maxWidth: 190,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {label}
              </Typography>
            </SubMenuItemContainer>
          </MuiMenuItem>
        </Fragment>
      ))}
    </Fragment>
  );
};

const SubMenuDivider = styled(Divider)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));

const SubMenuItemContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  cursor: 'pointer',
  [theme.breakpoints.up('xs')]: {
    height: '36px',
  },
  [theme.breakpoints.up('md')]: {
    height: 'initial',
  },
}));

const IconContainer = styled('span')(({ theme }) => ({
  color: theme.palette.action.active,
  marginRight: theme.spacing(0.625),
}));
