import { CommandBase, IParams } from '../framework/CommandBase';
import { AttachmentType, EntityType } from '../../entities/EntityType';
import { CommandError } from '../../ErrorStore';
import { Position } from '../../types';
import { Upload, UploadMetadata, UploadType } from '../../entities/assets/Upload';
import { EntityParserFactory } from '../../entities/constructs/EntityParserFactory';
import { Attachment } from '../../entities/assets/Attachment';
import { EntitiesAddedEvent } from './AddEntityCommand';
import { Preview } from '../../entities/assets/Preview';
import { sid } from '@xspecs/short-id';
import { Query } from '../../entities/assets/Query';

interface CreateUploadParams extends IParams {
  id: string;
  assetId: string;
  position: Position;
  url: string;
  name: string;
  type: UploadType;
  metadata?: UploadMetadata;
}

export class CreateUploadCommand extends CommandBase<CreateUploadParams> {
  execute(params: CreateUploadParams): EntitiesAddedEvent | CommandError {
    const ticketNum = this.resolveName(params.url);

    const existingEntity = this.model.entityRepository.get(params.id);
    if (existingEntity && !(existingEntity instanceof Query)) {
      return CommandError.of(new Error(`Entity with id ${params.id} already exists`), 'error');
    }
    const attachmentOrQuery: Attachment | Query = existingEntity
      ? (existingEntity as Query)
      : EntityParserFactory.parse<Attachment>(
          {
            id: params.id,
            type: EntityType.Attachment,
            subType: AttachmentType.Upload,
            position: params.position,
            name: '',
            scopes: this.model.entityRepository.getScopes(),
          },
          EntityType.Attachment,
        );
    const upload = EntityParserFactory.parse<Upload>(
      {
        id: params.assetId,
        type: EntityType.Upload,
        position: params.position,
        name: ticketNum ?? params.metadata?.title ?? params.name,
        url: params.url,
        scopes: this.model.entityRepository.getScopes(),
        subType: params.type,
        metadata: params.metadata,
      },
      EntityType.Upload,
    );
    attachmentOrQuery.linkAsset(upload);
    if (attachmentOrQuery instanceof Attachment) {
      const preview = this.createPreview(params);
      attachmentOrQuery.hide();
      preview.show();
      attachmentOrQuery.linkPreview(preview);
      this.model.entityRepository.add(preview);
    } else {
      attachmentOrQuery.setAnnotation({
        width: 1000 * ((params.metadata?.width ?? 200) / (params.metadata?.height ?? 200)),
        height: 1000,
        position: { x: 0, y: 0 },
        url: upload.url,
      });
    }
    this.model.entityRepository.add(upload);
    const entityIds = [upload.id];
    if (attachmentOrQuery instanceof Attachment) {
      this.model.entityRepository.add(attachmentOrQuery);
      entityIds.push(attachmentOrQuery.preview.id);
      entityIds.push(attachmentOrQuery.id);
      this.model.entityRepository.add(attachmentOrQuery);
    } else {
      this.model.entityRepository.update(attachmentOrQuery);
    }
    return new EntitiesAddedEvent({
      entityIds: entityIds,
    });
  }

  private createPreview(params: CreateUploadParams) {
    const preview = EntityParserFactory.parse<Preview>(
      {
        id: sid(),
        type: EntityType.Preview,
        position: params.position,
        name: params.metadata?.title ?? '',
        scopes: this.model.entityRepository.getScopes(),
      },
      EntityType.Preview,
      undefined,
      true,
    );
    preview.position = {
      x: params.position.x - preview.width / 2,
      y: params.position.y - preview.height / 2,
    };
    if (params.metadata?.width && params.metadata?.height) {
      preview.width = params.metadata?.width;
      preview.height = params.metadata?.height;
    }
    return preview;
  }

  private resolveName(url: string): string {
    return /[A-Z]+-\d+/g.exec(url)?.[0];
  }
}

// const regex = /[A-Z]+-\d+/g;
// const url = "https://2kgames.atlassian.net/browse/KWEB-2774";
// const match = url.match(regex);
//
// console.log(match);
