import { IClipboard } from './IClipboard';
import * as clipboard from 'clipboard-polyfill';

export class NavigatorClipboard implements IClipboard {
  public async copy(text: string): Promise<void> {
    await clipboard.writeText(text);
  }

  public async read(): Promise<string> {
    return await clipboard.readText();
  }

  public dispose(): void {}
}
