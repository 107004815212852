import { EntityType } from '@xspecs/single-source-model';
import { NodeProps, NodeTypes } from '@xyflow/react';
import { ComponentType } from 'react';

const RECTANGLE_ASPECT_RATIO = 1.75 / 1;
const SQUARE_ASPECT_RATIO = 1 / 1;

export const CONSTRUCTS_ASPECT_RATIO = {
  [EntityType.Command]: SQUARE_ASPECT_RATIO,
  [EntityType.Action]: RECTANGLE_ASPECT_RATIO,
  [EntityType.Constraints]: RECTANGLE_ASPECT_RATIO,
  [EntityType.Data]: SQUARE_ASPECT_RATIO,
  [EntityType.Event]: SQUARE_ASPECT_RATIO,
  [EntityType.ExternalSystem]: RECTANGLE_ASPECT_RATIO,
  [EntityType.Gateway]: RECTANGLE_ASPECT_RATIO,
  [EntityType.Interface]: RECTANGLE_ASPECT_RATIO,
  [EntityType.Moment]: RECTANGLE_ASPECT_RATIO,
  [EntityType.Process]: RECTANGLE_ASPECT_RATIO,
  [EntityType.Projection]: RECTANGLE_ASPECT_RATIO,
  [EntityType.ReadModel]: RECTANGLE_ASPECT_RATIO,
  [EntityType.Resolver]: RECTANGLE_ASPECT_RATIO,
};

export const wrapNodesWithHoc = (
  nodes: NodeTypes,
  hoc: (node: ComponentType<NodeProps>) => ComponentType<NodeProps>,
  keysToExclude: string[] = [],
) =>
  Object.keys(nodes).reduce((acc, key) => {
    acc[key] = keysToExclude.includes(key) ? nodes[key] : hoc(nodes[key]);
    return acc;
  }, {} as NodeTypes);
