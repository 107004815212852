import { createPluginFactory } from '@udecode/plate-common';
import { withPlateYjs } from './with-plate-yjs';

const KEY_YJS = 'yjs';

export enum FileType {
  DOC = 'doc',
  SPEC = 'spec',
  CONTEXT = 'context',
}

export const getCreateYjsPlugin = (fileType: FileType, userToken: string, disableAwareness = false) => {
  return createPluginFactory({
    key: KEY_YJS,
    withOverrides: (editor) => withPlateYjs({ editor, disableAwareness }),
  });
};
