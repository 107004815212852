import { useIntl } from 'react-intl';
import { Icon } from '../../../../../icons/icon';
import { Fragment } from 'react';
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { FiltersList, FiltersListProps } from '../../../filters-list/filters-list';

export type FiltersButtonMenuListProps = {
  onLabelsSelect: () => void;
  filtersListProps?: FiltersListProps;
};

export const FiltersButtonMenuList = (props: FiltersButtonMenuListProps) => {
  const { onLabelsSelect, filtersListProps } = props;

  const { formatMessage: f } = useIntl();

  return (
    <List>
      <ListItem disablePadding>
        <ListItemButton sx={{ gap: 1, py: 0.5 }} onClick={onLabelsSelect}>
          <ListItemIcon sx={{ minWidth: 'fit-content' }}>
            <Icon name="label" />
          </ListItemIcon>
          <ListItemText primary={f({ id: 'labels' })} />
        </ListItemButton>
      </ListItem>
      {filtersListProps ? (
        <Fragment>
          <Divider sx={{ mb: 1.5 }} />
          {filtersListProps.savedFilters.length > 0 ? (
            <FiltersList {...filtersListProps} />
          ) : (
            <Typography variant="caption" textAlign="center">
              {f({ id: 'no-saved-filters' })}
            </Typography>
          )}
        </Fragment>
      ) : null}
    </List>
  );
};
