import { PlateEditor } from '@udecode/plate-common';
import { ELEMENT_H1, ELEMENT_H2, ELEMENT_H3 } from '@udecode/plate-heading';
import { ELEMENT_LI, ELEMENT_OL, ELEMENT_UL, toggleList, unwrapList } from '@udecode/plate-list';

const preFormat = (editor: PlateEditor) => unwrapList(editor);

export const formatList = (editor: PlateEditor, elementType: string) => {
  toggleList(editor, { type: elementType });
};

const autoFormatBlockRules = [
  { mode: 'block', type: ELEMENT_H1, match: '# ', preFormat },
  { mode: 'block', type: ELEMENT_H2, match: '## ', preFormat },
  { mode: 'block', type: ELEMENT_H3, match: '### ', preFormat },
];

const autoFormatListRules = [
  {
    mode: 'block',
    type: ELEMENT_LI,
    match: ['* ', '- '],
    preFormat,
    format: (editor: PlateEditor) => formatList(editor, ELEMENT_UL),
  },
  {
    mode: 'block',
    type: ELEMENT_LI,
    match: ['1. ', '1) '],
    preFormat,
    format: (editor: PlateEditor) => formatList(editor, ELEMENT_OL),
  },
];

const autoFormatRules = [...autoFormatBlockRules, ...autoFormatListRules];

export const overrides = {
  options: { rules: autoFormatRules as any, enableUndoOnDelete: true },
};
