import { makeVar } from '@apollo/client';
import { Organization, Workspace } from './types';
import { NavBarProps } from '../components/nav-bar/nav-bar';
import { CursorData } from '../types/editor-common.types';
import { MediaType } from '../components/insert-media-modal/insert-media-modal';
import { WebSocketStatus } from '@hocuspocus/provider';

export const activeOrganizationVar = makeVar<Organization | null>(null);

export const activeWorkspaceVar = makeVar<Workspace | null>(null);

export const navBarOverridePropsVar = makeVar<NavBarProps>({});

export const showInsertMenuVar = makeVar(false);

export const showDocLinkTreeViewVar = makeVar(false);

export const showSpecLinkTreeViewVar = makeVar(false);

export const showSearchVar = makeVar<boolean>(false);

export const versionsVar = makeVar<Record<string, string>>({});

export const showOrganizationSettingsVar = makeVar(false);

export const showWorkspaceSettingsVar = makeVar(false);

export const showInviteUsersVar = makeVar(false);

export const fileActiveUsersVar = makeVar<Record<string, CursorData[]>>({});

export const authStatusVar = makeVar<{ status: 'unauthorized' | 'expired' | null }>({ status: null });

export const insertMediaModalToShow = makeVar<MediaType | null>(null);

export const domainNodeToInsertMedia = makeVar<string | null>(null);

export const activeCommentIdVar = makeVar<string | null>(null);

export const connectionStatusVar = makeVar<Record<string, { synced?: boolean; status?: WebSocketStatus }>>({});
