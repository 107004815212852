import { z } from 'zod';
import { AttachmentType, EntityType } from '../EntityType';
import { EntityBase } from '../EntityBase';
import type { Attachment } from '../assets/Attachment';
import { attachmentBaseSchema } from '../assets/AttachmentBaseSchema';
import { Z_INDEXES } from '../../ZIndexes';
import { Action } from './Action';
import { Preview } from '../assets/Preview';

const attachmentsSchema = z.array(attachmentBaseSchema);

export abstract class ConstructBase extends EntityBase {
  //static version = '2.0.1'; // Replaces transitions with edges;
  //static version = '2.0.2'; // Renames Tags to Labels;
  static version = '2.0.3'; // Removes not found attachments;

  static baseSchema = EntityBase.abstractBaseSchema.extend({
    type: z.nativeEnum(EntityType).describe(`The type of the construct`),
    attachments: attachmentsSchema.optional().default([]),
    zIndex: z.number().default(Z_INDEXES.Construct),
  });

  attachments: Attachment[] = [];
  static attachmentsPadding = 3;
  static references = ['attachments'];

  protected static parseBase<T extends EntityBase>(
    this: new (...args: any[]) => T,
    data: object,
    schema: z.ZodSchema<any> = ConstructBase.baseSchema,
  ): T {
    return super.parseBase(data, schema) as T;
    // const validatedData = ConstructBase.baseSchema.parse(data);
    // const construct = super.parseBase(data) as T;
    // const validatedDataConstruct = data as ConstructBase;
    // super.copyParsedValidatedData(validatedData, construct);
    // if (validatedDataConstruct.attachments) {
    //   if ('attachments' in construct) {
    //     construct['attachments'] = validatedDataConstruct.attachments.map((attachment) =>
    //       AssetBase.baseSchema.parse(attachment),
    //     );
    //   }
    // }
    // return construct;
  }

  isValid(): boolean {
    return ConstructBase.baseSchema.safeParse(this).success;
  }

  ingestEntity(entity: EntityBase): boolean {
    function collapsePreviewIntoAttachment(attachment: Attachment) {
      attachment.show();
      attachment.preview.hide();
      entity.position = { x: attachment.position.x + 25, y: attachment.position.y + 25 };
    }

    if (entity.type === EntityType.Attachment) {
      const attachment = entity as Attachment;
      this.attach(entity as Attachment);
      if (attachment.preview) {
        collapsePreviewIntoAttachment(attachment);
      }
      return true;
    }
    if (entity instanceof Preview && entity.parent.type === EntityType.Attachment) {
      const attachment = entity.parent;
      this.attach(attachment as Attachment);
      collapsePreviewIntoAttachment(attachment as Attachment);
      return true;
    }
    return false;
  }

  eject(entity: EntityBase, nullifyParent = true): boolean {
    if (entity.type === EntityType.Attachment) {
      const attachment = entity as Attachment;
      this.detach(attachment, nullifyParent);
      if (attachment?.asset?.type === EntityType.Upload) {
        attachment.preview.show();
        attachment.hide();
        attachment.preview.position = {
          x: attachment.position.x - attachment.preview.width / 2,
          y: attachment.position.y - attachment.preview.height / 2,
        };
      }
      return true;
    }
    return false;
  }

  attach(attachment: Attachment): void {
    if (this.attachments.find((oldAttachable) => oldAttachable.id === attachment.id)) return;
    attachment.parent = this;
    attachment.deselect();
    this.attachments.push(attachment);
    if ([EntityType.Action].includes(this.type)) {
      const script = (this as unknown as Action).script;
      script.position.y += attachment.height;
    }
  }

  detach(attachment: Attachment, nullifyParent = true): void {
    const index = this.attachments.findIndex((oldAttachable) => oldAttachable.id === attachment.id);
    if (index !== -1) {
      this.attachments.splice(index, 1);
    } else {
      return;
    }
    if (nullifyParent) {
      attachment.parent = undefined;
    }
    if ([EntityType.Action].includes(this.type)) {
      const script = (this as unknown as Action).script;
      script.position.y -= attachment.height;
    }
  }

  public dimensions(): { width: number; height: number; position: { x: number; y: number } } {
    if (this.attachments.length === 0) return super.dimensions();
    const totalHeight = this.getTotalHeight();
    const totalWidth = this.width;
    const adjustedY = this.getAdjustedY();
    return {
      width: totalWidth,
      height: totalHeight,
      position: {
        x: this.position.x,
        y: adjustedY,
      },
    };
  }

  private getAdjustedY(): number {
    if (this.attachments.length === 0) return this.position.y;
    // TODO: make this generic and use positions instead of types
    const attachmentsOnTop = this.attachments.filter((attachment) => attachment.subType === AttachmentType.Actor);
    const attachmentsStackHeight = attachmentsOnTop.reduce(
      (acc, attachment) => acc + attachment.height + ConstructBase.attachmentsPadding,
      0,
    );
    return this.position.y - attachmentsStackHeight;
  }

  recalculateDimensions() {
    const actorAttachments = this.attachments.filter((attachment) => attachment.subType === AttachmentType.Actor);
    const otherAttachments = this.attachments.filter((attachment) => attachment.subType !== AttachmentType.Actor);
    let offsetAbove = 0;
    actorAttachments.forEach((attachment) => {
      offsetAbove += attachment.height + ConstructBase.attachmentsPadding;
      attachment.position = { x: this.position.x, y: this.position.y - offsetAbove };
    });
    let pointer = this.position.y + this.height + ConstructBase.attachmentsPadding;
    otherAttachments.forEach((attachment, index) => {
      attachment.position = { x: this.position.x, y: pointer };
      pointer += attachment.height + ConstructBase.attachmentsPadding;
    });
  }

  serialize(reference: boolean = false): unknown {
    if (reference) return super.serialize(reference);
    return {
      ...(super.serialize() as any),
      attachments: this.attachments.map((attachment) => attachment.serialize(true)),
    };
  }

  private getTotalHeight(): number {
    if (this.attachments.length === 0) return this.height;
    return (
      this.height +
      this.attachments.reduce((acc, attachment) => acc + attachment.height + ConstructBase.attachmentsPadding, 0)
    );
  }

  getTopOffset(): number {
    const actors = this.attachments.filter((attachment) => attachment.subType === AttachmentType.Actor);
    return actors.reduce((acc, attachment) => acc + attachment.height + ConstructBase.attachmentsPadding, 0);
  }
}
