import { Box, Typography, useTheme } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { RoutePaths } from '../../config/route-paths/route-paths';
import { useIntl } from 'react-intl';
import { Icon } from '../../icons/icon';

export const AuthRedirect = () => {
  const { formatMessage: f } = useIntl();

  const theme = useTheme();

  return (
    <Box
      data-testid="auth-redirect-root"
      sx={{
        width: '100vw',
        height: '100vh',
        display: { xs: 'block', md: 'flex' },
        flexDirection: { md: 'column' },
      }}
    >
      <Box
        data-testid="auth-redirect-header"
        sx={{
          width: '100%',
          height: '64px',
          backgroundColor: 'primary.main',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '26px',
        }}
      >
        <Icon name="narrative-text" height={48} width={48} color={theme.palette.primary.main} />
      </Box>
      <Box
        data-testid="auth-redirect"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          flexGrow: '1',
          mt: { xs: 8, md: 'unset' },
        }}
      >
        <Box data-testid="auth-redirect-logo" sx={{ mb: 4.5 }}>
          <Icon name="narrative-filled" height={48} width={46} color={theme.palette.primary.main} />
        </Box>
        <Typography data-testid="auth-redirect-redirect-text" variant="h6" sx={{ mb: 1.5 }}>
          {f({ id: 'redirecting' })}
        </Typography>
        <Typography variant="body1" sx={{ maxWidth: '260px', color: 'primary.dark' }}>
          <NavLink
            data-testid="auth-redirect-click-text"
            to={RoutePaths.ChooseOrganization}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {f({ id: 'redirect-action' })}
          </NavLink>
        </Typography>
      </Box>
    </Box>
  );
};
