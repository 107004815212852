import { Box, Divider, FormControlLabel, FormGroup, Stack, Switch } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { PaletteConstructList } from './construct-list/palette-construct-list';
import { PaletteAttachmentsList } from './attachables-list/palette-attachments-list';
import { AssetTypes, EntityType } from '@xspecs/single-source-model';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { useApplication } from '../../../wrappers/application-context/application-context';

export const Palette: FC = () => {
  const { formatMessage: f } = useIntl();
  const { application } = useApplication();
  const showResolvedThreads = useSingleSourceStore.use.showResolvedThreads();

  const palette = useMemo(
    () => ({
      subscripts: [EntityType.ActionScript],
      experience: [EntityType.Moment, EntityType.Interface, EntityType.Action],
      informationFlow: [EntityType.Command, EntityType.Data, EntityType.Event],
      businessProcessModel: [EntityType.Process, EntityType.Constraints, EntityType.ExternalSystem],
      softwareModel: [EntityType.Resolver, EntityType.ReadModel, EntityType.Projection, EntityType.Gateway],
      attachments: [...AssetTypes.items],
    }),
    [],
  );

  const onChange = useCallback(
    (_, checked: boolean) => {
      application.model.threadInteractor.toggleResolvedThreadsVisibility(checked);
    },
    [application.model.threadInteractor],
  );

  return (
    <Stack
      sx={{
        boxShadow: '0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
        borderRadius: 4,
        backgroundColor: 'background.paper',
        padding: 1,
        gap: 0.5,
      }}
    >
      <Stack direction="row" gap={1} sx={{ backgroundColor: 'background.paper' }}>
        {/*<PaletteConstructList items={palette.subscripts} title={f({ id: 'subscripts' })} />*/}
        <PaletteConstructList items={palette.informationFlow} title={f({ id: 'information-flow' })} />
        <PaletteConstructList items={palette.businessProcessModel} title={f({ id: 'business-process-model' })} />
        <PaletteConstructList items={palette.softwareModel} title={f({ id: 'software-model' })} />
      </Stack>
      <Divider />
      <PaletteAttachmentsList
        items={palette.attachments.filter(
          (attachment) =>
            attachment !== EntityType.Actor && attachment !== EntityType.Spec && attachment !== EntityType.Upload,
        )}
      />
      <Divider />
      <Box p={1}>
        <FormGroup>
          <FormControlLabel
            label={f({ id: 'show-resolved-comments' })}
            control={<Switch onChange={onChange} checked={showResolvedThreads} />}
          />
        </FormGroup>
      </Box>
    </Stack>
  );
};
