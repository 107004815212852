import { CommandBase, CommandConstructor, IParams } from './CommandBase';
import { EventBase, EventConstructor } from './EventBase';
import { EventHandlerBase } from './EventHandlerBase';
import { SingleSourceModel } from '../../SingleSourceModel';
import { logger } from '@xspecs/logger';
import { DEBUG_CONFIG } from '../../debug-config';
import { CommandError } from '../../ErrorStore';

export class MessageBus {
  private commands: Map<CommandConstructor<CommandBase<IParams>>, CommandBase<IParams>> = new Map();
  private eventHandlers = new Map<EventConstructor<EventBase>, EventHandlerBase[]>();

  constructor(private readonly model: SingleSourceModel) {}

  registerCommand<T extends CommandBase<IParams>>(CommandClass: CommandConstructor<T>, commandInstance: T): void {
    this.commands.set(CommandClass, commandInstance);
  }

  registerEventHandler<T extends EventBase>(EventClasses: EventConstructor<T>[], handler: EventHandlerBase): void {
    EventClasses.forEach((EventClass) => {
      if (!this.eventHandlers.has(EventClass)) this.eventHandlers.set(EventClass, []);
      this.eventHandlers.get(EventClass).push(handler);
    });
  }

  // use this as the API entry point
  send<T extends IParams>(CommandClass: CommandConstructor<CommandBase<T>>, params: T, internal = false): void | Error {
    if (DEBUG_CONFIG.messageBus) logger.log(`MessageBus.send: Sending command ${CommandClass.name}`, params);
    const commandInstance = this.commands.get(CommandClass);
    let result: CommandError | EventBase;
    if (commandInstance) {
      try {
        result = commandInstance.execute(params);
        if (result instanceof CommandError) {
          if (DEBUG_CONFIG.messageBus)
            logger.error(`MessageBus.send: Error executing command ${CommandClass.name}, command resulted in:`, result);
          return this.storeAndReturnError(result).error;
        }
      } catch (error) {
        logger.error(`MessageBus.send: Error executing command ${CommandClass.name}, command threw an error:`, error);
        return error;
        // return this.storeAndReturnError(error);
      }
      const policyResult = this.handle(result);
      if (DEBUG_CONFIG.messageBus)
        logger.log(`MessageBus.send: Policy result`, policyResult ? policyResult : 'no policies run');
      if (policyResult instanceof CommandError) {
        return this.storeAndReturnError(policyResult).error;
      }
      if (!internal && commandInstance.shouldSave()) {
        if (DEBUG_CONFIG.messageBus) logger.log(`MessageBus.send: Saving model`);
        this.model.entityRepository.save();
      }
    } else {
      logger.error(`Command ${CommandClass.name} not found`);
      return new Error(`Command ${CommandClass.name} not found`);
    }
  }

  // use this in policies, do not use send or you'll get multiple saves
  sendInternal<T extends IParams>(CommandClass: CommandConstructor<CommandBase<T>>, params: T): void | Error {
    if (DEBUG_CONFIG.messageBus)
      logger.log(`MessageBus.sendInternal: Sending INTERNAL command ${CommandClass.name}`, params);
    return this.send(CommandClass, params, true);
  }

  handle(event: EventBase): Error | void {
    if (DEBUG_CONFIG.messageBus) logger.log(`MessageBus.handle: Handling event`, event);
    if (!event) return;
    const handlers = this.eventHandlers.get(event.constructor as EventConstructor<EventBase>);
    if (DEBUG_CONFIG.messageBus) logger.log(`MessageBus.handle: found ${handlers ? handlers.length : 0} handler(s)`);
    if (handlers && handlers.length)
      handlers.forEach((handler) => {
        if (DEBUG_CONFIG.messageBus) logger.log(`MessageBus.handle: calling ${handler.constructor.name}`);
        let result: void | Error;
        try {
          result = handler.execute(event);
        } catch (error) {
          logger.error(
            `MessageBus.send: Error executing policy ${handler.constructor.name}, policy threw an error:`,
            error,
          );
          return this.storeAndReturnError(error);
        }
        if (DEBUG_CONFIG.messageBus)
          logger.log(`MessageBus.handle: ${handler.constructor.name} executed with result`, result);
      });
  }

  private storeAndReturnError(error: CommandError): CommandError {
    this.model.errorStore.addError(error);
    return error;
  }
}
