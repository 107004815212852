import {
  PlateEditor,
  queryNode,
  someNode,
  TInsertNodeOperation,
  TNode,
  TNodeEntry,
  TSplitNodeOperation,
} from '@udecode/plate-common';
import { v4 as uuidv4 } from 'uuid';
import { ELEMENT_LINKED_DOCUMENT } from '../linked-document-plugin/linked-document-plugin';
import { ELEMENT_LINK } from '@udecode/plate-link';

export const isNodeIdUnique = (editor: PlateEditor, node: TNode) => {
  return !someNode(editor, { at: [], match: { id: node.id } });
};

export const onSplitNode = (operation: TSplitNodeOperation) => {
  const entry = [operation.properties, operation.path] as TNodeEntry;
  const isNode = queryNode(entry);
  const [node] = entry;
  if (isNode && node.type) {
    node.id = uuidv4();
  }
  return operation;
};

const assignUniqueIdsToNode = (editor: PlateEditor, node: any) => {
  if (!node.type) return;

  if (node.type === ELEMENT_LINK || node.type === ELEMENT_LINKED_DOCUMENT) return;

  if (node.id && isNodeIdUnique(editor, node)) return;

  node.id = uuidv4();
  if (node.children) {
    node.children.forEach((child) => {
      assignUniqueIdsToNode(editor, child);
    });
  }
};

export const onInsertNode = (editor: PlateEditor, operation: TInsertNodeOperation) => {
  if (!operation.node.id || (operation.node.id && !isNodeIdUnique(editor, operation.node))) {
    assignUniqueIdsToNode(editor, operation.node);
  }

  return operation;
};
