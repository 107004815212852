import { ReactNode, useMemo } from 'react';
import { Box, IconButton, Modal as MuiModal, SxProps, Theme } from '@mui/material';
import { Close } from '@mui/icons-material';

export type ModalProps = {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  rootSxOverrides?: SxProps<Theme>;
  headerSxOverrides?: SxProps<Theme>;
  headerStartContent?: ReactNode;
};

export const Modal = (props: ModalProps) => {
  const { id, isOpen, onClose, children, rootSxOverrides = {}, headerSxOverrides = {}, headerStartContent } = props;

  const rootSx = useMemo<SxProps<Theme>>(
    () => ({
      position: 'absolute' as const,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: { xs: '100%', md: 415 },
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 3,
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'column',
      gap: 1.5,
      outline: 0,
      height: { xs: '100%', md: 'fit-content' },
      overflowY: 'scroll',
      ...rootSxOverrides,
    }),
    [rootSxOverrides],
  );

  const headerSx = useMemo<SxProps<Theme>>(
    () => ({
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      ...headerSxOverrides,
    }),
    [headerSxOverrides],
  );

  return (
    <MuiModal data-testid={id} id={id} open={isOpen} onClose={onClose} keepMounted={false}>
      <Box key={String(isOpen)} sx={rootSx}>
        <Box sx={headerSx}>
          {headerStartContent}
          <IconButton
            data-testid="ModalCloseButton"
            sx={{ position: 'relative', top: '-12px', left: '12px' }}
            onClick={onClose}
          >
            <Close />
          </IconButton>
        </Box>
        {children}
      </Box>
    </MuiModal>
  );
};
