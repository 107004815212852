import { ItemId, TreeData, TreeItem } from '@atlaskit/tree';
import { v4 as uuidv4 } from 'uuid';
import { FileType } from '../../gql/graphql';

export interface TreeNode {
  id: string;
  name: string;
  parent?: TreeNode;
  fileType?: FileType;
}

export const rootId = uuidv4();
export const treeExpansionStateKey = 'treeExpansionState';
export const updateTreeStateInLocalStorage = (itemId: ItemId, state: boolean) => {
  const storedStateString = localStorage.getItem(treeExpansionStateKey);
  const storedState = storedStateString ? JSON.parse(storedStateString) : {};
  const newState = { ...storedState, [itemId]: state };
  localStorage.setItem(treeExpansionStateKey, JSON.stringify(newState));
};

export function toTree(nodes: TreeNode[]): TreeData {
  const state = JSON.parse(localStorage.getItem(treeExpansionStateKey) || '{}');
  const convertNodeToItem = (node: TreeNode) => createItem(node.id, node, parentMap.get(node.id) || []);
  const createItem = (id: string, data: any, children: string[]): TreeItem => ({
    id,
    children,
    hasChildren: children.length > 0,
    isExpanded: Boolean(state[id]),
    data,
  });
  const parentMap = new Map<string, string[]>();

  nodes.forEach((node) => {
    const parent = node.parent?.id || rootId;
    const children = parentMap.get(parent) || [];
    parentMap.set(parent, children.concat([node.id]));
  });

  const root = createItem(rootId, { title: 'Root' }, parentMap.get(rootId));

  return {
    rootId,
    items: {
      [root.id]: root,
      ...nodes.reduce((acc, i) => Object.assign(acc, { [i.id]: convertNodeToItem(i) }), {}),
    },
  };
}

export const getParents = (tree: TreeData, itemId: ItemId) => {
  const parents = [];
  let parent = tree.items[itemId].data.parent;
  while (parent) {
    parents.push(parent);
    parent = tree.items[parent].data.parent;
  }
  return parents;
};
