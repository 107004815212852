import { SingleSourceModel } from '../../SingleSourceModel';
import { EventConstructor, EventBase } from './EventBase';

export abstract class EventHandlerBase {
  constructor(protected readonly model: SingleSourceModel) {}

  public abstract handles(): EventConstructor<EventBase>[];

  abstract execute(event: EventBase): void | Error;
}
