interface ImageDimensions {
  width: number;
  height: number;
}

export const useImageDimensions = () => {
  return { getImageDimensions };
};

export const getImageDimensions = (input: File | string) => {
  return new Promise<ImageDimensions>((resolve, reject) => {
    const img = new Image();

    const handleImageLoad = () => {
      const { width, height } = img;
      resolve({ width, height });
    };

    img.onload = handleImageLoad;
    img.onerror = () => reject({ message: 'Failed to load image' });

    if (typeof input === 'string') {
      img.src = input;
    } else {
      const reader = new FileReader();
      reader.onload = (e) => {
        img.src = e.target?.result as string;
      };

      reader.onerror = () => reject({ message: 'Failed to read file' });
      reader.readAsDataURL(input);
    }
  });
};
