import { IconButton } from '@mui/material';
import { NodeProps, NodeResizer } from '@xyflow/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { LockRounded, LockOpenRounded } from '@mui/icons-material';

export const ImageNode = (props: NodeProps) => {
  const { data } = props;
  const nodeRef = useRef<HTMLImageElement>(null);

  const [isLocked, setIsLocked] = useState(false);

  const onLockClick = useCallback(() => {
    setIsLocked((prev) => !prev);
  }, []);

  useEffect(() => {
    if (nodeRef.current) {
      const parentElement = nodeRef.current.parentElement;
      if (parentElement) parentElement.style.pointerEvents = isLocked ? 'none' : 'auto';
    }
  }, [isLocked]);

  return (
    <>
      <IconButton onClick={onLockClick} sx={buttonSx}>
        {isLocked ? <LockRounded /> : <LockOpenRounded />}
      </IconButton>
      <NodeResizer keepAspectRatio minHeight={30} minWidth={30} />
      <img ref={nodeRef} alt={data.alt as string} src={data.src as string} style={imageStyle} />
    </>
  );
};

const imageStyle = {
  width: '100%',
  height: '100%',
};

const buttonSx = {
  pointerEvents: 'auto',
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%, -120%)',
};
