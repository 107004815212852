import { Stack, TextField, Typography } from '@mui/material';
import { EntityBase } from '@xspecs/single-source-model';
import { SelectedCriteria } from '../../selected-criteria/selected-criteria';
import { ChangeEventHandler, useCallback } from 'react';

type SavedSelectedFiltersItemProps = {
  name: string;
  criteria: EntityBase[];
  onDeleteLabel?: (id: string) => void;
  isEditMode: boolean;
  onNameChange: ChangeEventHandler<HTMLInputElement>;
};

export const SavedSelectedFiltersItem = (props: SavedSelectedFiltersItemProps) => {
  const { name, criteria, onDeleteLabel, isEditMode, onNameChange } = props;

  const onDelete = useCallback(
    (id: string) => () => {
      onDeleteLabel?.(id);
    },
    [onDeleteLabel],
  );

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      {isEditMode ? (
        <TextField sx={{ width: 220 }} size="small" value={name} onChange={onNameChange} />
      ) : (
        <Typography sx={{ maxWidth: 220 }} noWrap>
          {name}
        </Typography>
      )}
      <Stack direction="row" gap={1.5}>
        {criteria.map((entity) => (
          <SelectedCriteria
            key={entity.id}
            entity={entity}
            onDelete={onDeleteLabel ? onDelete(entity.id) : undefined}
          />
        ))}
      </Stack>
    </Stack>
  );
};
