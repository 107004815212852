import { create } from 'zustand';
import { createSelectors } from '../../common/zustand';

type ExplorerState = {
  expandedStates: Record<string, boolean>;
  expandAll: boolean;
  collapseAll: boolean;
};

type ExplorerActions = {
  setItemExpandedState: (id: string, expanded: boolean) => void;
  setExpandAll: (expandAll: boolean) => void;
  setCollapseAll: (collapseAll: boolean) => void;
};

const explorerStore = create<ExplorerState & ExplorerActions>((set, get) => ({
  expandedStates: {},
  expandAll: false,
  collapseAll: false,

  setCollapseAll: (collapseAll) => {
    set({ collapseAll });
  },
  setExpandAll: (expandAll) => {
    set({ expandAll });
  },
  setItemExpandedState: (id, expanded) => {
    set({ expandedStates: { ...get().expandedStates, [id]: expanded } });
  },
}));

export const useExplorerStore = createSelectors(explorerStore);
