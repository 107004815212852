import * as Y from 'yjs';
import { IEntityUpcaster, PropertyTransformFunction } from './IEntityUpcaster';
import { SerializedEntity } from '../types';
import { EntityParserFactory } from '../entities/constructs/EntityParserFactory';

interface UpcastRule {
  transform: PropertyTransformFunction;
  replacementEntityType?: string;
}

type PostProcessFunction = (storageMap: Y.Map<any>) => void;
type ModelLoadedFunction = () => void;

export class EntityUpcaster implements IEntityUpcaster {
  private upcastRules: Map<string, UpcastRule> = new Map();
  private retiredEntities: Map<string, string> = new Map();
  private readonly DEFAULT_VERSION: string = '1.0.0';
  private postProcessors: Map<string, PostProcessFunction> = new Map();
  private postProcessingExecuted: boolean = false;
  private afterModelLoadedExecuted: boolean = false;
  private upcastingPerformed: boolean = false;
  private lastKeyUsed: string | null = null;
  private modelLoadedFunction: ModelLoadedFunction[] = [];

  registerDeprecatedEntity(
    deprecatedEntityType: string,
    currentVersion: string,
    replacementEntityType?: string,
  ): EntityUpcaster {
    const key = `${deprecatedEntityType}:${currentVersion}`;
    this.upcastRules.set(key, {
      transform: (data) => [{ ...data }],
      replacementEntityType,
    });
    this.lastKeyUsed = key;
    return this;
  }

  registerDeprecatedEntities(
    entityTypes: string[],
    currentVersion: string,
    transformFunction: PropertyTransformFunction,
    replacementEntityType?: string,
  ): EntityUpcaster {
    entityTypes.forEach((entityType) => {
      this.registerDeprecatedEntity(entityType, currentVersion, replacementEntityType).withCustomTransformUpcast(
        transformFunction,
      );
    });
    return this;
  }

  withCustomTransformUpcast(transformFunction: PropertyTransformFunction): EntityUpcaster {
    if (this.lastKeyUsed && this.upcastRules.has(this.lastKeyUsed)) {
      const rule = this.upcastRules.get(this.lastKeyUsed);
      if (rule) {
        rule.transform = transformFunction;
      }
    }
    return this;
  }

  withAfterAllUpcasts(postProcessFunction: (storageMap: Y.Map<any>) => void): EntityUpcaster {
    if (this.lastKeyUsed) {
      this.postProcessors.set(this.lastKeyUsed, postProcessFunction);
    }
    return this;
  }

  hasAfterUpcastProcessing(): boolean {
    return this.upcastingPerformed && !this.postProcessingExecuted && this.postProcessors.size > 0;
  }

  afterUpcastsProcessing(storageMap: Y.Map<any>): void {
    if (this.upcastingPerformed) {
      this.postProcessors.forEach((processor) => {
        processor(storageMap);
      });
      this.postProcessingExecuted = true;
    }
  }

  hasAfterModelLoaded(): boolean {
    return this.upcastingPerformed && !this.afterModelLoadedExecuted && this.modelLoadedFunction.length > 0;
  }

  withAfterModelLoaded(modelLoadedFunction: ModelLoadedFunction): EntityUpcaster {
    this.modelLoadedFunction.push(modelLoadedFunction);
    return this;
  }

  afterModelLoaded(): void {
    if (this.postProcessingExecuted && !this.afterModelLoadedExecuted) {
      this.modelLoadedFunction.forEach((hook) => hook());
      this.afterModelLoadedExecuted = true;
    }
  }

  hasUpcaster(entityData: SerializedEntity): boolean {
    const compositeKey = `${entityData.type}:${entityData.version ?? this.DEFAULT_VERSION}`;
    return this.upcastRules.has(compositeKey);
  }

  upcast(entityData: SerializedEntity): SerializedEntity[] | null {
    this.upcastingPerformed = false;
    const compositeKey = `${entityData.type}:${entityData.version ?? this.DEFAULT_VERSION}`;
    const rule = this.upcastRules.get(compositeKey);
    if (!rule) return null;
    const transformedData = rule.transform(entityData);
    if (rule.replacementEntityType) {
      transformedData.forEach((data) => (data['type'] = rule.replacementEntityType));
    }
    transformedData.forEach((data: SerializedEntity) => {
      data.version = EntityParserFactory.getInstance(data.type).version;
    });
    this.upcastingPerformed = true;
    return transformedData as SerializedEntity[];
  }

  registerRetiredEntity(entityType: string, version?: string): EntityUpcaster {
    this.retiredEntities.set(entityType, version);
    return this;
  }

  isRetiredEntity(entityData?: SerializedEntity): boolean {
    if (!entityData) return true;
    if (this.retiredEntities.has(entityData.type)) {
      const version = this.retiredEntities.get(entityData.type);
      if (!version) return true;
      if (!entityData.version) return true;
      return version === entityData.version;
    }
    return false;
  }
}
