import { useSingleSourceExplorer } from '../../hooks/use-single-source-explorer';
import { NodeFile, TreeData, TreeItem } from '@xspecs/rules';
import { ChangeEventHandler, MouseEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import { TreeView } from '../tree-view/tree-view';
import { Attachment, ExplorerItem } from '@xspecs/single-source-model';
import { Box, Divider, Input, Typography } from '@mui/material';
import { RenderItemParams } from '@atlaskit/tree';
import { useSingleSourceStore } from '../../store/single-source-store/single-source-store';
import { useSingleSourceModel } from '../../hooks/use-single-source-model';
import { useActiveEntity } from '../../hooks/use-active-entity';
import { Search } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { useHotkeys } from '@mantine/hooks';
import { ExplorerTabs } from './tabs/explorer-tabs';
import { AppTypeEvent, useTrackEvents } from '../../hooks/use-track-events';
import { DeselectAllEntitiesCommand, UpdateEntitiesSelectionsCommand } from '@xspecs/single-source-model';

export const Explorer = () => {
  const { formatMessage: f } = useIntl();

  const inputRef = useRef<HTMLInputElement>();

  const { explorerItems, error } = useSingleSourceExplorer();

  const searchQuery = useSingleSourceStore.use.searchQuery();
  const singleSourceModel = useSingleSourceStore.use.singleSourceModel();
  const setSearchQuery = useSingleSourceStore.use.setSearchQuery();
  const setConstructToPanTo = useSingleSourceStore.use.setConstructToPanTo();

  const model = useSingleSourceModel();

  const tree = useMemo(() => toTree(explorerItems, 'root'), [explorerItems]);

  const activeEntityId = useActiveEntity();

  const { trackEvent } = useTrackEvents();

  const onClick = useCallback(
    (item: RenderItemParams['item'], event: MouseEvent<HTMLDivElement>) => {
      const isModKey = event.ctrlKey || event.metaKey || event.shiftKey;
      if (!isModKey) {
        singleSourceModel.messageBus.send(DeselectAllEntitiesCommand, {});
      }
      // FIXME - WHEN THIS IS NEEDED
      const isSelected = false;
      // const isSelected = singleSourceModel.entities.isSelectedBy(item.id.toString());

      if (isModKey && isSelected) {
        singleSourceModel.messageBus.send(UpdateEntitiesSelectionsCommand, {
          entitySelections: [{ id: item.id.toString(), selected: false }],
        });
        return;
      }

      singleSourceModel.messageBus.send(UpdateEntitiesSelectionsCommand, {
        entitySelections: [{ id: item.id.toString(), selected: true }],
      });
      let entityIdToPanTo = item.data.id;
      if (item.data.entity instanceof Attachment && item.data.entity.parent) {
        entityIdToPanTo = item.data.entity.parent.id;
      }
      setConstructToPanTo(entityIdToPanTo);
    },
    [setConstructToPanTo, singleSourceModel.messageBus],
  );

  const onSearchQueryChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      setSearchQuery(event.target.value);
      trackEvent(AppTypeEvent.SearchPerformed, { searchQuery: event.target.value });
    },
    [setSearchQuery, trackEvent],
  );

  const onKeyDown = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        if (!searchQuery) {
          inputRef.current.blur();
        }
        setSearchQuery('');
      }
    },
    [searchQuery, setSearchQuery],
  );

  useHotkeys([['mod+f', () => inputRef.current.focus()]]);

  useEffect(() => {
    model.entities.search(searchQuery);
  }, [model.entities, searchQuery]);

  return (
    <Box width="100%" height="100%">
      <ExplorerTabs />
      <Divider />
      <Box sx={{ px: 1, py: 2 }}>
        <Input
          inputRef={inputRef}
          size="small"
          startAdornment={<Search color="action" />}
          fullWidth
          placeholder={f({ id: 'search' })}
          onChange={onSearchQueryChange}
          onKeyDown={onKeyDown}
          value={searchQuery}
        />
      </Box>
      {error ? (
        <Typography align="center" variant="overline" fontSize="small">
          {f({ id: error })}
        </Typography>
      ) : (
        <TreeView treeData={tree} onClick={onClick} activeItemId={activeEntityId} />
      )}
    </Box>
  );
};

export function toTree(explorerItems: ExplorerItem[], rootId: string): TreeData {
  const convertNodeToItem = (node: NodeFile) => createItem(node.id, node, parentMap.get(node.id) || []);
  const createItem = (id: string, data: any, children: string[]): TreeItem => ({
    id,
    children,
    hasChildren: children.length > 0,
    isExpanded: true,
    data,
  });
  const parentMap = new Map<string, string[]>();

  explorerItems.forEach((node) => {
    const parent = node.parentId || rootId;
    const children = parentMap.get(parent) || [];
    parentMap.set(parent, children.concat([node.id]));
  });

  const root = createItem(rootId, { title: 'Root' }, parentMap.get(rootId) || []);

  return {
    rootId,
    items: {
      [root.id]: root,
      ...explorerItems.reduce((acc, i) => Object.assign(acc, { [i.id]: convertNodeToItem(i) }), {}),
    },
  };
}
