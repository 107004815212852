import { FC, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { useReactiveVar } from '@apollo/client';
import { activeOrganizationVar } from '../../state/state';
import { getToken } from '../../utils/getToken';
import { useAuth } from '../../auth';
import { singleSourceStore, useSingleSourceStore } from '../../store/single-source-store/single-source-store';
import { ErrorBoundary } from '../../components/error-boundary/error-boundary';
import { useApplication } from '../application-context/application-context';
import { FileStatus } from '../../components/file-status/file-status';
import { useSearchParams } from 'react-router-dom';
import { PreviewModeBanner } from '../../preview-mode-banner/preview-mode-banner';
import { useWindowEvent } from '@mantine/hooks';
import { useSnackStack } from '../snack-stack-context';
import { useSingleSourceModel } from '../../hooks/use-single-source-model';
import { CommandError } from '@xspecs/single-source-model';
import { Loading } from '../../components/loading/loading';
import { useOrganizationAndWorkspace } from '../../hooks/useOrganizationAndWorkspace';
import { UrlPreviewModal } from '../../components/single-source-model/url-preview-modal/url-preview-modal';

export const SingleSourceModelWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const { application } = useApplication();

  const isInitializedRef = useRef(false);
  const singleSourceModelId = useReactiveVar(activeOrganizationVar)?.singleSourceModel.id;
  const singleSourceModel = useSingleSourceStore.use.singleSourceModel();
  const isLoaded = useSingleSourceStore.use.isLoaded();
  const { user } = useAuth();
  const errors = useSingleSourceStore.use.errors();
  const { addToast, removeToast, toastsPack } = useSnackStack();
  const model = useSingleSourceModel();
  const [searchParams] = useSearchParams();
  const version = useMemo(() => searchParams.get('version') ?? null, [searchParams]);

  const { workspace, loading } = useOrganizationAndWorkspace();

  const clearError = useCallback(
    (error: CommandError | CommandError[], key: string) => {
      removeToast(key);
      model?.interactor.clearError(error);
    },
    [model?.interactor, removeToast],
  );

  useEffect(() => {
    if (!workspace) return;
    if (isInitializedRef.current) {
      return;
    }
    application.start({
      token: getToken(),
      user,
      scopes: [workspace.id],
      modelId: singleSourceModelId,
      version,
    });
    singleSourceStore.getState().setSingleSourceModel(application.model);
    isInitializedRef.current = true;
  }, [application, singleSourceModelId, user, version, workspace]);

  useEffect(() => {
    if (isLoaded && workspace) application.changeContext({ token: getToken(), user, scopes: [workspace.id] });
  }, [application, isLoaded, user, workspace]);

  useEffect(() => {
    const uniqueNewErrors = errors.slice(-1).filter((err) => !toastsPack.some((toast) => toast.key === err.key));

    if (uniqueNewErrors.length === 0) return;

    uniqueNewErrors.forEach((error) => {
      addToast({
        key: error.key,
        title: error.error.message,
        severity: error.severity,
        onClose: () => clearError(errors, error.key),
      });
    });
  }, [addToast, clearError, errors, toastsPack]);

  useWindowEvent('error', (e) => {
    throw e.error;
  });

  if (loading) {
    return <Loading rootProps={{ height: '100%' }} circularProgressProps={{ size: 24 }} />;
  }

  if (!singleSourceModel) {
    return null;
  }

  return (
    <>
      <ErrorBoundary>{children}</ErrorBoundary>
      <FileStatus fileId={singleSourceModelId} />
      {version ? <PreviewModeBanner version={version} /> : null}
      <UrlPreviewModal />
    </>
  );
};
