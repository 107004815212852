import { AttachmentType, EntityDetailsState, EntityType } from '@xspecs/single-source-model';
import { Box, Stack } from '@mui/material';
import { useMemo } from 'react';
import { Spec } from '../../spec/spec';
import { QueryDetails } from './query-details';
import { DocEditor } from '../../doc/editor/doc-editor';
import { EntityDescription } from '../entity/entity-description/entity-description';
import { EntityHeader } from '../entity/entity-header/entity-header';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';

type EntityDetailsProps = {
  entity: EntityDetailsState;
};

export const EntityDetails = (props: EntityDetailsProps) => {
  const { entity } = props;

  const { isSpec, isDoc, isQuery, isSchema } = useMemo(
    () => ({
      isSpec: Boolean(
        entity.type === EntityType.Spec ||
          (entity.type === EntityType.Attachment && entity.subType === AttachmentType.Spec),
      ),
      isDoc: Boolean(
        entity.type === EntityType.Doc ||
          (entity.type === EntityType.Attachment && entity.subType === AttachmentType.Doc),
      ),
      isQuery: Boolean(
        entity.type === EntityType.Query ||
          (entity.type === EntityType.Attachment && entity.subType === AttachmentType.Query),
      ),
      isSchema: Boolean(
        entity.type === EntityType.Schema ||
          (entity.type === EntityType.Attachment && entity.subType === AttachmentType.Schema),
      ),
    }),
    [entity],
  );

  const Content = useMemo(() => {
    const asset = entity.type === EntityType.Attachment ? entity.asset : entity;
    if (isQuery || isSchema) {
      return <QueryDetails id={asset.id} queryText={entity.queryText} />;
    }

    if (isDoc) {
      return (
        <Box height="100%" border="1px solid #eee" p={1} borderRadius={2}>
          <DocEditor documentId={asset.id} />
        </Box>
      );
    }

    if (isSpec) {
      return (
        <Stack gap={1} height="100%" overflow={'visible'}>
          <Spec id={asset.id} />
        </Stack>
      );
    }

    return null;
  }, [entity, isDoc, isQuery, isSchema, isSpec]);

  const annotator = useSingleSourceStore.use.annotator();

  return (
    <Stack sx={{ padding: 1, height: '100%', overflowY: 'scroll', overflow: 'visible' }} gap={1}>
      <EntityHeader id={entity.id} type={entity.type} name={entity.name} />
      {!isDoc && !isSpec && !isQuery && !isSchema ? <EntityDescription entityId={entity.id} /> : null}
      {annotator?.showAnnotatorView ? null : Content}
    </Stack>
  );
};
