import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { TransientStore } from '../../data/Transient';
import { NotificationTypes } from '../../observable/SingleSourceObserver';
import { CommandError } from '../../ErrorStore';

interface ViewEntityDetailsCommandParams extends IParams {
  entityId: string;
}

interface EntityDetailsViewEventParams extends IParams {
  entityId: string;
}

export class EntityDetailsViewEvent extends EventBase {
  static eventType = 'EntityDetailsViewEvent';

  constructor(public readonly params: EntityDetailsViewEventParams, public readonly source = ViewEntityDetailsCommand) {
    super();
  }
}

export class ViewEntityDetailsCommand extends CommandBase<ViewEntityDetailsCommandParams> {
  execute(params: ViewEntityDetailsCommandParams): EntityDetailsViewEvent | CommandError {
    const entity = this.model.entityRepository.get(params.entityId);
    if (!entity) return CommandError.of(new Error(`Entity with id ${params.entityId} not found`), 'error');
    TransientStore.provider.set('entityDetailView', params.entityId, undefined, true);
    this.model.observer.notify(NotificationTypes.OnUserStateChange, {
      added: [],
      updated: [{ entity, modifiedProperties: [] }],
      deleted: [],
    });
    return new EntityDetailsViewEvent({ entityId: params.entityId });
  }
}
