import { AttachmentType, UploadType } from '@xspecs/single-source-model';
import { Icon } from '../../../../../icons/icon';
import { lightTheme } from '../../../../../themes/light';
import { PhotoOutlined } from '@mui/icons-material';

type AttachableBaseIconProps = {
  type: AttachmentType;
  size: number;
  uploadType?: UploadType;
};

export const AttachmentIcon = (props: AttachableBaseIconProps) => {
  const { type, size, uploadType } = props;

  if (type === AttachmentType.Actor) {
    return <Icon width={size} height={size} name="actor" />;
  }

  if (type === AttachmentType.Spec) {
    return <Icon width={size} height={size} name="spec" color={lightTheme.palette.primary.main} />;
  }

  if (type === AttachmentType.Schema) {
    return <Icon width={size} height={size} name="key-value-pairs" />;
  }

  if (type === AttachmentType.Query) {
    return <Icon width={size} height={size} name="query" color="#D346A7" />;
  }

  if (type === AttachmentType.Doc) {
    return <Icon width={size} height={size} name="doc" color="#4274A2" />;
  }

  if (type === AttachmentType.Upload) {
    if (uploadType === UploadType.Image)
      return <PhotoOutlined width={size} height={size} name="upload" color="action" />;
    return <Icon width={size} height={size} name="upload" color="#4274A2" />;
  }

  return null;
};
