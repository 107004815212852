import { HelpOutlineOutlined } from '@mui/icons-material';
import { PaperProps, Typography } from '@mui/material';
import { Menu, MenuItem } from '@mui/material';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { MouseEvent, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { NavBarIconButton } from '../nav-bar-icon-button';

export const HelpMenu = () => {
  const [helpAnchorEl, setHelpAnchorEl] = useState<HTMLElement | null>(null);
  const showKeyboardModal = useSingleSourceStore.use.setShowKeyboardShortcutModal();

  const { formatMessage: f } = useIntl();

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setHelpAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setHelpAnchorEl(null);
  }, []);

  const openKeyboardShortcuts = useCallback(() => {
    showKeyboardModal(true);
  }, [showKeyboardModal]);

  const handleUserGuidesLink = useCallback(() => {
    window.open('https://www.narrative.tech/user-guide', '_blank');
  }, []);

  return (
    <>
      <NavBarIconButton id="nav-help-button" icon={<HelpOutlineOutlined color="inherit" />} onClick={handleClick} />
      <Menu
        data-testid="nav-help-menu"
        anchorEl={helpAnchorEl}
        open={Boolean(helpAnchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={MenuPaperProps}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        TransitionProps={{
          timeout: { enter: 200, exit: 200 },
          easing: { enter: 'ease-out', exit: 'ease-out' },
        }}
      >
        <MenuItem data-testid="help-menu-user-guides" onClick={handleUserGuidesLink}>
          <Typography variant="body1">{f({ id: 'user-guides' })}</Typography>
        </MenuItem>
        <MenuItem data-testid="help-menu-keyboard-shortcuts" onClick={openKeyboardShortcuts}>
          <Typography variant="body1">{f({ id: 'keyboard-shortcuts' })}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

const MenuPaperProps: Partial<PaperProps> = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    maxWidth: '220px',
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 1,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};
