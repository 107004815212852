export enum RoutePaths {
  AuthReturn = '/auth-return',
  ChooseOrganization = '/choose-organization',
  CreateOrganization = '/create-organization',
  Default = '/',
  Workspace = '/:organizationName/:workspaceName',
  Entity = '/:organizationName/:workspaceName/:entityId',
  Organization = '/:organizationName',
  Settings = '/:organizationName/settings',
  InvitePeople = '/:organizationName/invite-people',
  Login = '/login',
  CreateWorkspace = '/:organizationName/create-workspace',
  ConfirmEmail = '/confirm-email',
  NoAccess = '/no-access',
  Xolvio = '/xolvio',
  SwagShop = '/xolvio/swagshop',
}

export const getRoutePathFromParams = (routePath: RoutePaths, params: Record<string, string>): string => {
  let path = routePath.toString();
  Object.keys(params).forEach((key) => (path = path.replace(`:${key}`, encodeURIComponent(params[key]))));
  return path;
};
