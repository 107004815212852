import { useCallback, useEffect, useState } from 'react';
import { autoUpdate, useFloating, autoPlacement, FloatingPortal } from '@floating-ui/react';
import { isExpanded, useEditorSelection, useEditorState } from '@udecode/plate-common';
import { BalloonMenu } from './balloon-menu/balloon-menu';
import { Box } from '@mui/material';
import { useFloatingLinkSelectors } from '@udecode/plate-link';
import { getSelectionBoundingClientRect } from '@udecode/plate-floating';

export const FloatingBalloonMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const selection = useEditorSelection();
  const editor = useEditorState();

  const isLinkPopupOpen = useFloatingLinkSelectors().isOpen(editor.id);

  const { x, y, strategy, refs } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [autoPlacement({ allowedPlacements: ['top', 'top-end', 'top-start'] })],
    whileElementsMounted: autoUpdate,
  });

  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    setIsOpen(isExpanded(selection));
    refs.setReference({ getBoundingClientRect: getSelectionBoundingClientRect });
  }, [refs, selection]);

  if (!isOpen || isLinkPopupOpen) {
    return null;
  }

  return (
    <FloatingPortal>
      <Box
        data-testid="floating-balloon-menu"
        ref={refs.setFloating}
        sx={{
          position: strategy,
          top: y,
          left: x,
          backgroundColor: 'white',
          border: '1px solid #D8D8D8',
          borderRadius: '8px',
          height: 39,
          overflowX: 'scroll',
          width: '100%',
          maxWidth: 'fit-content',
        }}
      >
        <BalloonMenu closeMenu={closeMenu} />
      </Box>
    </FloatingPortal>
  );
};
