import { useSingleSourceStore } from '../store/single-source-store/single-source-store';

export const useSingleSourceExplorer = () => {
  const { items, error } = useSingleSourceStore.use.explorerResult();

  return {
    explorerItems: items,
    error,
  } as const;
};
