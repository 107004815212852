import { IconComponentProps } from '../icon';

export const NarrativeFilled = ({ color }: IconComponentProps) => {
  return (
    <>
      <g clipPath="url(#clip0_1023_25805)">
        <path
          d="M38.1333 0.493286H1.86667C0.833333 0.493286 0 1.32662 0 2.35995V38.6266C0 39.66 0.833333 40.4933 1.86667 40.4933H38.1333C39.1667 40.4933 40 39.66 40 38.6266V2.35995C40 1.32662 39.1667 0.493286 38.1333 0.493286Z"
          fill={color}
        />
        <path
          d="M28.3335 29.8267H25.6668C25.4802 29.8267 25.3335 29.9733 25.3335 30.16V32.8267C25.3335 33.0133 25.4802 33.16 25.6668 33.16H28.3335C28.5202 33.16 28.6668 33.0133 28.6668 32.8267V30.16C28.6668 29.9733 28.5202 29.8267 28.3335 29.8267Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.5868 17.0466C25.8002 16.8933 25.7935 16.5733 25.5735 16.4266L11.8535 7.21996C11.6335 7.07329 11.3335 7.22663 11.3335 7.49329V10.72C11.3335 10.8333 11.3868 10.9333 11.4802 10.9933L22.7135 18.5C22.9202 18.64 23.1935 18.6333 23.4002 18.4866L25.5935 17.04L25.5868 17.0466Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.3935 21.9599C28.2735 22.0466 27.9202 22.3066 27.9202 22.3066L11.8535 33.0999C11.6335 33.2533 11.3335 33.0999 11.3335 32.8266V29.5999C11.3335 29.4933 11.3868 29.3866 11.4802 29.3266L11.6002 29.2466C11.6002 29.2466 11.6002 29.2399 11.6068 29.2333L12.0802 28.8866L28.1468 18.0866C28.3668 17.9333 28.6668 18.0933 28.6668 18.3599V21.5866C28.6668 21.6999 28.6202 21.8199 28.5268 21.8599L28.3935 21.9533V21.9599Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1023_25805">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </>
  );
};
