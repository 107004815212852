import { FC } from 'react';
import { IconComponentProps } from '../icon';

export const Narrative: FC<IconComponentProps> = ({ color }) => {
  return (
    <>
      <path
        d="M17.8728 11.59L13.1467 6.22314C13.0852 6.15324 13.0095 6.0972 12.9247 6.05874C12.8398 6.02027 12.7478 6.00025 12.6547 6H7.15623C7.02979 6.00034 6.90613 6.0371 6.80004 6.10588C6.69395 6.17466 6.60992 6.27255 6.55801 6.38784C6.5061 6.50312 6.48851 6.63093 6.50734 6.75595C6.52616 6.88098 6.58061 6.99793 6.66417 7.09282L11.0412 12.0248L6.66417 16.9511C6.58061 17.046 6.52616 17.163 6.50734 17.288C6.48851 17.413 6.5061 17.5408 6.55801 17.6561C6.60992 17.7714 6.69395 17.8693 6.80004 17.9381C6.90613 18.0068 7.02979 18.0436 7.15623 18.0439H12.6489C12.7421 18.0437 12.8341 18.0237 12.9189 17.9852C13.0038 17.9467 13.0795 17.8907 13.141 17.8208L17.8728 12.4597C17.9786 12.3395 18.0369 12.1849 18.0369 12.0248C18.0369 11.8647 17.9786 11.7101 17.8728 11.59Z"
        fill={color}
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M2 2H22V22H2V2ZM3 3H21V21H3V3Z" fill={color} />
    </>
  );
};
