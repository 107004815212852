import { ILogger } from './ILogger';

class Logger implements ILogger {
  private static _instance: Logger;

  private constructor() {}

  static getInstance(): ILogger {
    if (this._instance === undefined) this._instance = new Logger();
    return this._instance;
  }

  hasDebug(): boolean {
    return !!(parseInt(process.env.DEBUG) || process.env.DEBUG === 'true' || process.env.DEBUG === 'esdb:*');
  }

  hasTrace(): boolean {
    return !!(parseInt(process.env.TRACE) || process.env.TRACE === 'true' || process.env.TRACE === 'esdb:*');
  }

  debug(message: string, ...args: any[]): void {
    // TODO: debug will not work in the client because process.env is not available in the browser
    // eslint-disable-next-line no-console
    if (this.hasDebug()) console.debug(message, ...args);
  }

  trace(message: string, ...args: any[]): void {
    // TODO: trace will not work in the client because process.env is not available in the browser
    // eslint-disable-next-line no-console
    if (this.hasTrace()) console.debug(message, ...args);
  }

  log(message: string, ...args: any[]): void {
    // eslint-disable-next-line no-console
    console.log(message, ...args);
  }

  warn(message: string, ...args: any[]): void {
    // eslint-disable-next-line no-console
    console.warn(message, ...args);
  }

  error(message: string, ...args: any[]): void {
    // eslint-disable-next-line no-console
    console.error(message, ...args);
  }

  inspect(object: unknown, color = true): void {
    this.log(require('util').inspect(object, false, null, color));
  }
}

export const logger = Logger.getInstance();
