import { ConstructBase } from './ConstructBase';
import { EntityType } from '../EntityType';
import { z } from 'zod';

export class Command extends ConstructBase {
  //static version = '2.0.0'; // renamed Action to Command;
  static version = '2.0.1'; // Replaces transitions with edges;
  static schema = ConstructBase.baseSchema.extend({
    width: z.number().default(80),
  });

  static parse(data: unknown): Command {
    return ConstructBase.parseBase.call(Command, data, Command.schema);
  }

  get type(): EntityType {
    return EntityType.Command;
  }
}
