import { Snackbar as MuiSnackbar, Box } from '@mui/material';
import { FC, useMemo } from 'react';
import { useSnackStack } from '../../wrappers/snack-stack-context';
import { SnackbarToast } from './snack-toast';

export const Snackbar: FC = () => {
  const { toastsPack } = useSnackStack();

  const firstToast = toastsPack[0];

  const anchorOrigin = useMemo(
    () => ({
      vertical: firstToast?.position?.vertical || 'bottom',
      horizontal: firstToast?.position?.horizontal || 'center',
    }),
    [firstToast?.position?.horizontal, firstToast?.position?.vertical],
  );

  const sx = useMemo(
    () => ({
      mt: 'env(safe-area-inset-top)',
      mb: 'env(safe-area-inset-bottom)',
    }),
    [],
  );

  return (
    <MuiSnackbar
      open={Boolean(firstToast)}
      autoHideDuration={null}
      transitionDuration={0}
      anchorOrigin={anchorOrigin}
      sx={sx}
    >
      <Box display="flex" flexDirection="column" gap={1}>
        {toastsPack.map((toast) => (
          <SnackbarToast key={toast.key} toast={toast} />
        ))}
      </Box>
    </MuiSnackbar>
  );
};
