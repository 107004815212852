import { graphql } from '../gql';

export const ORGANIZATIONS_QUERY = graphql(`
  query organizations {
    organizations {
      id
      name
      workspaces {
        id
        name
      }
      allowedEmailDomains {
        domain
      }
      singleSourceModel {
        id
      }
      hasBilling
      hasSubscription
      numberOfSeats
      contentSharingScope
    }
  }
`);

export const CUSTOMER_PORTAL_SESSION = graphql(`
  query customerPortalSession($organizationId: String!) {
    customerPortalSession(organizationId: $organizationId) {
      url
    }
  }
`);

export const FILES_QUERY = graphql(`
  query fileTree($workspaceId: String!) {
    fileTree(workspaceId: $workspaceId) {
      id
      name
      fileType
      children {
        id
        name
        fileType
      }
      parent {
        id
        name
        fileType
      }
    }
  }
`);

export const FILE_SUB_TREE_QUERY = graphql(`
  query fileSubTree($fileId: String!, $workspaceId: String!) {
    fileSubTree(fileId: $fileId, workspaceId: $workspaceId) {
      id
      name
      fileType
      children {
        id
        name
        fileType
      }
      parent {
        id
        name
        fileType
      }
    }
  }
`);

export const FILE_QUERY = graphql(`
  query file($workspaceId: String!, $fileId: String!) {
    file(workspaceId: $workspaceId, fileId: $fileId) {
      id
      name
      fileType
      children {
        id
        name
        fileType
      }
      parent {
        id
        name
        fileType
      }
    }
  }
`);

export const MEDIA_AUTH_QUERY = graphql(`
  query mediaAuth {
    mediaAuth {
      token
    }
  }
`);

export const CHANGESETS_TREE_QUERY = graphql(`
  query changesetTree($workspaceId: String!) {
    changesetTree(workspaceId: $workspaceId) {
      id
      name
      type
      isPublished
      parent {
        id
        name
        type
        isPublished
      }
    }
  }
`);

export const SEARCH_QUERY = graphql(`
  query search($workspaceId: String!, $input: String, $excludeFileTypes: [SearchResultType!]) {
    search(workspaceId: $workspaceId, input: $input, excludeFileTypes: $excludeFileTypes) {
      fileType
      id
      path
      title
    }
  }
`);

export const FILE_CHANGES_QUERY = graphql(`
  query files($fileIds: [String!]!) {
    files(ids: $fileIds) {
      id
      name
      fileType
      committedChanges {
        blockId
        line
        path
        content
        changeType
        changeset {
          id
          name
        }
      }
      publishedChanges {
        line
        path
        blockId
        content
      }
    }
  }
`);

export const CHANGESET_QUERY = graphql(`
  query changeset($changesetId: String!) {
    changeset(id: $changesetId) {
      name
      isPublished
      files {
        fileType
        id
        name
        committedChanges {
          blockId
          line
          path
          content
          changeType
        }
        publishedChanges {
          line
          path
          blockId
          content
        }
      }
    }
  }
`);

export const USERS_QUERY = graphql(`
  query users($organizationId: String!) {
    users(organizationId: $organizationId) {
      email
      id
      name
      picture
      role
    }
  }
`);

export const FILE_UPLOAD_URLS_QUERY = graphql(`
  query signedUploadUrlQuery($parentId: String!) {
    signedMediaUploadUrl(parentId: $parentId) {
      putUrl
      getUrl
      __typename
    }
  }
`);

export const SUBSCRIPTION_PLANS_QUERY = graphql(`
  query subscriptionPlans($organizationId: String!) {
    subscriptionPlans(organizationId: $organizationId) {
      id
      name
      description
      features
      price
      currency
      priceInterval
      canSubscribe
      isCustomPlan
      isCurrentPlan
    }
  }
`);

export const SUBSCRIPTION_SESSION_QUERY = graphql(`
  query subscriptionSession($organizationId: String!, $planId: String!) {
    subscriptionSession(organizationId: $organizationId, planId: $planId) {
      url
    }
  }
`);

export const VERSIONS_QUERY = graphql(`
  query versions($organizationId: String!) {
    versions(organizationId: $organizationId) {
      versionId
      createdAt
      isLatest
      size
    }
  }
`);

export const LINK_PREVIEW_DETAILS_QUERY = graphql(`
  query linkPreview($url: String!) {
    linkPreview(url: $url) {
      title
      imageUrl
      favicon
    }
  }
`);
