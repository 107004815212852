import { SerializedEntity } from '../types';
import { logger } from '@xspecs/logger';
import { EntityBase } from '../entities/EntityBase';

export class ChangeDetector {
  private initialState = new Map<string, SerializedEntity>();
  private modifiedProperties = new Map<string, string[]>();

  setInitialState(entity: EntityBase) {
    this.initialState.set(entity.id, entity.serialize() as SerializedEntity);
  }

  updateState(entity: EntityBase): boolean {
    let updatedProps = [];
    const originalEntity: SerializedEntity = this.initialState.get(entity.id);
    if (!originalEntity) {
      logger.warn('ChangeDetector:updateState no initial state found for entity', entity.id);
      return false;
    }
    const currentEntity = entity.serialize() as SerializedEntity;
    updatedProps = this.findModifiedProperties(originalEntity, currentEntity);
    if (updatedProps.length === 0) {
      return false;
    }
    const existingProperties = new Set(this.modifiedProperties.get(entity.id) || []);
    updatedProps.forEach((prop) => existingProperties.add(prop));
    this.modifiedProperties.set(entity.id, Array.from(existingProperties));

    this.setInitialState(entity);
    return true;
  }

  removeFromInitialState(entityId: string) {
    this.initialState.delete(entityId);
    this.modifiedProperties.delete(entityId);
  }

  clear() {
    this.initialState.clear();
    this.modifiedProperties.clear();
  }

  getModifiedProperties(entityId: string): string[] {
    return this.modifiedProperties.get(entityId) || [];
  }

  private findModifiedProperties(original: SerializedEntity, current: SerializedEntity, basePath = ''): string[] {
    let updatedProps = [];
    const allKeys = new Set([...Object.keys(original), ...Object.keys(current)]);

    for (const key of allKeys) {
      const fullPath = basePath ? `${basePath}.${key}` : key;
      if (typeof original[key] !== 'object' && typeof current[key] !== 'object') {
        if (original[key] !== current[key]) {
          updatedProps.push(fullPath);
        }
      } else if (Array.isArray(original[key]) || Array.isArray(current[key])) {
        if (!this.areArraysEqual(original[key], current[key])) {
          updatedProps.push(fullPath);
        }
      } else {
        if (!this.deepEqual(original[key], current[key])) {
          updatedProps = [
            ...updatedProps,
            ...this.findModifiedProperties(original[key] || {}, current[key] || {}, fullPath),
          ];
        }
      }
    }
    return updatedProps;
  }

  private areArraysEqual(arr1 = [], arr2 = []): boolean {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (!this.deepEqual(arr1[i], arr2[i])) return false;
    }
    return true;
  }

  private deepEqual(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) return true;
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
      return false;
    }
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) return false;
    for (const key of keys1) {
      if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) return false;
    }
    return true;
  }
}
