/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  mutation addOrganization($args: AddOrganizationArgs!) {\n    addOrganization(args: $args) {\n      error\n    }\n  }\n':
    types.AddOrganizationDocument,
  '\n  mutation addWorkspace($args: AddWorkspaceArgs!) {\n    addWorkspace(args: $args) {\n      error\n    }\n  }\n':
    types.AddWorkspaceDocument,
  '\n  mutation inviteUsers($args: InviteUsersArgs!) {\n    inviteUsers(args: $args) {\n      error\n    }\n  }\n':
    types.InviteUsersDocument,
  '\n  mutation verifyEmail {\n    verifyEmail {\n      error\n    }\n  }\n': types.VerifyEmailDocument,
  '\n  mutation acceptInvite($args: AcceptInviteArgs!) {\n    acceptInvite(args: $args) {\n      error\n    }\n  }\n':
    types.AcceptInviteDocument,
  '\n  mutation linkNewSpecToDoc($args: LinkNewSpecToDocArgs!) {\n    linkNewSpecToDoc(args: $args) {\n      error\n    }\n  }\n':
    types.LinkNewSpecToDocDocument,
  '\n  mutation linkExistingSpecToDoc($args: LinkExistingSpecToDocArgs!) {\n    linkExistingSpecToDoc(args: $args) {\n      error\n    }\n  }\n':
    types.LinkExistingSpecToDocDocument,
  '\n  mutation unlinkSpecFromDoc($args: UnlinkSpecFromDocArgs!) {\n    unlinkSpecFromDoc(args: $args) {\n      error\n    }\n  }\n':
    types.UnlinkSpecFromDocDocument,
  '\n  mutation deleteWorkspace($args: DeleteWorkspaceArgs!) {\n    deleteWorkspace(args: $args) {\n      error\n    }\n  }\n':
    types.DeleteWorkspaceDocument,
  '\n  mutation renameWorkspace($args: RenameWorkspaceArgs!) {\n    renameWorkspace(args: $args) {\n      error\n    }\n  }\n':
    types.RenameWorkspaceDocument,
  '\n  mutation deleteOrganization($args: DeleteOrganizationArgs!) {\n    deleteOrganization(args: $args) {\n      error\n    }\n  }\n':
    types.DeleteOrganizationDocument,
  '\n  mutation renameOrganization($args: RenameOrganizationArgs!) {\n    renameOrganization(args: $args) {\n      error\n    }\n  }\n':
    types.RenameOrganizationDocument,
  '\n  mutation updateUserRole($args: UpdateUserRoleArgs!) {\n    updateUserRole(args: $args) {\n      error\n    }\n  }\n':
    types.UpdateUserRoleDocument,
  '\n  mutation removeUserFromOrganization($args: RemoveUserFromOrganizationArgs!) {\n    removeUserFromOrganization(args: $args) {\n      error\n    }\n  }\n':
    types.RemoveUserFromOrganizationDocument,
  '\n  mutation updateOrganizationEmailDomains($args: UpdateOrganizationEmailDomainsArgs!) {\n    updateOrganizationEmailDomains(args: $args) {\n      error\n    }\n  }\n':
    types.UpdateOrganizationEmailDomainsDocument,
  '\n  mutation renameFile($args: RenameFileArgs!) {\n    renameFile(args: $args) {\n      error\n    }\n  }\n':
    types.RenameFileDocument,
  '\n  mutation moveFile($args: MoveFileArgs!) {\n    moveFile(args: $args) {\n      error\n    }\n  }\n':
    types.MoveFileDocument,
  '\n  mutation addFile($args: AddFileArgs!) {\n    addFile(args: $args) {\n      error\n    }\n  }\n':
    types.AddFileDocument,
  '\n  mutation deleteFile($args: DeleteFileArgs!) {\n    deleteFile(args: $args) {\n      error\n    }\n  }\n':
    types.DeleteFileDocument,
  '\n  mutation mentionUsers($args: MentionUsersArgs!) {\n    mentionUsers(args: $args) {\n      error\n    }\n  }\n':
    types.MentionUsersDocument,
  '\n  mutation resolveThread($args: ResolveThreadArgs!) {\n    resolveThread(args: $args) {\n      data\n      error\n    }\n  }\n':
    types.ResolveThreadDocument,
  '\n  mutation makeSuggestion($args: SuggestionArgs!) {\n    makeSuggestion(args: $args) {\n      error\n      data\n    }\n  }\n':
    types.MakeSuggestionDocument,
  '\n  mutation restoreVersion($args: RestoreVersionArgs!) {\n    restoreVersion(args: $args) {\n      error\n    }\n  }\n':
    types.RestoreVersionDocument,
  '\n  mutation updateContentSharingScope($args: UpdateContentSharingScopeArgs!) {\n    updateContentSharingScope(args: $args) {\n      error\n    }\n  }\n':
    types.UpdateContentSharingScopeDocument,
  '\n  query organizations {\n    organizations {\n      id\n      name\n      workspaces {\n        id\n        name\n      }\n      allowedEmailDomains {\n        domain\n      }\n      singleSourceModel {\n        id\n      }\n      hasBilling\n      hasSubscription\n      numberOfSeats\n      contentSharingScope\n    }\n  }\n':
    types.OrganizationsDocument,
  '\n  query customerPortalSession($organizationId: String!) {\n    customerPortalSession(organizationId: $organizationId) {\n      url\n    }\n  }\n':
    types.CustomerPortalSessionDocument,
  '\n  query fileTree($workspaceId: String!) {\n    fileTree(workspaceId: $workspaceId) {\n      id\n      name\n      fileType\n      children {\n        id\n        name\n        fileType\n      }\n      parent {\n        id\n        name\n        fileType\n      }\n    }\n  }\n':
    types.FileTreeDocument,
  '\n  query fileSubTree($fileId: String!, $workspaceId: String!) {\n    fileSubTree(fileId: $fileId, workspaceId: $workspaceId) {\n      id\n      name\n      fileType\n      children {\n        id\n        name\n        fileType\n      }\n      parent {\n        id\n        name\n        fileType\n      }\n    }\n  }\n':
    types.FileSubTreeDocument,
  '\n  query file($workspaceId: String!, $fileId: String!) {\n    file(workspaceId: $workspaceId, fileId: $fileId) {\n      id\n      name\n      fileType\n      children {\n        id\n        name\n        fileType\n      }\n      parent {\n        id\n        name\n        fileType\n      }\n    }\n  }\n':
    types.FileDocument,
  '\n  query mediaAuth {\n    mediaAuth {\n      token\n    }\n  }\n': types.MediaAuthDocument,
  '\n  query changesetTree($workspaceId: String!) {\n    changesetTree(workspaceId: $workspaceId) {\n      id\n      name\n      type\n      isPublished\n      parent {\n        id\n        name\n        type\n        isPublished\n      }\n    }\n  }\n':
    types.ChangesetTreeDocument,
  '\n  query search($workspaceId: String!, $input: String, $excludeFileTypes: [SearchResultType!]) {\n    search(workspaceId: $workspaceId, input: $input, excludeFileTypes: $excludeFileTypes) {\n      fileType\n      id\n      path\n      title\n    }\n  }\n':
    types.SearchDocument,
  '\n  query files($fileIds: [String!]!) {\n    files(ids: $fileIds) {\n      id\n      name\n      fileType\n      committedChanges {\n        blockId\n        line\n        path\n        content\n        changeType\n        changeset {\n          id\n          name\n        }\n      }\n      publishedChanges {\n        line\n        path\n        blockId\n        content\n      }\n    }\n  }\n':
    types.FilesDocument,
  '\n  query changeset($changesetId: String!) {\n    changeset(id: $changesetId) {\n      name\n      isPublished\n      files {\n        fileType\n        id\n        name\n        committedChanges {\n          blockId\n          line\n          path\n          content\n          changeType\n        }\n        publishedChanges {\n          line\n          path\n          blockId\n          content\n        }\n      }\n    }\n  }\n':
    types.ChangesetDocument,
  '\n  query users($organizationId: String!) {\n    users(organizationId: $organizationId) {\n      email\n      id\n      name\n      picture\n      role\n    }\n  }\n':
    types.UsersDocument,
  '\n  query signedUploadUrlQuery($parentId: String!) {\n    signedMediaUploadUrl(parentId: $parentId) {\n      putUrl\n      getUrl\n      __typename\n    }\n  }\n':
    types.SignedUploadUrlQueryDocument,
  '\n  query subscriptionPlans($organizationId: String!) {\n    subscriptionPlans(organizationId: $organizationId) {\n      id\n      name\n      description\n      features\n      price\n      currency\n      priceInterval\n      canSubscribe\n      isCustomPlan\n      isCurrentPlan\n    }\n  }\n':
    types.SubscriptionPlansDocument,
  '\n  query subscriptionSession($organizationId: String!, $planId: String!) {\n    subscriptionSession(organizationId: $organizationId, planId: $planId) {\n      url\n    }\n  }\n':
    types.SubscriptionSessionDocument,
  '\n  query versions($organizationId: String!) {\n    versions(organizationId: $organizationId) {\n      versionId\n      createdAt\n      isLatest\n      size\n    }\n  }\n':
    types.VersionsDocument,
  '\n  query linkPreview($url: String!) {\n    linkPreview(url: $url) {\n      title\n      imageUrl\n      favicon\n    }\n  }\n':
    types.LinkPreviewDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation addOrganization($args: AddOrganizationArgs!) {\n    addOrganization(args: $args) {\n      error\n    }\n  }\n',
): (typeof documents)['\n  mutation addOrganization($args: AddOrganizationArgs!) {\n    addOrganization(args: $args) {\n      error\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation addWorkspace($args: AddWorkspaceArgs!) {\n    addWorkspace(args: $args) {\n      error\n    }\n  }\n',
): (typeof documents)['\n  mutation addWorkspace($args: AddWorkspaceArgs!) {\n    addWorkspace(args: $args) {\n      error\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation inviteUsers($args: InviteUsersArgs!) {\n    inviteUsers(args: $args) {\n      error\n    }\n  }\n',
): (typeof documents)['\n  mutation inviteUsers($args: InviteUsersArgs!) {\n    inviteUsers(args: $args) {\n      error\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation verifyEmail {\n    verifyEmail {\n      error\n    }\n  }\n',
): (typeof documents)['\n  mutation verifyEmail {\n    verifyEmail {\n      error\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation acceptInvite($args: AcceptInviteArgs!) {\n    acceptInvite(args: $args) {\n      error\n    }\n  }\n',
): (typeof documents)['\n  mutation acceptInvite($args: AcceptInviteArgs!) {\n    acceptInvite(args: $args) {\n      error\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation linkNewSpecToDoc($args: LinkNewSpecToDocArgs!) {\n    linkNewSpecToDoc(args: $args) {\n      error\n    }\n  }\n',
): (typeof documents)['\n  mutation linkNewSpecToDoc($args: LinkNewSpecToDocArgs!) {\n    linkNewSpecToDoc(args: $args) {\n      error\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation linkExistingSpecToDoc($args: LinkExistingSpecToDocArgs!) {\n    linkExistingSpecToDoc(args: $args) {\n      error\n    }\n  }\n',
): (typeof documents)['\n  mutation linkExistingSpecToDoc($args: LinkExistingSpecToDocArgs!) {\n    linkExistingSpecToDoc(args: $args) {\n      error\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation unlinkSpecFromDoc($args: UnlinkSpecFromDocArgs!) {\n    unlinkSpecFromDoc(args: $args) {\n      error\n    }\n  }\n',
): (typeof documents)['\n  mutation unlinkSpecFromDoc($args: UnlinkSpecFromDocArgs!) {\n    unlinkSpecFromDoc(args: $args) {\n      error\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteWorkspace($args: DeleteWorkspaceArgs!) {\n    deleteWorkspace(args: $args) {\n      error\n    }\n  }\n',
): (typeof documents)['\n  mutation deleteWorkspace($args: DeleteWorkspaceArgs!) {\n    deleteWorkspace(args: $args) {\n      error\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation renameWorkspace($args: RenameWorkspaceArgs!) {\n    renameWorkspace(args: $args) {\n      error\n    }\n  }\n',
): (typeof documents)['\n  mutation renameWorkspace($args: RenameWorkspaceArgs!) {\n    renameWorkspace(args: $args) {\n      error\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteOrganization($args: DeleteOrganizationArgs!) {\n    deleteOrganization(args: $args) {\n      error\n    }\n  }\n',
): (typeof documents)['\n  mutation deleteOrganization($args: DeleteOrganizationArgs!) {\n    deleteOrganization(args: $args) {\n      error\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation renameOrganization($args: RenameOrganizationArgs!) {\n    renameOrganization(args: $args) {\n      error\n    }\n  }\n',
): (typeof documents)['\n  mutation renameOrganization($args: RenameOrganizationArgs!) {\n    renameOrganization(args: $args) {\n      error\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateUserRole($args: UpdateUserRoleArgs!) {\n    updateUserRole(args: $args) {\n      error\n    }\n  }\n',
): (typeof documents)['\n  mutation updateUserRole($args: UpdateUserRoleArgs!) {\n    updateUserRole(args: $args) {\n      error\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation removeUserFromOrganization($args: RemoveUserFromOrganizationArgs!) {\n    removeUserFromOrganization(args: $args) {\n      error\n    }\n  }\n',
): (typeof documents)['\n  mutation removeUserFromOrganization($args: RemoveUserFromOrganizationArgs!) {\n    removeUserFromOrganization(args: $args) {\n      error\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateOrganizationEmailDomains($args: UpdateOrganizationEmailDomainsArgs!) {\n    updateOrganizationEmailDomains(args: $args) {\n      error\n    }\n  }\n',
): (typeof documents)['\n  mutation updateOrganizationEmailDomains($args: UpdateOrganizationEmailDomainsArgs!) {\n    updateOrganizationEmailDomains(args: $args) {\n      error\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation renameFile($args: RenameFileArgs!) {\n    renameFile(args: $args) {\n      error\n    }\n  }\n',
): (typeof documents)['\n  mutation renameFile($args: RenameFileArgs!) {\n    renameFile(args: $args) {\n      error\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation moveFile($args: MoveFileArgs!) {\n    moveFile(args: $args) {\n      error\n    }\n  }\n',
): (typeof documents)['\n  mutation moveFile($args: MoveFileArgs!) {\n    moveFile(args: $args) {\n      error\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation addFile($args: AddFileArgs!) {\n    addFile(args: $args) {\n      error\n    }\n  }\n',
): (typeof documents)['\n  mutation addFile($args: AddFileArgs!) {\n    addFile(args: $args) {\n      error\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deleteFile($args: DeleteFileArgs!) {\n    deleteFile(args: $args) {\n      error\n    }\n  }\n',
): (typeof documents)['\n  mutation deleteFile($args: DeleteFileArgs!) {\n    deleteFile(args: $args) {\n      error\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation mentionUsers($args: MentionUsersArgs!) {\n    mentionUsers(args: $args) {\n      error\n    }\n  }\n',
): (typeof documents)['\n  mutation mentionUsers($args: MentionUsersArgs!) {\n    mentionUsers(args: $args) {\n      error\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation resolveThread($args: ResolveThreadArgs!) {\n    resolveThread(args: $args) {\n      data\n      error\n    }\n  }\n',
): (typeof documents)['\n  mutation resolveThread($args: ResolveThreadArgs!) {\n    resolveThread(args: $args) {\n      data\n      error\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation makeSuggestion($args: SuggestionArgs!) {\n    makeSuggestion(args: $args) {\n      error\n      data\n    }\n  }\n',
): (typeof documents)['\n  mutation makeSuggestion($args: SuggestionArgs!) {\n    makeSuggestion(args: $args) {\n      error\n      data\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation restoreVersion($args: RestoreVersionArgs!) {\n    restoreVersion(args: $args) {\n      error\n    }\n  }\n',
): (typeof documents)['\n  mutation restoreVersion($args: RestoreVersionArgs!) {\n    restoreVersion(args: $args) {\n      error\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateContentSharingScope($args: UpdateContentSharingScopeArgs!) {\n    updateContentSharingScope(args: $args) {\n      error\n    }\n  }\n',
): (typeof documents)['\n  mutation updateContentSharingScope($args: UpdateContentSharingScopeArgs!) {\n    updateContentSharingScope(args: $args) {\n      error\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query organizations {\n    organizations {\n      id\n      name\n      workspaces {\n        id\n        name\n      }\n      allowedEmailDomains {\n        domain\n      }\n      singleSourceModel {\n        id\n      }\n      hasBilling\n      hasSubscription\n      numberOfSeats\n      contentSharingScope\n    }\n  }\n',
): (typeof documents)['\n  query organizations {\n    organizations {\n      id\n      name\n      workspaces {\n        id\n        name\n      }\n      allowedEmailDomains {\n        domain\n      }\n      singleSourceModel {\n        id\n      }\n      hasBilling\n      hasSubscription\n      numberOfSeats\n      contentSharingScope\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query customerPortalSession($organizationId: String!) {\n    customerPortalSession(organizationId: $organizationId) {\n      url\n    }\n  }\n',
): (typeof documents)['\n  query customerPortalSession($organizationId: String!) {\n    customerPortalSession(organizationId: $organizationId) {\n      url\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query fileTree($workspaceId: String!) {\n    fileTree(workspaceId: $workspaceId) {\n      id\n      name\n      fileType\n      children {\n        id\n        name\n        fileType\n      }\n      parent {\n        id\n        name\n        fileType\n      }\n    }\n  }\n',
): (typeof documents)['\n  query fileTree($workspaceId: String!) {\n    fileTree(workspaceId: $workspaceId) {\n      id\n      name\n      fileType\n      children {\n        id\n        name\n        fileType\n      }\n      parent {\n        id\n        name\n        fileType\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query fileSubTree($fileId: String!, $workspaceId: String!) {\n    fileSubTree(fileId: $fileId, workspaceId: $workspaceId) {\n      id\n      name\n      fileType\n      children {\n        id\n        name\n        fileType\n      }\n      parent {\n        id\n        name\n        fileType\n      }\n    }\n  }\n',
): (typeof documents)['\n  query fileSubTree($fileId: String!, $workspaceId: String!) {\n    fileSubTree(fileId: $fileId, workspaceId: $workspaceId) {\n      id\n      name\n      fileType\n      children {\n        id\n        name\n        fileType\n      }\n      parent {\n        id\n        name\n        fileType\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query file($workspaceId: String!, $fileId: String!) {\n    file(workspaceId: $workspaceId, fileId: $fileId) {\n      id\n      name\n      fileType\n      children {\n        id\n        name\n        fileType\n      }\n      parent {\n        id\n        name\n        fileType\n      }\n    }\n  }\n',
): (typeof documents)['\n  query file($workspaceId: String!, $fileId: String!) {\n    file(workspaceId: $workspaceId, fileId: $fileId) {\n      id\n      name\n      fileType\n      children {\n        id\n        name\n        fileType\n      }\n      parent {\n        id\n        name\n        fileType\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query mediaAuth {\n    mediaAuth {\n      token\n    }\n  }\n',
): (typeof documents)['\n  query mediaAuth {\n    mediaAuth {\n      token\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query changesetTree($workspaceId: String!) {\n    changesetTree(workspaceId: $workspaceId) {\n      id\n      name\n      type\n      isPublished\n      parent {\n        id\n        name\n        type\n        isPublished\n      }\n    }\n  }\n',
): (typeof documents)['\n  query changesetTree($workspaceId: String!) {\n    changesetTree(workspaceId: $workspaceId) {\n      id\n      name\n      type\n      isPublished\n      parent {\n        id\n        name\n        type\n        isPublished\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query search($workspaceId: String!, $input: String, $excludeFileTypes: [SearchResultType!]) {\n    search(workspaceId: $workspaceId, input: $input, excludeFileTypes: $excludeFileTypes) {\n      fileType\n      id\n      path\n      title\n    }\n  }\n',
): (typeof documents)['\n  query search($workspaceId: String!, $input: String, $excludeFileTypes: [SearchResultType!]) {\n    search(workspaceId: $workspaceId, input: $input, excludeFileTypes: $excludeFileTypes) {\n      fileType\n      id\n      path\n      title\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query files($fileIds: [String!]!) {\n    files(ids: $fileIds) {\n      id\n      name\n      fileType\n      committedChanges {\n        blockId\n        line\n        path\n        content\n        changeType\n        changeset {\n          id\n          name\n        }\n      }\n      publishedChanges {\n        line\n        path\n        blockId\n        content\n      }\n    }\n  }\n',
): (typeof documents)['\n  query files($fileIds: [String!]!) {\n    files(ids: $fileIds) {\n      id\n      name\n      fileType\n      committedChanges {\n        blockId\n        line\n        path\n        content\n        changeType\n        changeset {\n          id\n          name\n        }\n      }\n      publishedChanges {\n        line\n        path\n        blockId\n        content\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query changeset($changesetId: String!) {\n    changeset(id: $changesetId) {\n      name\n      isPublished\n      files {\n        fileType\n        id\n        name\n        committedChanges {\n          blockId\n          line\n          path\n          content\n          changeType\n        }\n        publishedChanges {\n          line\n          path\n          blockId\n          content\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query changeset($changesetId: String!) {\n    changeset(id: $changesetId) {\n      name\n      isPublished\n      files {\n        fileType\n        id\n        name\n        committedChanges {\n          blockId\n          line\n          path\n          content\n          changeType\n        }\n        publishedChanges {\n          line\n          path\n          blockId\n          content\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query users($organizationId: String!) {\n    users(organizationId: $organizationId) {\n      email\n      id\n      name\n      picture\n      role\n    }\n  }\n',
): (typeof documents)['\n  query users($organizationId: String!) {\n    users(organizationId: $organizationId) {\n      email\n      id\n      name\n      picture\n      role\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query signedUploadUrlQuery($parentId: String!) {\n    signedMediaUploadUrl(parentId: $parentId) {\n      putUrl\n      getUrl\n      __typename\n    }\n  }\n',
): (typeof documents)['\n  query signedUploadUrlQuery($parentId: String!) {\n    signedMediaUploadUrl(parentId: $parentId) {\n      putUrl\n      getUrl\n      __typename\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query subscriptionPlans($organizationId: String!) {\n    subscriptionPlans(organizationId: $organizationId) {\n      id\n      name\n      description\n      features\n      price\n      currency\n      priceInterval\n      canSubscribe\n      isCustomPlan\n      isCurrentPlan\n    }\n  }\n',
): (typeof documents)['\n  query subscriptionPlans($organizationId: String!) {\n    subscriptionPlans(organizationId: $organizationId) {\n      id\n      name\n      description\n      features\n      price\n      currency\n      priceInterval\n      canSubscribe\n      isCustomPlan\n      isCurrentPlan\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query subscriptionSession($organizationId: String!, $planId: String!) {\n    subscriptionSession(organizationId: $organizationId, planId: $planId) {\n      url\n    }\n  }\n',
): (typeof documents)['\n  query subscriptionSession($organizationId: String!, $planId: String!) {\n    subscriptionSession(organizationId: $organizationId, planId: $planId) {\n      url\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query versions($organizationId: String!) {\n    versions(organizationId: $organizationId) {\n      versionId\n      createdAt\n      isLatest\n      size\n    }\n  }\n',
): (typeof documents)['\n  query versions($organizationId: String!) {\n    versions(organizationId: $organizationId) {\n      versionId\n      createdAt\n      isLatest\n      size\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query linkPreview($url: String!) {\n    linkPreview(url: $url) {\n      title\n      imageUrl\n      favicon\n    }\n  }\n',
): (typeof documents)['\n  query linkPreview($url: String!) {\n    linkPreview(url: $url) {\n      title\n      imageUrl\n      favicon\n    }\n  }\n'];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<
  infer TType,
  any
>
  ? TType
  : never;
