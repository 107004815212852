import { SingleSourceModelToolbarButtonProps } from './single-source-model-toolbar-button.types';
import { forwardRef } from 'react';
import { IconButton, Tooltip, Typography } from '@mui/material';

export const SingleSourceModelToolbarButton = forwardRef<HTMLButtonElement, SingleSourceModelToolbarButtonProps>(
  (props, ref) => {
    const { Icon, isActive, label, tooltip, ...rest } = props;

    return (
      <Tooltip title={tooltip} disableInteractive aria-label={tooltip}>
        <IconButton
          disableRipple
          aria-label={label}
          sx={{
            backgroundColor: isActive && 'action.selected',
            borderRadius: 0.5,
            transition: 'background-color 0.3s',
            gap: 1,
            '&:hover': {
              backgroundColor: 'action.hover',
            },
          }}
          ref={ref}
          {...rest}
        >
          {Icon}
          {label ? <Typography color="text.primary">{label}</Typography> : null}
        </IconButton>
      </Tooltip>
    );
  },
);

SingleSourceModelToolbarButton.displayName = 'SingleSourceModelToolbarButton';
