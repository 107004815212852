import { CursorOverlayData } from '@slate-yjs/react';
import { CursorData } from '../../types/editor-common.types';
import React, { CSSProperties, Fragment } from 'react';
import { addAlpha } from '../../utils';
import { RemoteUserCaret } from '../remote-user-caret/remote-user-caret';

export type RemoteUserSelectionProps = CursorOverlayData<CursorData>;

export const RemoteUserSelection = (props: RemoteUserSelectionProps) => {
  const { data, selectionRects, caretPosition } = props;

  if (!data) {
    return null;
  }

  const selectionStyle: CSSProperties = { backgroundColor: addAlpha(data.color, 0.5) };

  return (
    <Fragment>
      {selectionRects.map((position, i) => (
        <div
          data-testid={`RemoveUserSelection${i}`}
          style={{ ...selectionStyle, ...position, position: 'absolute', pointerEvents: 'none' }}
          key={i}
        />
      ))}
      {caretPosition ? <RemoteUserCaret caretPosition={caretPosition} data={data} /> : null}
    </Fragment>
  );
};
