import { AssetBase } from './AssetBase';
import { EntityType } from '../EntityType';
import { GqlEntityBase } from '../gql-entities/GqlEntityBase';
import { z } from 'zod';
import { Upload } from './Upload';
import { DocumentNode, Kind, OperationDefinitionNode, OperationTypeNode, parse, print, visit } from 'graphql';
import { GqlOperation } from '../gql-entities/GqlOperation';
import { GqlField } from '../gql-entities/GqlField';
import { sid } from '@xspecs/short-id';
import { EntityChangeset, UpdatedEntity } from '../../types';

type Annotation = {
  width: number;
  height: number;
  position: {
    x: number;
    y: number;
  };
  url: string;
};

type ViewPort = {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
};

export class Query extends AssetBase {
  get type(): EntityType {
    return EntityType.Query;
  }

  static schema = AssetBase.baseSchema.extend({
    upload: Upload.baseSchema.optional(),
    gqlEntities: GqlEntityBase.baseSchema.array().optional().default([]),
    ast: z.string().optional(),
    queryText: z.string().optional().default(''),
    annotation: z
      .object({
        width: z.number().optional().default(200),
        height: z.number().optional().default(200),
        position: z
          .object({
            x: z.number(),
            y: z.number(),
          })
          .optional(),
        url: z.string().optional(),
      })
      .optional(),
  });

  public upload: Upload;
  public gqlEntities: GqlEntityBase[] = [];
  public ast: string;
  public annotation: Annotation;
  public queryText: string;

  public addGqlEntity(entity: GqlEntityBase, viewPort: ViewPort = undefined): void {
    const existing = this.gqlEntities.find((e) => e.id === entity.id);
    if (existing) return;
    if (entity.position.x === 0 && entity.position.y === 0) entity.position = this.getNextEntityPosition(viewPort);
    if (!entity.parent) {
      if (entity instanceof GqlOperation) {
        entity.parent = this;
      } else {
        const parent = this.gqlEntities.find((e) => e instanceof GqlOperation) as GqlOperation;
        if (parent) {
          entity.parent = parent;
        }
      }
    }
    this.gqlEntities.push(entity);
  }

  public removeGqlEntity(entity: GqlEntityBase): void {
    const index = this.gqlEntities.findIndex((e) => e.id === entity.id);
    if (index === -1) return;
    this.gqlEntities.splice(index, 1);
  }

  public setAnnotation(annotation: Annotation): void {
    this.annotation = annotation;
  }

  public linkAsset(upload: Upload): void | Error {
    this.upload = upload;
    upload.parent = this;
    if (this.annotation) {
      this.annotation.url = undefined;
    }
  }

  public unlinkAsset(): void {
    this.upload.parent = undefined;
    this.upload = undefined;
    if (this.annotation) {
      this.annotation.url = undefined;
    }
  }

  public get operationName(): string {
    const invalidCharRegex = /[^a-zA-Z0-9_]/g;
    let validName = this.name.replace(invalidCharRegex, '_');
    validName = validName.replace(/^_+|_+$/g, '');
    if (!validName) {
      validName = 'UnnamedOperation';
    }
    return validName;
  }

  public syncQueryToGqlEntities(query: string, viewPort: ViewPort = undefined): EntityChangeset {
    const added: GqlEntityBase[] = [];
    const deleted: GqlEntityBase[] = [];
    const updated: UpdatedEntity[] = [];

    const emptyChangeset = this.handleEmptyQuery(query);
    if (emptyChangeset) {
      return emptyChangeset;
    }
    const emptyOperationChangeset = this.handleEmptyOperation(query);
    if (emptyOperationChangeset) {
      return emptyOperationChangeset;
    }

    let ast: DocumentNode;
    try {
      ast = parse(query);
    } catch (error) {
      return { added, updated, deleted };
    }

    const pathToEntityMap = new Map<string, GqlEntityBase>();
    this.gqlEntities.forEach((entity) => {
      pathToEntityMap.set(entity.path ?? entity.name, entity);
    });

    const parentStack: (GqlOperation | GqlField)[] = [];
    const parsedEntities: GqlEntityBase[] = [];
    const existingFieldCount = this.gqlEntities.filter((e) => e instanceof GqlField).length;
    let parsedFieldCount = 0;

    //  process Operations
    //this.processOperations(ast, parsedFieldCount);
    parsedFieldCount = this.countAstFieldNodes(ast);

    // handle additions
    if (parsedFieldCount > existingFieldCount) {
      this.processAdditions(ast, parentStack, parsedEntities, pathToEntityMap, added, viewPort);
      this.reorderEntitiesBasedOnAST(ast);
    }

    // Clear and Rebuild entityMap for second pass
    pathToEntityMap.clear();
    this.gqlEntities.forEach((entity) => {
      pathToEntityMap.set(entity.path, entity);
    });

    // Second Pass: Handle Updates
    visit(ast, {
      OperationDefinition: {
        enter: (node) => {
          const operationName = node.name?.value || '';
          const operation = this.gqlEntities[0];
          if (operation.name != operationName) {
            operation.name = operationName;
            updated.push({ entity: operation, modifiedProperties: ['name'] });
          }
          parentStack.push(operation);
          parsedEntities.push(operation);
        },
        leave: () => {
          parentStack.pop();
        },
      },
      Field: {
        enter: (node) => {
          const fieldName = node.name.value;
          const parent = parentStack[parentStack.length - 1];
          const path = this.getPathForField(parent, fieldName);
          let field = pathToEntityMap.get(path) as GqlField;
          const existingEntity = this.gqlEntities[parsedEntities.length] as GqlField;

          if (parent instanceof GqlOperation || parent instanceof GqlField) {
            if (existingEntity && existingEntity instanceof GqlField && existingEntity.parent === parent) {
              if (existingEntity.name !== fieldName) {
                existingEntity.name = fieldName;
                updated.push({ entity: existingEntity, modifiedProperties: ['name'] });
              }
              field = existingEntity;
            }
          }
          parentStack.push(field);
          parsedEntities.push(field);
        },
        leave: () => {
          parentStack.pop();
        },
      },
    });

    // Handle deletions by comparing existing `gqlEntities` to `parsedEntities`
    for (let i = 0; i < this.gqlEntities.length; i++) {
      const currentEntity = this.gqlEntities[i];
      const parsedEntity = parsedEntities[i];
      if (currentEntity && !parsedEntity && currentEntity.name !== '') {
        deleted.push(currentEntity);
        this.removeGqlEntity(currentEntity);
      }
    }

    // Remove empty operations
    for (const entity of [...this.gqlEntities]) {
      if (entity instanceof GqlOperation) {
        const operationFields = this.gqlEntities.filter((e) => e.parent === entity);
        if (operationFields.length === 0) {
          this.removeGqlEntity(entity);
          deleted.push(entity);
        }
      }
    }

    this.ast = JSON.stringify(ast);
    return { added, updated, deleted };
  }

  private getPathForField(parent: GqlOperation | GqlField, fieldName: string) {
    if (!parent) {
      return fieldName;
    }
    return parent instanceof GqlOperation ? fieldName : `${parent.path}.${fieldName}`;
  }

  /**
   * Processes the operations and counts fields
   * @param ast - The parsed AST document.
   * @param parsedFieldCount - The counter for parsed fields.
   */
  private processOperations(ast: DocumentNode, parsedFieldCount: number) {
    ast.definitions.forEach((definition) => {
      if (definition.kind === 'OperationDefinition') {
        const operationName = (definition.name && definition.name.value) || 'defaultOperation';
        const operation = this.gqlEntities.find((entity) => entity.name === operationName) as GqlOperation;

        if (!operation) {
          const newOperation = GqlOperation.parse({
            id: sid(),
            name: operationName,
            position: { x: 0, y: 0 },
            scopes: this.scopes,
          });
          newOperation.parent = this;
          this.addGqlEntity(newOperation);
        }
      }
      this.traverseFields(definition, parsedFieldCount);
    });
  }

  private reorderEntitiesBasedOnAST(ast: DocumentNode) {
    const expectedOrder: string[] = [];

    const recordPath = (node: any, parentPath: string = '') => {
      if (node.kind === 'Field') {
        const currentPath = parentPath ? `${parentPath}.${node.name.value}` : node.name.value;
        expectedOrder.push(currentPath);
        if (node.selectionSet && node.selectionSet.selections) {
          node.selectionSet.selections.forEach((childNode: any) => recordPath(childNode, currentPath));
        }
      }
    };

    ast.definitions.forEach((definition) => {
      if (definition.kind === 'OperationDefinition' || definition.kind === 'FragmentDefinition') {
        definition.selectionSet.selections.forEach((selection: any) => recordPath(selection));
      }
    });

    this.gqlEntities.sort((a, b) => {
      const indexA = expectedOrder.indexOf(a.path);
      const indexB = expectedOrder.indexOf(b.path);
      return indexA - indexB;
    });
  }

  /**
   * Traverses and counts fields in the AST.
   * @param node - The current node in the AST traversal.
   * @param parsedFieldCount - The counter for parsed fields.
   */
  private traverseFields(node: any, parsedFieldCount: number) {
    if (node.kind === 'Field') {
      parsedFieldCount++;
    }
    if (node.selectionSet && node.selectionSet.selections) {
      node.selectionSet.selections.forEach((childNode: any) => this.traverseFields(childNode, parsedFieldCount));
    }
  }

  private processAdditions(
    ast: DocumentNode,
    parentStack: (GqlOperation | GqlField)[],
    parsedEntities: GqlEntityBase[],
    pathToEntityMap: Map<string, GqlEntityBase>,
    added: GqlEntityBase[],
    viewPort: ViewPort,
  ) {
    // Traverse each definition in the AST
    ast.definitions.forEach((definition) => {
      if (definition.kind === 'OperationDefinition') {
        const operationName = (definition.name && definition.name.value) || '';
        let operation = this.gqlEntities[0];
        if (!operation) {
          // If the operation doesn't exist, create and add it.
          operation = GqlOperation.parse({
            id: sid(),
            name: operationName,
            position: { x: 0, y: 0 },
            scopes: this.scopes,
          });
          operation.parent = this;
          this.addGqlEntity(operation);
          added.push(operation);
          pathToEntityMap.set(operation.path, operation);
        } else {
          this.gqlEntities[0].name = operationName;
        }

        parsedEntities.push(operation);
        parentStack.push(operation);

        // Traverse fields within the operation definition
        this.traverseAdditions(definition, parentStack, parsedEntities, pathToEntityMap, added, viewPort);

        parentStack.pop();
      }
    });
  }

  private traverseAdditions(
    node: any,
    parentStack: (GqlOperation | GqlField)[],
    parsedEntities: GqlEntityBase[],
    entityMap: Map<string, GqlEntityBase>,
    added: GqlEntityBase[],
    viewPort: ViewPort = undefined,
  ) {
    if (node.kind === 'Field') {
      const fieldName = node.name.value;
      const parent = parentStack[parentStack.length - 1];
      let field: GqlField;

      // Determine the path for the field
      const fieldPath = this.getPathForField(parent, fieldName);

      // Look up the field using the path instead of name
      field = entityMap.get(fieldPath) as GqlField;

      if (!field) {
        // Create a new field if it doesn't exist in the entityMap
        field = GqlField.parse({
          id: sid(),
          name: fieldName,
          position: { x: 0, y: 0 },
          scopes: this.scopes,
        });

        if (parent) {
          field.parent = parent;
          this.addGqlEntity(field, viewPort);
          added.push(field);
          entityMap.set(fieldPath, field);
        }
      }

      parsedEntities.push(field);
      parentStack.push(field);
    }

    // Traverse child fields if there are any sub-selections
    if (node.selectionSet && node.selectionSet.selections) {
      node.selectionSet.selections.forEach((childNode: any) =>
        this.traverseAdditions(childNode, parentStack, parsedEntities, entityMap, added),
      );
    }

    if (node.kind === 'Field') {
      parentStack.pop();
    }
  }

  public syncGqlEntitiesToQuery(): string {
    try {
      const entityMap = new Map<string, GqlEntityBase[]>();
      this.gqlEntities.forEach((entity) => {
        const parentKey = entity.parent ? entity.parent.id : 'root';
        if (!entityMap.has(parentKey)) {
          entityMap.set(parentKey, []);
        }
        entityMap.get(parentKey)!.push(entity);
      });
      const rootOperation = this.gqlEntities.find((entity) => entity instanceof GqlOperation);
      if (!rootOperation) {
        return this.queryText;
      }
      const operationNode: OperationDefinitionNode = {
        kind: Kind.OPERATION_DEFINITION,
        operation: OperationTypeNode.QUERY,
        name: { kind: Kind.NAME, value: rootOperation.name },
        selectionSet: {
          kind: Kind.SELECTION_SET,
          selections: this.buildSelections(rootOperation, entityMap),
        },
      };
      const documentNode: DocumentNode = {
        kind: Kind.DOCUMENT,
        definitions: [operationNode],
      };
      this.storePositionsInAst(documentNode);
      this.queryText = print(this.removePositionFieldsFromAst(documentNode));
      this.ast = JSON.stringify(documentNode);
      return this.queryText;
    } catch (error) {
      return this.queryText;
    }
  }

  static parse(data: object): Query {
    return super.parseBase<Query>(data, Query.schema);
  }

  static references = ['upload', 'gqlEntities'];

  serialize(reference: boolean = false): unknown {
    if (reference) return super.serialize(reference);
    return {
      ...(super.serialize() as any),
      upload: this?.upload?.serialize(true),
      gqlEntities: this.gqlEntities.map((gqlEntities) => gqlEntities.serialize(true)),
      ast: this.ast,
      annotation: this.annotation,
    };
  }

  private handleEmptyQuery(query: string): EntityChangeset {
    const added: GqlEntityBase[] = [];
    const deleted: GqlEntityBase[] = [];
    const updated: UpdatedEntity[] = [];

    if (!query.trim()) {
      const entitiesToDelete = [...this.gqlEntities];
      entitiesToDelete.forEach((entity) => {
        this.removeGqlEntity(entity);
        deleted.push(entity);
      });
      this.gqlEntities = [];

      return { added, updated, deleted };
    }
    return undefined;
  }

  private handleEmptyOperation(query: string): EntityChangeset {
    const added: GqlEntityBase[] = [];
    const deleted: GqlEntityBase[] = [];
    const updated: UpdatedEntity[] = [];

    const emptyOperationMatch = query.match(/query\s+(\w+)\s*\{\s*\}/);
    if (emptyOperationMatch) {
      const operationName = emptyOperationMatch[1];
      const operationEntity = this.gqlEntities.find(
        (entity) => entity instanceof GqlOperation && entity.name === operationName,
      ) as GqlOperation;

      if (operationEntity) {
        const operationFields = this.gqlEntities.filter((entity) => entity.parent === operationEntity);
        operationFields.forEach((field) => {
          this.removeGqlEntity(field);
          deleted.push(field);
        });
      }

      return { added, updated, deleted };
    }
  }

  private storePositionsInAst(documentNode: DocumentNode): void {
    visit(documentNode, {
      Field: (node) => {
        const entity = this.gqlEntities.find((e) => e.name === node.name.value);
        if (entity && entity.position) {
          (node as any)._position = entity.position;
        }
      },
    });
  }

  private removePositionFieldsFromAst(documentNode: DocumentNode): DocumentNode {
    const cleanedDocumentNode = JSON.parse(JSON.stringify(documentNode));
    visit(cleanedDocumentNode, {
      Field: (node) => {
        if ((node as any)._position) {
          delete (node as any)._position;
        }
      },
    });
    return cleanedDocumentNode;
  }

  /**
   * Recursively build the selection set for a given parent entity using the entity map.
   * @param parent The parent entity whose selection set is being built.
   * @param entityMap The map containing parent-child relationships for the entities.
   * @returns An array of Field nodes representing the selection set.
   */
  private buildSelections(parent: GqlEntityBase, entityMap: Map<string, GqlEntityBase[]>): any[] {
    const selections = [];

    const childEntities = entityMap.get(parent.id) || [];
    for (const entity of childEntities) {
      if (entity instanceof GqlField) {
        const fieldNode = {
          kind: Kind.FIELD,
          name: { kind: Kind.NAME, value: entity.name.split('.').pop()! },
          selectionSet: undefined,
        };

        if (entityMap.has(entity.id)) {
          fieldNode.selectionSet = {
            kind: Kind.SELECTION_SET,
            selections: this.buildSelections(entity, entityMap),
          };
        }
        (fieldNode as any)._position = entity.position;

        selections.push(fieldNode);
      }
    }
    return selections;
  }

  private getNextEntityPosition(viewPort: ViewPort = undefined): { x: number; y: number } {
    const PADDING = 40; // Padding between entities
    const ENTITY_WIDTH = 200; // The width of each grid cell
    const ENTITY_HEIGHT = 200; // The height of each grid cell
    const MAX_COLUMNS = 5; // Maximum number of columns before wrapping to the next row

    // Calculate initial positions based on entity count
    const entityCount = this.gqlEntities.filter((e) => e instanceof GqlField && e.isLeaf).length;

    let column = entityCount % MAX_COLUMNS;
    let row = Math.floor(entityCount / MAX_COLUMNS);

    // Initialize default position
    let x = column * (ENTITY_WIDTH + PADDING);
    let y = row * (ENTITY_HEIGHT + PADDING);

    // Detect collisions and adjust position
    let collisionDetected = this.isPositionColliding(x, y);
    while (collisionDetected) {
      // Move to the right in the grid
      column += 1;

      // Wrap to the next row if we exceed the maximum columns
      if (column >= MAX_COLUMNS) {
        column = 0;
        row += 1;
      }

      // Update x and y based on new column and row
      x = column * (ENTITY_WIDTH + PADDING);
      y = row * (ENTITY_HEIGHT + PADDING);
      collisionDetected = this.isPositionColliding(x, y);
    }
    return { x, y };
  }

  private isPositionColliding(x: number, y: number): boolean {
    const COLLISION_THRESHOLD = 50; // Minimum distance between entities to avoid collision
    return this.gqlEntities
      .filter((e) => e instanceof GqlField && e.isLeaf)
      .some(
        (entity) =>
          Math.abs(entity.position.x - x) < COLLISION_THRESHOLD &&
          Math.abs(entity.position.y - y) < COLLISION_THRESHOLD,
      );
  }

  private countAstFieldNodes(ast: DocumentNode): number {
    let fieldCount = 0;

    function traverse(node: any) {
      if (node.kind === 'Field') {
        fieldCount++;
      }

      if (node.selectionSet && node.selectionSet.selections) {
        node.selectionSet.selections.forEach((selection: any) => traverse(selection));
      }
    }

    ast.definitions.forEach((definition) => {
      if (definition.kind === 'OperationDefinition' || definition.kind === 'FragmentDefinition') {
        traverse(definition);
      }
    });

    return fieldCount;
  }
}
