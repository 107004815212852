import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import fetch from 'cross-fetch';
import { setContext } from '@apollo/client/link/context';
import { getToken } from '../../utils/getToken';
import { onError } from '@apollo/client/link/error';
import { authStatusVar } from '../../state/state';
import { isDev } from '../../utils';

export const getApolloHost = () => {
  if (window.location.hostname === 'app.narrative.tech') {
    return 'https://api-main.narrative.tech/graphql';
  }
  const onProd = window.location.hostname.match(/app.xspecs.ai/);
  const onEnv = window.location.hostname.match(/.xspecs.io/);
  return onProd
    ? 'https://api-main.narrative.tech/graphql'
    : onEnv
    ? `https://api-${window.location.hostname}/graphql`
    : 'http://localhost:4000/graphql';
};

if (isDev) window.__DEV__ = false;

const httpLink = createHttpLink({
  uri: getApolloHost(),
  fetch,
});

const authLink = setContext(async (_, { headers }) => {
  const token = getToken();
  return { headers: { ...headers, authorization: token ? `Bearer ${getToken()}` : '' } };
});

const onGQLErrorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (const error of graphQLErrors) {
      const { message } = error;
      if (message === 'unauthorized') {
        authStatusVar({ status: 'unauthorized' });
        break;
      }
      if (message === 'expired') {
        authStatusVar({ status: 'expired' });
        break;
      }
    }
  }
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, onGQLErrorLink, httpLink]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});
