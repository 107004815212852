import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { Attachment } from '../../entities/assets/Attachment';
import { Upload } from '../../entities/assets/Upload';

interface ToggleAttachmentsExpandedCommandParams extends IParams {
  id: string;
}

export class ToggleAttachmentsExpandedEvent extends EventBase {
  static eventType = 'ToggleAttachmentsExpandedEvent';

  constructor(
    public readonly params: ToggleAttachmentsExpandedCommandParams,
    public readonly source = ToggleAttachmentsExpandedCommand,
  ) {
    super();
  }
}

export class ToggleAttachmentsExpandedCommand extends CommandBase<ToggleAttachmentsExpandedCommandParams> {
  execute(params: ToggleAttachmentsExpandedCommandParams): ToggleAttachmentsExpandedEvent | CommandError {
    const attachment = this.model.entityRepository.get<Attachment>(params.id);
    if (!(attachment instanceof Attachment))
      return CommandError.of(new Error('Trying to toggle an attachment that is not an attachment'), 'error');
    const asset = attachment.asset;
    if (!(asset instanceof Upload)) return;
    attachment.preview.isVisible = !attachment.preview.isVisible;
    this.model.entityRepository.update(attachment.preview);
    this.model.entityRepository.update(asset);
    this.model.entityRepository.update(attachment);
    return new ToggleAttachmentsExpandedEvent(params);
  }
}
