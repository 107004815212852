import { ControlButton, Controls, ReactFlowState, useReactFlow, useStore } from '@xyflow/react';
import { CenterFocusStrong, ZoomIn, ZoomOut } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import { ReactNode, useCallback } from 'react';
import { shallow } from 'zustand/shallow';
import { useKeySymbols } from '../../../../hooks/useKeySymbols';

const selector = (s: ReactFlowState) => ({
  minZoomReached: s.transform[2] <= s.minZoom,
  maxZoomReached: s.transform[2] >= s.maxZoom,
});

export const CanvasControls = () => {
  const { formatMessage: f } = useIntl();

  const { altKey, modKey } = useKeySymbols();
  const { zoomIn, zoomOut, fitView } = useReactFlow();
  const { minZoomReached, maxZoomReached } = useStore(selector, shallow);

  const onZoomIn = useCallback(() => {
    zoomIn();
  }, [zoomIn]);

  const onZoomOut = useCallback(() => {
    zoomOut();
  }, [zoomOut]);

  const onFitView = useCallback(() => {
    fitView();
  }, [fitView]);

  return (
    <Controls showFitView={false} showInteractive={false} showZoom={false}>
      <CanvasControlsButton
        icon={<ZoomIn />}
        onClick={onZoomIn}
        tooltip={`${f({ id: 'zoom-in' })} ( ${modKey} + )`}
        disabled={maxZoomReached}
      />
      <CanvasControlsButton
        icon={<ZoomOut />}
        onClick={onZoomOut}
        tooltip={`${f({ id: 'zoom-out' })} ( ${modKey} - )`}
        disabled={minZoomReached}
      />
      <CanvasControlsButton
        icon={<CenterFocusStrong />}
        onClick={onFitView}
        tooltip={f({ id: 'fit-view' }) + ` ( ${altKey} + 1 )`}
      />
    </Controls>
  );
};

type CanvasControlsButtonProps = {
  icon: ReactNode;
  onClick: () => void;
  tooltip: string;
  disabled?: boolean;
};

const CanvasControlsButton = (props: CanvasControlsButtonProps) => {
  const { icon, onClick, tooltip, disabled } = props;

  return (
    <Tooltip title={tooltip} placement="right" disableInteractive>
      <Box bgcolor="background.paper">
        <ControlButton onClick={onClick} disabled={disabled} aria-label={tooltip}>
          {icon}
        </ControlButton>
      </Box>
    </Tooltip>
  );
};
