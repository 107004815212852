import { IconComponentProps } from '../icon';
import { FC } from 'react';

export const Actor: FC<IconComponentProps> = () => {
  return (
    <>
      <circle cx="12" cy="12" r="10" fill="#FFF9B1" />
      <circle cx="12" cy="12" r="9.5" stroke="black" strokeOpacity="0.54" />
      <path
        d="M12 11.9999C13.2892 11.9999 14.3333 10.9558 14.3333 9.66659C14.3333 8.37742 13.2892 7.33325 12 7.33325C10.7108 7.33325 9.66666 8.37742 9.66666 9.66659C9.66666 10.9558 10.7108 11.9999 12 11.9999ZM12 13.1666C10.4425 13.1666 7.33333 13.9483 7.33333 15.4999V16.6666H16.6667V15.4999C16.6667 13.9483 13.5575 13.1666 12 13.1666Z"
        fill="black"
        fillOpacity="0.54"
      />
    </>
  );
};
