import { IconComponentProps } from '../icon';
import { FC } from 'react';

export const KeyValuePairs: FC<IconComponentProps> = () => {
  return (
    <>
      <path
        d="M0 1.5C0 0.671573 0.671573 0 1.5 0H4.5C5.32843 0 6 0.671573 6 1.5V4.5C6 5.32843 5.32843 6 4.5 6H1.5C0.671573 6 0 5.32843 0 4.5V1.5Z"
        fill="black"
        fillOpacity="0.6"
      />
      <path d="M1.5 1.5H4.5V4.5H1.5V1.5Z" fill="white" />
      <path
        d="M9 1.5C9 0.671573 9.67157 0 10.5 0H16.5C17.3284 0 18 0.671573 18 1.5V4.5C18 5.32843 17.3284 6 16.5 6H10.5C9.67157 6 9 5.32843 9 4.5V1.5Z"
        fill="black"
        fillOpacity="0.6"
      />
      <path d="M10.5 1.5H16.5V4.5H10.5V1.5Z" fill="white" />
      <path d="M6 2.25H9V3.75H6V2.25Z" fill="black" fillOpacity="0.6" />
      <path
        d="M0 8.5C0 7.67157 0.671573 7 1.5 7H4.5C5.32843 7 6 7.67157 6 8.5V11.5C6 12.3284 5.32843 13 4.5 13H1.5C0.671573 13 0 12.3284 0 11.5V8.5Z"
        fill="black"
        fillOpacity="0.6"
      />
      <path d="M1.5 8.5H4.5V11.5H1.5V8.5Z" fill="white" />
      <path
        d="M9 8.5C9 7.67157 9.67157 7 10.5 7H16.5C17.3284 7 18 7.67157 18 8.5V11.5C18 12.3284 17.3284 13 16.5 13H10.5C9.67157 13 9 12.3284 9 11.5V8.5Z"
        fill="black"
        fillOpacity="0.6"
      />
      <path d="M10.5 8.5H16.5V11.5H10.5V8.5Z" fill="white" />
      <path d="M6 9.25H9V10.75H6V9.25Z" fill="black" fillOpacity="0.6" />
      <path
        d="M0 15.5C0 14.6716 0.671573 14 1.5 14H4.5C5.32843 14 6 14.6716 6 15.5V18.5C6 19.3284 5.32843 20 4.5 20H1.5C0.671573 20 0 19.3284 0 18.5V15.5Z"
        fill="black"
        fillOpacity="0.6"
      />
      <path d="M1.5 15.5H4.5V18.5H1.5V15.5Z" fill="white" />
      <path
        d="M9 15.5C9 14.6716 9.67157 14 10.5 14H16.5C17.3284 14 18 14.6716 18 15.5V18.5C18 19.3284 17.3284 20 16.5 20H10.5C9.67157 20 9 19.3284 9 18.5V15.5Z"
        fill="black"
        fillOpacity="0.6"
      />
      <path d="M10.5 15.5H16.5V18.5H10.5V15.5Z" fill="white" />
      <path d="M6 16.25H9V17.75H6V16.25Z" fill="black" fillOpacity="0.6" />
    </>
  );
};
