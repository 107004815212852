import { Box } from '@mui/material';
import { Label } from '@xspecs/single-source-model';
import { LabelsStackItem } from './item/labels-stack-item';
import { useMemo } from 'react';

type LabelsStackProps = {
  labels: Label[];
  hasOverlay: boolean;
};

export const LabelsStack = (props: LabelsStackProps) => {
  const { labels, hasOverlay } = props;

  const sx = useMemo(
    () => ({
      display: 'inline-flex',
      width: '100%',
      flexFlow: 'wrap',
      gap: 0.5,
    }),
    [],
  );

  return (
    <Box>
      <Box display="inline-block">
        <Box sx={sx}>
          {labels.map((label) => {
            if (!label) return;
            return <LabelsStackItem key={label.id} {...label} hasOverlay={hasOverlay} />;
          })}
        </Box>
      </Box>
    </Box>
  );
};
