import { Box, Slide } from '@mui/material';
import { useIntl } from 'react-intl';
import { useCallback, useRef, useState } from 'react';
import { FiltersButtonMenuList } from './list/filters-button-menu-list';
import { LabelsSelector, LabelsSelectorProps } from '../../../labels/labels-selector/labels-selector';
import { FiltersListProps } from '../../filters-list/filters-list';

enum View {
  Default,
  Labels,
}

export type FiltersButtonMenuProps = {
  labelsSelectorProps: Omit<LabelsSelectorProps, 'hideManageLabels' | 'hideHeader' | 'placeholder'>;
  filtersListProps?: FiltersListProps;
};

export const FiltersButtonMenu = (props: FiltersButtonMenuProps) => {
  const { labelsSelectorProps, filtersListProps } = props;

  const { formatMessage: f } = useIntl();

  const [view, setView] = useState<View>(View.Default);

  const onLabelsSelect = useCallback(() => {
    setView(View.Labels);
  }, []);

  const rootRef = useRef<HTMLDivElement>(null);

  return (
    <Box ref={rootRef} sx={{ overflow: 'hidden' }}>
      {view === View.Labels ? (
        <Slide in direction="left" container={rootRef.current} mountOnEnter unmountOnExit>
          <Box>
            <LabelsSelector
              {...labelsSelectorProps}
              hideHeader
              hideManageLabels
              placeholder={f({ id: 'label-name' })}
            />
          </Box>
        </Slide>
      ) : null}
      {view === View.Default ? (
        <FiltersButtonMenuList onLabelsSelect={onLabelsSelect} filtersListProps={filtersListProps} />
      ) : null}
    </Box>
  );
};
