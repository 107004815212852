import { AssetBase } from './AssetBase';
import { EntityType } from '../EntityType';

export class Actor extends AssetBase {
  get type(): EntityType {
    return EntityType.Actor;
  }

  static parse(data: unknown): Actor {
    return AssetBase.parseBase.call(Actor, data, Actor.baseSchema);
  }
}
