import { EntityType } from '../EntityType';
import { GqlEntityBase } from './GqlEntityBase';

export class GqlField extends GqlEntityBase {
  public type: EntityType = EntityType.GqlField;

  isValid(): boolean {
    return GqlEntityBase.baseSchema.safeParse(this).success;
  }

  get isLeaf(): boolean {
    return !this.query?.gqlEntities.some((entity) => entity.parent === this);
  }

  static parse(data: unknown): GqlField {
    return GqlEntityBase.parseBase.call(GqlField, data);
  }
}
