interface Dimensions {
  width: number;
  height: number;
}

export const resizeImage = (originalDimensions: Dimensions, targetWidth: number = 300): Dimensions => {
  const { width, height } = originalDimensions;
  const ratio = height / width;
  const newHeight = Math.round(targetWidth * ratio);
  return {
    width: targetWidth,
    height: newHeight,
  };
};
