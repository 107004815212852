import { FC, useState } from 'react';
import { Box, Button, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useSingleSourceStore } from '../../../../store/single-source-store/single-source-store';
import { Modal } from '../../../modal/modal';

export const ProposeSchemaChangesModal: FC = () => {
  const showProposeSchemaChangesModal = useSingleSourceStore.use.showProposeSchemaChangesModal();
  const setShowProposeSchemaChangesModal = useSingleSourceStore.use.setShowProposeSchemaChangesModal();

  const [title, setTitle] = useState('');
  const [variant, setVariant] = useState('');
  const [reason, setReason] = useState('');

  const onClose = () => setShowProposeSchemaChangesModal(false);

  const handleSubmit = () => {
    onClose();
  };

  return (
    <Modal
      id="ProposeSchemaChangesModal"
      isOpen={showProposeSchemaChangesModal}
      onClose={onClose}
      headerStartContent={
        <Stack flexGrow={1}>
          <Typography variant="h6">Propose schema changes</Typography>
        </Stack>
      }
    >
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="body2" color="text.secondary">
          Schema proposals give teams a collaborative space to evolve their graphs. Edit subgraph schemas, start
          discussions, and request reviews from your teammates.
        </Typography>
        <TextField
          label="Proposal title"
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
          fullWidth
          helperText='e.g. "Add fields to type User"'
        />

        <TextField
          select
          label="Source variant"
          value={variant}
          onChange={(e) => setVariant(e.target.value)}
          fullWidth
          required
        >
          <MenuItem value="Variant 1">Variant 1</MenuItem>
          <MenuItem value="Variant 2">Variant 2</MenuItem>
          <MenuItem value="Variant 3">Variant 3</MenuItem>
        </TextField>

        <TextField
          label="Why are you creating this proposal?"
          variant="outlined"
          multiline
          rows={4}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          fullWidth
          helperText={`Give your team context about your proposal, including the proposal's intent. For example, "Adding new fields to type X because of Y."`}
        />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
          <Button variant="outlined" color="error" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Create proposal
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
